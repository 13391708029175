import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import ProfileTopbarCustomer from "../../Components/TopBar/ProfileTopbarCustomer";
import MobileTopBarCustomer from "../../Components/MobileTopBar/MobileTopBarCustomer";
import MobileNavBarCustomer from "../../Components/MobileNavBar/MobileNavBarCustomer";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
const CustomerNotificationLayout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  //for loading effect used this useEffect
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
     <><div style={{backgroundColor:'#F7FAFB'}}>
          <Grid>
            <div className="d-none d-md-block">
              <ProfileTopbarCustomer />
            </div>
            <div className="d-block d-sm-none">
              <MobileTopBarCustomer />
            </div>
          </Grid>
          <div
            className="container profile-layout-padding-class"
            style={{ paddingTop: "5.5rem"}}
          >
            <Grid className="" >
            {isLoading ? (
         <div className="hhloader-div">
         <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
         <Typewriter
           options={{
             strings: ["Loading..."],
             autoStart: true,
             loop: true,
             delay: 30,
           }}
           className="loading-text-in-loader"
         />
       </div>
      ) : (
        
        <>{children}</>
      )}
              
             
            </Grid>
          </div>
          

          <Grid>
            <div className="d-none d-md-block">
              <Footer />
            </div>
            <div className="d-block d-sm-none">
              <MobileNavBarCustomer />
            </div>
          </Grid>
          </div>
        </>
      
    </>
  );
};

export default CustomerNotificationLayout;
