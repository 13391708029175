import React, { useState, useRef, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import CreatePostEmojiTextArea from "../CreatePostEmojiTextArea/CreatePostEmojiTextArea";
import "./CustomerCreatePostButton.css";
import camera from "../../../Assets/Svg/cam-vector.png";
import imageUpload from "../../../Assets/Svg/camera-vector.svg";
import DropdownWithCheckBox from "../DropdownWithCheckBox/DropdownWithCheckBox";
import Webcam from "react-webcam";
import captureIcon from "../../../Assets/Images/capture-cam-icon.png";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_CREATE_POST } from "../../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import Dropzone from "react-dropzone";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";

const CreatePostModalPopup = ({
  createPostPopupOpen,
  setCreatePostPopupOpen,
  imgSrc,
  setImgSrc,
  customerProfileImage,
}) => {
  const [showEmojiTextArea, setShowEmojiTextArea] = useState(true);
  const [showImageIconDropZoneArea, setShowImageIconDropZoneArea] =
    useState(false);
  const [showCameraIconDropZoneArea, setShowCameraIconDropZoneArea] =
    useState(false);
  const [showCapturedImage, setShowCapturedImage] = useState(false);
  const [cameraDropZoneBox, setCameraDropZoneBox] = useState(false);
  const webcamRef = useRef(null);
  const [filePreviews, setFilePreviews] = useState([]);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [text, setText] = useState("");
  const [selectedFileFromDropZone, setSelectedFileFromDropZone] =
    useState(null);
  const [capturedImgSrc, setCapturedImgSrc] = useState(null);
  const [resizedPreviews, setResizedPreviews] = useState([]);
  const [previewDropedFile, setPreviewDropedFile] = useState(true);
  const [previewImageUploadByFile, setPreviewImageUploadByFile] = useState(null);
  console.log("selectedFileFromDropZone", selectedFileFromDropZone);
  const [isLoading, setIsLoading] = useState(true);

  const Token = localStorage.getItem("token");
  console.log(Token);
  console.log(imgSrc);
  const closeCamToTakePicture = () => {
    setShowCapturedImage(!!imgSrc);
    setImgSrc(!!capturedImgSrc ? capturedImgSrc : imgSrc);
    setCameraDropZoneBox(false);
    setShowCameraIconDropZoneArea(false);
  };

  const capturePicture = useCallback(() => {
    if (webcamRef.current) {
      const capturedImgSrc = webcamRef.current.getScreenshot();
      setImgSrc(capturedImgSrc);
      setCapturedImgSrc(capturedImgSrc);
      setShowCameraIconDropZoneArea(false);
      setShowCapturedImage(true);
    }
  }, [webcamRef, setImgSrc, setCapturedImgSrc, setShowCapturedImage]);

  const retake = () => {
    setImgSrc(null);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
    setCameraDropZoneBox(true);
    setShowCameraIconDropZoneArea(true);
  };
  const uploadImageInCreatePostVariable =
    selectedFileFromDropZone && capturedImgSrc;
  console.log(
    "uploadImageInCreatePostVariable",
    uploadImageInCreatePostVariable
  );
  console.log("capturedImgSrc", capturedImgSrc);
  const isTextAreaEmpty = text.trim() === "";

  const showDropZoneImage = () => {
    setShowImageIconDropZoneArea(true);
    setShowEmojiTextArea(true);
    setShowCameraIconDropZoneArea(false);
    setCameraDropZoneBox(false);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
  };

  const openCamToTakePicture = () => {
    setTimeout(() => {
      setShowCameraIconDropZoneArea(false);
      setShowEmojiTextArea(true);
      setShowImageIconDropZoneArea(false);
      setIsLoading(false);
      setCameraDropZoneBox(true);
    }, 2000);
  };
  const onClickCameraDropZoneOpenCamera = () => {
    setShowCameraIconDropZoneArea(true);
    setIsLoading(false);
  };


  const closeModalForCreatePost = () => {
    setCreatePostPopupOpen(false);
  };
  console.log("imgSrc", imgSrc);

  const handleDrop = (acceptedFiles) => {
    console.log("Accepted files:", acceptedFiles);

    const updatedPreviews = [];

    acceptedFiles.forEach((file) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (resizedFile) => {
          console.log("Resized file:", resizedFile);
          const preview = URL.createObjectURL(resizedFile);
          console.log(
            "setPreviewImageUploadByFile",
            setPreviewImageUploadByFile
          );

          updatedPreviews.push({ file: resizedFile, preview });

          if (updatedPreviews.length === acceptedFiles.length) {
            setSelectedFileFromDropZone(updatedPreviews);
            setPreviewImageUploadByFile(preview);
          }
        },
        "blob",
        500,
        500
      );
    });
  };

  const onClickHandleUpload = async () => {
    const Token = localStorage.getItem("token");

    const formData = new FormData();
    if (!optionSelected) {
      toast.error("Please select a value for maid, nanny, or carer.");
      return;
    }
    formData.append("community", optionSelected.value);
    formData.append("description", text);
    if (isTextAreaEmpty) {
      // If text area is empty, return without performing the upload action
      setShowDescriptionError(true);
      return;
    }

    if (selectedFileFromDropZone) {
      console.log(selectedFileFromDropZone);
      selectedFileFromDropZone.forEach((file) => {
        const blob = new Blob([file.file], { type: "image/jpeg" });

        formData.append("postImage", blob, "filename.jpg");
      });
    } else if (imgSrc) {
      console.log("capturedImgSrc");
      console.log("imgSrc", imgSrc);

      function base64ToBlob(base64Data, contentType) {
        const base64WithoutPrefix = base64Data.replace(
          /^data:[^;]+;base64,/,
          ""
        );
        const byteCharacters = atob(base64WithoutPrefix);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
      }
      const contentType = "image/png";

      const capturedBlob = base64ToBlob(imgSrc, contentType);
      console.log("capturedBlob", capturedBlob);
      formData.append("postImage", capturedBlob);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
        // "Content-Type": "image/jpeg",
      },
    };

    axios
      .post(`${BaseUrl}${CUSTOMER_CREATE_POST}`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          console.log("Post created successfully");
          toast.info("Your post is created successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          // setCreatePostPopupOpen(false);
          // setPreviewImageUploadByFile(true);
          // setShowCameraIconDropZoneArea(false);
        } else {
          console.log("Error creating post");
          toast.error("Error in creating post");
        }
      })
      .catch((error) => {
        console.log("Error creating post", error);
      });
  };

  const onClickUploadButton = () => {
    onClickHandleUpload();
    setShowCameraIconDropZoneArea(false);
    setShowCapturedImage(false);
  };

  const showPreviewDropedFile = () => {
    setPreviewDropedFile(true);
  };

  return (
    <div className="">
      <Modal
        show={createPostPopupOpen}
        onHide={closeModalForCreatePost}
        className="custom-create-post-modal centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="custom-modal-title">
            Create a Post
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="ch-cp-popup-drodown-div">
            <img
              src={customerProfileImage ?? DefaultProfilePic}
              alt=""
              className="ch-cp-profile-img psb-profile-img psb-profile-img-placeholder"
            />
            <DropdownWithCheckBox
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
            />
            {console.log("optionSelected", optionSelected)}
          </div>
          {showEmojiTextArea && (
            <div>
              <p className="ch-cp-modal-popup-text">
                Description(Please be as detailed as possible)
              </p>
              {showDescriptionError && (
                <p className="ch-cp-description-error">
                  *Description cannot be empty.
                </p>
              )}
              <CreatePostEmojiTextArea text={text} setText={setText} />

              {console.log("text", text)}
            </div>
          )}
          {showImageIconDropZoneArea && (
            <div className="ch-cp-camera-image-upload-dropzone">
              <Dropzone onDrop={handleDrop} accept="image/jpeg, image/png">
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="ch-cp-image-dropzone">
                      {previewImageUploadByFile ? (
                        <div className="d-flex flex-column">
                          {/* <img src={removeUploadedFileIcon} alt="removeuploadedfile" className="ch-cp-remove-uploaded-file-icon"/> */}
                          <img
                            src={previewImageUploadByFile}
                            alt="Preview"
                            className="ch-cp-preview-image-upload-by-file ch-cp-image-dropzone-text"
                          />
                        </div>
                      ) : (
                        <>
                          <p className="ch-cp-image-dropzone-text">
                            Drag and drop an image or
                          </p>
                          <button
                            className="ch-cp-drop-zone-button"
                            onClick={showPreviewDropedFile}
                          >
                            {" "}
                            Upload{" "}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
              {previewDropedFile && (
                <div className="dropzone-preview">
                  {resizedPreviews.map((preview, index) => (
                    <img
                      key={index}
                      src={preview.preview}
                      alt="Preview"
                      className="preview-image"
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          {cameraDropZoneBox && !imgSrc && (
            <div className="ch-cp-camera-image-upload-dropzone">
              <p
                className="ch-cp-image-dropzone-text"
                onClick={onClickCameraDropZoneOpenCamera}
              >
                Click to start camera
              </p>
            </div>
          )}
          {showCameraIconDropZoneArea && (
            <div>
              {isLoading ? (
                <div className="hhloader-div">
                  <div className="hhloader-div">
                    <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
                  </div>
                </div>
              ) : (
                <Modal
                  show={openCamToTakePicture}
                  onHide={closeCamToTakePicture}
                  className="custom-camera-modal centered"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="custom-modal-title">
                      Camera
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="custom-camera-modal-body">
                    <div className="ch-cp-camera-div">
                      {imgSrc ? (
                        <img src={imgSrc} alt="webcam" />
                      ) : (
                        <Webcam
                          height={341}
                          width={700}
                          ref={webcamRef}
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      <div className="btn-container"></div>
                    </div>{" "}
                  </Modal.Body>
                  <Modal.Footer className="custom-modal-footer">
                    <div className="">
                      <div>
                        {imgSrc ? (
                          <div>
                            <button
                              onClick={retake}
                              className="ch-cp-drop-zone-button"
                            >
                              Retake
                            </button>
                            <button
                              onClick={capturePicture}
                              className="ch-cp-drop-zone-button"
                            >
                              Upload
                            </button>
                          </div>
                        ) : (
                          <button onClick={capturePicture} className="">
                            <img
                              src={captureIcon}
                              alt=""
                              className="ch-cp-popup-vector"
                            />
                          </button>
                        )}
                      </div>
                      {/* <Button variant="primary">Post</Button> */}
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          )}
          {/* {showCapturedImage && (
            <div className="ch-cp-captured-image-container">
              <img
                src={imgSrc}
                alt="Captured"
                className="ch-cp-captured-image"
              />
            </div>
          )} */}
          {showCapturedImage && (
            <div className="ch-cp-captured-image-container">
              <img
                src={imgSrc}
                alt="Captured"
                className="ch-cp-captured-image"
              />
              <div className="ch-cp-captured-image-buttons">
                <button
                  onClick={retake}
                  className="ch-cp-drop-zone-button my-3 mx-auto"
                >
                  Retake
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <div className="ch-cp-popup-footer">
            <div>
              <button onClick={showDropZoneImage}>
                <img src={imageUpload} alt="" className="ch-cp-popup-vector" />
              </button>
              <button onClick={openCamToTakePicture}>
                <img src={camera} alt="" className="ch-cp-popup-vector" />
              </button>
            </div>
            <Button className="post-btn-create-post-popup" onClick={onClickUploadButton}>
              Post
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export { CreatePostModalPopup };
const CustomerCreatePostButton = ({ profileImageUrl }) => {
  const [imgSrc, setImgSrc] = useState(false);
  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);
  const openModalForCreatePost = () => {
    setCreatePostPopupOpen(true);
  };

  return (
    <div className="ch-create-post-button-box">
      <img src={profileImageUrl ?? DefaultProfilePic} alt="" className="ch-profile-img" />
      <button
        className="ch-create-post-button"
        onClick={openModalForCreatePost}
      >
        Create a post
      </button>

      <CreatePostModalPopup
        createPostPopupOpen={createPostPopupOpen}
        setCreatePostPopupOpen={setCreatePostPopupOpen}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        customerProfileImage={profileImageUrl}
      />
    </div>
  );
};
export default CustomerCreatePostButton;
