import React from "react";
import "./ConfirmPassword.css";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ConfirmPassword = ({register}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{position:"relative"}}>
      <label className="label-registration">
        Confirm Password <span className="error-symbol">*</span>
      </label>
      <input
        className="input-box-cpassword"
        type={showPassword ? "text" : "password"}
        placeholder="Your password"
        {...register}
      />
      {showPassword ? (
        <FaEye className="eye-icon" onClick={toggleShowPassword} style={{position:"absolute" , right:"10px" , top:"44px", color:"#667085"
        }}/>
      ) : (
        <FaEyeSlash  className="eye-icon" onClick={toggleShowPassword} style={{position:"absolute" , right:"10px" , top:"44px",  color:"#667085"}}/>
      )}
    </div>
  );
};
export default ConfirmPassword;
