import getUnreadMessagesCommentsCountApi from "../../servicesApi/getUnreadMessagesCommentsCountApi";

import {
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE,
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST,
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS,
} from "../constants/deleteNotificationsConstatnts";

export const getUnreadMessagesCommentsCountAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST });

      const response = await getUnreadMessagesCommentsCountApi();
      console.log("API Response Data api action cust count:", response);
      console.log("API Response Data api action cust count: response.data", response.data);

      if (response && response.status === 200) {
        dispatch({
          type: GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS,
          payload: {
            unreadMessages: response.data.unreadMessages,
            unreadComments: response.data.unreadComments,
          },        });
      } else {
        dispatch({
          type: GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE,
        payload: error,
      });
    }
  };
};
