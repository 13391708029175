import React, { useEffect, useState } from "react";
import ProfileLayoutCustomer from "../../../Layout/ProfileLayout/ProfileLayoutCustomer";
import "./CustomerProfile.css";
import CustomerProfileEdit from "../../../Components/Customer/CustomerProfileEdit/CustomerProfileEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_PROFILE } from "../../../ApiEndPoints/ApiEndPoints";
import parse from "html-react-parser";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CustomerProfile = () => {
  const navigate = useNavigate();

  const [showEditPage, setShowEditPage] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEditPage(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_PROFILE}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        // console.log(result.customerData, "result");
        setData(result.customerData);
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  }, [Token]);

  return (
    <ProfileLayoutCustomer showCameraIcon={true}>
      <div className="customer-profile-desktop">
        {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />{" "}
          </div>
        ) : (
          <div>
            {!showEditPage && (
              <div className="container cs-profile-main">
                <div className="cp-title-div pb-4">
                  <h3 className="cp-title">Profile</h3>
                  <button onClick={handleChangeEdit} className="cp-edit-btn">
                    Edit
                  </button>
                </div>
                <div className="cp-profile-card">
                  <h4>User Info</h4>
                  <hr className="cp-hr-line"></hr>
                  <div>
                    <div className="cp-label-div">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Name</p>
                      </div>
                      <div className="cp-text-div">
                        <p className="cp-text">{` ${data.firstName || ""} ${data.lastName || "" }`}</p>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Age</p>
                      </div>
                      <div className="cp-text-div">
                        <p className="cp-text">{data.age}</p>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Nationality</p>
                      </div>
                      <div className="cp-text-div">
                        <p className="cp-text">{data.nationality}</p>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Martial status</p>
                      </div>
                      <div className="cp-text-div">
                        <p className="cp-text">{data.maritalStatus}</p>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Religion</p>
                      </div>
                      <div className="cp-text-div">
                        <p className="cp-text">{data.religion}</p>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2" style={{alignItems:"normal"}}>
                      <div className="cp-label-div-part1">
                        <p className="cp-label">About</p>
                      </div>
                      <div className="cp-text-about-div">
                        <p className="cp-text-about-text">
                          {data.about && parse(data.about)}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showEditPage && (
              <CustomerProfileEdit
                prevdata={data}
                setShowEditPage={setShowEditPage}
              />
            )}
          </div>
        )}
      </div>
    </ProfileLayoutCustomer>
  );
};

export default CustomerProfile;
