import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import ProfileTopbarCustomer from "../../Components/TopBar/ProfileTopbarCustomer";
import ProfileSideBarCreatePost from "../../Components/CustomerCreatePost/ProfileSideBarCreatePost/ProfileSideBarCreatePost";
import MobileTopBarCustomer from "../../Components/MobileTopBar/MobileTopBarCustomer";
import MobileNavBarCustomer from "../../Components/MobileNavBar/MobileNavBarCustomer";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { RiArrowDropDownFill } from "react-icons/ri";
import "./CustomerCreatePostProfileLayout.css";
import { useLocation, useNavigate } from "react-router-dom";

const CustomerCreatePostProfileLayout = ({ children, handleDrop}) => {
  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const openModalForCreatePost = () => {
    setCreatePostPopupOpen(true);
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClickNavigate = (path) => {
    navigate(path);
  };
  const closeModalForCreatePost = () => {
    setCreatePostPopupOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="create-post-page">
      <Grid>
        <div className="d-none d-md-block">
          <ProfileTopbarCustomer />
        </div>

        <div className="d-block d-sm-none">
          <MobileTopBarCustomer />
        </div>
      </Grid>
      {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem"}}
        >
          <Grid container spacing={6} className="">
            <Grid item xs={12} md={3} lg={3}>
              <div className="d-none d-lg-block">
                {/* <ProfileSideBarCreatePost /> */}
                <ProfileSideBarCreatePost
                  createPostPopupOpen={createPostPopupOpen}
                  setCreatePostPopupOpen={setCreatePostPopupOpen}
                  openModalForCreatePost={openModalForCreatePost}
                  closeModalForCreatePost={closeModalForCreatePost} handleDrop={handleDrop}
                />
                <div className="psb-about-div pt-4">
                  <p onClick={() => handleClickNavigate("/about-us")}>About</p>
                  <p>Pricing</p>
                  <p onClick={() => handleClickNavigate("/contact-us")}>
                    Help Center
                  </p>
                </div>
                <p
                  className="psb-terms pt-4 pb-4"
                  onClick={() => handleClickNavigate("/termsofservice")}
                >
                  Terms & Privacy{" "}
                  <RiArrowDropDownFill
                    color={"#7B7B7B"}
                    size={35}
                    style={{ marginTop: "-3px" }}
                  />
                </p>
              </div>
            </Grid>
            {isLoading ? (
              <div className="hhloader-div">
                <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
                <Typewriter
                  options={{
                    strings: ["Loading..."],
                    autoStart: true,
                    loop: true,
                    delay: 30,
                  }}
                  className="loading-text-in-loader"
                />
              </div>
            ) : (
              <Grid item xs={12} md={9} lg={9}>
                {children}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <Grid>
        <div className="d-none d-md-block">
          <Footer />
        </div>
        <div className="d-block d-sm-none">
          <MobileNavBarCustomer />
        </div>{" "}
      </Grid>
    </div>
  );
};

export default CustomerCreatePostProfileLayout;
