import React, { useState } from "react";
import "./Password.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Password = ({ register, label }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ position: "relative" }}>
      <label className="label-registration">
        {label ? label : "Password"} <span className="error-symbol">*</span>
      </label>
      <input
        className="input-box-password"
        type={showPassword ? "text" : "password"}
        placeholder="Your password"
        {...register}
      />
      {showPassword ? (
        <FaEye onClick={toggleShowPassword} style={{position:"absolute" , right:"10px" , top:"44px", color:"#667085"
        }}/>
      ) : (
        <FaEyeSlash  onClick={toggleShowPassword} style={{position:"absolute" , right:"10px" , top:"44px",  color:"#667085"}}/>
      )}
    </div>
  );
};
export default Password;
