import React, { useEffect, useState } from "react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "swiper/css/navigation";
import { useRef } from "react";
import sectionbanner from "../../Assets/Images/sectionbanner.png";
import usercomment from "../../Assets/Svg/usercomment.svg";
import mobilebanner from "../../Assets/Images/mobilebanner.png";
import serviceImage from "../../Assets/Images/icons/serviceImage.png";
import serviceImage2 from "../../Assets/Images/icons/serviceImage2.png";
import serviceImage3 from "../../Assets/Images/icons/serviceImage3.png";
import latestjobwomen from "../../Assets/Images/latestjobwomen.png";
import Handshake from "../../Assets/Images/icons//Handshake.png";
import Onlineshop from "../../Assets/Images/icons/Onlineshop.png";
import Support from "../../Assets/Images/icons/Support.png";
import wood from "../../Assets/Images/wood.png";
import person4 from "../../Assets/Images/person/person4.png";
import mobilewood from "../../Assets/Images/mobilewood.png";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import TestimonalCard from "../../Components/TestimonalCard/TestimonalCard";
import LatestJoiningCard from "../../Components/LatestJoiningCard/LatestJoiningCard";
import parse from "html-react-parser";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  GET_LATEST_JOINING,
  GET_LATEST_JOB,
} from "../../ApiEndPoints/ApiEndPoints";
import JobTitleCard from "../../Components/JobTitleCard/JobTitleCard";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/swiper.min.css";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import NoJobsFoundImage from "../../Assets/Images/nojobsfound.png";
import { useResponsive } from "./../../CustomHooks/useResponsive/index";

const HomePage = () => {
  const [latestJoinee, setLatestJoinee] = useState([]);
  const [jobs, setJobs] = useState([]);
  const breakPoint = useResponsive([375, 576, 768, 1200]);

  let slidesPerView, spaceBetween, slidesPerViewCustomer, spaceBetweenCustomer;

  if (breakPoint <= 2) {
    slidesPerView = 1;
    slidesPerViewCustomer = 1;
    spaceBetween = 100;
    spaceBetweenCustomer = 100;
  } else if(breakPoint >= 2 && breakPoint <= 3) { 
    slidesPerView = 2;
    slidesPerViewCustomer = 2;
    spaceBetween = 100;
    spaceBetweenCustomer = 30;
  }else{
    slidesPerView = 4;
    slidesPerViewCustomer = 2;
    spaceBetween = 50;
    spaceBetweenCustomer = 30;
  }

  // const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);
  const { t } = useTranslation();

  const Token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${GET_LATEST_JOINING}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("LATEST-JOINIEE", result);
        // setIsLoading(false);
        setLatestJoinee(result.employees);
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${GET_LATEST_JOB}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("LATEST-JOB", data);
        // setIsLoading(false);
        setJobs(data.latestJob);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const slides = [
    {
      image: sectionbanner,
      text: t("translation.homeslide1text"),
      description: t("translation.homeslide1description"),
    },
  ];

  const nextSlide = () => {
    console.log("Next slide button clicked");
    swiperRef.current.swiper.slideNext();
  };

  // Function to handle previous slide
  const prevSlide = () => {
    swiperRef.current.swiper.slidePrev();
  };

  return (
    <>
      <StaticHomePageLayout>
        <div className="home-main-pg-container">
          <Swiper
            ref={swiperRef}
            loop={false}
            autoplay={{ delay: 4000 }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination, Navigation]}
            // onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {slides.map((slide, index) => (
              <SwiperSlide className="banner-swiper-image" key={index}>
                <div className="full-banner">
                  <div className="banner-area-home">
                    <img src={slide.image} alt="" className="picture-home-b" />
                    <img
                      src={mobilebanner}
                      alt=""
                      className="picture-mobile-home-b"
                    />

                    <div className="content-head-section-st">
                      <h1 className="b-1">{slide.text}</h1>
                      <p className="b-2">{slide.description}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <div className="below-banner"> */}
          {/* Custom arrow buttons */}
          {/* <div className="slider-btn">
              <div className="arrow-btn custom-prev-btn" onClick={prevSlide}>
                <AiOutlineArrowLeft />
              </div>
              <div className="arrow-btn custom-next-btn" onClick={nextSlide}>
                <AiOutlineArrowRight />
              </div>
            </div>
          </div> */}

          {/* <!-- Services Section --> */}

          <div
            id="services"
            className="services-main background-gradient-color"
          >
            <div className="services-head">
              <h2>{t("translation.ourservices")}</h2>
              <p>{t("translation.ourservexp")}</p>
            </div>
            <div className="service-frame">
              <div className="frame-ho">
                <div
                  className={`frame1-1 ${
                    breakPoint === 0
                      ? "small-device"
                      : breakPoint === 1
                      ? "mobile"
                      : breakPoint === 2
                      ? "tablet"
                      : "desktop"
                  }`}
                >
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Maids")}
                      </h4>
                    </div>
                    <div
                      className={`hh-home-our-ser-para-div ${
                        breakPoint === 0
                          ? "small-device"
                          : breakPoint === 1
                          ? "mobile"
                          : breakPoint === 2
                          ? "tablet"
                          : "desktop"
                      }`}
                    >
                      <p className="hh-home-our-ser-para">
                        {t("translation.ourmaids")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-ho">
                <div
                  className={`frame1-1 ${
                    breakPoint === 0
                      ? "small-device"
                      : breakPoint === 1
                      ? "mobile"
                      : breakPoint === 2
                      ? "tablet"
                      : "desktop"
                  }`}
                >
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage2} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Nanny")}
                      </h4>
                    </div>
                    <div
                      className={`hh-home-our-ser-para-div ${
                        breakPoint === 0
                          ? "small-device"
                          : breakPoint === 1
                          ? "mobile"
                          : breakPoint === 2
                          ? "tablet"
                          : "desktop"
                      }`}
                    >
                      <p className="hh-home-our-ser-para">
                        {t("translation.ourcooks")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-ho">
                <div
                  className={`frame1-1 ${
                    breakPoint === 0
                      ? "small-device"
                      : breakPoint === 1
                      ? "mobile"
                      : breakPoint === 2
                      ? "tablet"
                      : "desktop"
                  }`}
                >
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage3} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Caregiver")}
                      </h4>
                    </div>
                    <div
                      className={`hh-home-our-ser-para-div ${
                        breakPoint === 0
                          ? "small-device"
                          : breakPoint === 1
                          ? "mobile"
                          : breakPoint === 2
                          ? "tablet"
                          : "desktop"
                      }`}
                    >
                      <p className="hh-home-our-ser-para">
                        {t("translation.ourdrivers")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Latest Jobs Section --> */}

          <div className="career">
            {/* <div className="container"> */}
            <div className="c-left">
              <div className="content2">
                <p className="hh-home-late-jobs-tag">
                  {" "}
                  {t("translation.tagline")}
                </p>
                <div className="content22">
                  <h2 className="hh-home-late-jobs-title">
                    {t("translation.latestJobs")}
                  </h2>
                  <p className="hh-home-late-jobs-para">
                    {t("translation.beapart")}
                  </p>
                </div>
              </div>
              <img src={latestjobwomen} alt="women_pic" className="lady-img" />
            </div>
            <div id="latest-jobs" className="c-right">
              {jobs && jobs.length > 0 ? (
                <>
                  {jobs.slice(0, 3).map((job) => (
                    <JobTitleCard
                      key={job.custReqId}
                      title={job.position}
                      expectations={
                        job?.expectations !== null
                          ? parse(job?.expectations)
                          : ""
                      }
                      location={`${job.city}, ${job.state}, ${job.country}`}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className="hh-home-lat-join-when-no-job-div">
                    <div className="no-jobs-found-img-div">
                      <img
                        src={NoJobsFoundImage}
                        alt="no-job-found"
                        className="no-job-found-img"
                      />
                    </div>
                    <div className="hh-home-no-job-found-text-div">
                      <p className="hh-home-no-job-found-text">
                        No jobs available at the moment.
                      </p>
                      <p className="hh-home-no-job-found-text">
                        {" "}
                        Please visit again later.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* </div> */}
          </div>
          {/* <!-- why-choose Section --> */}
          <div className="choose-us background-gradient-color">
            <div className="title">
              <h2 className="hh-home-wcu-title">
                {t("translation.whychooseus")}
              </h2>
              <p className="hh-home-wcu-para">
                {t("translation.whyheartyhelpers")}{" "}
              </p>
            </div>
            <div className="row">
              <div className="column">
                <img src={Handshake} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.Reliable")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    {t("translation.ourhelpers")} 
                  </p>
                </div>
              </div>
              <div className="column">
                <img src={Onlineshop} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.TransparentPricing")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    {t("translation.eliminate")}
                  </p>
                </div>
              </div>
              <div className="column">
                <img src={Support} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.CustomerSupport")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    {t("translation.dedicated")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* about us -- */}
          <div className="about">
            <div className="about-1">
              <p className="hh-home-about-tag">
                {t("translation.Featureone")} 
              </p>
              <div className="about-content">
                <h2 className="hh-home-about-title">
                  {t("translation.AboutHeartyHelper")}
                </h2>
                <p className="hh-home-about-para">
                  {t("translation.foundmission")}
                </p>
                <p className="hh-home-about-para"></p>
              </div>
            </div>
            <img src={wood} alt="Connect People" className="wood-img" />
            <img
              src={mobilewood}
              alt="Connect People"
              className="wood-img-mobile"
            />
          </div>

          {/* <!-- Latest Joining Section --> */}

          <div className="joining background-gradient-color">
            <div className="joining-head">
              <h2 className="hh-home-lat-joining-title">
                {t("translation.LatestJoining")}
              </h2>
              <p className="hh-home-lat-joining-para">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </div>
            <div className="all-blocks-crd">
              <Swiper
                loop={true}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => {
                  swiper.on("click", () => {
                    swiper.autoplay.start();
                  });
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                {latestJoinee?.map((latestJoinees) => (
                  <SwiperSlide
                    key={latestJoinees.empId}
                    className="swiper-slide"
                  >
                    <LatestJoiningCard
                      name={latestJoinees.name}
                      profileImageUrl={
                        latestJoinees.profileImageUrl || usercomment
                      }
                      role={latestJoinees.role ?? "Role N/A"}
                      bio={
                        latestJoinees?.bio !== null
                          ? parse(latestJoinees?.bio)
                          : "Bio N/A"
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="testimonial">
            <div className="t-head">
              <h2 className="hh-home-cu-testi-heading">
                {t("translation.Customertestimonials")}
              </h2>
              <p className="hh-home-cu-testi-tagline">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="boxes-with-dots">
              <div className="boxes">
                {/* <!-- Box of One Customer --> */}
                <Swiper
                  loop={true} // Add this line to enable continuous looping
                  slidesPerView={slidesPerViewCustomer}
                  spaceBetween={spaceBetweenCustomer}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => {
                    swiper.on("click", () => {
                      swiper.autoplay.start();
                    });
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper made finding a reliable nanny for our twins a breeze. Their platform's transparency and the quality of service exceeded our expectations. Highly recommended!"
                      name="Rajesh Patel"
                      position="Mumbai"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="I needed urgent handyman services, and HeartyHelper came through with flying colors. Quick response, professional service, and easy booking. Will definitely use them again!"
                      name="Nareepong Thongchai"
                      position="Bangkok"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper's cooks have transformed our family dinners. From dietary restrictions to gourmet meals, they cater to our needs perfectly. It's like having a personal chef at home!"
                      name="David Smith"
                      position="London"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="We hired a pet sitter through HeartyHelper for our dog, and we couldn't be happier. Our pet was well taken care of, and the sitter was attentive and loving. Five stars!"
                      name="Alok Tiwari"
                      position="Delhi"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="As a busy professional, finding a reliable driver was crucial. HeartyHelper provided a trustworthy chauffeur who is always punctual and professional. Excellent service!"
                      name="Samantha White"
                      position="Sydney"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="I've used HeartyHelper for both cleaning and gardening services. Each time, the helpers were thorough and friendly. The platform's ease of use makes it my go-to for household needs."
                      name="Prakash Kumar"
                      position="Chennai"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper connected us with an amazing elder care companion for my mother. The caregiver is compassionate and provides excellent support. Truly grateful for their service."
                      name="Ananya Rao"
                      position="Bangalore"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper connected us with an amazing elder care companion for my mother. The caregiver is compassionate and provides excellent support. Truly grateful for their service."
                      name="Elena P."
                      position="Singapore"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="Using HeartyHelper for tutoring services has been fantastic for my kids. The tutors are knowledgeable and patient, making learning enjoyable. Thank you, HeartyHelper!"
                      name="Meera Singh"
                      position="Mumbai"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="From start to finish, HeartyHelper has been a lifesaver. Their platform is user-friendly, their helpers are reliable, and the entire experience has been smooth and hassle-free."
                      name="Natthapong Wongwiset"
                      position="Bangkok"
                      company="HeartyHelper"
                    />
                  </SwiperSlide>
                </Swiper>
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
              </div>{" "}
            </div>
          </div>
        </div>
      </StaticHomePageLayout>
    </>
  );
};
export default HomePage;
