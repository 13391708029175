import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "swiper/css/navigation";
import Aboutbanner from "../../Assets/Images/Aboutbanner.png";
import mobileabout from "../../Assets/Images/mobileabout.png";
import aboutsec1 from "../../Assets/Images/aboutsec1.png";
import aboutsec2 from "../../Assets/Images/aboutsec2.png";
import AboutUsTeamCard from "../../Components/AboutUsTeamCard/AboutUsTeamCard";
import TestimonalCard from "../../Components/TestimonalCard/TestimonalCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { useTranslation } from "react-i18next";
import aboutcard from "../../Assets/Images/aboutcard.png";
import person4 from "../../Assets/Images/person/person4.png";
import { useResponsive } from "../../CustomHooks/useResponsive";

const AboutUs = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const breakPoint = useResponsive([375, 576, 768, 1200]);

  let slidesPerView, spaceBetween, slidesPerViewCustomer, spaceBetweenCustomer;

  if (breakPoint <= 2) {
    slidesPerView = 1;
    slidesPerViewCustomer = 1;
    spaceBetween = 100;
    spaceBetweenCustomer = 100;
  } else if(breakPoint >= 2 && breakPoint <= 3) { 
    slidesPerView = 2;
    slidesPerViewCustomer = 2;
    spaceBetween = 100;
    spaceBetweenCustomer = 30;
  }else{
    slidesPerView = 4;
    slidesPerViewCustomer = 2;
    spaceBetween = 50;
    spaceBetweenCustomer = 30;
  }

  // Simulate a loading delay with setTimeout
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000); // Adjust the delay as needed
  // }, []);
  const { t, i18n } = useTranslation(); //for languge conversion

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StaticHomePageLayout>
        <div>
          <div className="full-banner-about-sec">
            <div className="banner-area-about-sec">
              <img src={Aboutbanner} alt="" className="picture-about-sec" />
              <img
                src={mobileabout}
                alt=""
                className="picture-mobile-about-sect"
              />

              <div className="content-head-sec-about">
                <h1 className="about-sec-headi">
                  {t("aboutUsTranslations.ConnectingHearts")}<br></br>
                  {t("aboutUsTranslations.EmpoweringHomes")}
                </h1>

                <p className="about-sec-parag">
                  {t("aboutUsTranslations.aboutusTag")}
                </p>
              </div>
            </div>
          </div>

          {/* <!-- Home section End --> */}

          {/* <!-- layout start from here --> */}
          <div
            className="about-layout"
          >
            <div className="column-about-sec">
              <p className="column-about-sec-tag">
                {" "}
                {t("aboutUsTranslations.Ourstory")}
              </p>
              <h2 className="column-about-sec-heading">
                {t("aboutUsTranslations.OurStoryTag")} 
              </h2>
            </div>

            <div className="Timeline">
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>{t("aboutUsTranslations.june")}</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>{t("aboutUsTranslations.founded")} </p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>{t("aboutUsTranslations.july")}</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>{t("aboutUsTranslations.platform")}</p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>{t("aboutUsTranslations.october")}</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>{t("aboutUsTranslations.expansion")}</p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>{t("aboutUsTranslations.january")}</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>{t("aboutUsTranslations.introductionof")}</p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  {/* <div className="divider">
              <svg
                width="2"
                height="100"
                viewBox="0 0 2 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="4.37114e-08"
                  x2="0.999996"
                  y2="100"
                  stroke="#04AFF0"
                  stroke-width="2"
                />
              </svg>
            </div> */}
                </div>
                <div className="text">
                  <h6>{t("aboutUsTranslations.present")}</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>{t("aboutUsTranslations.continuouslyevolving")}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Layout end here --> */}
          {/* <!-- Start our vision from here --> */}
          <div className="about-vision">
            <div className="about-frame">
              <p className="about-frame-tag">
                
                {t("aboutUsTranslations.Ourvision")}
              </p>
              <div className="abo-text">
                <div className="left">
                  {t("aboutUsTranslations.atheartyhelper")} 
                </div>
                <div className="right">
                  {t("aboutUsTranslations.OurvisionPara")} 
                </div>
              </div>
            </div>
          </div>

          {/* <!-- End our vision  here --> */}

          {/* <!-- start our values from here  --> */}

          <div className="ab-values">
            <div className="ab-section">
              <p className="about-section-our-values-tag">
                {t("aboutUsTranslations.Ourvalues")}
              </p>
              <div className="ab-content">
                <h2 className="about-section-our-values-heading">
                  {t("aboutUsTranslations.OurvaluesHeading")}
                </h2>
                <h6 className="about-section-our-values-para">
                  {t("aboutUsTranslations.weuphold")}
                </h6>
              </div>
            </div>
            <div className="ab-row">
              <div className="ab-column">
                <img src={aboutsec1} className="img1" />
                <div className="ab-para">
                  <h4>{t("aboutUsTranslations.professionalism")}</h4>
                  <p>{t("aboutUsTranslations.upholdingthe")}</p>
                </div>
              </div>
              <div className="ab-column">
                <img src={aboutsec2} className="img2" />
                <div className="ab-para">
                  <h4>{t("aboutUsTranslations.communitycon")}</h4>
                  <p>{t("aboutUsTranslations.fosteringmea")}</p>
                </div>
              </div>
              <div className="ab-column">
                <img src={aboutsec1} className="img3" />
                <div className="ab-para">
                  <h4>{t("aboutUsTranslations.innvoation")}</h4>
                  <p>{t("aboutUsTranslations.continuouslypur")}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End our values here  --> */}

          {/* <!-- Team start here --> */}

          <div className="ab-us-team">
            <div className="ab-us-content">
              <h2 className="ab-us-content-heading">
                {t("aboutUsTranslations.TeamsThatCare")}
              </h2>
              <p className="ab-us-content-tagline">
                {t("aboutUsTranslations.atheartyhelperour")}
              </p>
            </div>
            <div className="ab-us-members">
              <Swiper
                loop={true} // Add this line to enable continuous looping
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                autoplay={{ delay: 7000 }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination]}
                className="mySwiper about-Swiper"
              >
                <SwiperSlide className="swiper-slide about-slider">
                  <AboutUsTeamCard
                    image={aboutcard}
                    name="Rajesh Sharma"
                    position="Founder & Visionary"
                    description="Rajesh's vision drives HeartyHelper's mission to revolutionize household service hiring through innovation and community empowerment. "
                    twitter="https://twitter.com/alisahester"
                    linkedin="https://linkedin.com/in/alisahester"
                    website="https://alisahester.com"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide about-slider">
                  <AboutUsTeamCard
                    image={aboutcard}
                    name="Priya Patel"
                    position="Customer Support Lead"
                    description="Priya ensures seamless interactions and support for all clients, providing expert guidance and assistance when you need it most. "
                    twitter="https://twitter.com/alisahester"
                    linkedin="https://linkedin.com/in/alisahester"
                    website="https://alisahester.com"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide about-slider">
                  <AboutUsTeamCard
                    image={aboutcard}
                    name="Amit Kumar"
                    position="Operations Manager"
                    description="Amit oversees the platform's day-to-day operations, ensuring reliability and efficiency in service delivery."
                    twitter="https://twitter.com/alisahester"
                    linkedin="https://linkedin.com/in/alisahester"
                    website="https://alisahester.com"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide about-slider">
                  <AboutUsTeamCard
                    image={aboutcard}
                    name="Neha Reddy"
                    position="Marketing Specialist"
                    description="Neha promotes HeartyHelper's values and services, connecting with our community and fostering meaningful relationships."
                    twitter="https://twitter.com/alisahester"
                    linkedin="https://linkedin.com/in/alisahester"
                    website="https://alisahester.com"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide about-slider">
                  <AboutUsTeamCard
                    image={aboutcard}
                    name="Ravi Verma"
                    position="Technical Lead"
                    description="Ravi ensures our platform remains secure, user-friendly, and continuously improves to meet your needs."
                    twitter="https://twitter.com/alisahester"
                    linkedin="https://linkedin.com/in/alisahester"
                    website="https://alisahester.com"
                  />
                </SwiperSlide>
              </Swiper>
              {/* <div className="upper2">
                    <AboutUsTeamCard />
                  </div>
                  <div className="upper3">
                    <AboutUsTeamCard />
                  </div>
                  <div className="upper4">
                    <AboutUsTeamCard />
                  </div> */}
            </div>
            {/* <div className="slider-btn-home">
            <div className="arrow-btn-home">
              <AiOutlineArrowRight />
            </div>
            <div className="arrow-btn-home">
              <AiOutlineArrowRight />
            </div>
          </div> */}
          </div>
          <div className="testimonial">
            <div className="t-head">
              <h2 className="hh-home-cu-testi-heading">
                {t("aboutUsTranslations.Customertestimonials")}
              </h2>
              <p className="hh-home-cu-testi-tagline">
                {t("aboutUsTranslations.certainly")}
              </p>
            </div>
            <div className="boxes-with-dots">
              <div className="boxes">
                {/* <!-- Box of One Customer --> */}
                <Swiper
                  loop={true} // Add this line to enable continuous looping
                  slidesPerView={slidesPerViewCustomer}
                  spaceBetween={spaceBetweenCustomer}
                  autoplay={{ delay: 5000 }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="eartyHelper made finding a reliable nanny for our twins a breeze. Their platform's transparency and the quality of service exceeded our expectations. Highly recommended!"
                      name="Rajesh Patel "
                      position="position"
                      company="Mumbai "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="I needed urgent handyman services, and HeartyHelper came through with flying colors. Quick response, professional service, and easy booking. Will definitely use them again!"
                      name="Nareepong Thongchai"
                      position="position"
                      company=" Bangkok "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper's cooks have transformed our family dinners. From dietary restrictions to gourmet meals, they cater to our needs perfectly. It's like having a personal chef at home!"
                      name="David Smith "
                      position="position"
                      company="  London  "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="We hired a pet sitter through HeartyHelper for our dog, and we couldn't be happier. Our pet was well taken care of, and the sitter was attentive and loving. Five stars!"
                      name="Alok Tiwari"
                      position="position"
                      company="Delhi"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="As a busy professional, finding a reliable driver was crucial. HeartyHelper provided a trustworthy chauffeur who is always punctual and professional. Excellent service!"
                      name="Samantha White "
                      position="position"
                      company="  Sydney "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="I've used HeartyHelper for both cleaning and gardening services. Each time, the helpers were thorough and friendly. The platform's ease of use makes it my go-to for household needs."
                      name="Prakash Kumar "
                      position="position"
                      company="Chennai "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="eartyHelper connected us with an amazing elder care companion for my mother. The caregiver is compassionate and provides excellent support. Truly grateful for their service."
                      name="Ananya Rao "
                      position="position"
                      company="Bangalore"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="HeartyHelper connected us with an amazing elder care companion for my mother. The caregiver is compassionate and provides excellent support. Truly grateful for their service."
                      name="Elena P. "
                      position="position"
                      company=" Singapore "
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="Using HeartyHelper for tutoring services has been fantastic for my kids. The tutors are knowledgeable and patient, making learning enjoyable. Thank you, HeartyHelper!"
                      name="Meera Singh "
                      position="position"
                      company="Mumbai"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard
                      image={person4}
                      review="From start to finish, HeartyHelper has been a lifesaver. Their platform is user-friendly, their helpers are reliable, and the entire experience has been smooth and hassle-free."
                      name="Natthapong Wongwiset  "
                      position="position"
                      company="Bangkok "
                    />
                  </SwiperSlide>
                </Swiper>
              </div>{" "}
            </div>
          </div>

          {/* <!-- Team end here --> */}
        </div>
      </StaticHomePageLayout>
    </>
  );
};

export default AboutUs;
