import React, { useState, useEffect } from "react";
import "./EmployeeQualificationEdit.css";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_QUALIFICATION_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import { useNavigate } from "react-router-dom";

const EmployeeQualificationEdit = ({ setShowempqualification, prevdata }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm();

  const Token = localStorage.getItem("token");

  const onClickCancel = () => {
    setShowempqualification(false);
    window.scrollTo(0, 0);
  };

  const onSubmit = (data) => {
    console.log(data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      language: data.language,
      personality: data.personality,
      skills: data.skills,
    });

    console.log(raw);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_QUALIFICATION_UPDATE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "employee qualification data");
        if (result.statuscode === 200) {
          toast.info("Qualification data  is successfully updated");

          window.location.reload();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  console.log(prevdata, "prev data");

  useEffect(() => {
    if (prevdata) {
      Object.entries(prevdata).forEach(([name, value]) => {
        setValue(name, value || "");
        setValue("language", prevdata.language);
        setValue("personality", prevdata.personality);
        setValue("skills", prevdata.skills);
      });
    }
    console.log("data changed from store");
  }, [prevdata]);

  const language = [
      { value: "English", label: "English" },
      { value: "Thai", label: "Thai" },
   
    ];
    const skills = [
      { value: "carpetCleaning", label: "Carpet cleaning" },
    { value: "sweeping", label: "Sweeping" },
    { value: "deepCleaning", label: "Deep cleaning" },
    { value: "mopping", label: "Mopping" },
    { value: "windowTreatment", label: "Window treatment and cleaning" },
    { value: "vacuuming", label: "Vacuuming" },
    { value: "dusting", label: "Dusting" },
    { value: "bathroomCleaning", label: "Bathroom and bedroom cleaning" },
    { value: "infectionControl", label: "Infection control" },
    { value: "polishing", label: "Polishing" },
    { value: "cookingThaiFood", label: "Cooking Thai Food" },
    { value: "cookingForBaby", label: "Cooking Food for Baby" }
    ];
    const personality = [
      { value: "Pleasant Personality", label: "Pleasant Personality" },
      { value: "Physical Fitness", label: "Physical Fitness" },
      { value: "Personal hygiene", label: "Personal hygiene" },
      { value: "Eye for detail", label: "Eye for detail" },
      { value: "Cooperation", label: "Cooperation" },
      { value: "Adaptability", label: "Adaptability" },
      { value: "Honesty", label: "Honesty" },
      { value: "Tact & Diplomacy", label: "Tact & Diplomacy" },
      { value: "Right Attitude", label: "Right Attitude" },
      { value: "Calm demeanor", label: "Calm demeanor" },
      { value: "Courteous", label: "Courteous" },
      { value: "Punctuality", label: "Punctuality" },
      { value: "Good Memory", label: "Good Memory" },
    ];

  return (
    <div className="empeduc-edit-container">
      <div className="emp-profile-card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="emp-title">Edit Your language proficiency</h4>
              </div>
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Language</label>
              <div className="profileeditinput div">
                {/* <MultiSelectDropdown
                  options={[
                    { value: "Hindi", label: "Hindi" },
                    { value: "Marathi", label: "Marathi" },
                    { value: "English", label: "English" },
                  ]}
                  onChange={(selectedOptions) => {
                    // Handle the selected options
                  }}
                /> */}
                <MultiSelectDropdown
                  control={control}
                  name="language"
                  options={language}
                  rules={{ required: "Please select at least one option" }}
                />
                {errors.multiselectField && (
                  <p className="error-msg">Please select at least one option</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Skills</label>
              <div className="profileeditinput div">
                {/* <MultiSelectDropdown
                  options={[
                    { value: "Singing", label: "Singing" },
                    { value: "Dancing", label: "Dancing" },
                    { value: "Acting", label: "Acting" },
                  ]}
                  onChange={(selectedOptions) => {
                    // Handle the selected options
                  }}
                /> */}
                <MultiSelectDropdown
                  control={control}
                  name="skills"
                  options={skills}
                  rules={{ required: "Please select at least one option" }}
                />
                {errors.multiselectField && (
                  <p className="error-msg">Please select at least one option</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Personality</label>
              <div className="profileeditinput div">
                {/* <MultiSelectDropdown
                  options={[
                    { value: "Angry", label: "Angry" },
                    { value: "Polite", label: "Polite" },
                    { value: "Kind", label: "Kind" },
                  ]}
                  onChange={(selectedOptions) => {
                    // Handle the selected options
                  }}
                /> */}
                <MultiSelectDropdown
                  control={control}
                  name="personality"
                  options={personality}
                  rules={{ required: "Please select at least one option" }}
                />
                {errors.multiselectField && (
                  <p className="error-msg">Please select at least one option</p>
                )}
              </div>
            </div>
          </div>
          <div className="emp-cancel-btns-div pt-3">
            <button
              className="emp-edit-btn-cp-cancel-btn"
              type="button"
              onClick={onClickCancel}
            >
              Cancel
            </button>
            <button className="emp-edit-btn cp-save-btn" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EmployeeQualificationEdit;
