import React, { useEffect, useState } from "react";
import "./EmployeeMessenger.css";
import EmployeeMessangerEdit from "../../../Components/Employee/EmployeeMessangerEdit/EmployeeMessangerEdit";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_MESSANGER } from "../../../ApiEndPoints/ApiEndPoints";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmployeeMessenger = () => {
  const [showEmployeeMessangerEdit, setShowEmployeeMessangerEdit] =
    useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEmployeeMessangerEdit(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_MESSANGER}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "employee messanger");
        setData(result);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <EmployeeProfileLayout showCameraIcon={false}>
        {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        ) : (
          <div className="empMessenger-container">
            {
              <div className="container-emp-messanger-main">
                <div className="emp-title-div pb-4">
                  <h3 className="emp-title">Messenger</h3>
                  {!showEmployeeMessangerEdit && (
                    <button className="emp-edit-btn" onClick={handleChangeEdit}>
                      Edit
                    </button>
                  )}
                </div>
                {!showEmployeeMessangerEdit && (
                    <div className="emp-messanger-card1">
                      <h4>Linked Account</h4>
                      <hr className="emp-hr-line"></hr>
                      <div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">WhatsApp account </p>
                          <p className="emp-text-t">
                            <a
                             href="https://web.whatsapp.com/"
                             
                              target="_blank"
                              rel="noopener noreferrer"
                              className="emp-link"
                            >
                              {data?.whatsappAccount}
                            </a>
                          </p>
                        </div>

                        <div className="emp-label-div-desktop">
                          <p className="emp-label">WeChat account</p>
                          <p className="emp-text-t">
                            <a
                              href="https://www.wechat.com/"

                              target="_blank"
                              rel="noopener noreferrer"
                              className="emp-link"
                            >
                              {data?.wechatAccount}
                            </a>
                          </p>
                        </div>

                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Facebook account</p>
                          <p className="emp-text-t">
                            <a
                              href="https://www.facebook.com/laurel/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="emp-link"
                            >
                              {data?.facebookAccount}
                            </a>
                          </p>
                        </div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Line account</p>
                          <p className="emp-text-t">
                            <a
                              href="https://line.me/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="emp-link"
                            >
                              {data?.lineAccount}
                            </a>
                          </p>
                        </div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Telegram account</p>
                          <p className="emp-text-t">
                            <a
                              href="https://t.me/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="emp-link"
                            >
                              {data?.telegramAccount}
                            </a>
                          </p>
                        </div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Contact number</p>
                          <p className="emp-text-t">{data.phoneNumber}</p>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            }
            {showEmployeeMessangerEdit && (
              <EmployeeMessangerEdit
                setShowEmployeeMessangerEdit={setShowEmployeeMessangerEdit}
                prevdata={data}
              />
            )}
          </div>
        )}
      </EmployeeProfileLayout>
    </>
  );
};

export default EmployeeMessenger;
