import React, { useState } from "react";
import "./ToggleButton.css";

const ToggleButton = ({
  showCustomer,
  setShowCustomer,
  showEmployee,
  setShowEmployee,
}) => {
  const handleCustomerClick = () => {
    setShowCustomer(true);
    setShowEmployee(false);
  };
  const handleEmployeeClick = () => {
    setShowCustomer(false);
    setShowEmployee(true);
  };

  return (
    <>
      <div className="button-box">
        {showCustomer ? (
          <div id="btn" style={{ left: "0" }}></div>
        ) : (
          <div id="btn" style={{ left: "99px" }}></div>
        )}
        <div className="button-r-toggle">
          <button
            id="c-button-r"
            type="button"
            className={
              showCustomer ? "toggle-btn toggle-btn-activated" : "toggle-btn"
            }
            onClick={handleCustomerClick}
          >
            Customer
          </button>
          <button
            id="e-button-r"
            type="button"
            className={
              showEmployee ? "toggle-btn toggle-btn-activated" : "toggle-btn"
            }
            onClick={handleEmployeeClick}
          >
            Employee
          </button>
        </div>
      </div>
    </>
  );
};

export default ToggleButton;
