import React from "react";
import "./InputField.css";

const InputField = ({label, type, placeholder,register}) => {
    return (
      <div>
        <label className="label-registration">{label} <span className="error-symbol">*</span></label>
        <input className="input-box" type={type} placeholder={placeholder} {...register}/>
      </div>
    );
  }
  
  export default InputField;