import React, { useState, useEffect } from "react";
import "./ProfileTopBar.css";
import bell from "../../Assets/Svg/bell-icon.svg";
import chat from "../../Assets/Svg/chat-icon.svg";
import logo from "../../Assets/Svg/logo.svg";
import home from "../../Assets/Svg/home-icon.svg";
import { toast } from "react-toastify";
// import fake from "../../Assets/Svg/fake-img.svg";
import profile from "../../Assets/Svg/profile-icon.svg";
import activeBell from "../../Assets/Svg/activeBell.svg";
import activeHome from "../../Assets/Svg/activeHome.svg";
import activeChat from "../../Assets/Svg/activeChat.svg";
import activeProfile from "../../Assets/Svg/activeProfile.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { handleClick } from "../../Utils/Utils";
import { EMPLOYEE_PROFILE} from "../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../ApiBaseUrl/Api";
// import HHLogo from "../../../Assets/Images/logo.png";
// import Typewriter from "typewriter-effect";
import DefaultProfilePic from "../../Assets/Images/Default_pfp.svg.png";

const ProfileTopbarEmployee = () => {
  const [activeIcon, setActiveIcon] = useState("");
  const [hoveredImages, setHoveredImages] = useState({});
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [data, setData] = useState({});
  // const [isLoading, setIsLoading] = useState(true);
  const Token = localStorage.getItem("token");
  const handleMouseEnter = (index) => {
    setHoveredImages((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredImages((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };
  const onClickProfile = () => {
    navigate("/employee-profile");
  };

  const handleButtonClick = (iconName) => {
    setActiveIcon(iconName);
    // if (iconName == "home") {
    //   navigate("/employee-profile");
    // } else if (iconName == "profile") {
    //   navigate("/available-helpers");
    // } else if (iconName == "chat") {
    //   navigate("/employee-chat");
    // } else if (iconName == "bell") {
    //   navigate("/customer-notification");
    // } else {
    //   return null;
    // }
  };
  const onClickLogo = () => {
    navigate("/");
  };
  //get api for customer profile
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_PROFILE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        // console.log(result, "result");
        setData(result.employeeData);
        // setIsLoading(false);

      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div className="profile-topbar">
      <div className="container d-flex justify-content-between align-items-center pt-2">
        <div>
          <img
            src={logo}
            alt=""
            onClick={onClickLogo}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <div className="d-flex justify-content-center pr-top-bar-img-div">
              <Link to="/employee-post">
                <img
                  className={`${
                    activeIcon === "home" ||
                    hoveredImages[1] ||
                    pathname === "/employee-post"
                      ? "active-top-bar-icons"
                      : "top-bar-icons"
                  }`}
                  src={
                    pathname === "/employee-post" ||
                    activeIcon === "home" ||
                    hoveredImages[1]
                      ? activeHome
                      : home
                  }
                  alt=""
                  onClick={() => handleButtonClick("home")}
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={() => handleMouseLeave(1)}
                />
              </Link>
            </div>
            <div>
              <hr
                className={
                  pathname == "/employee-post" ||
                  activeIcon == "home" ||
                  hoveredImages[1]
                    ? "top-bar-hr"
                    : "d-none"
                }
              ></hr>
            </div>
          </div>

          <div className="">
            <div className="d-flex justify-content-center align-items-center pr-top-bar-img-div">
              <Link to="/available-jobs">
                <img
                  className={`${
                    pathname === "/available-jobs" ||
                    activeIcon === "profile" ||
                    hoveredImages[2]
                      ? "active-top-bar-icons"
                      : "top-bar-icons"
                  }`}
                  onClick={() => handleButtonClick("profile")}
                  onMouseEnter={() => handleMouseEnter(2)}
                  onMouseLeave={() => handleMouseLeave(2)}
                  src={
                    pathname === "/available-jobs" ||

                    activeIcon === "profile" ||
                    hoveredImages[2]
                      ? activeProfile
                      : profile
                  }
                  alt=""
                  style={{ margin: "0px 100px 0px 100px" }}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-center align-items-center ">
              <hr
                className={
                  pathname === "/available-jobs" ||
                  activeIcon === "profile" ||
                  hoveredImages[2]
                    ? "top-bar-hr"
                    : "d-none"
                }
              ></hr>
            </div>
          </div>

          <div className="">
            <div className="d-flex justify-content-center pr-top-bar-img-div">
              <Link to="/employee-chat">
              {/* <Link to="/employee-profile"> */}
                <img
                  className={`${
                    activeIcon === "chat" ||
                    hoveredImages[3] ||
                    pathname === "/employee-chat"
                      ? "active-top-bar-icons"
                      : "top-bar-icons"
                  }`}
                  src={
                    pathname === "/employee-chat" ||
                    activeIcon === "chat" ||
                    hoveredImages[3]
                      ? activeChat
                      : chat
                  }
                  alt=""
                  onClick={() => handleButtonClick("chat")}
                  onMouseEnter={() => handleMouseEnter(3)}
                  onMouseLeave={() => handleMouseLeave(3)}
                />
              </Link>
            </div>
            <div>
              <hr
                className={
                  pathname === "/employee-chat" ||
                  activeIcon === "chat" ||
                  hoveredImages[3]
                    ? "top-bar-hr"
                    : "d-none"
                }
              ></hr>
            </div>
          </div>

          <div className="">
            <div className="d-flex justify-content-center align-items-center pr-top-bar-img-div">
              {/* <Link to="/customer-notification"> */}
              <Link to="/employee-notification">
                <img
                  className={`${
                    activeIcon === "bell" ||
                    hoveredImages[4] ||
                    pathname === "/employee-notification"
                      ? "active-top-bar-icons"
                      : "top-bar-icons"
                  }`}
                  src={
                    pathname === "/employee-notification" ||
                    activeIcon === "bell" ||
                    hoveredImages[4]
                      ? activeBell
                      : bell
                  }
                  alt=""
                  onClick={() => handleButtonClick("bell")}
                  onMouseEnter={() => handleMouseEnter(4)}
                  onMouseLeave={() => handleMouseLeave(4)}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <hr
                className={
                  pathname == "/employee-notification" ||
                  activeIcon == "bell" ||
                  hoveredImages[4]
                    ? "top-bar-hr"
                    : "d-none"
                }
              ></hr>
            </div>
          </div>
        </div>
        <div>
          <button onClick={onClickProfile}>
            <img
              src={data.profileImageUrl ?? DefaultProfilePic}
              alt=""
              // style={{ borderRadius: "50%" }}
              className="hh-top-bar-profile-icon"
            />
          </button>
        </div>
      </div>
      
    </div>

    
  );
};

export default ProfileTopbarEmployee;