import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import CustomerProfile from "./Pages/Customer/CustomerProfile";
import CustomerNotification from "./Pages/CustomerBlogs/CustomerNotification/CustomerNotification";
import CustomerChat from "./Pages/CustomerBlogs/CustomerChat/CustomerChat";
import AvailableHelpers from "./Pages/CustomerBlogs/AvailableHelpers/AvailableHelpersPage/AvailableHelpers.jsx";
import CustomerCreatePost from "./Pages/CustomerBlogs/CustomerCreatePost/CustomerCreatePost";
import CustomerAccount from "./Pages/Customer/CustomerAccount/CustomerAccount";
import CustomerInviteFriends from "./Pages/Customer/CustomerInviteFriends/CustomerInviteFriends";
import CustomerSubscription from "./Pages/Customer/CustomerSubscription/CustomerSubscription";
import CustomerProfileNotification from "./Pages/Customer/CustomerProfileNotification/CustomerProfileNotification";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ProtectedRoutes from "./ProtectedRouter";
import EmployeeProfile from "./Pages/Employee/EmployeeProfile";
import EmployeeAccount from "./Pages/Employee/EmployeeAccount";
import EmployeeMessanger from "./Pages/Employee/EmployeeMessenger/index.jsx";
import EmployeeExperience from "./Pages/Employee/EmployeeExperience/EmployeeExperience.jsx";
import EmployeeEducation from "./Pages/Employee/EmployeeEducation";
import EmployeeQualifications from "./Pages/Employee/EmployeeQualifications";
import EmployeeDocuments from "./Pages/Employee/EmployeeDocument/index";
import CustomerRequests from "./Pages/Customer/CustomerRequests/CustomerRequests";
import EmployeeChat from "./Pages/Employee/EmployeeBlogs/EmployeeChat/EmployeeChat";
import EmployeeExperienceEdit from "./Components/Employee/EmployeeExperienceEdit/EmployeeExperienceEdit";
import AvailableHelperIndividualFullView from "./Pages/CustomerBlogs/AvailableHelpers/AvailableHelperIndividualFullView/AvailableHelperIndividualFullView";
import EmployeeCreatePost from "./Pages/Employee/EmployeeBlogs/EmployeeCreatePost/EmployeeCreatePost.jsx";
import AvailableJobs from "./Pages/Employee/EmployeeBlogs/AvailableJobs/AvailableJobsPage/AvailableJobs.jsx";
import EmployeeInviteFriends from "./Pages/Employee/EmployeeInviteFriends/index.jsx";
import EmployeeNotification from "./Pages/Employee/EmployeeNotification/EmployeeNotification.jsx";
import AvailableJobIndividualFullView from "./Pages/Employee/EmployeeBlogs/AvailableJobs/AvailableJobIndividualFullView/AvailableJobIndividualFullView";
// import EmployeeCreatePost from "./Pages/Employee/EmployeeCreatePost/EmployeeCreatePost";
import HomePage from "./Pages/HomePage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import ClientAgreement from "./Pages/ClientAgremment/ClientAgremment";
import HelperAgrement from "./Pages/HelperAgrement/HelperAgrement";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./Pages/CookiesPolicy/CookiesPolicy";
import TopBarStaticPage from "./Components/TopBarStaticPage/TopBarStaticPage.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer theme="colored" />
        {/* <TopBarStaticPage/> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/termsofservice" element={<TermsAndCondition />} />
          <Route path="/client-agreement" element={<ClientAgreement />} />
          <Route path="/helper-agreement" element={<HelperAgrement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<CustomerProfile />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route
            path="/customer-profile-notification"
            element={<CustomerProfileNotification />}
          />

          <Route path="/" element={<ProtectedRoutes />}>
            {/* customer blogs pages 4 */}
            <Route path="customer-post" element={<CustomerCreatePost />} />
            <Route path="customer-requests" element={<CustomerRequests />} />
            {/* CustomerRequests is the page from the CustomerCreatePost page  */}
            <Route path="available-helpers" element={<AvailableHelpers />} />
            <Route
              path="available-helper-fullview/:empId"
              element={<AvailableHelperIndividualFullView />}
            />
            {/* AvailableHelperIndividualFullView is single full view of employee in availale helpers page */}
            <Route path="customer-chat" element={<CustomerChat />} />
            <Route
              path="customer-notification-chat"
              element={<CustomerNotification />}
            />

            {/* Profile sidebar pages customer side */}
            <Route path="profile" element={<CustomerProfile />} />
            <Route path="customer-account" element={<CustomerAccount />} />
            <Route
              path="customer-subscription"
              element={<CustomerSubscription />}
            />

            <Route
              path="customer-invite-friends"
              element={<CustomerInviteFriends />}
            />
            <Route
              path="/customer-profile-notification"
              element={<CustomerProfileNotification />}
            />
            {/* Profile sidebar pages Employee side */}
            <Route path="employee-profile" element={<EmployeeProfile />} />
            <Route path="employee-account" element={<EmployeeAccount />} />
            <Route path="employee-messanger" element={<EmployeeMessanger />} />
            <Route path="employee-documents" element={<EmployeeDocuments />} />
            <Route
              path="employee-qualification"
              element={<EmployeeQualifications />}
            />
            <Route
              path="employee-experience"
              element={<EmployeeExperience />}
            />
            <Route
              path="employee-editexperience"
              element={<EmployeeExperienceEdit />}
            />
            {/* EmployeeExperienceEdit page is from edit option of EmployeeExperience */}
            <Route path="employee-education" element={<EmployeeEducation />} />
            <Route
              path="employee-invite-friends"
              element={<EmployeeInviteFriends />}
            />

            {/* Employee blogs pages 4 */}
            <Route path="employee-post" element={<EmployeeCreatePost />} />
            <Route path="employee-chat" element={<EmployeeChat />} />
            <Route
              path="employee-notification"
              element={<EmployeeNotification />}
            />

            <Route path="available-jobs" element={<AvailableJobs />} />
            <Route
              path="available-job-fullview/:custReqId"
              element={<AvailableJobIndividualFullView />}
            />

            {/* <Route path="/mobileDefaultLayout" element = {<MobileDefaultLayout/>}/>  */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
