import React, { useState, useEffect } from "react";
// import "./CustomerCreatePost.css";
// import CustomerCreatePostProfileLayout from "../../../../Layout/CustomerCreatePostProfileLayout/CustomerCreatePostProfileLayout";
// import CustomerCreatePostButton from "../../../Components/CustomerCreatePost/CustomerCreatePostButton/CustomerCreatePostButton";
import CustomerCreatedPostBox from "../../../../Components/CustomerCreatePost/CustomerCreatedPostBox/CustomerCreatedPostBox";
import { BaseUrl } from "../../../../ApiBaseUrl/Api";
import { EMPLOYEE_GET_ALL_POSTS } from "../../../../ApiEndPoints/ApiEndPoints";

import EmployeeCreatePostLayout from "../../../../Layout/EmployeeCreatePostLayout/EmployeeCreatePostLayout";
import loadMorePostButtonImg from "../../../../Assets/Images/loadMorePostButtonImg.png";


const EmployeeCreatePost = () => {
  const [postData, setPostData] = useState([]);
  const Token = localStorage.getItem("token");
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [showingPostsCount, setShowingPostsCount] = useState(5);
  console.log("totalPostsCount", totalPostsCount);
  console.log("showingPostsCount", showingPostsCount);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [displayedPostsCount, setDisplayedPostsCount] = useState(5);
  const loadMorePosts = () => {
    // Increase the displayedPostsCount to load more posts
    setDisplayedPostsCount(displayedPostsCount + 5); // You can change the increment as needed
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    console.log("use ef=fect calling");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTEsImVtYWlsSWQiOiJpbnRlcm45dGVjaGN1cnZlQGdtYWlsLmNvbSIsImxvZ2luVHlwZSI6ImVtcGxveWVlIiwiZXhwIjoxNjkwMjE1NjY2LCJpYXQiOjE2OTAyMTIwNjZ9.QgKF2jP0FachBg8WLCkLTipByU8cX3304xGzOKk3gm8");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}${EMPLOYEE_GET_ALL_POSTS}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("employeeResult@@@", result);
        if (result.statuscode == 200) {
          setPostData(result);
          setProfileImageUrl(result?.employeeImage); // Set the profile image URL
          // console.log("setProfileImageUrl", setProfileImageUrl);
          // console.log("profileImageUrl", profileImageUrl);
        } else {
          console.log("some thing went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  console.log(postData, "postData in e,cpe");
  return (
    <div >
      <EmployeeCreatePostLayout>
        {/* <CustomerCreatePostButton profileImageUrl= {profileImageUrl}  /> */}

        {/* <div className="ch-post-text-div">
          <p className="ch-post-text">Post</p>
          <hr className="ch-post-hr-line" />
        </div> */}

        <CustomerCreatedPostBox
          postData={postData}
          setPostData={setPostData}
          showingPostsCount={showingPostsCount}
          setShowingPostsCount={setShowingPostsCount}
          totalPostsCount={totalPostsCount}
          setTotalPostsCount={setTotalPostsCount}
          fromEmployee={true} CustomerCreatedPostBox={false}
        />
       
        

      
 
      </EmployeeCreatePostLayout>
    </div>
  );
};

export default EmployeeCreatePost;
