import React, { useState, useEffect } from "react";
import "./CustomerCreatePost.css";
import CustomerCreatePostProfileLayout from "../../../Layout/CustomerCreatePostProfileLayout/CustomerCreatePostProfileLayout";
import CustomerCreatePostButton from "../../../Components/CustomerCreatePost/CustomerCreatePostButton/CustomerCreatePostButton";
import CustomerCreatedPostBox from "../../../Components/CustomerCreatePost/CustomerCreatedPostBox/CustomerCreatedPostBox";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_GET_ALL_POSTS } from "../../../ApiEndPoints/ApiEndPoints";
import loadMorePostButtonImg from "../../../Assets/Images/loadMorePostButtonImg.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileSideBarSpecialRequestBox from "../../../Components/CustomerCreatePost/ProfileSideBarSpecialRequestBox/ProfileSideBarSpecialRequestBox";

const CustomerCreatePost = () => {
  const [postData, setPostData] = useState([]);
  const Token = localStorage.getItem("token");
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [showingPostsCount, setShowingPostsCount] = useState(5);
  console.log("totalPostsCount", totalPostsCount);
  console.log("showingPostsCount", showingPostsCount);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const navigate = useNavigate();
  const handleLoadMorePosts = () => {
    // setShowingPostsCount(showingPostsCount + 5);
    setShowingPostsCount((prevCount) => prevCount + 5);
  };
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  useEffect(() => {
    // window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}${CUSTOMER_GET_ALL_POSTS}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200 || response.status === 201) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else if (response.status === 422) {
          // Handle validation issues
          console.error("Validation error: Data validation failed");
          // You can display a validation error message to the user if needed
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })

      .then((result) => {
        console.log("result", result);
        setPostData(result);
        setProfileImageUrl(result.customerImage); // Set the profile image URL
        // console.log("setProfileImageUrl", setProfileImageUrl);
        // console.log("profileImageUrl", profileImageUrl);
      })
      .catch((error) => console.log("error", error));
  }, [Token]);
  const handleDrop = (acceptedFiles) => {
    console.log("Accepted files:", acceptedFiles);

    const updatedPreviews = [];

    acceptedFiles.forEach((file) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (resizedFile) => {
          console.log("Resized file:", resizedFile);
          const preview = URL.createObjectURL(resizedFile);
          console.log(
            "setPreviewImageUploadByFile",
            setPreviewImageUploadByFile
          );

          updatedPreviews.push({ file: resizedFile, preview });

          if (updatedPreviews.length === acceptedFiles.length) {
            setSelectedFileFromDropZone(updatedPreviews);
            setPreviewImageUploadByFile(preview);
          }
        },
        "blob",
        500,
        500
      );
    });
  };
  return (
    <div>
      <CustomerCreatePostProfileLayout handleDrop={handleDrop}>
        <CustomerCreatePostButton profileImageUrl={profileImageUrl}  />
        <div className="ch-post-req-button-mob-view-div">
          <button
            className="ch-post-req-helper-button-mob-view"
            onClick={() => handleClickNavigate("/customer-requests")}
          >
            Request Helper
          </button>
        </div>

        <div className="ch-post-text-div">
          <p className="ch-post-text">Post</p>
          <hr className="ch-post-hr-line" />
        </div>

        {/* this is for postlists created by customer */}
        <CustomerCreatedPostBox
          postData={postData}
          setPostData={setPostData}
          showingPostsCount={showingPostsCount}
          setShowingPostsCount={setShowingPostsCount}
          totalPostsCount={totalPostsCount}
          setTotalPostsCount={setTotalPostsCount} handleDrop={handleDrop} showDeleteComponent={true}
        />
        {/* <div>
          {showingPostsCount < totalPostsCount && (
            <div className="cp-comment-box-load-more-button-for-posts-div">
              <button
                className="cp-comment-box-load-more-button-for-posts"
                onClick={handleLoadMorePosts}
              >
                Load More
              </button>
              <img
                src={loadMorePostButtonImg}
                alt="loadMorePostButtonImg"
                className="load-more-post-button-img"
                onClick={handleLoadMorePosts}
              />
            </div>
          )}
        </div> */}
      </CustomerCreatePostProfileLayout>
    </div>
  );
};

export default CustomerCreatePost;
