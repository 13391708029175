import React, { useState, useEffect } from "react";
import "./EmployeeExperience.css";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";

import EmployeeExperienceEdit from "../../../Components/Employee/EmployeeExperienceEdit/EmployeeExperienceEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_EXPERIENCE } from "../../../ApiEndPoints/ApiEndPoints";

import AddMoreExperience from "../../../Components/Employee/AddMoreExperience/AddMoreExperience";
import HHLogo from "../../../Assets/Images/loadMorePostButtonImg.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Typewriter from "typewriter-effect";

const EmployeeExperience = () => {
  const [showEmpExperience, setShowEmpExperience] = useState(false);
  const [showAddMoreExep, setShowAddMoreExep] = useState(false);
  const [data, setData] = useState([]);
  // const navigate = useNavigate();
  const Token = localStorage.getItem("token");
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [experienceLimit, setExperienceLimit] = useState(5); //adding to limit of adding exp by employee

  const handleShowAddMore = () => {
    if (data.length >= experienceLimit) {
      // Display a message or toast indicating the limit is reached
      toast.info("You've reached the maximum limit of experiences (5).");
    } else {
      setShowAddMoreExep(true);
      setShowEmpExperience(false);
    }
  };
  const handleChangeEdit = (experience) => {
    console.log(experience);
    setSelectedExperience(experience);
    setShowEmpExperience(true);
    setShowAddMoreExep(false);
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_EXPERIENCE}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })
      .then((result) => {
        console.log(result.experienceData, "This is experience result");
        setData(result.experienceData);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
    window.scrollTo(0, 0);
  }, []);
  // const startDateObj = new Date(data.startDate);
  // const startDateFormatted = startDateObj.toISOString().split("T")[0];

  return (
    <EmployeeProfileLayout showCameraIcon={false}>
      {isLoading ? (
        <div className="hhloader-div">
          <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
          <Typewriter
            options={{
              strings: ["Loading..."],
              autoStart: true,
              loop: true,
              delay: 30,
            }}
            className="loading-text-in-loader"
          />
        </div>
      ) : (
        <div className="emp-experience-container">
          <div className="container-emp-messanger-main">
            <div className="emp-title-div pb-4">
              <h3 className="emp-title">Experience</h3>
            </div>
            {!showEmpExperience && (
              <div>
                {!showAddMoreExep && data.length >= 0 && (
                  <div>
                    {data.map((experience) => (
                      <div
                        className="emp-messanger-card1"
                        key={experience.expId}
                      >
                        <div
                          className="top-card-bar"
                          style={{ marginBottom: "20px" }}
                        >
                          <h4 className="emp-experience-title">
                            Your work experience
                          </h4>
                          {!showEmpExperience && (
                            <button
                              className="emp-edit-btn"
                              onClick={() => handleChangeEdit(experience)}
                            >
                              Edit
                            </button>
                          )}
                        </div>

                        <hr className="emp-hr-line" />

                        <div>
                          <div className="emp-label-div">
                            <p className="emp-label">Position </p>
                            <p className="emp-text">{experience.position}</p>
                          </div>
                          <div className="emp-label-div">
                            <p className="emp-label">Start date</p>
                            <p className="emp-text">
                              {new Date(
                                experience.startDate
                              ).toLocaleDateString("en-GB")}
                            </p>
                          </div>
                          <div className="emp-label-div">
                            <p className="emp-label">End date</p>
                            <p className="emp-text">
                              {new Date(experience.endDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </p>
                          </div>
                          <div className="emp-label-div">
                            <p className="emp-label">Employee country</p>
                            <p className="emp-text">
                              {experience.employedCountry}
                            </p>
                          </div>
                          <div className="emp-label-div">
                            <p className="emp-label">Location</p>
                            <p className="emp-text">{experience.location}</p>
                          </div>
                          <div className="emp-label-div">
                            <p className="emp-label">Duties</p>
                            <p className="emp-exp emp-label-field-div">
                              {experience.duties.map((duty, index) => (
                                <p className="emp emp-text-multi" key={index}>
                                  {duty}
                                </p>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    {!showEmpExperience && (
                      <div className="emp-cancel-btns-div pt-3">
                        {data.length < experienceLimit && (
                          <button
                            className="emp-edit-btn cp-save-btn add-more-btn-expereience"
                            type="submit"
                            style={{ width: "168px" }}
                            onClick={handleShowAddMore}
                          >
                            + Add Exp
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {showAddMoreExep && (
            <AddMoreExperience
              setShowAddMoreExep={setShowAddMoreExep}
              onCancel={() => setShowAddMoreExep(false)}
            />
          )}

          {showEmpExperience && selectedExperience && (
            <EmployeeExperienceEdit
              prevdata={selectedExperience}
              setShowEmpExperience={setShowEmpExperience}
              experienceId={selectedExperience.expId}
            />
          )}
        </div>
      )}
    </EmployeeProfileLayout>
  );
};

export default EmployeeExperience;
