import React, { useState,useEffect } from "react";
// import { BsThreeDots } from "react-icons/bs";
// import { LuMessageSquare } from "react-icons/lu";
import "./CustomerChatList.css";
import StatusIconOnline from "../../../Employee/EmployeeChatComponents/StatusIcons/StatusIconOnline/StatusIconOnline";
import DefaultProfilePic from "../../../../Assets/Images/Default_pfp.svg.png";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomerChatListAction } from "../../../../redux/actions/getAllCustomerChatListAction";

const CustomerChatList = ({
  onChatClick,
  emailIdOfEmployee,
  emailIdOfCustomer,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const activeUsers = useSelector((state) => state.activeUsers);
  const customerChatListObject = useSelector(
    (state) => state.customerChatListData
  );
  console.log("customerChatListObject", customerChatListObject);
  const customerChatList = customerChatListObject.chats;
  console.log("customerChatList", customerChatList);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getAllCustomerChatListAction())
  }, []);

  const onlineStatus = (email) =>
    activeUsers.some((user) => user.userId === email);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const filteredCustomerChatList = Array.isArray(customerChatList)
    ? customerChatList.filter((customerConversation) =>
        customerConversation.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      {customerChatList?.length === 0 ? (
        <div className="ecc-no-chat-selected">
          <p className="ecc-no-chat-selected-text">Your chat is empty</p>
        </div>
      ) : (
        <div className="ecc-emp-chat-list-div">
          <div className="ecc-chat-heading-div">
            <div className="ecc-chat-heading-text-div">
              <p className="ecc-emp-chat-heading">Chats</p>
            </div>
            <div className="ecc-chat-icons-div">
              <div className="ecc-chat-icons">{/* <BsThreeDots /> */}</div>
              <div className="ecc-chat-icons">{/* <LuMessageSquare /> */}</div>
            </div>
          </div>
          
            <div className="ecc-search-employees-chat-list">
              <input
                type="text"
                placeholder="Search                                            "
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="cce-chat-list-div">
              {filteredCustomerChatList.map((customerConversation, index) => (
                <div
                  className="emp-chat-list-cust-side-div"
                  key={customerConversation.emailId}
                >
                  <hr className="ecc-hr-line"></hr>
                  <div
                    className="ecc-emp-single-chat-div-list"
                    // key={index}
                    onClick={() => {
                      onChatClick(
                        customerConversation.emailId,
                        customerConversation.name,
                        customerConversation.profileImage
                      );
                    }}
                  >
                    <div className="ecc-emp-single-chat-div">
                      <div className="ecc-emp-chat-profile-pic-div ecc-indchat-profile-pic-div">
                        <img
                          src={
                            customerConversation.profileImage ??
                            DefaultProfilePic
                          }
                          className="customer-profile-img"
                          alt="customer-profile"
                        />

                        {/*  if user active shows color green, if offline shows grey color*/}
                        <div className="ecc-chat-active-status-icon-div">
                          {onlineStatus(customerConversation.emailId) ? (
                            <StatusIconOnline color="#22C55E" />
                          ) : (
                            <StatusIconOnline color="#D1D5DB" />
                          )}
                        </div>
                      </div>
                      <div className="ecc-emp-single-chat-div-part">
                        <div className="ecc-emp-single-chat-details">
                          <span className="ecc-emp-chat-customer-name">
                            {customerConversation.name ?? "Unknown Employee"}
                          </span>
                          <span className="ecc-emp-chat-time">
                            <span className="ecc-emp-chat-time">
                              {onlineStatus(customerConversation.emailId)
                                ? "Online"
                                : "Offline"}
                            </span>
                          </span>
                        </div>
                        <div>
                          <p className="ecc-emp-chat-msg">
                            {customerConversation.lastMessage
                              ? customerConversation.lastMessage.length > 50
                                ? customerConversation.lastMessage.substring(
                                    0,
                                    50
                                  ) + " ..."
                                : customerConversation.lastMessage
                              : "Message not found"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <hr className="ecc-hr-line"></hr>
            </div>
          
        </div>
      )}
    </>
  );
};

export default CustomerChatList;
