import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import ProfileTopbarEmployee from "../../Components/TopBar/ProfileTopbarEmployee";
import EmployeeProfileSideBar from "../../Components/EmployeeProfileSideBar/EmployeeProfileSideBar";
import MobileTopBarEmployee from "../../Components/MobileTopBar/MobileTopBarEmployee";
import MobileNavBarEmployee from "../../Components/MobileNavBar/MobileNavBarEmployee";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";

const EmployeeProfileLayout = ({ showCameraIcon, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  //for loading effect used this useEffect
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  //this loader props for the loader styles

  return (
    <div style={{backgroundColor:'#F7FAFB'}}>
    <>
    <Grid>
      <div className="d-none d-md-block">
        <ProfileTopbarEmployee />
      </div>
      <div className="d-block d-sm-none">
        <MobileTopBarEmployee />
      </div>
    </Grid>
    <div
      className="container profile-layout-padding-class"
      style={{ paddingTop: "5.5rem" }}
    >
      <Grid container spacing={6} className="">
        <Grid item xs={12} md={3} lg={3}>
          <div className="d-none d-lg-block">
            <EmployeeProfileSideBar showCameraIcon={showCameraIcon}/>
          </div>
        </Grid>
        {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
        <Typewriter
          options={{
            strings: ["Loading..."],
            autoStart: true,
            loop: true,
            delay: 30,
          }}
          className="loading-text-in-loader"
        />
          </div>
        ) : (
          <Grid item xs={12} md={9} lg={9}>
            {children}
          </Grid>
        )}
      </Grid>
    </div>

    <Grid>
      <div className="d-none d-md-block">
        <Footer />
      </div>
      <div className="d-block d-sm-none">
        <MobileNavBarEmployee />
      </div>
    </Grid>
  </>
  </div>
  );
};

export default EmployeeProfileLayout;
