import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const heartlyHelper = localStorage.getItem("token");
    if (heartlyHelper) {
        return true;
    } else {
        return false;
    }

};
const ProtectedRoutes = () => {

    const auth = useAuth();
    return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;