import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ProfileTopbarCustomer from "../../../Components/TopBar/ProfileTopbarCustomer";
import MobileTopBarCustomer from "../../../Components/MobileTopBar/MobileTopBarCustomer";
import Footer from "../../../Components/Footer/Footer";
import MobileNavBarCustomer from "../../../Components/MobileNavBar/MobileNavBarCustomer";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
// import "./AvailableJobsIndividualFullViewLayout.css";
import AvailableJobsSideBar from "../../../Components/Employee/AvailableJobsComponents/AvailableJobsSideBar/AvailableJobsSideBar";
import MobileNavBarEmployee from "../../../Components/MobileNavBar/MobileNavBarEmployee";
import ProfileTopbarEmployee from "../../../Components/TopBar/ProfileTopbarEmployee";
import MobileTopBarEmployee from "../../../Components/MobileTopBar/MobileTopBarEmployee";
const AvailableJobsIndividualFullViewLayout = ({
  handleApplyFilters,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Grid>
        <div className="d-none d-md-block">
          <ProfileTopbarEmployee />
        </div>
        <div className="d-block d-sm-none">
          <MobileTopBarEmployee />
        </div>
      </Grid>
      {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem" }}
        >
          <Grid container spacing={6} className="">
            {/* <Grid item xs={12} md={3} lg={3}>
            <div className="d-none d-lg-block">
              <AvailableJobsSideBar onApplyFilters={handleApplyFilters}/>
            </div>
          </Grid> */}
            {isLoading ? (
              <div className="hhloader-div">
                <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
                <Typewriter
                  options={{
                    strings: ["Loading..."],
                    autoStart: true,
                    loop: true,
                    delay: 30,
                  }}
                  className="loading-text-in-loader"
                />
              </div>
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                {children}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <Grid>
        <div className="d-none d-md-block">
          <Footer />
        </div>
        <div className="d-block d-sm-none">
          <MobileNavBarEmployee />
        </div>{" "}
      </Grid>
    </>
  );
};

export default AvailableJobsIndividualFullViewLayout;
