import React from "react";
import "./ZipCode.css";

const ZipCode =({register, showLabel=true}) => {
    return (
        <div>
          {showLabel && (
                  <label className="label-registration">Zip Code <span className="error-symbol">*</span></label>

      )}
          <input className="input-box-zc" type="number" placeholder="0000" {...register}/>
        </div>
      );
    }
export default ZipCode;