import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./Styles/Styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Store from "./redux/Store";
import i18n from "./i18n.js";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

let persistor = persistStore(Store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={Store}>
      {/* <I18nextProvider i18n={i18n}> */}
      <Suspense fallback="...loading">
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Suspense>
      {/* </I18nextProvider>, */}
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
