import React, { useState, useEffect } from "react";
import "./EmployeeDocument.css";
import Dropzone from "../../../Components/DropZone/DropZone";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_DOCUMENTS } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { EMPLOYEE_DOCUMENTS_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import { Construction } from "@mui/icons-material";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";
import { useNavigate } from "react-router-dom";

const EmpDocs = () => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  function getFileNameFromURL(url) {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.split("/").pop();
  }

  const Token = localStorage.getItem("token");

  const handleFilesDropped = (files, index) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = new Blob([files[0]], { type: files[0].type });
      return updatedFiles;
    });
    setErrorMessages((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = "";
      return updatedErrors;
    });
  };

  const handleSaveButtonClick = () => {
    const newErrorMessages = [];

    if (uploadedFiles.length === 0) {
      newErrorMessages.push(
        "Please select files for Background Check Verification."
      );
    }

    setErrorMessages(newErrorMessages);

    if (newErrorMessages.length === 0) {
      console.log("API call with files:", uploadedFiles);
      const first_file = uploadedFiles[0];
      console.log("first file", first_file);
      const Second_file = uploadedFiles[0];
      console.log("second file", Second_file);
      const Third_file = uploadedFiles[0];
      console.log("Third file", Third_file);
      const Fourth_file = uploadedFiles[0];
      console.log("fourth file", Fourth_file);
      const Fifth_file = uploadedFiles[0];
      console.log("fifth file", Fifth_file);
      const Sixth_file = uploadedFiles[0];
      console.log("sixth file", Sixth_file);
      const Seventh_file = uploadedFiles[0];
      console.log("seventh file", Seventh_file);
      //do api call here
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Token}`);

      var formdata = new FormData();
      formdata.append(
        "backGroundCheckDoc",
        uploadedFiles[0] != undefined && uploadedFiles[0]
      );
      formdata.append(
        "referenceLetterDoc",
        uploadedFiles[1] != undefined && uploadedFiles[1]
      );
      formdata.append(
        "passportDoc",
        uploadedFiles[2] != undefined && uploadedFiles[2]
      );
      formdata.append(
        "visaDoc",
        uploadedFiles[3] != undefined && uploadedFiles[3]
      );
      formdata.append(
        "workPermitDoc",
        uploadedFiles[4] != undefined && uploadedFiles[4]
      );
      formdata.append(
        "healthInsurenceDoc",
        uploadedFiles[5] != undefined && uploadedFiles[5]
      );
      formdata.append(
        "driverLicenseDoc",
        uploadedFiles[6] != undefined && uploadedFiles[6]
      );

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseUrl}${EMPLOYEE_DOCUMENTS_UPDATE}`, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            // Handle unauthorized access by redirecting to the login page
            navigate("/login");
            toast.error("Connection timed out, Please log in to access");
            return;
          } else if (response.status === 200) {
            return response.json();
          } else if (response.status === 500) {
            // Handle server error (something went wrong)
            console.error("Server error: Something went wrong");
            
          } else {
           
            console.error("Unknown error: Status code", response.status);
            
          }
        })
        .then((result) => {
          console.log(result, "employee documents data");
          if (result.statuscode === 200) {
            toast.info("document  is successfully Uploaded");

            //window.location.reload();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_DOCUMENTS}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "result @@@@@");
        setData(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  console.log(
    data.backGroundCheckDocUrl
      ? getFileNameFromURL(data.backGroundCheckDocUrl)
      : "ABC",
    "getfilename@2@"
  );
  console.log(data, "getdocsdata @@@");
  console.log(data.backGroundCheckDocUrl, "databackground@!@");
  return (
    <EmployeeProfileLayout showCameraIcon={false}>
      <div className="container-emp-document-main">
        <div className="emp-title-div-d pb-5">
          <h3 className="emp-title">Documents</h3>
        </div>
        {/* <hr className="emp-hr-line" /> */}

        <div className="emp-messanger-card1">
          <h4>Upload your documents here</h4>
          <hr className="emp-hr-line" />
          <p className="emp-update-text pb-4">
            You must submit your documents to obtain employment.
            <span
              style={{ color: "#04AFF0", fontWeight: 600, cursor: "pointer" }}
            >
              Learn more
            </span>
          </p>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Background Check Verification</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 0)}
              uploadedFileUrl={data.backGroundCheckDocUrl}
              uploadedFileName={
                data.backGroundCheckDocUrl
                  ? getFileNameFromURL(data.backGroundCheckDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[0] && (
              <p className="error-message">{errorMessages[0]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Reference Letter Verification</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 1)}
              uploadedFileUrl={data.referenceLetterDocUrl}
              uploadedFileName={
                data.referenceLetterDocUrl
                  ? getFileNameFromURL(data.referenceLetterDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[1] && (
              <p className="error-message">{errorMessages[1]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Passport Verification</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 2)}
              uploadedFileUrl={data.passportDocUrl}
              uploadedFileName={
                data.passportDocUrl
                  ? getFileNameFromURL(data.passportDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[2] && (
              <p className="error-message">{errorMessages[2]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Visa Verification</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 3)}
              uploadedFileUrl={data.visaDocUrl}
              uploadedFileName={
                data.visaDocUrl ? getFileNameFromURL(data.visaDocUrl) : ""
              }
            />
            {/* {errorMessages[3] && (
              <p className="error-message">{errorMessages[3]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Work Permit Verification</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 4)}
              uploadedFileUrl={data.workPermitDocUrl}
              uploadedFileName={
                data.workPermitDocUrl
                  ? getFileNameFromURL(data.workPermitDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[4] && (
              <p className="error-message">{errorMessages[4]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Health Insurance card (optional)</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 5)}
              uploadedFileUrl={data.healthInsurenceDocUrl}
              uploadedFileName={
                data.healthInsurenceDocUrl
                  ? getFileNameFromURL(data.healthInsurenceDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[5] && (
              <p className="error-message">{errorMessages[5]}</p>
            )} */}
          </div>

          <div
            className="emp-label-div-desktop"
            style={{ marginBottom: "24px" }}
          >
            <p className="emp-label">Drivers License Verification (optional)</p>
            <Dropzone
              onFilesDropped={(files) => handleFilesDropped(files, 6)}
              uploadedFileUrl={data.driverLicenseDocUrl}
              uploadedFileName={
                data.driverLicenseDocUrl
                  ? getFileNameFromURL(data.driverLicenseDocUrl)
                  : ""
              }
            />
            {/* {errorMessages[6] && (
              <p className="error-message">{errorMessages[6]}</p>
            )} */}
          </div>
        </div>

        <div className="emp-cancel-btns-div pt-3">
          {uploadedFiles.length > 0 && (
            <button
              onClick={handleSaveButtonClick}
              className="emp-edit-btn cp-save-btn save-btn-in-documents-employee"
              type="submit"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </EmployeeProfileLayout>
  );
};

export default EmpDocs;
