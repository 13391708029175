import React from "react";

const StatusIconOnline = ({ color }) => {
  return (
    <div
      className="status-icon-online"
      style={{
        backgroundColor: color,
        width: "12px",
        height: "12px",
        bottom: "0",
        right: "0",
        borderRadius: "50%",
        // position: "",
      }}
    ></div>
  );
};

export default StatusIconOnline;
