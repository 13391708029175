import React from "react";
import "./StaticPageFooter.css";
import { CgFacebook } from "react-icons/cg";
import { BsInstagram } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import logo from "../../Assets/Svg/logo.svg";
import engFlag from "../../Assets/Images/englishflag.png";
import thaiflag from "../../Assets/Images/thailand.png";
import pseudo from "../../Assets/Images/pseudo.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

// You may need to adjust the import path

const StaticPageFooter = ({ selectedLanguage, changeLanguage }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); //for languge conversion
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const onClickLogo = () => {
    navigate("/");
  };

  return (
    <div className="static-footer-container">
      <div className="footer-box">
        <div className="footer-col-1">
          <div>
            <img
              src={logo}
              alt=""
              onClick={onClickLogo}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="footer-col-11">
            <div>
              <h6>{t("translation.Address")}:</h6>
              <p>{t("translation.addressthai")}</p>
            </div>
            <div>
              <h6>{t("translation.Contact")}:</h6>
              <p className="ph-no-static-page">+66-98369-5514</p>
              <a
                className="emaill-static-page"
                href="mailto:support@heartyhelper.co"
              >
                support@heartyhelper.co
              </a>
            </div>
          </div>
          <div className="social-links">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/HeartHelper.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CgFacebook className="icon-white" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/HeartyHelper"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="instagram"
                >
                  <BsInstagram className="icon-white" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/HeartyHelper"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <FaTwitter className="icon-white" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/HeartyHelper"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin"
                >
                  <BsLinkedin className="icon-white" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-col-2">
          <div className="footer-grid">
            <div className="line1">
              <div>
                <Link style={{ color: "white" }} to="/">
                  {t("translation.home")}{" "}
                </Link>
              </div>
              <div>
                <Link style={{ color: "white" }} to="/about-us">
                  {t("translation.aboutus")}
                </Link>
              </div>
              <div>
                <Link style={{ color: "white" }} to="/contact-us">
                  {t("translation.ContactUs")}{" "}
                </Link>
              </div>
            </div>
            <div className="line1">
              <div>
                <Link style={{ color: "white" }} to="/termsofservice">
                  {t("translation.Termsofservices")}
                </Link>
              </div>
              <div>
                <Link style={{ color: "white" }} to="/client-agreement">
                  {t("translation.ClientAgreement")}
                </Link>
              </div>
              <div>
                <Link style={{ color: "white" }} to="/helper-agreement">
                  {t("translation.HelperAgreement")}
                </Link>
              </div>
            </div>
            <div className="line1">
              <div>
                <Link style={{ color: "white" }} to="/privacy-policy">
                  {t("translation.PrivacyPolicy")}
                </Link>
              </div>
              <div>
                <Link style={{ color: "white" }} to="/cookies-policy">
                  {t("translation.CookiesPolicy")}
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-lang">
            {/* <img src={pseudo} alt="" style={{ cursor: "pointer" }} />
            English &#x25BC; */}
            <div className="custom-select">
              {i18n.language === "en" ? (
                <img src={engFlag} alt="English Flag" className="flag-icon" />
              ) : (
                <img src={thaiflag} alt="Thai Flag" className="flag-icon" />
              )}

              <select
                className="selectBox selectBox-langu-change selectBox-langu-change-footer"
                name="lang"
                onChange={(e) => changeLanguage(e.target.value)}
                value={selectedLanguage}
              >
                <option value="en" className="lan-change-option-drpdown">
                  English
                </option>
                <option value="thai" className="lan-change-option-drpdown">
                  Thai
                </option>
              </select>
              <span className="custom-arrow lang-change-arrow"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-credits">
        <p>© 2023 Hearty Helper. {t("translation.Allrightsreserved.")}</p>
        <div className="footer-credits-link">
          <p>
            <Link style={{ color: "white" }} to="/privacy-policy">
              {t("translation.PrivacyPolicy")}
            </Link>
          </p>
          <p>
            <Link style={{ color: "white" }} to="/termsofservice">
              {t("translation.Termsofservices")}
            </Link>
          </p>
          <p>
            <Link style={{ color: "white" }} to="/cookies-policy">
              {t("translation.CookiesSettings")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StaticPageFooter;
