import React from "react";
import { ImLocation } from "react-icons/im";
import { MdOutlineWatchLater } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const JobTitleCard = ({
  title,
  department,
  expectations,
  location,
  contractType,
}) => {
  const navigate = useNavigate();
const { t, i18n } = useTranslation();

  const handleClickSignup = () => {
    navigate("/login");
  };

  return (
    <div className="card">
      <div className="head-home-pg">
        <div className="title-job-home">
          <h5>{title}</h5>
          <label className="Dep"> {t("translation.tag")}</label>
        </div>
        <p>{expectations}</p>
      </div>
      <div className="info-home">
        <div className="info2-home">
          <ImLocation className="infi-ic" />
          <p>{location}</p>
        </div>
      </div>
      <button className="blue-button" onClick={handleClickSignup}>
        {t("translation.applynow")}
      </button>
    </div>
  );
};

export default JobTitleCard;
