import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineLink } from "react-icons/ai";
import ProfileLayoutCustomer from "../../../Layout/ProfileLayout/ProfileLayoutCustomer";
import { toast } from "react-toastify";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  LineIcon,
  TelegramIcon,
  EmailIcon,
  RedditIcon,
  TwitterIcon,
} from "react-share";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  LineShareButton,
  TelegramShareButton,
  EmailShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import "./CustomerInviteFriends.css";

const CustomerInviteFriends = () => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const websiteUrl = "https://www.heartyhelper.co/";
  const titleTag = "Welcome to the Hearty Helper!";
  const title =
    "Welcome to the Hearty Helper! Our mission is simple To bridge the gap between individuals seeking domestic services and those looking for rewarding job opportunities. We strive to create a harmonious environment where both customers and service providers can benefit from a seamless and efficient experience.";
  const emailBody = `Welcome to the Hearty Helper! Our mission is simple: To bridge the gap between individuals seeking domestic services and those looking for rewarding job opportunities. We strive to create a harmonious environment where both customers and service providers can benefit from a seamless and efficient experience. Check out Hearty Helper: ${websiteUrl}`;
  const twitterHashtags = ["heartyhelper", "Heary Helper"];
  const twitterrelated = ["heartyhelper", "Heary Helper"];
  const copyToClipboard = () => {};
  const handleCopy = () => {
    setIsCopied(true);
    toast.success("Link copied to clipboard!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <>
      <ProfileLayoutCustomer showCameraIcon={false}>
        <div className="container cs-profile-main">
          <h4>Invite Friends</h4>
          <div className="share-invite-friends-icons-div">
            <FacebookShareButton
              url={websiteUrl}
              title={title}
              quote="link"
              hashtag="#heartyhelper"
            >
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>
            <LinkedinShareButton
              url={websiteUrl}
              title={titleTag}
              summary={title}
              source={websiteUrl}
            >
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
            <WhatsappShareButton url={websiteUrl} title={title} separator=" ">
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            <LineShareButton url={websiteUrl} title={titleTag}>
              <LineIcon size={40} round={true} />
            </LineShareButton>
            <TelegramShareButton url={websiteUrl} title={title}>
              <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <EmailShareButton
              url={websiteUrl}
              subject={"Welcome to the Hearty Helper!"}
              body={emailBody}
            >
              <EmailIcon size={40} round={true} />
            </EmailShareButton>
            <TwitterShareButton
              url={websiteUrl}
              title={title}
              related={twitterrelated}
              hashtags={twitterHashtags}
              via="heartyhelper"
            >
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
            <CopyToClipboard text={websiteUrl} onCopy={handleCopy}>
              <div>
                <AiOutlineLink
                  size={35}
                  color={"#212529"}
                  className="copy-clipboard-invite-friends"
                  onClick={copyToClipboard}
                />
                {/* {isCopied && <p>Link copied to clipboard!</p>} */}
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </ProfileLayoutCustomer>
    </>
  );
};

export default CustomerInviteFriends;
