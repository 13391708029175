export const DELETE_ALL_CUSTOMER_NOTIFICATIONS_REQUEST = 'DELETE_ALL_CUSTOMER_NOTIFICATIONS_REQUEST';
export const DELETE_ALL_CUSTOMER_NOTIFICATIONS_SUCCESS = 'DELETE_ALL_CUSTOMER_NOTIFICATIONS_SUCCESS';
export const DELETE_ALL_CUSTOMER_NOTIFICATIONS_FAILURE = 'DELETE_ALL_CUSTOMER_NOTIFICATIONS_FAILURE';

export const DELETE_CUSTOMER_NOTIFICATION_BY_ID_REQUEST = 'DELETE_CUSTOMER_NOTIFICATION_BY_ID_REQUEST';
export const DELETE_CUSTOMER_NOTIFICATION_BY_ID_SUCCESS = 'DELETE_CUSTOMER_NOTIFICATION_BY_ID_SUCCESS';
export const DELETE_CUSTOMER_NOTIFICATION_BY_ID_FAILURE = 'DELETE_CUSTOMER_NOTIFICATION_BY_ID_FAILURE';

export const DELETE_ALL_EMPLOYEE_NOTIFICATIONS_REQUEST = 'DELETE_ALL_EMPLOYEE_NOTIFICATIONS_REQUEST';
export const DELETE_ALL_EMPLOYEE_NOTIFICATIONS_SUCCESS = 'DELETE_ALL_EMPLOYEE_NOTIFICATIONS_SUCCESS';
export const DELETE_ALL_EMPLOYEE_NOTIFICATIONS_FAILURE = 'DELETE_ALL_CUSTOMER_NOTIFICATIONS_FAILURE';

export const DELETE_EMPLOYEE_NOTIFICATION_BY_ID_REQUEST = 'DELETE_EMPLOYEE_NOTIFICATION_BY_ID_REQUEST';
export const DELETE_EMPLOYEE_NOTIFICATION_BY_ID_SUCCESS = 'DELETE_EMPLOYEE_NOTIFICATION_BY_ID_SUCCESS';
export const DELETE_EMPLOYEE_NOTIFICATION_BY_ID_FAILURE = 'DELETE_EMPLOYEE_NOTIFICATION_BY_ID_FAILURE';


export const GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST = 'GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST';
export const GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS = 'GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS';
export const GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE = 'GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE';
