import React from "react";
import "./ThankyouModal.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ThankYouModal = ({ setShowThankYou }) => {
  const handleThankYou = () => {
    setShowThankYou(true);
  };

  const navigate = useNavigate();
  const onClickClose = () => {
    navigate("/login");
  };

  return (
    <>
      <Modal
        show={handleThankYou}
        onHide={onClickClose}
        contentClassName="modal-container-thy"
      >
        <Modal.Header className="header-thy">
          <Modal.Title className="title-thy">You have logged out!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="body-thy">
          Thank you for using our services. It has been a pleasure assisting
          you. If you have any further questions or need help in the future,
          feel free to reach out. Goodbye and take care!
        </Modal.Body>
        <Modal.Footer className="footer-thy">
          <div className="close-thy-div">
            <button className="close-thy" onClick={onClickClose}>
              Close
            </button>
          </div>
          <div></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ThankYouModal;
