import React, { useEffect, useState } from "react";
import "./ProfileSideBar.css";
import camera from "../../Assets/Svg/camera.svg";
import { BsFillPersonFill, BsFillCreditCardFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { HiBell } from "react-icons/hi";
import { AiFillGift } from "react-icons/ai";
import { GoSignOut } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { CUSTOMER_PROFILE } from "../../ApiEndPoints/ApiEndPoints";
import CustomerProfilePictureModal from "../../Modal/ProfilePicture/Customer/CustomerProfilePictureModal";
import CustomerSignOut from "../../Modal/SignOut/Customer/CustomerSignOut";
import { RiArrowDropDownFill } from "react-icons/ri";
import DefaultProfilePic from "../../Assets/Images/Default_pfp.svg.png";

const ProfileSideBar = ({ showCameraIcon }) => {
  const [data, setData] = useState({});
  const [profilePicture, setProfilePicture] = useState(false); //for profile picture change popup
  const [showCustomerSignout, setShowCustomerSignout] = useState(false); //for signout popup
  // const [imageLoaded, setImageLoaded] = useState(false); // Track the image loading status
  const [profileImageLoaded, setProfileImageLoaded] = useState(false); //for profile image loader

  const Token = localStorage.getItem("token");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };

  //get api for customer profile
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_PROFILE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "result");
        setData(result.customerData);
      })
      .catch((error) => console.log("error", error));
  }, []);

  //signout
  const onClickSignOut = () => {
    setShowCustomerSignout(true);
  };

  //profile picture popup
  const onClickCamera = () => {
    setProfilePicture(true);
  };
  const handleProfileImageLoad = () => {
    setProfileImageLoaded(true);
  };
  // Function to handle the image load event
  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };

  return (
    <>
      <div className="psb-main">
        <div className="psb-card px-3">
          <div className="psb-cust-text-div">
            <p className="psb-cust-text">Customer</p>
          </div>
          <div className="psb-profile-img-div">
            {/* <img
              src={data.profileImageUrl}
              alt=""
              className="psb-profile-img"
            /> */}
            {/* Display the placeholder image when the high-resolution image is not loaded */}
            {!profileImageLoaded && (
              <img
                src={data.profileImageUrl ?? DefaultProfilePic}
                alt=""
                className="psb-profile-img psb-profile-img-placeholder"
              />
            )}
            {/* Display the final image when it is loaded */}
            <img
              src={data.profileImageUrl ?? DefaultProfilePic}
              alt=""
              className={`psb-profile-img ${
                profileImageLoaded ? "" : "hidden"
              }`}
              onLoad={handleProfileImageLoad}
            />
            {showCameraIcon && (
              <img
                src={camera}
                alt=""
                className="psb-camera-img"
                onClick={onClickCamera}
              />
            )}
          </div>
          <p className="psb-name pt-3">
            {data.firstName} {data.lastName}
          </p>
        </div>
        <div className="psb-btns-div pt-5">
          <div
            onClick={() => handleClickNavigate("/profile")}
            className={`${
              pathname === "/profile" ? "psb-btn-active" : "psb-btn"
            }`}
          >
            <BsFillPersonFill /> <p className="px-2">Profile</p>
          </div>

          <div
            onClick={() => handleClickNavigate("/customer-account")}
            className={`${
              pathname === "/customer-account"
                ? "psb-btn-active mt-3"
                : "psb-btn mt-3"
            }`}
          >
            <IoMdSettings /> <p className="px-2">Account</p>{" "}
          </div>
          <div
            onClick={() => handleClickNavigate("/customer-subscription")}
            className={`${
              pathname === "/customer-subscription"
                ? "psb-btn-active mt-3"
                : "psb-btn mt-3"
            }`}
          >
            <BsFillCreditCardFill /> <p className="px-2">Subscription</p>
          </div>
          <div
            onClick={() =>
              handleClickNavigate("/customer-profile-notification")
            }
            className={`${
              pathname === "/customer-profile-notification"
                ? "psb-btn-active mt-3"
                : "psb-btn mt-3"
            }`}
          >
            <HiBell /> <p className="px-2">Notifications</p>
          </div>
          <div
            onClick={() => handleClickNavigate("/customer-invite-friends")}
            className={`${
              pathname === "/customer-invite-friends"
                ? "psb-btn-active mt-3"
                : "psb-btn mt-3"
            }`}
          >
            <AiFillGift />
            <p className="px-2">Invite friends</p>
          </div>
          <div className="psb-btn mt-3">
            <GoSignOut />
            <p className="px-2" onClick={onClickSignOut}>
              Sign out
            </p>
          </div>
        </div>
        <div className="psb-about-div pt-4">
          <p onClick={() => handleClickNavigate("/about-us")}>About</p>
          <p>Pricing</p>
          <p onClick={() => handleClickNavigate("/contact-us")}>Help Center</p>
        </div>
        <p
          className="psb-terms pt-4 pb-4"
          onClick={() => handleClickNavigate("/termsofservice")}
        >
          Terms & Privacy{" "}
          <RiArrowDropDownFill
            color={"#7B7B7B"}
            size={35}
            style={{ marginTop: "-3px" }}
          />{" "}
        </p>
      </div>
      {profilePicture && (
        <CustomerProfilePictureModal setProfilePicture={setProfilePicture} />
      )}
      {showCustomerSignout && (
        <CustomerSignOut setShowCustomerSignout={setShowCustomerSignout} />
      )}
    </>
  );
};
export default ProfileSideBar;
