import React, { useState } from "react";
import "./ForgotPasswordco.css";
import { ForgotPasswordContent } from "../../Content/ForgotPassword.content";
import BackButton from "../BackButton/BackButton";
import Email from "../Email/Email";
import { RegistrationContent } from "../../Content/RegistrationPage.content";
import { useForm } from "react-hook-form";
import OtpVerification from "../OtpVerification/OtpVerification";
import Button from "../Button/Button";
import { OtpVerificationPage } from "../../Content/OtpVerificationPage.content";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  RESET_PASSWORD_OTP_CUSTOMER,
  RESET_PASSWORD_OTP_EMPLOYEE,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";

const ForgotPassword = ({ showCustomer, setShowForgotPassword }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [otpVerification, setShowOtpVerification] = useState(false);
  const [data, setData] = useState({});

  const onSubmit = (data) => {
    // console.log("Login form Submit", data);
    if (data) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `${BaseUrl}${
          showCustomer == true
            ? RESET_PASSWORD_OTP_CUSTOMER
            : RESET_PASSWORD_OTP_EMPLOYEE
        }${data.email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.statuscode === 200) {
            setData(data);
            setShowOtpVerification(true);
            toast.info("OTP successfully sent to your registered email id");
          } else if (result.statuscode == 400) {
            toast.error("This email is not associated with any account");
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };
  const onClickBackButton = () => {
    setShowForgotPassword(false);
  };
  const navigate = useNavigate();
  const onClickSignUp = () => {
    navigate("/");
  };
  // console.log("fpshowCustomer",showCustomer);

  return (
    <>
      {!otpVerification && (
        <div>
          <div className="backbutton-f">
            <BackButton onClick={onClickBackButton} />
          </div>
          <div className="forgotpassword-container">
            <form
              className="forgotpasswordform"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="forgotpasswordheader">
                {ForgotPasswordContent.FORGOTPASSWORDHEADER}
              </div>
              <div className="forgotpasswordparagraph">
                {ForgotPasswordContent.FORGOTPASSWORDPARAGRAPH}
              </div>
              <div className="fpemail">
                <Email
                  register={register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <span className="error-msg">Please enter Email</span>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <span className="error-msg">Invalid Email Address</span>
                )}
              </div>
              <div className="button-Next">
                <Button buttonName={"Next"} />
              </div>
            </form>
            <div className="footer-fp">
              <p className="footer-content-forgotpassword">
                Don’t have an account?{" "}
                <span>
                  <button className="Signup-button" onClick={onClickSignUp}>
                    {" "}
                    SignUp
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {otpVerification && (
        <OtpVerification
          setShowOtpVerification={setShowOtpVerification}
          otpVerification={otpVerification}
          forgetPasswordData={data}
          showCustomer={showCustomer}
        />
      )}
    </>
  );
};
export default ForgotPassword;
