import React, { useState, useEffect } from "react";
import "./EmployeeProfileEdit.css";
import { useForm, Controller, useFormState } from "react-hook-form";
import TextEditor from "../../TextEditor/TextEditor";
import Country from "../../../Components/CountryInput/Country";
import { toast } from "react-toastify";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { useNavigate } from "react-router-dom";
import { EMPLOEE_PROFILE_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";

// import Select from "react-select";
// import { RHFInput } from "react-hook-form-input";

const EmployeeProfileEdit = ({ setShowEmployeeEditPage, prevdata }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    control,
    setValue,
    reset,
  } = useForm({ defaultValues: prevdata });
  const { isDirty, isSubmitting } = useFormState({ control });

  const resetForm = () => {
    reset(prevdata);
    setShowError(false);
  };
  const numberArray = Array.from({ length: 83 }, (_, index) => index + 18);

  const yearOptions = Array.from({ length: 60 }, (_, index) => index + 1).map(
    (year) => ({
      value: `${year} years`,
      label: `${year} years`,
    })
  );
  const [showError, setShowError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const Token = localStorage.getItem("token");

  const options = [
    { value: "Nanny", label: "Nanny" },
    { value: "carer", label: "carer" },
    { value: "Maid", label: "Maid" },
  ];
  const handleSelectOptionsChange = (selected) => {
    console.log("handleSelectOptionsChange", selected);
    setSelectedOptions(selected);
  };

  const onClickCancel = () => {
    setShowEmployeeEditPage(false);
    resetForm();
    window.scrollTo(0, 0);
  };

  const onSubmit = (data) => {
    console.log(data, "All Form data");
    if (selectedOptions.length <= 0) {
      setShowError(true);
    }

    const fullName = data.first_name + "," + data.last_name;
    const fullsalary = `${data.sal} - ${data.Last_sal}`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      name: fullName,
      maritalStatus: data.Martial,
      workExperience: data.experience,
      religion: data.religion,
      country: data.country,
      bio: data.Description,
      age: data.age,
      salary: fullsalary,
      workSchedule: data.workschedule,
      provience: data.provience,
      role: data.role,
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOEE_PROFILE_UPDATE}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        console.log(result, "employee profile data");
        if (result.statuscode === 200) {
          toast.info("Profile is successfully updated");

          window.location.reload();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  console.log(prevdata, "prev data");

  useEffect(() => {
    Object.entries(prevdata).forEach(([name, value]) => {
      setValue(name, value || " ");
    });

    setValue("first_name", prevdata.firstName);

    // const last_sal = fullName.split(" ")[1];
    setValue("last_name", prevdata.lastName || "");
    setValue("age", prevdata.age || "");
    setValue("Martial", prevdata.maritalStatus || "");
    setValue("experience", prevdata.workExperience || "");
    setValue("religion", prevdata.religion || "");
    setValue("country", prevdata.country || "");
    setValue("Description", prevdata.bio || "");
    // setValue("fullsalary", prevdata.salary || "");
    setValue("workschedule", prevdata.workSchedule || "");
    setValue("provience", prevdata.provience || "");
    setValue("role", prevdata.role || "");
    setValue("nationality", prevdata.country || "");
    const [firstSal, lastSal] = prevdata.salary
      ? prevdata.salary.split(" - ")
      : ["", ""];

    setValue("sal", firstSal);
    setValue("Last_sal", lastSal);

    console.log(prevdata, "data changed from store");
  }, [prevdata, setValue]);

  const multiSelectRef = React.useRef(); // Create a ref

  const workschedule = [
    { value: "Part Time", label: "part-Time" },
    { value: "Full Time", label: "Full Time" },
    { value: "Flexible", label: "Flexible" },
    { value: "Remote", label: "Remote" },
  ];
  const role = [
    { value: "Nanny", label: "Nanny" },
    { value: "Carer", label: "Carer" },
    { value: "Maid", label: "Maid" },
  ];
  const provience = [
    { value: "AB", label: "Alberta" },
    { value: "British Columbia", label: "British Columbia" },
    { value: "Manitoba", label: "Manitoba" },
    { value: "New Brunswick", label: "New Brunswick" },
    { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
    { value: "Nova Scotia", label: "Nova Scotia" },
    { value: "Ontario", label: "Ontario" },
    { value: "Prince Edward Island", label: "Prince Edward Island" },
    { value: "Quebec", label: "Quebec" },
    { value: "Saskatchewan", label: "Saskatchewan" },
    { value: "Northwest Territories", label: "Northwest Territories" },
    { value: "Nunavut", label: "Nunavut" },
    { value: "Yukon", label: "Yukon" },
  ];

  return (
    <div className="container-emp-profile-main">
      <div className="emp-profile-card">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="emp-title">Update Profile</h4>
            <p className="emp-update-text">Update your profile here.</p>
          </div>
        </div>
        <hr className="emp-hr-line"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="emp-form-label">Name</label>
              </div>
              {/* <label className="emp-form-label">Name</label> */}
              <div className="emp-pro-edit-sal">
                <div className="namefields">
                  <input
                    className="emp-form-input"
                    type="text"
                    {...register("first_name", {
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.first_name && (
                    <p className="error-msg">Please Enter The Name</p>
                  )}
                </div>

                <div className="namefields">
                  <input
                    className="emp-form-input"
                    type="text"
                    {...register("last_name", {
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.last_name && (
                    <p className="error-msg">Please Enter The Name</p>
                  )}
                </div>
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit">Age</label>
              </div>
              {/* <label className="emp-form-label">Age</label> */}
              <div className="emp-pro-edit-value">
                <select
                  className="cp-form-input"
                  {...register("age", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  {numberArray.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>

                {errors.age && (
                  <p className="error-msg">Please select the age</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit-emp">Role</label>
              </div>
              <div className="profileeditinput div">
                <MultiSelectDropdown
                  control={control}
                  name="role"
                  options={role}
                  rules={{ required: "Please select at least one option" }}
                />
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit-emp">
                  Work Experience
                </label>
              </div>
              <div className="emp-pro-edit-value">
                <select
                  className="emp-form-input"
                  name="experience"
                  {...register("experience", { required: true })}
                >
                  <option value="">Select Experience</option>
                  {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                {errors.experience && (
                  <p className="error-msg">Please select experience</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="emp-form-label">Work Schedule</label>
              </div>
              <div className="profileeditinput div">
                <MultiSelectDropdown
                  control={control}
                  name="workschedule"
                  options={workschedule}
                  rules={{ required: "Please select at least one option" }}
                />
                {errors.multiselectField && (
                  <p className="error-msg">Please select at least one option</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                {" "}
                <label className="emp-form-label">Salary</label>
              </div>
              <div className="emp-pro-edit-sal">
                <div className="namefields">
                  <select
                    className="emp-form-input"
                    {...register("sal", {
                      required: true,
                    })}
                  >
                    {[...Array(20)].map((_, index) => {
                      const value = (index + 1) * 100;
                      return (
                        <option key={value} value={`$ ${value}`}>
                          $ {value}
                        </option>
                      );
                    })}
                  </select>
                  {errors.sal && (
                    <p className="error-msg">Please select the salary</p>
                  )}
                </div>
                <span style={{ marginTop: "10px" }}>-</span>

                <div className="namefields">
                  <select
                    className="emp-form-input"
                    {...register("Last_sal", {
                      required: true,
                    })}
                  >
                    {[...Array(21)].map((_, index) => {
                      const value = (index + 20) * 100;
                      return (
                        <option key={value} value={`$ ${value}`}>
                          $ {value}
                        </option>
                      );
                    })}
                  </select>
                  {errors.Last_sal && (
                    <p className="error-msg">Please Select the salary</p>
                  )}
                </div>
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit">Country</label>
              </div>
              <div className="emp-pro-edit-value">
                <Country
                  label="false"
                  className="cp-form-input"
                  register={register("country", {
                    required: true,
                  })}
                />

                {errors.country && (
                  <p className="error-msg">Please select the country</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit-emp">Province</label>
              </div>
              <div className="profileeditinput div">
                <MultiSelectDropdown
                  control={control}
                  name="provience"
                  options={provience}
                  rules={{ required: "Please select at least one option" }}
                />
                {errors.multiselectField && (
                  <p className="error-msg">Please select at least one option</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="cp-form-label-proedit"> Martial Status</label>
              </div>
              <div className="emp-pro-edit-value">
                <select
                  className="emp-form-input"
                  {...register("Martial", {
                    required: true,
                  })}
                >
                  <option value=""> select</option>
                  <option value="Single"> Single</option>
                  <option value="Married"> Married</option>
                  <option value="Widowed "> Widowed </option>
                  <option value="Separated"> Separated</option>
                  <option value="Divorced "> Divorced</option>
                </select>
                {errors.Martial && (
                  <p className="error-msg">Please select the martial status</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="emp-form-label"> Religion</label>
              </div>
              <div className="emp-pro-edit-value">
                <select
                  className="emp-form-input"
                  {...register("religion", {
                    required: true,
                  })}
                >
                  <option value=""> select</option>
                  <option value="Buddhism"> Buddhism</option>
                  <option value="Islam"> Islam</option>
                  <option value="Christianity"> Christianity</option>
                  <option value="Hinduism"> Hinduism</option>
                  <option value="Sikhism"> Sikhism</option>
                  <option value="Confucianism"> Confucianism</option>
                  <option value="Other religions"> Other religions</option>
                  <option value="Not religious"> Not religious</option>
                </select>
                {errors.religion && (
                  <p className="error-msg">Please select the religion</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit-emp">
                <label className="emp-form-label"> Bio</label>
                <p>Write a short introduction.</p>
              </div>
              <div className="emp-proedit-text-editor">
                <Controller
                  name="Description"
                  control={control}
                  rules={{ required: true, maxLength: 250 }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <TextEditor
                        data={value}
                        onBlur={onBlur}
                        onChange={(event, editor) => onChange(editor.getData())}
                      />
                      {errors.Description && (
                        <span className="error-msg">
                          Please enter the description
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
          </div>
          {/* <TextEditor /> */}
          <div className="emp-cancel-btns-div pt-3">
            <button
              className="emp-edit-btn-cp-cancel-btn"
              type="button"
              onClick={onClickCancel}
            >
              Cancel
            </button>
            <button className="emp-edit-btn cp-save-btn" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeProfileEdit;
