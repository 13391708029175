import React, { useEffect, useState } from "react";
// import { BsThreeDots } from "react-icons/bs";
// import { LuMessageSquare } from "react-icons/lu";
import "./EmployeeChatList.css";
import StatusIconOnline from "../StatusIcons/StatusIconOnline/StatusIconOnline";
import DefaultProfilePic from "../../../../Assets/Images/Default_pfp.svg.png";
import { useSelector, useDispatch } from "react-redux";
import { getAllEmployeeChatListAction } from "../../../../redux/actions/getAllEmployeeChatListAction";


const EmployeeChatList = ({
  onChatClick,
  emailIdOfCustomer,
  emailIdOfEmployee,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const activeUsers = useSelector((state) => state.activeUsers);
  const dispatch = useDispatch();
  const employeeChatListObject = useSelector(
    (state) => state.employeeChatListData
  );
  console.log("employeeChatListObject", employeeChatListObject);
  const employeeChatList = employeeChatListObject.chats;
  console.log("employeeChatList", employeeChatList);
  const onlineStatus = (email) =>
    activeUsers.some((user) => user.userId === email);
    useEffect(()=>{
      dispatch(getAllEmployeeChatListAction())
    }, []);

  // Function to handle the search input
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };
  const filteredEmployeeChatList = Array.isArray(employeeChatList)
    ? employeeChatList.filter((employeeConversation) =>
        employeeConversation.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      {employeeChatList?.length === 0 ? (
        <div className="ecc-no-chat-selected">
          <p className="ecc-no-chat-selected-text">Your chat is empty</p>
        </div>
      ) : (
        <div className="ecc-emp-chat-list-div">
          <div className="ecc-chat-heading-div">
            <div className="ecc-chat-heading-text-div">
              <p className="ecc-emp-chat-heading">Chats</p>
            </div>
            <div className="ecc-chat-icons-div">
              <div className="ecc-chat-icons">{/* <BsThreeDots /> */}</div>
              <div className="ecc-chat-icons">{/* <LuMessageSquare /> */}</div>
            </div>
          </div>

          <div className="ecc-search-employees-chat-list">
            <input
              type="text"
              placeholder="Search                                            "
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="cce-chat-list-div">
            {filteredEmployeeChatList.map((employeeConversation, index) => (
              <div
                className="emp-chat-list-cust-side-div"
                key={employeeConversation.emailId}
              >
                <hr className="ecc-hr-line"></hr>

                <div
                  className="ecc-emp-single-chat-div-list"
                  // key={index}
                  onClick={() => {
                    onChatClick(
                      employeeConversation.emailId,
                      employeeConversation.name,
                      employeeConversation.profileImage
                    );
                  }}
                >
                  <div className="ecc-emp-single-chat-div">
                    <div className="ecc-emp-chat-profile-pic-div ecc-indchat-profile-pic-div">
                      <img
                        src={
                          employeeConversation.profileImage ?? DefaultProfilePic
                        }
                        className="customer-profile-img"
                        alt="customer-profile"
                      />

                      {/*  if user active shows color green, if offline shows grey color*/}
                      <div className="ecc-chat-active-status-icon-div">
                        {onlineStatus(employeeConversation.emailId) ? (
                          <StatusIconOnline color="#22C55E" />
                        ) : (
                          <StatusIconOnline color="#D1D5DB" />
                        )}
                      </div>
                    </div>
                    <div className="ecc-emp-single-chat-div-part">
                      <div className="ecc-emp-single-chat-details">
                        <span className="ecc-emp-chat-customer-name">
                          {employeeConversation.name ?? "Unknown Customer"}
                        </span>
                        <span className="ecc-emp-chat-time">
                          {" "}
                          {onlineStatus(employeeConversation.emailId)
                            ? "Online"
                            : "Offline"}
                        </span>
                      </div>
                      <div>
                        <p className="ecc-emp-chat-msg">
                          {employeeConversation.lastMessage
                            ? employeeConversation.lastMessage.length > 50
                              ? employeeConversation.lastMessage.substring(
                                  0,
                                  50
                                ) + " ..."
                              : employeeConversation.lastMessage
                            : "Message not found"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <hr className="ecc-hr-line"></hr>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeChatList;
