import React,{useState} from "react";
import person4 from "../../Assets/Images/person/person4.png";


const LatestJoiningCard =({profileImageUrl,name,role,bio, maxCharacters = 80 })=>{

    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

     // Check if bio is null or undefined
  if (bio == null) {
    return null; // Or handle this case in another way
  }

    return (
      <div className="block-card">
        <img
          src={profileImageUrl}
          alt="Person1"
          className="b-img"
          style={{ objectFit: "cover"}}
        />

        <div className="block-card-content">
          <div>
            <h6 className="joinee-name">{name.replace(",", " ")}</h6>
            <p className="joini-role">{role}</p>
          </div>
          <div className="block-card-content">
            {expanded ? (
              <div>
                <p className="joine-bio">{bio}</p>
                <button onClick={toggleExpand}>Read Less</button>
              </div>
            ) : (
              <p className="joine-bio">
                {bio.length > maxCharacters
                  ? bio.slice(0, maxCharacters) + "..."
                  : bio}
                {bio.length > maxCharacters && (
                  <button onClick={toggleExpand}>Read More</button>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    );
}
export default LatestJoiningCard;
