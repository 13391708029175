const RegistrationContent = {
  REGISTRATIONHEADER: "Register your account",
  REGISTRATIONDESCRIPTION:
    "Fill the details below to submit register account.",
  CUSTOMERREGISTRATIONIMAGEHEADER: "FIND THE PERFECT HELPER FOR YOUR NEEDS!",
  REGISTRATIONIMAGEDESCRIPTION: "SIGN UP NOW!",

  EMPLOYEEREGISTRATIONIMAGEHEADER:"FIND THE PERFECT PLACE TO WORK!",

  REGISTRATIONFOOTER: "Already have account?",

  REGISTRATIONHEADERMOBILE: "DISCOVER YOUR IDEAL WORKSPACE AND FIND THE PERFECT ASSISTANT TO MEET YOUR NEEDS!",
};
module.exports = {
  RegistrationContent,
};
