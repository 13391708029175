import React, { useState, useEffect } from "react";
import AvailableJobsLayout from "../../../../../Layout/AvailableJobsLayout/AvailableJoblistLayout/AvailableJobsLayout";
import AvailableJobsList from "../../../../../Components/Employee/AvailableJobsComponents/AvailableJobsList/AvailableJobsList";
import { BaseUrl } from "../../../../../ApiBaseUrl/Api";
import { AVAILABLE_JOBS_POSTS_LISTS } from "../../../../../ApiEndPoints/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HHLogo from "../../../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
const AvailableJobs = () => {
  const [customerData, setCustomerData] = useState([]);
  const Token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0); // Initialize totalPostsCount
  const [filters, setFilters] = useState({ position: "" });
  const itemsPerPage = 10;
  // Adding a state variable for sorting
  const [sortBy, setSortBy] = useState("");
  const [dataLength, setDataLength] = useState(0);

  const fetchCustomersPostsData = async (page, filters) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${Token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // Construct the API request URL using the correct page number
      const apiUrl = `${BaseUrl}${AVAILABLE_JOBS_POSTS_LISTS}${page}?sortBy=${sortBy}`;
      const response = await fetch(apiUrl, requestOptions);

      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");

        return;
      } else if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        const filteredData = applyFilters(
          data.customerRequestsData || [],
          filters
        );
        // setCustomerData(data.customerRequestsData || []);
        setCustomerData(filteredData);
        console.log("customerpostData", data.customerRequestsData);
        console.log(
          "Filtered Positions:",
          filteredData.map((item) => item.position)
        );

        // setCustomerData(data);
        setTotalRequests(data.noOfJobs || 0); // Set the totalPostsCount
        setDataLength(filteredData.length);

        setCurrentPage(page - 1);
        setIsLoading(false);
        window.scrollTo(0, 0);
      } else if (response.status === 500) {
        const errorData = await response.json();
        console.error("Server error: Something went wrong", errorData);
        console.error("Server error: Something went wrong");
      } else if (response.status === 422) {
        // Handle validation issues
        console.error("Validation error: Data validation failed");
        // You can display a validation error message to the user if needed
      } else {
        console.error("Unknown error: Status code", response.status);
      }
    } catch (error) {
      console.error("Error fetching data from API", error); // Use 'error' instead of 'Error'
    }
  };

  const extractExperienceValue = (workExperience) => {
    if (!workExperience) {
      return 0; // Handle the case where workExperience is null or undefined
    }

    if (workExperience === "N/A" || workExperience === "dummydata") {
      return 0; // Treat "N/A" and "dummydata" as 0 years of experience
    }

    const matches = workExperience.match(/\d+/);
    if (matches) {
      return parseInt(matches[0]);
    }

    return 0;
  };
  const parseSalary = (salary) => {
    if (!salary) return 0; // Return 0 for missing or invalid salary values
    const [min, max] = salary
      .replace(/\$/g, "") // Remove the dollar sign
      .split("-") // Split the salary range
      .map((value) => parseInt(value.trim().replace(/,/g, ""))); // Parse and remove commas

    return (min + max) / 2; // Calculate the average salary
  };
  const sortedData = [...customerData].sort((a, b) => {
    const experienceA = extractExperienceValue(a.workExperience);
    const experienceB = extractExperienceValue(b.workExperience);

    if (sortBy === "lesstomoreexp") {
      return experienceA - experienceB; // Sort by experience: more to less
    } else if (sortBy === "moretolessexp") {
      return experienceB - experienceA; // Sort by experience: less to more
    } else if (sortBy === "lesstomoresal") {
      const salaryA = parseSalary(a.salary);
      const salaryB = parseSalary(b.salary);
      return salaryA - salaryB; // Sort by salary: less to more
    } else if (sortBy === "moretolesssal") {
      const salaryA = parseSalary(a.salary);
      const salaryB = parseSalary(b.salary);
      return salaryB - salaryA; // Sort by salary: more to less
    } else {
      // Default sorting by empId
      return a.empId - b.empId;
    }
  });
  const applyFilters = (data, filters) => {
    const filtered = data.filter((customerRequestDetailInfo) => {
      const positionMatch =
        !filters.position ||
        customerRequestDetailInfo.position === filters.position;

      const ageMatch =
        !filters.minAge ||
        !filters.maxAge ||
        (customerRequestDetailInfo.age >= filters.minAge &&
          customerRequestDetailInfo.age <= filters.maxAge);

      const salaryMatch =
        !filters.minSalary ||
        !filters.maxSalary ||
        (customerRequestDetailInfo.salary >= filters.minSalary &&
          customerRequestDetailInfo.salary <= filters.maxSalary);

      const workScheduleMatch =
        !filters.workSchedule ||
        (Array.isArray(customerRequestDetailInfo.workSchedule) &&
          (filters.workSchedule.length === 0 ||
            filters.workSchedule.every((option) =>
              customerRequestDetailInfo.workSchedule.includes(option)
            )));
      const countryMatch =
        !filters.country ||
        customerRequestDetailInfo.country === filters.country;
      const stateMatch =
        !filters.state || customerRequestDetailInfo.state === filters.state;
      const cityMatch =
        !filters.city || customerRequestDetailInfo.city === filters.city;

      return (
        positionMatch &&
        ageMatch &&
        salaryMatch &&
        workScheduleMatch &&
        countryMatch &&
        stateMatch &&
        cityMatch
      );
    });

    return filtered;
  };
  useEffect(() => {
    fetchCustomersPostsData(currentPage + 1, filters);
  }, [currentPage, filters]);

  const handleApplyFilters = (e, newFilters) => {
    e.preventDefault();
    setFilters(newFilters);
  };
  const handlePageChange = (selectedPage) => {
    console.log("selectedPage", selectedPage);

    fetchCustomersPostsData(selectedPage, filters);
  };
  const handleSortChange = (e) => {
    const newSortBy = e.target.value;
    setSortBy(newSortBy);
    // Fetch data again with the updated sorting
    // fetchHelpersDataAfterFilter(1, filters);
  };
  return (
    <AvailableJobsLayout
      handleApplyFilters={handleApplyFilters}
      filters={filters}
      setFilters={setFilters}
      handleSortChange={handleSortChange}
      sortBy={sortBy}
      setSortBy={setSortBy}
      totalRequests={totalRequests}
      dataLength={dataLength}
    >
      {isLoading ? (
        <div className="hhloader-div-ah">
          <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
          <Typewriter
            options={{
              strings: ["Loading..."],
              autoStart: true,
              loop: true,
              delay: 30,
            }}
            className="loading-text-in-loader"
          />
        </div>
      ) : (
        <AvailableJobsList
          data={sortedData}
          handlePageChange={handlePageChange}
          totalRequests={totalRequests}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    </AvailableJobsLayout>
  );
};

export default AvailableJobs;
