import React, { useState, useEffect } from "react";
import "./RegistrationForm.css";
import { useForm } from "react-hook-form";
import { RegistrationContent } from "../../Content/RegistrationPage.content";
import InputField from "../../Components/InputField/InputField";
import Email from "../../Components/Email/Email";
import PhoneNumber from "../../Components/PhoneNumber/PhoneNumber";
import Password from "../../Components/Password/Password";
import ConfirmPassword from "../../Components/ConfirmPassword/ConfirmPassword";
import Country from "../../Components/CountryInput/Country";
import State from "../../Components/StateInput/State";
import City from "../../Components/City/City";
import ZipCode from "../../Components/ZipCode/ZipCode";
import Button from "../../Components/Button/Button";
import OtpVerification from "../OtpVerification/OtpVerification";
import { toast } from "react-toastify";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  SEND_OTP_CUSTOMER,
  SEND_OTP_EMPLOYEE,
} from "../../ApiEndPoints/ApiEndPoints";

const RegistrationForm = ({ showCustomer, showEmployee, setShowOtp }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValue,
  } = useForm();

  console.log(showCustomer, showEmployee);

  const password = watch("password");
  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, showPhoneNumberError] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    reset();
  }, [showEmployee]);

  const getPhoneNumber = (value, isValid) => {
    setPhoneNumber(value);
    setValidPhoneNumber(isValid);
  };

  const onSubmit = (data) => {
    // console.log(data);
    if (phoneNumber) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}${
          showCustomer == true ? SEND_OTP_CUSTOMER : SEND_OTP_EMPLOYEE
        }${data.email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.success == "OTP Has Send On The Email") {
            setFormSubmitted(true);
            setShowOtp(true);
            setData({ data, phoneNumber });
            // console.log(phoneNumber, data);
            toast.info("OTP successfully sent to your registered email id");
          }
          // else if (result.success == "Customer is Registered Successfully")
          else if (result.statuscode == 409) {
            toast.error(
              "This email is already registered please try with another mail"
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      showPhoneNumberError(true);
    }
  };
  // console.log(data, "from registeration form submission");
  if (formSubmitted) {
    return (
      <OtpVerification
        formSubmitted={formSubmitted}
        setFormSubmitted={setFormSubmitted}
        setShowOtp={setShowOtp}
        data={data}
        showCustomer={showCustomer}
        showEmployee={showEmployee}
      />
    );
  }

  return (
    <>
      <div className="form-container">
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-heading">
            {RegistrationContent.REGISTRATIONHEADER}
          </div>
          <div className="form-description">
            {RegistrationContent.REGISTRATIONDESCRIPTION}
          </div>
          <div className="fields-1">
            <div className="field-fn">
              <InputField
                label="First Name"
                type="text"
                placeholder="John"
                register={register("first_name", {
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                })}
              />
              {errors.first_name && (
                <span className="error-msg">Please enter your first name</span>
              )}
            </div>
            <div className="field-ln">
              <InputField
                label="Last Name"
                type="text"
                placeholder="Dew"
                register={register("last_name", {
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                })}
              />
              {errors.last_name && (
                <span className="error-msg">Please enter your last name</span>
              )}
            </div>
          </div>
          <div id="ep-container" className="fields-1">
            <div className="field-email">
              <Email
                register={register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="error-msg">Please enter Email</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="error-msg">Invalid Email Address</span>
              )}
            </div>
            <div className="field-ph">
              <div className="phone-n-container">
                <PhoneNumber getPhoneNumber={getPhoneNumber} />
                {phoneNumberError && !validPhoneNumber && !phoneNumber && (
                  <span className="error-msg">
                    Please enter your phone number
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="fields-2">
            <Password
              register={register("password", {
                required: true,
                minLength: 6,
                maxLength: 10,
                pattern:
                  // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).*$/i,
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,10}$/,
              })}
            />
            {errors.password && errors.password.type === "required" && (
              <span className="error-msg">Please enter a password</span>
            )}
            {errors.password && errors.password.type === "minLength" && (
              <span className="error-msg">
                Password must be at least 8 characters long
              </span>
            )}
            {errors.password && errors.password.type === "maxLength" && (
              <span className="error-msg">
                Password should be 10 characters only
              </span>
            )}
            {errors.password && errors.password.type === "pattern" && (
              <span className="error-msg">
                Password must contain at least one uppercase letter, one lowercase letter, one number
                and one special character
              </span>
            )}
          </div>
          <div className="fields-2">
            <ConfirmPassword
              register={register("confirm_password", {
                required: true,
                validate: (value) => value === password,
              })}
            />
            {errors.confirm_password &&
              errors.confirm_password.type === "required" && (
                <span className="error-msg">
                  Please enter the password again.
                </span>
              )}
            {errors.confirm_password &&
              errors.confirm_password.type === "validate" && (
                <span className="error-msg">Passwords do not match.</span>
              )}
          </div>
          <div className="fields-1">
            <div className="field-country ">
              <Country
                register={register("country", {
                  required: true,
                })}
                label={"Country"} 
                labelStyle={{ fontSize: "14px" }} 
              />
              {errors.country && (
                <span className="error-msg">Please select your Country</span>
              )}
            </div>
            <div className="field-state">
              <State
                register={register("state", {
                  required: true,
                })}
                selectedCountry={selectedCountry}
              />
              {errors.state && (
                <span className="error-msg">Please select your State</span>
              )}
            </div>
          </div>
          <div className="fields-1">
            <div className="field-city">
              <City
                register={register("city", {
                  required: true,
                })}
                selectedState={selectedState}
                selectedCountry={selectedCountry}
              />
              {errors.city && (
                <span className="error-msg">Please select your City</span>
              )}
            </div>
            <div className="field-zipcode">
            <ZipCode
                register={register("zip_code", {
                  required: true,
                  // pattern: /^[\d\w]{3,}(?:-[\d\w]+)?$/,
                  pattern: /^[0-9]+$/,
                    maxLength: 6,
                    minLength: 4,
                })}
              />
              {errors.zip_code && (
                <span className="error-msg">Please enter the zip code</span>
              )}
            </div>
          </div>
          <div className="terms-policy-container">
            <p className="terms-policy">
              By signing in, you agree to our{" "}
              <a className="link-terms" href="/termsofservice">
                <strong>Terms & Condition</strong>
              </a>{" "}
              and{" "}
              <a className="link-terms" href="privacy-policy">
                <strong>
                  Privacy Policy.<span className="error-symbol">*</span>
                </strong>
              </a>
            </p>
          </div>
          <Button buttonName={"Continue"} />
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;
