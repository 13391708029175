import React, { useState } from "react";
import "./MobileTopBar.css";
import logo from "../../Assets/Svg/logo.svg";
import menubutton from "../../Assets/Images/menu-button.png";
import MobileSideBarEmployee from "../MobileSideBar/MobileSideBarEmployee";
import { useLocation, useNavigate } from "react-router-dom";

const MobileTopBarEmployee = () => {
  const [showMobileSideBarEmployee, setShowMobileSideBarEmployee] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  const handleClicktoggle = () => {
    setShowMobileSideBarEmployee(!showMobileSideBarEmployee);
  };
  const onClickLogo = () => {
    navigate("/");
  };
  return (
    <>
      <div className="mobile-topbar-container">
        <img className="mtp-icon" src={logo} alt="" onClick={onClickLogo}/>
        <img
          className="mtp-icon"
          src={menubutton}
          alt=""
          onClick={handleClicktoggle}
        />
      </div>
      {showMobileSideBarEmployee && <MobileSideBarEmployee />}
    </>
  );
};
export default MobileTopBarEmployee;
