import React,{useState, useEffect} from "react";
import "./EmployeeProfileSideBar.css";
import empfake from "../../Assets/Svg/empfake.svg";
import camera from "../../Assets/Svg/camera.svg";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillFileTextFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import {AiFillGift} from "react-icons/ai";
import { GoSignOut } from "react-icons/go";  
import { BiCaretDown } from "react-icons/bi";  
import { useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { EMPLOYEE_PROFILE } from "../../ApiEndPoints/ApiEndPoints";
import EmployeeProfilePictureModal from "../../Modal/ProfilePicture/Employee/EmployeeProfilePictureModal";
import EmployeeSignOut from "../../Modal/SignOut/Employee/EmployeeSignOut";
import DefaultProfilePic from "../../Assets/Images/Default_pfp.svg.png";

const EmployeeProfileSideBar = ({showCameraIcon}) => {
  const [data, setData] = useState({});
  const [profilePictureEmployee, setProfilePictureEmployee] = useState(false);//for profile picture change popup
  const [showEmployeeSignout,setShowEmployeeSignout]= useState(false);//for signout popup
  const [imageLoaded, setImageLoaded] = useState(false); // Track the image loading status
  const [profileImageLoaded, setProfileImageLoaded] = useState(false);   //for profile image loader
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };
 // Function to handle the image load event
 const handleImageLoad = () => {
  setImageLoaded(true);
};
  const Token = localStorage.getItem("token");
 
  //get api for employee profile
  useEffect(() => {
    window.scrollTo(0, 0);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}${EMPLOYEE_PROFILE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "result");
        setData(result.employeeData);
      })
      .catch((error) => console.log("error", error));
  }, []);
//profile picture popup
const onClickCamera = () => {
  setProfilePictureEmployee(true);
};
const onClickSignOutEmployee = () =>{
  setShowEmployeeSignout(true);
}
 const handleProfileImageLoad =()=>{
  setProfileImageLoaded(true);
 }
  return (
    <>
    <div className="Employee-psb-main">
      <div className="Employee-psb-card px-3">
        <div className="Employee-psb-text-div">
          <p className="Employee-psb-text">Employee</p>
        </div>
        <div className="psb-profile-img-div">
          {/* <img src={data.profileImageUrl} alt="" className="psb-profile-img" /> */}
          {/* Display the placeholder image when the high-resolution image is not loaded */}
          {!imageLoaded && (
              <img
                src={data.profileImageUrl ?? DefaultProfilePic}
                alt=""
                className="psb-profile-img psb-profile-img-placeholder"
              />
            )}
            {/* Display the final image when it is loaded */}
            <img
              src={data.profileImageUrl ?? DefaultProfilePic}
              alt=""
              className={`psb-profile-img ${imageLoaded ? "" : "hidden"}`}
              onLoad={handleImageLoad}
            />
          {/* <img src={camera} alt=""  className="Employee-psb-camera-img" onClick={onClickCamera} /> */}
          {showCameraIcon && (<>
              <img
              src={camera}
              alt=""
              className="Employee-psb-camera-img"
              onClick={onClickCamera}
            /></>)}
        </div>
        <p className="Employee-psb-name pt-3"> {data.firstName} {data.lastName}</p>
      </div>
      {/* buttons */}
      <div className="Employee-psb-btns-div pt-4">
        <div onClick={() => handleClickNavigate("/employee-profile")}
          className={`${
            pathname == "/employee-profile"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          
          <BsFillPersonFill />
          <p className="px-2">Profile</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-account")}
          className={`${
            pathname == "/employee-account"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <IoMdSettings />
          <p className="px-2">Account</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-messanger")}
          className={`${
            pathname == "/employee-messanger"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <IoIosChatbubbles />
          <p className="px-2">Messenger</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-documents")}
          className={`${
            pathname == "/employee-documents"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <BsFillFileTextFill />
          <p className="px-2">Documents</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-qualification")}
          className={`${
            pathname == "/employee-qualification"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <FaStar />
          <p className="px-2">Qualifications</p>
        </div>
        <div  onClick={() => handleClickNavigate("/employee-experience")}
          className={`${
            pathname == "/employee-experience"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <FaBriefcase />
          <p className="px-2">Experience</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-education")}
          className={`${
            pathname == "/employee-education"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <FaGraduationCap />
          <p className="px-2">Education</p>
        </div>
        <div onClick={() => handleClickNavigate("/employee-invite-friends")}
          className={`${
            pathname == "/employee-invite-friends"
              ? "psb-btn-active mt-3"
              : "psb-btn mt-3"
          }`}
        >
          <AiFillGift />
          <p className="px-2">Invite friends</p>
        </div>
        <div className="Employee-psb-btn mt-3">
          <GoSignOut />
          <p className="px-2" onClick={onClickSignOutEmployee}>Sign out</p>
        </div>
        <div className="Employee-psb-about-div mt-3">
      
        <p onClick={() => handleClickNavigate("/about-us")}>About</p>
      
          <p>Pricing</p>
          <p>Help Center</p>
        </div>
        <p className="Employee-psb-terms pt-4 pb-4">Terms & Privacy <BiCaretDown className="psb-term-ico"/></p>
      </div>
    </div>
    {profilePictureEmployee && <EmployeeProfilePictureModal setProfilePictureEmployee={setProfilePictureEmployee}/>}
    {showEmployeeSignout && <EmployeeSignOut setShowEmployeeSignout={setShowEmployeeSignout}/> }
    </>
  );
};
export default EmployeeProfileSideBar;
