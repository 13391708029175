import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CUSTOMER_SUBSCRIPTION_DETAILS_PUTAPI } from "../../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { toast } from "react-toastify";
import ZipCode from "../../ZipCode/ZipCode";
import Country from "../../../Components/CountryInput/Country";
import State from "../../../Components/StateInput/State";
import City from "../../../Components/City/City";
import { useNavigate } from "react-router-dom";

const CustomerSubscriptionEditPutData = ({ setShowEditPage, prevdata }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
    setValue,
    watch,
  } = useForm();
  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const onClickCancel = () => {
    setShowEditPage(false);
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();

  useEffect(() => {
    // const fullName = prevdata.firstName + " " + prevdata.lastName;

    const Address = `${prevdata.city}, ${prevdata.state}, ${prevdata.country}, ${prevdata.zip}`;
    if (prevdata) {
      // setValue("first_name", prevdata.firstName || "");
      // setValue("last_name", prevdata.lastName || "");
      setValue("name", prevdata.name || "");
      setValue("address", Address || "");
      setValue("country", prevdata.country || "");
      setValue("state", prevdata.state || "");
      setValue("emailId", prevdata.emailId || "");
      setValue("city", prevdata.city || "");
      setValue("zip", prevdata.zip || "");
    }
  }, [prevdata, setValue]);
  const Token = localStorage.getItem("token");

  const onSubmit = (data) => {
    console.log(data, "All Form data");
    //  const fullName = (data.first_name + ","+ data.last_name);
    // console.log(fullName, "Girish Name");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      name: data.name,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      emailId: data.emailId,
      zip: data.zip,
    });
    console.log(raw);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_SUBSCRIPTION_DETAILS_PUTAPI}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        console.log(result, "customer profile data");
        if (result.statuscode === 200) {
          toast.info("Billing information is successfully updated");
          setShowEditPage(false);
          window.location.reload();
          // navigate(`/customer-subscription`);
        } else {
          const apiErrors = result.errors; // Get the errors array from the API response
          if (apiErrors && apiErrors.length > 0) {
            // Log the specific error messages
            apiErrors.forEach((error) => {
              console.log(error.msg);
            });
          } else {
            console.log("Something went wrong");
            toast.error("Something went wrong");
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="container cs-profile-main mb-3">
      <div className="cp-profile-card">
        <div className="d-flex justify-content-between">
          <h4 className="cp-title">Billing information</h4>
        </div>
        <hr className="cp-hr-line"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="cp-form-div">
            <div className="cp-form-label-input-proedit-name">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Name</label>
              </div>{" "}
              <div className="profileeditinput-proedit-name">
                <input
                  type="text"
                  className="cp-form-input"
                  {...register("name", {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                  })}
                />
                <br></br>
                {errors.name && (
                  <p className="error-msg">Please Enter The Name</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Address</label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <input
                  type="text"
                  className="cp-form-input"
                  {...register("address", {
                    required: true,
                    // minLength: 3,
                    // maxLength: 30,
                  })}
                />

                {errors.address && (
                  <p className="error-msg">Please Enter The Address</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Country</label>
              </div>
              <div className="profileeditinput-proedit">
                <Country
                  label="false"
                  className="cp-form-input"
                  register={register("country", {
                    required: true,
                  })}
                />
                {errors.country && (
                  <p className="error-msg">Please select the country</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">
                  State/Province/Region
                </label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <State
                  showLabel={false}
                  label="false"
                  register={register("state", {
                    required: true,
                  })}
                  selectedCountry={selectedCountry}
                  className="cp-form-input"
                />

                {errors.state && (
                  <p className="error-msg">Please Select The </p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Email</label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <input
                  type="text"
                  className="cp-form-input"
                  {...register("emailId", {
                    required: true,
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  })}
                />
                {errors.emailId && errors.emailId.type === "required" && (
                  <p className="error-msg">Please Enter The Email</p>
                )}
                {errors.emailId && errors.emailId.type === "pattern" && (
                  <p className="error-msg">Please Enter a Valid Email</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">City</label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <City
                  showLabel={false}
                  register={register("city", {
                    required: true,
                  })}
                  selectedState={selectedState}
                  selectedCountry={selectedCountry}
                  className="cp-form-input"
                />

                {errors.city && (
                  <p className="error-msg">Please Select The City</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Zip/Postal code</label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <ZipCode
                  label="false"
                  showLabel={false}
                  register={register("zip", {
                    required: true,
                    pattern: /^[0-9]+$/,
                    maxLength: 6,
                    minLength: 5,
                  })}
                />
                {errors.zip && errors.zip.type === "required" && (
                  <p className="error-msg">Please Enter The Zipcode</p>
                )}
                {errors.zip && errors.zip.type === "pattern" && (
                  <p className="error-msg">Please Enter Valid Zipcode</p>
                )}
                {errors.zip && errors.zip.type === "minLength" && (
                  <p className="error-msg">Please Enter Valid Zipcode</p>
                )}
                {errors.zip && errors.zip.type === "maxLength" && (
                  <p className="error-msg">Please Enter Valid Zipcode</p>
                )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
          </div>

          <div className="cp-cancel-btns-div pt-3">
            <button
              className="cp-edit-btn cp-cancel-btn"
              type="button"
              onClick={onClickCancel}
            >
              Cancel
            </button>
            <button className="cp-edit-btn cp-save-btn" type="submit">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerSubscriptionEditPutData;
