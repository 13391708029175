

import React from "react";
import Select from "react-select";
import { useController } from "react-hook-form";



const MultiSelectDropdown = ({ control, name, label, rules, options }) => {
  const {
    field: { ref, onChange, value =[]},
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue: [],
    name,
    rules,
  });

  const handleSelectChange = (selected) => {
    const selectedValues = selected
      ? selected.map((option) => option.value)
      : [];
    onChange(selectedValues);
  };

  return (
    <div>
      <Select
      
        options={options}
        isMulti
        value={value ? options.filter((option) => value.includes(option.value)) : null}
        onChange={handleSelectChange}
        inputRef={ref}
      />
      {invalid && <span className="error-msg">{error?.message}</span>}
    </div>
  );
};

export default MultiSelectDropdown;
