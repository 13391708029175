import {
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE,
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST,
  GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS,
} from "../constants/deleteNotificationsConstatnts";

const initialState = {
  loading: false,
  error: null,
  unreadMessages: null,
  unreadComments: null,
};

export const getUnreadMessagesCommentsCountReducer = (
  state = initialState,
  action
) => {
  console.log("Reducer called with action:", action);

  switch (action.type) {
    case GET_UNREAD_MESSAGES_COMMENTS_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case GET_UNREAD_MESSAGES_COMMENTS_COUNT_SUCCESS:
        console.log("unreadMessages:", action.payload.unreadMessages);
        console.log("unreadComments:", action.payload.unreadComments);
        return {
          ...state,
          unreadMessages: action.payload.unreadMessages,
          unreadComments: action.payload.unreadComments,
          loading: false,
        };
      
    case GET_UNREAD_MESSAGES_COMMENTS_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getUnreadMessagesCommentsCountReducer;
