import React, { useEffect, useState } from "react";
import "./CustomerAccount.css";
// import deleteIcon from "../../../Assets/Images/delete.png";
// import authIcon from "../../../Assets/Svg/auth-icon.svg";
import CustomerAccountEdit from "../../../Components/Customer/CustomerAccountEdit/CustomerAccountEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import {GET_CUSTOMER_DETAILS} from "../../../ApiEndPoints/ApiEndPoints";
// import {CUSTOMER_ACCOUNT_DELETE,} from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModalCustomer from "../../../Modal/DeleteIconModal/Customer/DeleteModal";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import ProfileLayoutCustomer from "../../../Layout/ProfileLayout/ProfileLayoutCustomer";


const CustomerAccount = () => {
  const navigate = useNavigate();
  const [showEditPage, setShowEditPage] = useState(false);
  const [data, setData] = useState({});
  const [showCustomerDelete, setShowCustomerDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEditPage(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${GET_CUSTOMER_DETAILS}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        // console.log(result.customerInfo, "customer account");
        setData(result.customerInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  }, []);

  // const onClickDelete = () => {
  //   setShowCustomerDelete(true);
  // };

  return (
    <>
    <ProfileLayoutCustomer showCameraIcon={false}>  
      {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
      ) : (
        <div>
          <div className="container cs-profile-main">
            <div className="cp-title-div pb-4">
              <h3 className="cp-title">Account</h3>
              {!showEditPage && (
                <button className="cp-edit-btn" onClick={handleChangeEdit}>
                  Edit
                </button>
              )}
            </div>
            {!showEditPage && (
                <div className="cs-account-section-mob">
                  <div className="cp-profile-card pb-4 mb-4">
                    <h4>Account info</h4>
                    <div>
                      <div className="cp-label-div">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Email</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.emailId}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Phone number</p>
                        </div>{" "}
                        <p className="cp-text">{data.phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className="cp-profile-card pb-4 mb-4">
                    <h4>Linked account</h4>
                    <div>
                      <div className="cp-label-div">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Facebook account</p>
                        </div>{" "}
                        <p className="cp-text">{data.facebookAccount}</p>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">LinkedIn account</p>
                        </div>{" "}
                        <p className="cp-text">{data.linkedinAccount}</p>
                      </div>
                    </div>
                  </div>
                  <div className="cp-profile-card pb-4 mb-4">
                    <h4>Security</h4>
                    <div>
                      <div className="cp-label-div">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Password</p>
                        </div>{" "}
                        <input
                          className="cp-text"
                          type="password"
                          value={data.password}
                        />
                      </div>
                      {/* <div className="cp-label-div pt-2">
                  <p className="cp-label">Two Step Authentication</p>
                  <p className="cp-text">
                    <img src={authIcon} alt="" /> &nbsp;&nbsp; +611 321 132 7412
                    is Selected as authentication number
                  </p>
                </div> */}
                    </div>
                  </div>
                  {/* <div className="cp-profile-card pb-4 mb-4">
              <h4>Account management</h4>
              <div>
                <div className="cp-label-div pt-2">
                  <p className="cp-label" style={{ color: "red" }}>
                    Delete account
                  </p>

                  <div className="cp-text">
                    <img
                      src={deleteIcon}
                      style={{ cursor: "pointer" }}
                      alt="delete"
                      onClick={onClickDelete}
                    />
                  </div>
                </div>
              </div>
            </div> */}
                </div>
            )}
          </div>

          {showEditPage && (
            <CustomerAccountEdit
              setShowEditPage={setShowEditPage}
              prevData={data}
            />
          )}
          {showCustomerDelete && (
            <DeleteModalCustomer
              setShowCustomerDelete={setShowCustomerDelete}
            />
          )}
        </div>
      )}
      </ProfileLayoutCustomer>
    </>
  );
};

export default CustomerAccount;
