import React from "react";
import "./AvailableHelpersJobPostList.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const AvailableHelpersJobPostList = ({
  data,
  handlePageChange,
  currentPage,
  itemsPerPage,
  totalPostsCount, 
}) => {
  console.log("data", data);

  const navigate = useNavigate();
  //  function to show the full view navigate to full page
  const availableHelperIndividualFullView = (empId) => {
    navigate(`/available-helper-fullview/${empId}`);
  };
  console.log("data", data);


  const noPostsFound = data && data.length === 0;
  return (
    <div className="ahjpl-div">
      {/* Display message when no posts are found */}
      {noPostsFound && (
        <div className="no-posts-message-div">
          <p className="no-posts-message text-center">
            *** No posts found according to the applied filters. ***
          </p>
        </div>
      )}
      {data.map((jobpost, index) => {
        // console.log("jobpost", jobpost);

        const salaryString = jobpost.salary;
        const [minSalary, maxSalary] = salaryString
          ? salaryString
              .split("-")
              .map((salary) => salary.trim().replace("$", ""))
          : [null, null];


        return (
          <div className="ahjpl-ind-div" key={jobpost.empId}>
            <div className="ahjplid-part-a">
              {/* ahjplid   available helpers job post list individuak div part a iamge div */}
              <div className="ahjplid-pa-img-div">
                <img
                  alt=""
                  className="ahjplid-pa-img"
                  src={jobpost.profileImageUrl ?? DefaultProfilePic}
                />
              </div>
              <div className="ahjplid-pa-name-div">
                {/* ahid  unique id give to avaialble helper */}
                <h5 className="ahjplid-pa-name-div-ahid"> H#{jobpost.empId}</h5>
                <p className="ahjplid-pa-name-div-location">
                  {jobpost.city ?? "city not found"},{" "}
                  {jobpost.state ?? "state not found"},{" "}
                  {jobpost.country ?? "country not found"}
                </p>
              </div>
            </div>
            <div className="ahjplid-part-b">
              <div className="ahjplid-pb-s1">
                <div className="ahjplid-pb-s1-position-div">
                  <div className="ahjplid-pb-s1-position-label-div">
                    <p className="ahjplid-pb-s1-position-label">Position</p>
                  </div>
                  <div className="ahjplid-pb-s1-position-values-div">
                    {Array.isArray(jobpost.role) ? (
                      jobpost.role.map((role) => (
                        <div className="ahjplid-pb-s1-position-value-div">
                          <p className="ahjplid-pb-s1-position-value">{role}</p>
                        </div>
                      ))
                    ) : (
                      <div className="ahjplid-pb-s1-position-value-div">
                        <p className="ahjplid-pb-s1-position-value">
                          No roles found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ahjplid-pb-s1-button-div">
                  <button
                    className="ahjplid-pb-s1-button"
                    onClick={() =>
                      availableHelperIndividualFullView(jobpost.empId)
                    }
                  >
                    View <IoIosArrowForward />
                  </button>
                </div>
              </div>
              <div className="ahjplid-pb-s2">
                <div className="ahjplid-pb-s2-position-label-div">
                  <p className="ahjplid-pb-s1-position-label">Bio</p>
                </div>
                <p className="ahjplid-pb-s2-text">
                {jobpost.bio !== null && jobpost.bio !== undefined ? (
      jobpost.bio.length <= 97 ? (
        parse(jobpost.bio)
      ) : (
        parse(jobpost.bio.substring(0, 97) + "...")
      )
    ) : (
      "Not available"
    )}
                </p>
              </div>
              <div className="ahjplid-pb-s3">
                <div className="ahjplid-pb-s3-row">
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Experience</p>
                    <p className="ahjplid-pb-s3-r-value">
                      {jobpost.workExperience ?? "N/A"}
                    </p>
                  </div>
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Salary</p>
                    <p className="ahjplid-pb-s3-r-value-salary">
                      {minSalary && maxSalary
                        ? `$ ${parseInt(
                            minSalary
                          ).toLocaleString()} - $ ${parseInt(
                            maxSalary
                          ).toLocaleString()}`
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="ahjplid-pb-s3-row">
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Work Schedule</p>
                    <p className="ahjplid-pb-s3-r-value">
                      {Array.isArray(jobpost.workSchedule) ? (
                        <div className="ahjplid-pb-s1-position-values-div">
                          {jobpost.workSchedule.join(" | ")}
                        </div>
                      ) : (
                        <div className="ahjplid-pb-s3-r-cell">
                          <p className="ahjplid-pb-s3-r-value">N/A</p>
                        </div>
                      )}{" "}
                    </p>
                  </div>
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Age</p>
                    <p className="ahjplid-pb-s3-r-value">
                      {jobpost.age ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="ahjpl-pagination-div">
        <>
          {" "}
          {/* Pagination controls */}
          {/* Previous button */}
          <div className="ahpl-pagination-prev-button-div">
            <button
              onClick={() => handlePageChange(currentPage - 0)}
              disabled={currentPage === 0}
              className="ahpl-pagination-prev-button"
            >
              <IoIosArrowBack color={"#04AFF0"} size={20} /> Prev
            </button>
            {/* {console.log("handlePageChange", currentPage - 0)} */}
          </div>
          {/* Page buttons */}
          <div className="ahpl-pagination-page-buttons-div">
            {Array.from(
              { length: Math.ceil(totalPostsCount / itemsPerPage) },
              (_, index) => (
                <div className="ahpl-pagination-page-button-div" key={index}>
                  {" "}
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`ahpl-page-button ${
                      currentPage === index ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                </div>
              )
            )}
          </div>
          {/* Next button */}
          <div className="ahpl-pagination-prev-button-div">
            <button
              onClick={() => handlePageChange(currentPage + 2)}
              disabled={
                currentPage === Math.ceil(totalPostsCount / itemsPerPage) - 1
              }
              className="ahpl-pagination-prev-button"
            >
              {" "}
              Next
              <IoIosArrowForward color={"#04AFF0"} size={20} />
              {/* {console.log("handlePageChange", currentPage + 2)} */}
              
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default AvailableHelpersJobPostList;
