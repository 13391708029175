import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { EMPLOYEE_PROFILE } from "../../ApiEndPoints/ApiEndPoints";
import EmployeeProfilePictureModal from "../../Modal/ProfilePicture/Employee/EmployeeProfilePictureModal";
import EmployeeSignOut from "../../Modal/SignOut/Employee/EmployeeSignOut";
import { BiSolidDownArrow } from "react-icons/bi";
import { toast } from "react-toastify";
import DefaultProfilePic from "../../Assets/Images/Default_pfp.svg.png";


const EmployeeCreatePostSideBar = () => {
  const [data, setData] = useState({});
  const [profilePictureEmployee, setProfilePictureEmployee] = useState(false); //for profile picture change popup
  const [showEmployeeSignout, setShowEmployeeSignout] = useState(false); //for signout popup
  const [showEmployeeprofile, setShowEmployeeprofile] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClickNavigate = (path) => {
    navigate(path);
  };

  const Token = localStorage.getItem("token");
  const handleChangeEdit = () => {
    setShowEmployeeprofile(true);
  };

  //get api for employee profile
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_PROFILE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "result");
        setData(result.employeeData);
      })
      .catch((error) => console.log("error", error));
  }, []);

  //profile picture popup
  const onClickCamera = () => {
    setProfilePictureEmployee(true);
  };
  const onClickSignOutEmployee = () => {
    setShowEmployeeSignout(true);
  };

  return (
    <>
      <div className="Employee-psb-main">
        <div className="Employee-psb-card px-3" style={{height:"335px"}}>
          <div className="Employee-psb-text-div">
            <p className="Employee-psb-text">Employee</p>
          </div>
          <div className="Employee-psb-img-div">
            <img
              src={data.profileImageUrl ?? DefaultProfilePic}
              alt=""
              className="Employee-psb-profile-img"
            />
            {/* <img src={camera} alt=""  className="Employee-psb-camera-img" onClick={onClickCamera} /> */}
          </div>
          <p className="Employee-psb-name pt-3">
            {" "}
            {data.firstName} {data.lastName}
          </p>
          <div className="Employee_CREdit_btn pt-3" >
          <button
            className="emp-edit-btn cp-save-btn add-more-btn-expereience"
            type="submit"
            style={{ width: "235px" , height:"37px"}}
            onClick={() => handleClickNavigate("/employee-profile")}
          >
           Edit Profile
          </button>
          </div>
        </div>
        

        {/* buttons */}

        <div className="Employee-psb-about-div mt-3">
          <p onClick={() => handleClickNavigate("/about-us")}>About</p>
          <p>Pricing</p>
          <p>Help Center</p>
        </div>
        <p onClick={() => handleClickNavigate("/termsofservice")}className="Employee-psb-terms pt-4 pb-4" >Terms & Privacy<BiSolidDownArrow color={"#7B7B7B"} size={15} style={{ marginTop: '5px', marginLeft: '10px' }} /></p>
      </div>

      {profilePictureEmployee && (
        <EmployeeProfilePictureModal
          setProfilePictureEmployee={setProfilePictureEmployee}
        />
      )}
      {showEmployeeSignout && (
        <EmployeeSignOut setShowEmployeeSignout={setShowEmployeeSignout} />
        
      )}
      
    </>
  );
};
export default EmployeeCreatePostSideBar;