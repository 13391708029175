import React, { useState } from "react";
import "./MobileNavBar.css";
import bell from "../../Assets/Svg/bell-icon.svg";
import chat from "../../Assets/Svg/chat-icon.svg";
import home from "../../Assets/Svg/home-icon.svg";
import profile from "../../Assets/Svg/profile-icon.svg";
import activeBell from "../../Assets/Svg/activeBell.svg";
import activeHome from "../../Assets/Svg/activeHome.svg";
import activeChat from "../../Assets/Svg/activeChat.svg";
import activeProfile from "../../Assets/Svg/activeProfile.svg";
import { useLocation, Link } from "react-router-dom";

const MobileNavBarCustomer = () => {
  const [activeIcon, setActiveIcon] = useState("");
  const [hoveredImages, setHoveredImages] = useState({});
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [data, setData] = useState({});
  // const Token = localStorage.getItem("token");

  const handleMouseEnter = (index) => {
    setHoveredImages((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredImages((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };
  const handleButtonClick = (iconName) => {
    setActiveIcon(iconName);
    // if (iconName == "home") {
    //   navigate("/customer-post");
    // } else if (iconName == "profile") {
    //   navigate("/available-helpers");
    // } else if (iconName == "chat") {
    //   navigate("/customer-chat");
    // } else if (iconName == "bell") {
    //   navigate("/customer-notification");
    // } else {
    //   return null;
    // }
  };
  //   const onClickLogo = () => {
  //     navigate("/");
  //   };

  return (
    <div className="mobile-c-navbar">
      {/* <Link to="/customer-post">
                <img
                  className={`${
                    activeIcon === "home" ||
                    hoveredImages[1] ||
                    pathname === "/customer-post"
                      ? "active-top-bar-icons-mob"
                      : "top-bar-icons-mob"
                  }`}
                  src={
                    pathname === "/customer-post" ||
                    activeIcon === "home" ||
                    hoveredImages[1]
                      ? activeHome
                      : home
                  }
                  alt=""
                  onClick={() => handleButtonClick("home")}
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={() => handleMouseLeave(1)}
                />
              </Link> */}
      {/* <img className="mobile-navbar-icon" src={home} />  */}
      {/* <img className="mobile-navbar-icon" src ={profile}/>
            <img className="mobile-navbar-icon" src={chat}/>
            <img className="mobile-navbar-icon" src={bell}/>      */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="">
          <div className="d-flex justify-content-center pr-top-bar-img-div-mob">
            <Link to="/customer-post">
              <img
                className={`${
                  activeIcon === "home" ||
                  hoveredImages[1] ||
                  pathname === "/customer-post"
                    ? "active-top-bar-icons-mob"
                    : "top-bar-icons-mob"
                }`}
                src={
                  pathname === "/customer-post" ||
                  activeIcon === "home" ||
                  hoveredImages[1]
                    ? activeHome
                    : home
                }
                alt=""
                style={{ margin: "0px 10px 0px 10px" }}
                onClick={() => handleButtonClick("home")}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={() => handleMouseLeave(1)}
              />
            </Link>
          </div>
          {/* <div>
            <hr
              className={
                pathname === "/customer-post" ||
                activeIcon === "home" ||
                hoveredImages[1]
                  ? "top-bar-hr-mob"
                  : "d-none"
              }
            ></hr>
          </div> */}
        </div>

        <div className="">
          <div className="d-flex justify-content-center align-items-center pr-top-bar-img-div-mob">
            <Link to="/available-helpers">
            {/* <Link to="/customer-post"> */}
              <img
                className={`${
                  pathname === "/available-helpers" ||
                  activeIcon === "profile" ||
                  hoveredImages[2]
                    ? "active-top-bar-icons-mob"
                    : "top-bar-icons-mob"
                }`}
                onClick={() => handleButtonClick("profile")}
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={() => handleMouseLeave(2)}
                src={
                  pathname === "/available-helpers" ||
                  activeIcon === "profile" ||
                  hoveredImages[2]
                    ? activeProfile
                    : profile
                }
                alt=""
                style={{ margin: "0px 10px 0px 10px" }}
              />
            </Link>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center ">
            <hr
              className={
                pathname === "/available-helpers" ||
                activeIcon === "profile" ||
                hoveredImages[2]
                  ? "top-bar-hr-mob"
                  : "d-none"
              }
            ></hr>
          </div> */}
        </div>

        <div className="">
          <div className="d-flex justify-content-center pr-top-bar-img-div-mob">
            <Link to="/customer-chat">
            {/* <Link to="/customer-post"> */}
              <img
                className={`${
                  activeIcon === "chat" ||
                  hoveredImages[3] ||
                  pathname === "/customer-chat"
                    ? "active-top-bar-icons-mob"
                    : "top-bar-icons-mob"
                }`}
                src={
                  pathname === "/customer-chat" ||
                  activeIcon === "chat" ||
                  hoveredImages[3]
                    ? activeChat
                    : chat
                }
                alt=""
                style={{ margin: "0px 10px 0px 10px" }}
                onClick={() => handleButtonClick("chat")}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={() => handleMouseLeave(3)}
              />
            </Link>
          </div>
          {/* <div>
            <hr
              className={
                pathname === "/customer-chat" ||
                activeIcon === "chat" ||
                hoveredImages[3]
                  ? "top-bar-hr-mob"
                  : "d-none"
              }
            ></hr>
          </div> */}
        </div>

        <div className="">
          <div className="d-flex justify-content-center align-items-center ">
            {/* <Link to="/customer-notification"> */}
            <Link to="/customer-notification-chat">
              <img
                className={`${
                  activeIcon === "bell" ||
                  hoveredImages[4] ||
                  pathname === "/customer-notification-chat"
                    ? "active-top-bar-icons-mob"
                    : "top-bar-icons-mob"
                }`}
                src={
                  pathname === "/customer-notification-chat" ||
                  activeIcon === "bell" ||
                  hoveredImages[4]
                    ? activeBell
                    : bell
                }
                alt=""
                style={{ margin: "0px 10px 0px 10px" }}
                onClick={() => handleButtonClick("bell")}
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={() => handleMouseLeave(4)}
              />
            </Link>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center">
            <hr
              className={
                pathname === "/customer-notification" ||
                activeIcon === "bell" ||
                hoveredImages[4]
                  ? "top-bar-hr-mob"
                  : "d-none"
              }
            ></hr>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileNavBarCustomer;
