import React, { useEffect, useState } from "react";
import "./EmployeeProfile.css";
import EmployeeProfileEdit from "../../../Components/Employee/EmployeeProfileEdit/EmployeeProfileEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_PROFILE } from "../../../ApiEndPoints/ApiEndPoints";
// import MobileDefaultLayout from "../../../Layout/MobileDefaultLayout/MobileDefaultLayout";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const EmpProfile = () => {
  const [showEmployeeEditPage, setShowEmployeeEditPage, setShowEmployeeprofile] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEmployeeEditPage(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_PROFILE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        // console.log(result.employeeData, "result");
        setData(result.employeeData);
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => console.log("error", error));
  }, []);


  return (
    <>
   <EmployeeProfileLayout showCameraIcon={true} >
    {isLoading ? (
        <>
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        </>
      ) : (<><div className="employee-profile-desktop">
      {!showEmployeeEditPage && (
        <div className="container-emp-profile-main" >
          <div className="emp-title-div pb-4">
            <h3 className="emp-title">Profile</h3>
            <button onClick={handleChangeEdit} className="emp-edit-btn">
              Edit
            </button>
          </div>
          <div className="emp-profile-card1">
            <h4>User Info</h4>
            <hr className="emp-hr-line"></hr>
            <div>
              <div className="emp-label-div-profile">
                <p className="emp-label">Age </p>
                <p className="emp-text">{data.age}</p>
              </div>
              <div className="emp-label-div-profile pt-2">
                <p className="emp-label">Position</p>
                {/* <hr className="emp-hr-line"></hr> */}
                <div className="emp-label-field-div">
                  {/* <p className="emp-text">{data.role}</p> */}
                  {data.role != null
                    ? data.role.map((schedule, index) => (
                        <p key={index} className="emp-text-multi">
                          {schedule}
                        </p>
                      ))
                    : null}
                </div>
              </div>
              <div className="emp-label-div-profile pt-2">
                <p className="emp-label">Work Experience</p>
                <p className="emp-text">{data.workExperience}</p>
              </div>
              <div className="emp-label-div-profile pt-2">
                <p className="emp-label">salary</p>
                <p className="emp-text">{data.salary}</p>
              </div>
            </div>
          </div>
          <div className="emp-profile-card2 mt-4">
            <h4>Work schedule</h4>
            <hr className="emp-hr-line-prof"></hr>
            <div className="emp-label-field-div">
              {/* <p className="emp-text-multi">{data.workSchedule}</p> */}
              {data.workSchedule != null
                ? data.workSchedule.map((schedule, index) => (
                    <p key={index} className="emp-text-multi">
                      {schedule}
                    </p>
                  ))
                : null}
            </div>
           
          </div>

          <div className="emp-profile-card3 mt-4">
            <h4>Province</h4>
            <hr className="emp-hr-line-prof"></hr>
            {/* <p className="emp-label-province">{data.provience}</p> */}
            <div className="emp-label-field-div">
              {data.provience != null
                ? data.provience.map((schedule, index) => (
                    <p key={index} className="emp-text-multi">
                      {schedule}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="emp-profile-card4 mt-4">
            <h4>Bio</h4>
            <hr className="emp-hr-line-prof"></hr>
            <p>
          
              {data.bio && parse(data.bio)}{" "}
            </p>
          </div>
        </div>
      )}
      {showEmployeeEditPage && (
        <EmployeeProfileEdit
          prevdata={data}
          setShowEmployeeEditPage={setShowEmployeeEditPage}
        />
      )}
  </div></>)}
  </EmployeeProfileLayout></>
  );
};
export default EmpProfile;