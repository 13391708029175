import React,{useState, useEffect} from "react";
import "./EmployeeEducationEdit.css";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_EDUCATION_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import { useNavigate } from "react-router-dom";


const EmpEducEdit = ({ setShowEmpEducationEdit, prevdata}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue 
  } = useForm();


  const Token = localStorage.getItem("token");
  const onClickCancel = () => {
    setShowEmpEducationEdit(false);
    window.scrollTo(0, 0);
  };
  const onSubmit = (data) => {
    console.log(data);


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      school: data.School,
      educationalLevel: data.educlevel,
      classOf: data.classof
    });
    console.log(raw);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${BaseUrl}${EMPLOYEE_EDUCATION_UPDATE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "education data");
        if (result.statuscode === 200) {
          toast.info("Education data is successfully updated");
          
          window.location.reload();
        } 
        else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
    
  };
  console.log(prevdata, "prev data"); 

  useEffect(()=>{
    Object.entries(prevdata).forEach(([name, value]) => {
      setValue(name, value);
      setValue("School", prevdata.school);
    setValue("educlevel", prevdata.educationalLevel);
    setValue("classof", prevdata.classOf);
    });
    console.log("data changed from store")
  },[prevdata])


    // Custom validation function for "Class of"
    const validateClassOf = (value) => {
      const currentYear = new Date().getFullYear();
      const enteredYear = parseInt(value, 10);
      if (isNaN(enteredYear) || enteredYear < 1900 || enteredYear > currentYear) {
        return "Please enter a valid year between 1900 and " + currentYear;
      }
      return true;
    };

  return (
    <div className="container emp-educ-main">
      <div className="emp-profile-card5">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="emp-title">Education</h4>
            <p className="emp-update-text">Your highest level of education.</p>
          </div>
        </div>
        <hr className="emp-hr-line"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="emp-form-label-input">
              <label className="emp-form-label">School</label>
              <div className="profileeditinput">
              <input
                  className="emp-form-input"
                  type="text"
                  {...register("School", {
                    required: true,
                   
                    
                  })}
                  defaultValue="C.P High School"
                />

                {errors.School && (
                  <p className="error-msg">Please Enter  the School Name</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Education Level</label>
              <div className="profileeditinput">
              <input
                  className="emp-form-input"
                  type="text"
                  {...register("educlevel", {
                    required: true,
                    
                  })}
                  defaultValue="Highest level completed "
                />

                {errors.educlevel && (
                  <p className="error-msg">Please Enter the Education Level</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Class of</label>
              <div className="profileeditinput">
              <input
                  className="emp-form-input"
                  type="text"
                  {...register("classof", {
                    required: true,
                    validate: validateClassOf,
                  })}
                  defaultValue="2020"
                />

                {errors.classof && (
                  <p className="error-msg">Please Enter Valid year</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-cancel-btns-div pt-3">
              <button
                className="emp-edit-btn-cp-cancel-btn"
                type="button"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button className="emp-edit-btn cp-save-btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EmpEducEdit;
