import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import { ForgotPasswordContent } from "../../Content/ForgotPassword.content";
import BackButton from "../../Components/BackButton/BackButton";
import Email from "../../Components/Email/Email";
import { RegistrationContent } from "../../Content/RegistrationPage.content";
import { useForm } from "react-hook-form";
import OtpVerification from "../../Components/OtpVerification/OtpVerification";
import Button from "../../Components/Button/Button";
import Banner from "../../Components/Banner/Banner";
import LoginImage from "../../Assets/Images/LoginImage.png";
import { LoginContent } from "../../Content/LoginPage.content";
import { useNavigate, useLocation } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  RESET_PASSWORD_OTP_CUSTOMER,
  RESET_PASSWORD_OTP_EMPLOYEE,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";

const ForgotPassword = ({
  showCustomer,
  showEmployee,
  setShowForgotPassword,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //   const showCustomer = JSON.parse(localStorage.getItem(showCustomer));
  // console.log("fshowCustomer",showCustomer);
  const navigate = useNavigate();
  const location = useLocation();
  const [otpVerification, setShowOtpVerification] = useState(false);
  const [data, setData] = useState({});
  const [user, setUser] = useState("");

  const onSubmit = (data) => {
    // console.log("Login form Submit", data);
    if (data) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `${BaseUrl}${
          user === "customer"
            ? RESET_PASSWORD_OTP_CUSTOMER
            : RESET_PASSWORD_OTP_EMPLOYEE
        }${data.email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.statuscode === 200) {
            setData(data);
            setShowOtpVerification(true);
            toast.info("OTP successfully sent to your registered email id");
          } else if (result.statuscode === 400) {
            toast.error("This email is not associated with any account");
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };
  const onClickBackButton = () => {
    navigate("/login");
  };
  const onClickSignUp = () => {
    navigate("/");
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const valueParam = searchParams.get("user");
    setUser(valueParam);
  }, [location]);

  return (
    <>
      <div className="fp-container">
        <div className="banner-fp">
          <Banner
            image={LoginImage}
            headerContent={
              user === "customer"
                ? LoginContent.LOGINIMAGEHEADER
                : LoginContent.EMPLOYEECONTENT
            }
            descriptionContent={LoginContent.LOGINIMAGEDESCRIPTION}
          />
        </div>
        <div className="fp-form-container">
          {!otpVerification && (
            <div>
              <div className="backbutton-f">
                <BackButton onClick={onClickBackButton} />
              </div>
              <div className="forgotpassword-container">
                <form
                  className="forgotpasswordform"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="forgotpasswordheader">
                    {ForgotPasswordContent.FORGOTPASSWORDHEADER}
                  </div>
                  <div className="forgotpasswordparagraph">
                    {ForgotPasswordContent.FORGOTPASSWORDPARAGRAPH}
                  </div>
                  <div className="fpemail">
                    <Email
                      register={register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="error-msg">Please enter Email</span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span className="error-msg">Invalid Email Address</span>
                    )}
                  </div>
                  <div className="button-Next">
                    <Button buttonName={"Next"} />
                  </div>
                </form>
                <div className="footer-fp">
                  <p className="footer-content-forgotpassword">
                    Don’t have an account?{" "}
                    <span>
                      <button className="Signup-button" onClick={onClickSignUp}>
                        {" "}
                        SignUp
                      </button>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {otpVerification && (
            <OtpVerification
              setShowOtpVerification={setShowOtpVerification}
              otpVerification={otpVerification}
              forgetPasswordData={data}
              showCustomer={user}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
