import React from "react";
import "./BackButton.css";
import Backicon from "../../Assets/Images/backleft.png";

const BackButton = ({onClick}) => {
  return (
    <div className="back-icon-container">
      <button className="back-icon-btn" onClick={onClick}>
        <img className="icon-image" src={Backicon} />
        Back
      </button>
    </div>
  );
};
export default BackButton;
