import React, { useCallback, useEffect, useState } from "react";
import "./DropZone.css";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/Svg/upload.svg";
import fileuploaded from "../../Assets/Svg/fileuploaded.svg";
import checkbox from "../../Assets/Svg/checkbox.svg";

const Dropzone = ({ onFilesDropped, data, uploadedFileName ,uploadedFileUrl}) => {
  const [showUploadedFile, setShowUploadedFile] = useState(null);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setShowUploadedFile(file);
      setShowUploadSuccess(true);
      setIsChecked(false);
      if (onFilesDropped) {
        onFilesDropped(acceptedFiles);
      }
    },
    [onFilesDropped]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCheckboxClick = (e) => {
    e.preventDefault();
    setShowUploadedFile(null);
    setShowUploadSuccess(false);
  };

  useEffect(() => {
    if (uploadedFileUrl) {
      setShowUploadedFile(uploadedFileUrl);
      setShowUploadSuccess(true);
    } else {
      setShowUploadedFile(null);
      setShowUploadSuccess(false);
    }
    console.log(uploadedFileUrl,"uploadedfiles@@@");
  }, [uploadedFileUrl]);

  return (
    <div
    
      className={`dropzone-container ${showUploadSuccess ? "uploaded" : ""}`}
    >
     
     

      {showUploadedFile ? (
        <div className="uploaded-file-info">
           <div className="img_area_text">
          <div className="image-text">
            <img
              src={fileuploaded}
              alt="file_Uploaded"
              className="uploaded-image"
            />
            <div className="file-info">
            <a href={showUploadedFile} target="_blank"> {uploadedFileName} </a>
              {/* <p style={{ fontWeight: 600 }}>{showUploadedFile}</p> */}
              <p className="file-size">{showUploadedFile.size} kb</p>
            </div>
          </div>
          <img
            src={checkbox}
            alt="file_Uploaded"
            className="checkb-image"
            onClick={handleCheckboxClick}
          />
         

         </div>
          {showUploadSuccess && (
            <div className="progress-with-text">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "100%" }}
                  aria-valuenow={100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span className="progress-text">100%</span>
            </div>
          )}
          
          </div>
      ) : (
        <div className="drag-container"   {...getRootProps()}>
           <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <img src={upload} alt="Uploaded" />
              <p>
                <span style={{ color: "#04AFF0", fontWeight: 600 }}>
                  Click to upload
                </span>
                or drag and drop
              </p>
              <p>SVG, PNG, JPG, or PDF (max. 1mb)</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropzone;