import React, { useEffect, useState } from "react";
import ProfileLayoutCustomer from "../../../Layout/ProfileLayout/ProfileLayoutCustomer";
import Form from "react-bootstrap/Form";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import {
  CUSTOMER_NOTIFICATION,
  CUSTOMER_NOTIFICATION_UPDATE,
} from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";

const CustomerProfileNotification = () => {
  const navigate = useNavigate();

  const [isSwitchOn, setIsSwitchOn] = useState({
    appMessage: 0,
    appComments: 0,
    emailFeedback: 0,
    emailReminder: 0,
    emailNews: 0,
    emailPromotion: 0,
    emailSuppourt: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleSwitchChange = (e) => {
    const switchId = e.target.id;
    const isChecked = e.target.checked ? 1 : 0;
    setIsSwitchOn((prevSwitches) => ({
      ...prevSwitches,
      [switchId]: isChecked,
    }));
  };
  // console.log("switch after click", isSwitchOn);

  const Token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_NOTIFICATION}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        // console.log(result.notification_status, "notification result");
        setIsSwitchOn(result.notification_status);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const onClickSave = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(isSwitchOn);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_NOTIFICATION_UPDATE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statuscode === 200) {
          toast.info("Your notification settings have been updated");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
    <ProfileLayoutCustomer showCameraIcon={false}> 
      {isLoading ? (
        <div className="hhloader-div">
          <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
          <Typewriter
            options={{
              strings: ["Loading..."],
              autoStart: true,
              loop: true,
              delay: 30,
            }}
            className="loading-text-in-loader"
          />
        </div>
      ) : (
        <div>
            {" "}
            {isSwitchOn && (
              <div
                className="container cs-profile-main cs-profile-main-ntfn"
                style={{ marginBottom: "100px" }}
              >
                <div className="cp-title-div pb-4">
                  <h3 className="cp-title">Notifications setting</h3>
                </div>
                <div className="cp-profile-card pb-4 mb-4">
                  <h4>In app notifications</h4>
                  <div>
                    <div className="cp-label-div">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Message</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="appMessage"
                            checked={isSwitchOn.appMessage === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Comments</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="appComments"
                            checked={isSwitchOn.appComments === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cp-profile-card pb-4 mb-4">
                  <h4>Email notifications</h4>
                  <div>
                    <div className="cp-label-div">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Feedback emails</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="emailFeedback"
                            checked={isSwitchOn.emailFeedback === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Reminder emails</p>
                      </div>
                      <div className="cp-label-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="emailReminder"
                            checked={isSwitchOn.emailReminder === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">News emails</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="emailNews"
                            checked={isSwitchOn.emailNews === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Promotional emails</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="emailPromotion"
                            checked={isSwitchOn.emailPromotion === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="cp-label-div pt-2">
                      <div className="cp-label-div-part1">
                        <p className="cp-label">Support emails</p>
                      </div>
                      <div className="cp-text-div">
                        <Form className="cp-text">
                          <Form.Check
                            type="switch"
                            id="emailSuppourt"
                            checked={isSwitchOn.emailSuppourt === 1}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "99px",
                      height: "48px",
                      background: "var(--color-brand-color)",
                      color: "var(--color-white)",
                      border: "1px solid #04aff0",
                      boxShadow:
                        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
                      borderRadius: "12px",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                    onClick={onClickSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
      )}
      </ProfileLayoutCustomer>
    </>
  );
};

export default CustomerProfileNotification;
