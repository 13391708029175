import React,{useState, useEffect} from "react";

import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import { useTranslation } from "react-i18next";

const CookiesPolicy =() => {
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation(); //for languge conversion

    useEffect(() => {
      window.scrollTo(0, 0);
       }, []);

     // Simulate a loading delay with setTimeout
   useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);


    return(
    <>
    {isLoading ? (
   <>
     <div className="hhloader-div">
       <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
       <Typewriter
         options={{
           strings: ["Loading..."],
           autoStart: true,
           loop: true,
           delay: 30,
         }}
         className="loading-text-in-loader"
       />
     </div>
   </>
 ) : (<>  <StaticHomePageLayout>

<header className="header-privacy-p">
              <div className="content-privacy-p">
                <div className="heading-privacy-p">{t("translation.CookiesPolicy")}</div>
                <div className="date-privacy-p">{t("translation.Effectivedate")}: 28th June 2024</div>
              </div>
            </header>

            <section className="container-privacy-p">
              <div className="contain-privacy-p">
                <div className="left-privacy-p">
                  <div className="table-privacy-p">Table of contents</div>
                  <div className="table-heading-privacy-p">
                    <p className="table-h2-privacy-p">1. What Are Cookies?</p>
                    <p className="table-h2-privacy-p">2. Types of Cookies We Use </p>
                    <p className="table-h2-privacy-p">3. How We Use Cookies</p>
                    <p className="table-h2-privacy-p">4. Your Cookie Choices</p>
                    <p className="table-h2-privacy-p">5. Changes to This Policy</p>
                    <p className="table-h2-privacy-p">6. Contact Us</p>

                  </div>
                </div>

                <div className="right-privacy-p">
                  <div className="rich-text-privacy-p">
                    <div className="h2-privacy-p">
                      <h2 className="heading-2-privacy-p">1. What Are Cookies? </h2>
                    </div>
                    
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a website. They enable the website to remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another. 
                      </p>
                    </div>

                    <div className="h3-privacy-p">
                      <h3 className="heading-3-privacy-p">2. Types of Cookies We Use </h3>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.1 Essential Cookies 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      These cookies are necessary for the website to function properly. They enable core functionalities such as security, network management, and accessibility.  
                      </p>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.2 Performance Cookies 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      These cookies collect information about how visitors use our website, such as which pages are visited most often, and if users receive error messages from certain pages. These cookies do not collect information that identifies a visitor. All information collected by these cookies is aggregated and therefore anonymous. It is only used to improve how a website works.
                      </p>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.3 Functionality Cookies 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      These cookies allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts, and other parts of web pages that you can customize. 
                      </p>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.4 Advertising/Targeting Cookies 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement, as well as to help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator's permission. They remember that you have visited a website and this information is shared with other organizations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organization. 
                      </p>
                    </div>
                    

                    <div className="h4-privacy-p">
                      <h4 className="heading-4-privacy-p">3. How We Use Cookies </h4>
                    </div>
                    <div className="p-h4-privacy-p">
                      <p className="h4-bold-privacy-p">
                      We use cookies to improve your experience on our website, including remembering your preferences, analyzing traffic patterns, and personalizing content and ads. 
                      </p>
                    </div>

                    <div className="h5-privacy-p">
                      <h5 className="heading-5-privacy-p">4. Your Cookie Choices </h5>
                    </div>
                    <div className="p-h5-privacy-p">
                      <p className="h5-bold-privacy-p">
                      You can control and manage cookies in various ways. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can, however, obtain up-to-date information about blocking and deleting cookies via these links: 
                      </p>
                    </div>
                    <div className="p-h5-light-privacy-p">
                      <div className="divider-privacy-p">
                        <p className="divide-privacy-p"></p>
                        <p className="h5-light-privacy-p">
                        Please note that if you choose to block cookies, you may not be able to access certain parts of our website or use some of its features. 
                        </p>
                        
                      </div>
                    </div>

                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">5. Changes to This Policy </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      We may update this Cookies Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website. 
                      </p>
                    </div>
                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">6. Contact Us </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      If you have any questions about this Cookies Policy or our use of cookies, please contact us at [contact information]. 

 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>


    </StaticHomePageLayout></>)}</>
   ) }

export default CookiesPolicy;