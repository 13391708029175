import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ProfileSideBarCreatePost.css";
// import fake from "../../../Assets/Svg/fake-img.svg";
// import camera from "../../../Assets/Svg/camera.svg";
// import { Grid } from "@mui/material";
import ProfileSideBarNotificationBox from "../ProfileSideBarNotificationBox/ProfileSideBarNotificationBox";
import ProfileSideBarSpecialRequestBox from "../ProfileSideBarSpecialRequestBox/ProfileSideBarSpecialRequestBox";
// import emoji from "../../../Assets/Svg/emoji-vector.svg";
import imageUpload from "../../../Assets/Svg/camera-vector.svg";
// import camFeatureIcon from "../../../Assets/Images/capture-cam-icon.png";
import { Modal, Button } from "react-bootstrap";
// import { useForm, Controller } from "react-hook-form";
import DropdownWithCheckBox from "../DropdownWithCheckBox/DropdownWithCheckBox";
// import TextEditor from "../../TextEditor/TextEditor";
import Dropzone from "react-dropzone";
// import CustomerCreatePostButton from "../CustomerCreatePostButton/CustomerCreatePostButton";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_PROFILE } from "../../../ApiEndPoints/ApiEndPoints";
// import CreatePostModalPopup from "../../../Components/CustomerCreatePost/CustomerCreatePostButton/CustomerCreatePostButton";
import { CUSTOMER_CREATE_POST } from "../../../ApiEndPoints/ApiEndPoints";
import camera from "../../../Assets/Svg/cam-vector.png";
import Resizer from "react-image-file-resizer";
import captureIcon from "../../../Assets/Images/capture-cam-icon.png";
import axios from "axios";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import CreatePostEmojiTextArea from "../CreatePostEmojiTextArea/CreatePostEmojiTextArea";
// import HHLogo from "../../../Assets/Images/logo.png";
// import Typewriter from "typewriter-effect";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";

const ProfileSideBarCreatePost = ({
  children, handleDrop
  // createPostPopupOpen,
  // setCreatePostPopupOpen,
  // openModalForCreatePost,
  // closeModalForCreatePost, 
}) => {
  // const [createPostPopupOpen, setCreatePostPopupOpen] = useState(true);
  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false); // Track the image loading status

  // const [profilePictureChangeBox, setProfilePictureChangeBox] = useState(true);
  const [data, setData] = useState({});
  const Token = localStorage.getItem("token");
  const [imgSrc, setImgSrc] = useState(false);
  const [showEmojiTextArea, setShowEmojiTextArea] = useState(true);
  const [showImageIconDropZoneArea, setShowImageIconDropZoneArea] =
    useState(false);
  const [showCameraIconDropZoneArea, setShowCameraIconDropZoneArea] =
    useState(false);
  const [showCapturedImage, setShowCapturedImage] = useState(false);
  const [cameraDropZoneBox, setCameraDropZoneBox] = useState(false);
  const webcamRef = useRef(null);
  // const [filePreviews, setFilePreviews] = useState([]);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [text, setText] = useState("");
  const [selectedFileFromDropZone, setSelectedFileFromDropZone] =
    useState(null);
  const [capturedImgSrc, setCapturedImgSrc] = useState(null);
  const [resizedPreviews, setResizedPreviews] = useState([]);
  const [previewDropedFile, setPreviewDropedFile] = useState(true);
  const [previewImageUploadByFile, setPreviewImageUploadByFile] =
    useState(null);
  const [profileImageLoaded, setProfileImageLoaded] = useState(false); //for profile image loader

  console.log("selectedFileFromDropZone", selectedFileFromDropZone);

  console.log(Token);
  console.log(imgSrc);
  const closeCamToTakePicture = () => {
    setShowCapturedImage(!!imgSrc);
    setImgSrc(!!capturedImgSrc ? capturedImgSrc : imgSrc);
    setCameraDropZoneBox(false);
    setShowCameraIconDropZoneArea(false);
  };
  // Function to handle the image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const capturePicture = useCallback(() => {
    if (webcamRef.current) {
      const capturedImgSrc = webcamRef.current.getScreenshot();
      setImgSrc(capturedImgSrc);
      setCapturedImgSrc(capturedImgSrc);
      setShowCameraIconDropZoneArea(false);
      setShowCapturedImage(true);
    }
  }, [webcamRef, setImgSrc, setCapturedImgSrc, setShowCapturedImage]);

  const retake = () => {
    setImgSrc(null);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
    setCameraDropZoneBox(true);
    setShowCameraIconDropZoneArea(true);
  };

  const uploadImageInCreatePostVariable =
    selectedFileFromDropZone && capturedImgSrc;
  console.log(
    "uploadImageInCreatePostVariable",
    uploadImageInCreatePostVariable
  );
  console.log("capturedImgSrc", capturedImgSrc);
  const isTextAreaEmpty = text.trim() === "";

  const showDropZoneImage = () => {
    setShowImageIconDropZoneArea(true);
    setShowEmojiTextArea(true);
    setShowCameraIconDropZoneArea(false);
    setCameraDropZoneBox(false);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
  };

  const openCamToTakePicture = () => {
    setTimeout(() => {
      setShowCameraIconDropZoneArea(false);
      setShowEmojiTextArea(true);
      setShowImageIconDropZoneArea(false);
      setCameraDropZoneBox(true);
    }, 1000);
    // setIsLoading(false);
  };
  const onClickCameraDropZoneOpenCamera = () => {
    setShowCameraIconDropZoneArea(true);
    // setIsLoading(false);
  };

  const closeModalForCreatePost = () => {
    setCreatePostPopupOpen(false);
  };
  console.log("imgSrc", imgSrc);



  const onClickHandleUpload = async () => {
    const Token = localStorage.getItem("token");

    const formData = new FormData();
    if (!optionSelected) {
      toast.error("Please select a value for maid, nanny, or carer.");
      return;
    }
    formData.append("community", optionSelected.value);
    formData.append("description", text);
    if (isTextAreaEmpty) {
      // If text area is empty, return without performing the upload action
      setShowDescriptionError(true);
      return;
    }

    if (selectedFileFromDropZone) {
      console.log(selectedFileFromDropZone);
      selectedFileFromDropZone.forEach((file) => {
        const blob = new Blob([file.file], { type: "image/jpeg" });

        formData.append("postImage", blob, "filename.jpg");
      });
    } else if (imgSrc) {
      console.log("capturedImgSrc");
      console.log("imgSrc", imgSrc);

      function base64ToBlob(base64Data, contentType) {
        const base64WithoutPrefix = base64Data.replace(
          /^data:[^;]+;base64,/,
          ""
        );
        const byteCharacters = atob(base64WithoutPrefix);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
      }
      const contentType = "image/png";

      const capturedBlob = base64ToBlob(imgSrc, contentType);
      console.log("capturedBlob", capturedBlob);
      formData.append("postImage", capturedBlob);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
        // "Content-Type": "image/jpeg",
      },
    };

    axios
      .post(`${BaseUrl}${CUSTOMER_CREATE_POST}`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          console.log("Post created successfully");
          toast.info("Your post is created successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          setCreatePostPopupOpen(false);
          setPreviewImageUploadByFile(true);
        } else {
          console.log("Error creating post");
          toast.error("Error in creating post");
        }
      })
      .catch((error) => {
        console.log("Error creating post", error);
      });
  };

  const onClickUploadButton = () => {
    onClickHandleUpload();
  };

  const showPreviewDropedFile = () => {
    setPreviewDropedFile(true);
  };

  //get api for customer profile
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_PROFILE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "result");
        setData(result.customerData);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const openModalForCreatePost = () => {
    setCreatePostPopupOpen(true);
    console.log("createPostPopupOpen", createPostPopupOpen);
  };
  console.log("createPostPopupOpen", createPostPopupOpen);

  // const closeModalForCreatePost = () => {
  //   setCreatePostPopupOpen(false);
  // };

  // const handleProfileImageLoad =()=>{
  //   setProfileImageLoaded(true);
  //  }
  return (
    <div className="psb-main">
      <div className="psb-card psb-card-create-post px-3">
        <div className="psb-cust-text-div">
          <p className="psb-cust-text">Customer</p>
        </div>
        <div className="psb-profile-img-div">
          {/* Display the placeholder image when the high-resolution image is not loaded */}
          {!imageLoaded && (
            <img
              src={data.profileImageUrl ?? DefaultProfilePic}
              alt=""
              className="psb-profile-img psb-profile-img-placeholder"
            />
          )}
          {/* Display the final image when it is loaded */}
          <img
            src={data.profileImageUrl ?? DefaultProfilePic}
            alt=""
            className={`psb-profile-img ${imageLoaded ? "" : "hidden"}`}
            onLoad={handleImageLoad}
          />{" "}
        </div>
        <p className="psb-name">
          {data.firstName} {data.lastName}
        </p>
        <div className="create-post-button-div">
          <button
            className="create-post-button"
            onClick={openModalForCreatePost}
          >
            Create post
          </button>
          {createPostPopupOpen && (
            <Modal
              show={createPostPopupOpen}
              onHide={closeModalForCreatePost}
              className="custom-create-post-modal centered"
            >
              <Modal.Header closeButton>
                <Modal.Title className="custom-modal-title">
                  Create a Post
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom-modal-body">
                <div className="ch-cp-popup-drodown-div">
                  <img
                    src={data.profileImageUrl ?? DefaultProfilePic}
                    alt=""
                    className="ch-cp-profile-img"
                  />
                  <DropdownWithCheckBox
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                  />
                  {console.log("optionSelected", optionSelected)}
                </div>
                {showEmojiTextArea && (
                  <div>
                    <p className="ch-cp-modal-popup-text">
                      Description(Please be as detailed as possible)
                    </p>
                    {showDescriptionError && (
                      <p className="ch-cp-description-error">
                        *Description cannot be empty.
                      </p>
                    )}
                    <CreatePostEmojiTextArea text={text} setText={setText} />

                    {console.log("text", text)}
                  </div>
                )}
                {showImageIconDropZoneArea && (
                  <div className="ch-cp-camera-image-upload-dropzone">
                    <Dropzone
                      onDrop={handleDrop}
                      accept="image/jpeg, image/png"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="ch-cp-image-dropzone">
                            {previewImageUploadByFile ? (
                              <div className="d-flex flex-column">
                                {/* <img src={removeUploadedFileIcon} alt="removeuploadedfile" className="ch-cp-remove-uploaded-file-icon"/> */}
                                <img
                                  src={previewImageUploadByFile}
                                  alt="Preview"
                                  className="ch-cp-preview-image-upload-by-file ch-cp-image-dropzone-text"
                                />
                              </div>
                            ) : (
                              <>
                                <p className="ch-cp-image-dropzone-text">
                                  Drag and drop an image or
                                </p>
                                <button
                                  className="ch-cp-drop-zone-button"
                                  onClick={showPreviewDropedFile}
                                >
                                  {" "}
                                  Upload{" "}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {previewDropedFile && (
                      <div className="dropzone-preview">
                        {resizedPreviews.map((preview, index) => (
                          <img
                            key={index}
                            src={preview.preview}
                            alt="Preview"
                            className="preview-image"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {cameraDropZoneBox && !imgSrc && (
                  <div className="ch-cp-camera-image-upload-dropzone">
                    <p
                      className="ch-cp-image-dropzone-text"
                      onClick={onClickCameraDropZoneOpenCamera}
                    >
                      Click to start camera
                    </p>
                  </div>
                )}
                {showCameraIconDropZoneArea && (
                  <div>
                    {isLoading ? (
                      <div className="hhloader-div">
                        {/* <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div> */}
                        <p>Loading....</p>
                      </div>
                    ) : (
                      <Modal
                        show={openCamToTakePicture}
                        onHide={closeCamToTakePicture}
                        className="custom-camera-modal centered"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="custom-modal-title">
                            Camera
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-camera-modal-body">
                          <div className="ch-cp-camera-div">
                            {imgSrc ? (
                              <img src={imgSrc} alt="webcam" />
                            ) : (
                              <Webcam
                                height={341}
                                width={700}
                                ref={webcamRef}
                                style={{ width: "100%", height: "auto" }}
                              />
                            )}
                            <div className="btn-container"></div>
                          </div>{" "}
                        </Modal.Body>
                        <Modal.Footer className="custom-modal-footer">
                          <div className="">
                            <div>
                              {imgSrc ? (
                                <div>
                                  <button
                                    onClick={retake}
                                    className="ch-cp-drop-zone-button"
                                  >
                                    Retake
                                  </button>
                                  <button
                                    onClick={capturePicture}
                                    className="ch-cp-drop-zone-button"
                                  >
                                    Upload
                                  </button>
                                </div>
                              ) : (
                                <button onClick={capturePicture} className="">
                                  <img
                                    src={captureIcon}
                                    alt=""
                                    className="ch-cp-popup-vector"
                                  />
                                </button>
                              )}
                            </div>
                            {/* <Button variant="primary">Post</Button> */}
                          </div>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </div>
                )}
                {showCapturedImage && (
                  <div className="ch-cp-captured-image-container">
                    <img
                      src={imgSrc}
                      alt="Captured"
                      className="ch-cp-captured-image"
                    />
                    <div className="ch-cp-captured-image-buttons">
                      <button
                        onClick={retake}
                        className="ch-cp-drop-zone-button my-3 mx-auto"
                      >
                        Retake
                      </button>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="custom-modal-footer">
                <div className="ch-cp-popup-footer">
                  <div>
                    <button onClick={showDropZoneImage}>
                      <img
                        src={imageUpload}
                        alt=""
                        className="ch-cp-popup-vector"
                      />
                    </button>
                    <button onClick={openCamToTakePicture}>
                      <img src={camera} alt="" className="ch-cp-popup-vector" />
                    </button>
                  </div>
                  <Button
                    className="post-btn-create-post-popup"
                    onClick={onClickUploadButton}
                  >
                    Post
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
          {/* <CreatePostModalPopup
            createPostPopupOpen={createPostPopupOpen}
            setCreatePostPopupOpen={setCreatePostPopupOpen}
            imgSrc={data.profileImageUrl}
            setImgSrc={setImgSrc}
            customerProfileImage={data.profileImageUrl}
          /> */}
        </div>
      </div>

      <ProfileSideBarNotificationBox />
      <ProfileSideBarSpecialRequestBox />
    </div>
  );
};

export default ProfileSideBarCreatePost;
