import React, { useState, useEffect } from "react";
import "./CustomerCreatedPostBox.css";
import ReactMarkdown from "react-markdown";
import DotIcon from "../../../Assets/Svg/Ellipse.svg";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";
import CategoryIcon from "../../../Assets/Svg/category-Icon.svg";
import LocationIcon from "../../../Assets/Svg/location-Icon.png";
import CommentIcon from "../../../Assets/Images/comment.png";
import RepliesIcon from "../../../Assets/Svg/replies-Icon.svg";
import loadMorePostButtonImg from "../../../Assets/Images/loadMorePostButtonImg.png";
import { LuBold } from "react-icons/lu";
import { AiOutlineItalic } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import { json, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import {
  CUSTOMER_GET_ALL_POSTS,
  EMPLOYEE_GET_ALL_COMMENTS,
  CUSTOMER_GET_ALL_COMMENTS,
  EMPLOYEE_GET_PROFILE_COMMENT_REPLY,
  CUSTOMER_GET_PROFILE_COMMENT_REPLY,
  EMPLOYEE_ADD_COMMENTS,
  CUSTOMER_ADD_COMMENTS,
  EMPLOYEE_REPLY,
  CUSTOMER_REPLY,
  EMPLOYEE_REPLY_TO_CUSTOMER_POST,
  ADD_COMMENTS,
  EMPLOYEE_GET_COMMENT_IN_POSTS_LISTS,
  CUSTOMER_REPLY_TO_EMPLOYEE_COMMENT_POSTAPI,
} from "../../../ApiEndPoints/ApiEndPoints";
import CustomerPostDeletePostComponent from "../CustomerPostDeletePostComponent/CustomerPostDeletePostComponent";

const CustomerCreatedPostBox = ({
  postData,
  setPostData,
  showingPostsCount,
  setShowingPostsCount,
  totalPostsCount,
  setTotalPostsCount,
  fromEmployee,
  handleDrop,
  showDeleteComponent,
}) => {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [formattedText1, setFormattedText1] = useState("");
  const [formattedText2, setFormattedText2] = useState("");
  const [text, setText] = useState(""); // Add this state variable
  const [showCommentBoxInCp, setShowCommentBoxInCp] = useState(false);
  const [totalCommentsData, setTotalCommentsData] = useState(null);
  const [numCommentsToShow, setNumCommentsToShow] = useState(3); //for to show 3 comments only
  const [replyText, setReplyText] = useState("");
  // const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [custPostId, setCustPostId] = useState(null); // Added custPostId state
  const [selectedPostId, setSelectedPostId] = useState(null); //for post id
  const [selectedCommentId, setSelectedCommentId] = useState(null); //for commentid for specified post id
  const [selectedReplyId, setSelectedReplyId] = useState(null); // for replyid on comment id on specified post id
  const [openReplyIndex, setOpenReplyIndex] = useState(-1);
  const [openReplyCommentId, setOpenReplyCommentId] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [name, setName] = useState("");
  const [openReplyBoxToReply, setOpenReplyBoxToReply] = useState(false);
  const [displayedPostsCount, setDisplayedPostsCount] = useState(5);
  const Token = localStorage.getItem("token");
  var dataGetForCreatedPost = [];

  const loadMorePosts = () => {
    console.log("Load More button clicked");
    // Increase the displayedPostsCount to load more posts
    setDisplayedPostsCount(displayedPostsCount + 5); // You can change the increment as needed
  };
  const loadMoreComments = () => {
    // Increase the number of comments to show by 3
    setNumCommentsToShow(numCommentsToShow + 3);
  };
  const handleChange = (value) => {
    setReplyText(value);
  };
  const handleBoldClick = () => {
    setFormattedText((prevText) => prevText + "**");
  };

  // Handle click on the italic button
  const handleItalicClick = () => {
    setFormattedText((prevText) => prevText + "*");
  };

  // Handle click on the link button
  const handleLinkClick = () => {
    const url = prompt("Enter the link URL:");
    if (url) {
      setFormattedText((prevText) => `[Link Text](${url})`);
    }
  };

  console.log("replyText", replyText);
  const showCommentBoxInCpf = async (postId) => {
    console.log("postId", postId);
    setShowCommentBoxInCp(!showCommentBoxInCp);
    // setShowCommentBoxInCp(true);
    setSelectedPostId(postId); // Set the custPostId state
    console.log("selectedPostId", selectedPostId);
    if (!showCommentBoxInCp) {
      await fetchCommentsData(postId);
    }
    console.log("setShowCommentBoxInCp", setShowCommentBoxInCp);
    console.log("showCommentBoxInCp", showCommentBoxInCp);
  };

  //to get profile image in the comment box

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    const endpoint = fromEmployee
      ? EMPLOYEE_GET_PROFILE_COMMENT_REPLY
      : CUSTOMER_GET_PROFILE_COMMENT_REPLY;

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${endpoint}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })
      .then((result) => {
        setProfileImage(result.profileImageUrl); // Assuming the API response structure contains the profileImage field
        setName(result.name);
        // alert(result.profileImage);  // Assuming the API response structure contains the name field
      })
      .catch((error) => console.log("error", error));
  }, []);

  const fetchCommentsData = async (postId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    const apiEndpoint = fromEmployee
      ? EMPLOYEE_GET_ALL_COMMENTS
      : CUSTOMER_GET_ALL_COMMENTS;

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BaseUrl}${apiEndpoint}/${postId}`,
      requestOptions
    );
    const commentresult = await response.json();
    setTotalCommentsData(commentresult.commentsInfo);
    console.log("totalCommentsData", totalCommentsData);
  };

  useEffect(() => {
    if (showCommentBoxInCp && custPostId !== "") {
      fetchCommentsData(custPostId);
    }
  }, [showCommentBoxInCp, custPostId]);
  console.log("totalCommentsData", totalCommentsData);
  console.log("showCommentBoxInCp", showCommentBoxInCp);
  console.log("custPostId", custPostId);

  const {
    // register,
    handleSubmit,
    formState: { errors },
    // watch,
    control,
  } = useForm();

  const convertToSimplifiedHour = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const currentDate = new Date();
    const differenceInSeconds = Math.floor((currentDate - date) / 1000);

    if (differenceInSeconds <= 60) {
      // Less than a minute
      return "Just now";
    } else if (differenceInSeconds <= 3600) {
      // Less than an hour
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds <= 86400) {
      // Less than a day
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds <= 604800) {
      // Less than a week
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else {
      // After seven days, show the date format in dd-mm-yyyy
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate.replace(/\//g, "-"); // Replace slashes with dashes
    }
  };

  const empConvertToSimplifiedHour = (empDateTimeString) => {
    const date = new Date(empDateTimeString);
    const currentDate = new Date();
    const differenceInSeconds = Math.floor((currentDate - date) / 1000);

    if (differenceInSeconds <= 60) {
      // Less than a minute
      return "Just now";
    } else if (differenceInSeconds <= 3600) {
      // Less than an hour
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds <= 86400) {
      // Less than a day
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds <= 604800) {
      // Less than a week
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else {
      // After seven days, show the date format in dd-mm-yyyy
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate.replace(/\//g, "-"); // Replace slashes with dashes
    }
  };

  const formatDateDifference = (createdDate) => {
    const currentDate = new Date();
    const timeDifference = Math.floor((currentDate - createdDate) / 1000); // Difference in seconds

    if (timeDifference < 60) {
      // Less than a minute
      return `Just now`;
    } else if (timeDifference < 3600) {
      // Less than an hour
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} min ago`;
    } else if (timeDifference < 86400) {
      // Less than a day
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} h ago`;
    } else if (timeDifference < 604800) {
      // Less than a week
      const days = Math.floor(timeDifference / 86400);
      return `${days} d ago`;
    } else {
      // After seven days, show the date format in dd-mm-yyyy
      const formattedDate = createdDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate.replace(/\//g, "-"); // Replace slashes with dashes
    }
  };

  const addcommenttopost = async (custPostId) => {
    // Check if the comment content is empty
    if (!formattedText1) {
      // Handle the case when the comment is empty
      console.log("Comment content is empty. Comment not added.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      content: formattedText1, // Use the actual comment content from replyText state
    });

    const endpoint = fromEmployee
      ? EMPLOYEE_ADD_COMMENTS
      : CUSTOMER_ADD_COMMENTS;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw, // Pass the actual comment content in the body
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BaseUrl}${endpoint}/${custPostId}`,
        requestOptions
      );

      const result = await response.json();
      console.log("result this is post api", result);
      if (result.statuscode === 201) {
        // Comment added successfully
        setFormattedText1("");
        fetchCommentsData(custPostId);
        // setShowCommentBoxInCp(false);
        setFormattedText1("");
        toast.success("Comment added", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 500,
        });

        // window.scrollTo(0, document.body.scrollHeight);

        console.log("Comment added successfully");

        // Add code here to handle any necessary state updates or UI changes
      } else {
        // Handle API error
        console.log("Failed to add comment");
        // Add code here to handle the error scenario
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error occurred during API request:", error);
      // Add code here to handle the error scenario
    }
  };

  const submitreplytocomment = async (commentId) => {
    // Check if the reply message is empty
    if (!formattedText2) {
      // Handle the case when the reply message is empty
      console.log("Reply message is empty. Reply not submitted.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      replayMessage: formattedText2,
    });

    const endpoint = fromEmployee ? EMPLOYEE_REPLY : CUSTOMER_REPLY;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${BaseUrl}${endpoint}/${commentId}`,
        requestOptions
      );

      const result = await response.json();
      if (result.statuscode === 201) {
        // Comment added successfully
        // setFormattedText("");
        console.log("reply added successfully");

        // Add code here to handle any necessary state updates or UI changes
      } else {
        // Handle API error
        console.log("Failed to add reply");
        // Add code here to handle the error scenario
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error occurred during API request:", error);
      // Add code here to handle the error scenario
    }
  };

  const openReplyBoxToReplyFunction = (commentId, commentIndex) => {
    setOpenReplyCommentId(commentId);
    setOpenReplyIndex(commentIndex);
  };
  useEffect(() => {
    console.log("########i m inside useeffext");
    if (postData.customerPosts) {
      console.log("#######i m inside useeffect after if");
      setTotalPostsCount(postData.customerPosts.length);
    } else if (postData.posts) {
      setTotalPostsCount(postData.posts.length);
    }
  }, [postData]);

  console.log(postData, "##########postdata");
  useEffect(() => {
    // window.scrollTo(0, 0);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}${CUSTOMER_GET_ALL_POSTS}`, requestOptions)
      .then((result) => {
        console.log("result", result);

        console.log("dataGetForCreatedPost", dataGetForCreatedPost);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const commentReplyHandleSubmit = async (
    data,
    e,
    commentId,
    postId,
    index
  ) => {
    //e.preventDefault();
    setReplyText(data.reply);

    const endpoint = fromEmployee ? EMPLOYEE_REPLY : CUSTOMER_REPLY;

    var requestOptions = {
      method: "POST",
      // headers: myHeaders,
      redirect: "follow",
      // body: JSON.stringify({ text: replyText }),
    };

    const response = await fetch(
      `${BaseUrl}${endpoint}/${commentId}`,
      requestOptions
    );

    const result = await response.json();
    if (result.status === 200) {
      let arrData = [...totalCommentsData];
      arrData[index].replies.push({
        replyContent: replyText,
        id: result.data.commentId,
        commentId: commentId,
        postId: postId,
        empId: null,
        customerId: null,
        employeeReplyingToComment: null,
        customerReplyingToComment: null,
        createdAt: result.data.createdAt,
      });
      setTotalCommentsData(arrData);
      console.log("arrData", arrData);
      setOpenReplyBoxToReply(!openReplyBoxToReply);
      setReplyText("");
    }
  };

  const onSubmit = async (data, e, commentId, postId, index) => {
    e.preventDefault();
    setReplyText(data.reply);

    await commentReplyHandleSubmit(commentId, postId, index);
  };

  let newPostData = [];

  if (postData.posts && postData.posts.length > 0) {
    newPostData = postData.posts;
    console.log("Data from posts:", newPostData);
  } else if (postData.customerPosts && postData.customerPosts.length > 0) {
    newPostData = postData.customerPosts;
    console.log("Data from customerPosts:", newPostData);
  }

  console.log("Final newPostData:", newPostData);

  return (
    <>
      {newPostData?.slice(0, displayedPostsCount).map((posts, index, reply) => {
        console.log(posts, "posts");
        const dateTimeString = posts.updatedAt;
        const simplifiedHour = convertToSimplifiedHour(dateTimeString);
        const empDateTimeString = posts.createdAtDate;
        const empSimplifiedHour = empConvertToSimplifiedHour(empDateTimeString);
        const createdDate = new Date(reply.createdAt);
        const formattedDate = formatDateDifference(createdDate);
        const postId = posts.custPostId;
        // const formatedCustomerName = {postData.customerName}
        console.log("postId", postId);
        return (
          <div className="ch-created-post-box" key={index}>
            <div className="ch-created-post-box-header">
              <div className="ch-cp-customer-details">
                <div>
                  <img
                    src={
                      postData?.customerImage?.replace(/,/g, " ") ||
                      posts?.profileImageUrl?.replace(/,/g, " ") ||
                      DefaultProfilePic
                    }
                    alt=""
                    className="ch-cjp-profile-img"
                  />
                  <span className="ch-cjp-cust-name">
                    {postData?.customerName?.replace(/,/g, " ")
                      ? postData?.customerName?.replace(/,/g, " ")
                      : posts?.name?.replace(/,/g, " ")}
                  </span>
                </div>
                <div>
                  <img src={DotIcon} className="ch-cjp-dot-icon" alt="" />

                  <span className="ch-cjp-details">
                    {fromEmployee == true ? empSimplifiedHour : simplifiedHour}
                  </span>
                  <img
                    src={CategoryIcon}
                    className="ch-cjp-category-icon"
                    alt=""
                  />
                  <span className="ch-cjp-details">{posts?.community}</span>
                  <img
                    src={LocationIcon}
                    className="ch-cjp-location-icon"
                    alt=""
                  />
                  <span className="ch-cjp-details">
                    {postData?.customerCountry?.replace(/,/g, " ")
                      ? postData?.customerCountry?.replace(/,/g, " ")
                      : posts?.location?.replace(/,/g, " ")}
                  </span>
                </div>
              </div>

              {showDeleteComponent && (
                <div>
                  {/* this component responsible for open menu for delete and update the customer post edit by renuka */}
                  <CustomerPostDeletePostComponent
                    postId={postId}
                    profileImageUrl={profileImage}
                    handleDrop={handleDrop}
                  />
                </div>
              )}
            </div>
            <div className="ch-job-description-div">
              <p className="ch-job-description">{posts?.description}</p>
              <img src={posts?.imageUrl} alt="" className="ch-job-posted-img" />
            </div>
            <hr className="ch-cjp-hr-line" />
            <div className="text-center">
              <img
                src={CommentIcon}
                onClick={() => showCommentBoxInCpf(posts.custPostId)}
                className="ch-cjp-comment-icon"
                alt=""
              />
              <span
                className="ch-cjp-details-button"
                onClick={() => showCommentBoxInCpf(posts.custPostId)}
              >
                Comment ({posts.noOfComments})
              </span>

              <img src={RepliesIcon} className="ch-cjp-replies-icon" alt="" />
              <span className="ch-cjp-details-button">
                Replies ({posts.noOfReplies ? posts.noOfReplies : 0})
              </span>
            </div>

            {showCommentBoxInCp && selectedPostId === posts.custPostId && (
              <div className="cp-comment-box">
                <p className="cp-comment-box-heading mb-5">Comments</p>
                <div className="add_coment_b">
                  <div
                    className="add_comment_ip_b"
                    // key={comment.commentId}
                  >
                    <div className="comment_img_div">
                      <img
                        src={profileImage || DefaultProfilePic} //  default image URL if profileImage is not available
                        alt=""
                        className="Employee-profile-img"
                      />
                      <div className="name-area" style={{ paddingTop: "5px" }}>
                        <p className="Employee-name">{name}</p>
                      </div>
                    </div>

                    <div className="add_comment_textarea">
                      <textarea
                        style={{ width: "93%", marginLeft: "26px" }}
                        value={formattedText1}
                        onChange={(e) => setFormattedText1(e.target.value)}
                        placeholder="Write your comment here..."
                        className="cp-comment-box-reply-textarea"
                      />
                    </div>
                    <div className="formatting-buttons">
                      <button
                        onClick={handleBoldClick}
                        className="formatting-button"
                      >
                        <LuBold />
                      </button>
                      <button
                        onClick={handleItalicClick}
                        className="formatting-button"
                      >
                        <AiOutlineItalic />
                      </button>
                      <button
                        onClick={handleLinkClick}
                        className="formatting-button"
                      >
                        <IoMdLink />
                      </button>
                    </div>
                  </div>
                  <button
                    className="comment-by-employee-button"
                    onClick={() => addcommenttopost(posts.custPostId)}
                  >
                    comment
                  </button>
                </div>
                {/* {JSON.stringify(totalCommentsData)} */}
                {totalCommentsData &&
                  totalCommentsData
                    ?.slice(0, numCommentsToShow)
                    .map((comment, commentIndex) => (
                      <div key={comment.commentId}>
                        <div className="cp-comment-box-emp-com-div mb-2 pt-4">
                          <img
                            alt=""
                            src={comment?.profileImage || DefaultProfilePic}
                            className="ch-cp-comment-profile-img"
                          />
                          <p className="cp-comment-box-emp-name">
                            {comment?.name?.replace(",", " ")}
                          </p>
                          <p className="cp-comment-box-commented-time">
                            {/* {fromEmployee == true
                            ? empSimplifiedHour
                            : simplifiedHour} */}

                            {formatDateDifference(new Date(comment.createdAt))}
                          </p>
                        </div>
                        <div>
                          <p className="cp-comment-box-comment">
                            {comment.content}
                          </p>

                          <div>
                            {/* <button
                            className="reply-to-comment-by-employee-button"
                            onClick={() =>
                              openReplyBoxToReplyFunction(comment.commentId)
                            }
                          >
                            Reply
                          </button> */}
                          </div>
                          {openReplyIndex === commentIndex ? (
                            <div>
                              <form
                                id="noter-save-form"
                                onSubmit={commentReplyHandleSubmit}
                              >
                                <div className="add_coment_b">
                                  <div
                                    className="add_reply_ip_b"
                                    key={comment.commentId}
                                  >
                                    <div className="comment_img_div">
                                      <img
                                        src={profileImage || DefaultProfilePic}
                                        alt=""
                                        className="Employee-profile-img"
                                      />
                                      <div
                                        className="name-area"
                                        style={{ paddingTop: "5px" }}
                                      >
                                        <p className="Employee-name">{name}</p>
                                      </div>
                                    </div>

                                    <div className="add_comment_textarea">
                                      <textarea
                                        style={{
                                          width: "93%",
                                          marginLeft: "26px",
                                        }}
                                        value={formattedText2}
                                        onChange={(e) =>
                                          setFormattedText2(e.target.value)
                                        }
                                        placeholder="Type your reply..."
                                        className="cp-comment-box-reply-textarea"
                                      />
                                    </div>
                                    <div className="formatting-buttons">
                                      <button
                                        onClick={handleBoldClick}
                                        className="formatting-button"
                                      >
                                        <LuBold />
                                      </button>
                                      <button
                                        onClick={handleItalicClick}
                                        className="formatting-button"
                                      >
                                        <AiOutlineItalic />
                                      </button>
                                      <button
                                        onClick={handleLinkClick}
                                        className="formatting-button"
                                      >
                                        <IoMdLink />
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    className="reply-to-comment-by-button"
                                    onClick={() =>
                                      submitreplytocomment(
                                        comment.commentId,
                                        commentIndex
                                      )
                                    }
                                  >
                                    Reply
                                  </button>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="submit"
                                className="reply-to-comment-by-employee-button"
                                onClick={() =>
                                  openReplyBoxToReplyFunction(
                                    comment.commentId,
                                    commentIndex
                                  )
                                }
                              >
                                reply
                              </button>
                            </div>
                          )}

                          {openReplyBoxToReply &&
                            selectedCommentId === comment.id && (
                              <div className="CCPB-replyBox">
                                <form
                                  onSubmit={handleSubmit((data, e) =>
                                    onSubmit(
                                      data,
                                      e,
                                      comment.id,
                                      comment.postId
                                    )
                                  )}
                                >
                                  <button
                                    type="submit"
                                    className="CCPB-replySubmitBtn"
                                  >
                                    Reply
                                  </button>
                                </form>
                              </div>
                            )}
                          {comment.replies && comment.replies.length > 0 && (
                            <div className="CCPB-replySection">
                              {comment.replies.map((reply, j) => (
                                <div className="CCPB-replyBox" key={j}>
                                  <img
                                    src={
                                      reply.customerReplyingToComment
                                        ? reply.personProfileImageUrl ||
                                          DefaultProfilePic
                                        : reply.personProfileImageUrl ||
                                          DefaultProfilePic
                                    }
                                    alt="User"
                                    className="ch-cp-comment-profile-img"
                                  />
                                  <span className=" cp-comment-box-emp-name">
                                    {reply?.personName.replace(",", " ")}{" "}
                                  </span>{" "}
                                  <span className="timecss">
                                    {formatDateDifference(
                                      new Date(reply.createdAt)
                                    )}{" "}
                                  </span>
                                  {/* <span>{reply.createdAt.trim()}</span> */}
                                  {/* <p className="replytime">
                                {fromEmployee == true
                            ? empSimplifiedHour
                            : simplifiedHour}</p> */}
                                  <div className="CCPB-replyContent">
                                    <div className="CCPB-replyHead">
                                      <h5>
                                        {reply.customerReplyingToComment
                                          ? reply.customerReplyingToComment
                                          : reply.employeeReplyingToComment}
                                      </h5>
                                    </div>
                                    <div className="CCPB-replyText" mb-10>
                                      {reply.replayMessage}
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="reply-to-reply-by-employee-button"
                                      onClick={() =>
                                        openReplyBoxToReplyFunction(
                                          comment.commentId,
                                          commentIndex
                                        )
                                      }
                                    >
                                      reply
                                    </button>
                                  </div>
                                  {/* reply to reply buton give here */}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                {numCommentsToShow < totalCommentsData?.length && (
                  <div className="load-more-comments-btn-div">
                    <button
                      onClick={loadMoreComments}
                      className="load-more-comments-btn"
                    >
                      Load More Comments
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}

      {displayedPostsCount < totalPostsCount && (
        <div className="cp-comment-box-load-more-button-for-posts-div">
          <button
            className="cp-comment-box-load-more-button-for-posts"
            onClick={loadMorePosts}
          >
            Load More
          </button>
          <img
            src={loadMorePostButtonImg}
            alt="loadMorePostButtonImg"
            className="load-more-post-button-img"
            onClick={loadMorePosts}
          />
        </div>
      )}
    </>
  );
};

export default CustomerCreatedPostBox;
