import axios from 'axios';
import {BaseUrl} from  '../ApiBaseUrl/Api';
import {EMPLOYEE_DELETE_ALL_NOTIFICATIONS} from '../ApiEndPoints/ApiEndPoints';
import axiosInstance from "./axiosInstance";

export const employeeDeleteAllNotificationsApi = async()=>{
    try{
        const response = await axiosInstance.delete(`${BaseUrl}${EMPLOYEE_DELETE_ALL_NOTIFICATIONS}`);
        console.log("response delete notification service api employee", response);
        return response;
    }
    catch(error){
        throw error.response.error
    }
};

export default employeeDeleteAllNotificationsApi;