import React from "react";
import "./Footer.css";
import logo from "../../Assets/Svg/logo.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="profile-footer">
      <div className="container d-flex justify-content-between align-items-center pt-2">
        <div className="footer-logo-text">
          <img src={logo} alt="" />
          <h4 className="footer-text-hh">HeartyHelper</h4>
        </div>
        <div>
          <p className="footer-text">© 2023 HeartyHelper Co.,Ltd. All rights reserved.</p>
        </div>
        <div className="d-flex" style={{gap:"20px"}}>
          <a className="footer-text"><Link  style={{ color: 'white' }} to="/privacy-policy">Privacy Policy</Link></a>
          <a className="footer-text"><Link  style={{ color: 'white' }} to="/termsofservice">Terms of services</Link></a>
          <a className="footer-text"><Link  style={{ color: 'white' }} to="/cookies-policy">Cookies Setting</Link></a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
