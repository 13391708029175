// i18n.js file this is for the language covertion to thai

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import enTranslations from "./locales/en/enTranslations.json";
import thaiTranslations from "./locales/thai/thaiTranslations.json";

console.log("Initializing i18n...");

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations, 
      },
      thai: {
        translation: thaiTranslations,
      },
    },
    lng: "en",
    fallbackLng: "en",
  });

console.log("i18n initialized successfully!");

export default i18n;
