import React, { useEffect, useState } from "react";
import "./EmployeeAccount.css";
// import deleteIcon from "../../../Assets/Images/delete.png";
import EmployeeAccountEdit from "../../../Components/Employee/EmployeeAccount/EmployeeAccountEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_ACCOUNT } from "../../../ApiEndPoints/ApiEndPoints";
// import authIcon from "../../../Assets/Svg/auth-icon.svg";
import DeleteModalEmployee from "../../../Modal/DeleteIconModal/Employee/DeleteModalEmployee";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const EmpAccount = () => {
  const navigate = useNavigate();
  const [showEmployeeAccountEdit, setShowEmployeeAccountEdit] = useState(false);
  const [data, setData] = useState({});
  const [showDeleteEmployee,setShowDeleteEmployee]= useState(false)
  const [isLoading, setIsLoading] = useState(true);

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEmployeeAccountEdit(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_ACCOUNT}`, requestOptions)
     // .then((response) => response.json())
     .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result.employeeData, "employee account");
        setData(result.employeeData);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);
  // const onClickDelete = () => {
  //   setShowDeleteEmployee(true);
  // };

  return (
    <>
    <EmployeeProfileLayout showCameraIcon={false}>
      {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
      ) : (
          <div className="emp-account-container">
            {
              <div className="container-cs-profile-main">
                <div className="emp-title-div pb-4">
                  <h3 className="emp-title">Account</h3>
                  {!showEmployeeAccountEdit && (
                    <button className="emp-edit-btn" onClick={handleChangeEdit}>
                      Edit
                    </button>
                  )}
                </div>
                {!showEmployeeAccountEdit && (
                  <div>
                    <div className="emp-profile-card1 pb-4 mb-4">
                      <h4>Account info</h4>
                      <div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Email</p>
                          <p className="emp-text">{data.emailId}</p>
                        </div>
                        <div className="emp-label-div-desktop pt-2">
                          <p className="emp-label">Phone number</p>
                          <p className="emp-text">{data.phoneNumber}</p>
                        </div>
                      </div>
                    </div>
                    <div className="emp-profile-card2 pb-4 mb-4">
                      <h4>Linked account</h4>
                      <div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Facebook account</p>
                          <p className="emp-text">{data.facebookAccount}</p>
                        </div>
                        <div className="emp-label-div-desktop pt-2">
                          <p className="emp-label">LinkedIn account</p>
                          <p className="emp-text">{data.linkedinAccount}</p>
                        </div>
                      </div>
                    </div>
                    <div className="emp-profile-card3 pb-4 mb-4">
                      <h4>Security</h4>
                      <div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Password</p>
                          <input
                            className="emp-text"
                            type="password"
                            value={data.password}
                          />
                        </div>
                        {/* <div className="emp-label-div-desktop pt-2">
                    <p className="emp-label">Two Step Authentication</p>
                    <p className="emp-text">
                  <img src={authIcon} alt="" /> &nbsp;&nbsp; +611 321 132 7412
                  is Selected as authentication number
                </p>
                  </div> */}
                      </div>
                    </div>
                    {/* <div className="emp-profile-card4 pb-4 mb-4">
                <h4>Account management</h4>
                <div>
                  <div className="emp-label-div-desktop pt-2">
                    <p className="emp-label" style={{ color: "red" }}>
                      Delete account
                    </p>

                    <div className="emp-text">
                      <img
                        src={deleteIcon}
                        style={{ cursor: "pointer" }}
                        alt="delete"
                        onClick={onClickDelete}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
                  </div>
                )}
              </div>
            }
            {showEmployeeAccountEdit && (
              <EmployeeAccountEdit
                setShowEmployeeAccountEdit={setShowEmployeeAccountEdit}
                prevData={data}
              />
            )}
            {showDeleteEmployee && (
              <DeleteModalEmployee
                setShowDeleteEmployee={setShowDeleteEmployee}
              />
            )}
          </div>
      )}
      </EmployeeProfileLayout>
    </>
  );
};
export default EmpAccount;
