import React, { useState, useEffect } from "react";
import Editor from "ckeditor5-custom-build/ckeditor5/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const TextEditor = ({ data, onBlur, onChange , errorLabel=true}) => {
  const MAX_CHARACTERS = 250;
  const [characterCount, setCharacterCount] = useState(data ? data.length : 0);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] =
    useState(false);
  useEffect(() => {
    const content = data ? data : "";
    const plainText = content.replace(/<[^>]*>/g, "");
    setCharacterCount(plainText.length);
    setIsCharacterLimitExceeded(plainText.length > MAX_CHARACTERS);
  }, [data]);

  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    placeholder: "Enter text here...",
  };

  editorConfig.toolbar.items.splice(
    editorConfig.toolbar.items.indexOf("table"),
    1
  );

  const handleEditorChange = (event, editor) => {
    const content = editor.getData();
    const plainText = content.replace(/<[^>]*>/g, "");
    const truncatedContent = plainText.slice(0, MAX_CHARACTERS);
    setCharacterCount(truncatedContent.length);
    setIsCharacterLimitExceeded(truncatedContent.length > MAX_CHARACTERS);
    onChange(event, editor);
  };

  return (
    <div>
      <CKEditor
        editor={Editor}
        config={editorConfig}
        data={data}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={handleEditorChange}
        onBlur={onBlur}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
      {errorLabel && (
        <p
          style={{ color: isCharacterLimitExceeded ? "red" : "#7B7B7B" }}
          className="pt-2"
        >
          {isCharacterLimitExceeded
            ? "Character limit exceeded!"
            : `${MAX_CHARACTERS - characterCount} characters left`}
        </p>
      )}
    </div>
  );
};

export default TextEditor;
