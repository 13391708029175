import React, { useEffect, useState, useRef } from "react";
import StatusIconOnline from "../../../Employee/EmployeeChatComponents/StatusIcons/StatusIconOnline/StatusIconOnline";
import "./CustomerChatWithIndividualEmployee.css";
import sendMsgIcon from "../../../../Assets/Images/send-msg-Button.png";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../ApiBaseUrl/Api";
import {
  CUSTOMER_GET_SINGLE_FULL_VIEW_CHAT_WITH_EMPLOYEE,
  CUSTOMER_SEND_MESSAGE_TO_EMPLOYEE,
} from "../../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import DefaultProfilePic from "../../../../Assets/Images/Default_pfp.svg.png";
import socket from "../../../../sockets/socket";
import { useDispatch, useSelector } from "react-redux";
import NoChatFoundImage from "../../../../Assets/Images/nojobsfound.png";
import { getAllCustomerChatListAction } from "../../../../redux/actions/getAllCustomerChatListAction";

const CustomerChatWithIndividualEmployee = ({
  emailIdOfEmployee,
  employeeName,
  employeeProfileImage,
  emailIdOfCustomer,
  setEmailIdOfCustomer,
}) => {
  const [indAllMessagesList, setIndAllMessagesList] = useState([]);
  const [messageToEmployee, setMessageToEmployee] = useState("");
  const Token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const indchatReceivedMsgDivRef = useRef(); //creating the ref for the scroll to bottom messages div
  const autoScrollRef = useRef(true);
  const activeUsers = useSelector((state) => state.activeUsers);
  const onlineStatus = (email) =>
    activeUsers.some((user) => user.userId === email);

  // this useEffect is to get all messages of employee and customer
  const fetchMessages = () => {
    if (!emailIdOfEmployee) return;

    // setMessageToEmployee("");
    // window.scrollTo(0, 0);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (emailIdOfEmployee) {
      fetch(
        `${BaseUrl}${CUSTOMER_GET_SINGLE_FULL_VIEW_CHAT_WITH_EMPLOYEE}/${emailIdOfEmployee}`,
        requestOptions
      )
     
        .then((response) => {
          // console.log("response.status", response.status);
          // console.log("response", response);
          if (response.status === 401) {
            //this is for token expire
            navigate("/login");
            toast.error("Connection timed out, Please log in to access");
            return;
          } else if (response.status === 200) {
            return response.json();
          } else if (response.status === 500) {
            console.error("Server error: Something went wrong");
          } else {
            console.error("Unknown error: Status code", response.status);
          }
          // console.log("response.messages", response.messages);
        })
        .then((result) => {
          // console.log("API Response:", result);

          if (result && Array.isArray(result.messages)) {
            // console.log("result.messages", result.messages);
            setIndAllMessagesList(result.messages);
            const customerEmailId = result.messages.find(
              (message) => message.senderId !== emailIdOfEmployee
            )?.senderId;
            setEmailIdOfCustomer(customerEmailId);
          } else {
            console.error(
              "Invalid or empty messages array in the API response"
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    fetchMessages();
  }, [emailIdOfEmployee]);

  useEffect(() => {
    // Set up a listener for incoming messages
    socket.on("receive-message", (message) => {
      console.log("Received a message:", message);
      fetchMessages();
      dispatch(getAllCustomerChatListAction());

    });
  }, [emailIdOfEmployee]);

  // function to change the format of time coming from api
  function formatMessageTime(createdAt) {
    const messageDate = new Date(createdAt);
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - messageDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysAgo < 7) {
      // If the message is within the last 7 days, display "Friday 9:10 PM" format
      const options = {
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return messageDate.toLocaleTimeString("en-US", options);
    } else if (daysAgo === 1) {
      // If the message is from today, display "9:10 PM" format
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return messageDate.toLocaleTimeString("en-US", options);
    } else {
      // If the message is older than 7 days, display "09/21/2023, 12:30 PM" format
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return messageDate.toLocaleDateString("en-US", options);
    }
  }

  // this below sendMessageToEmployee function is for send message to employee post api call
  const sendMessageToEmployee = () => {
    setMessageToEmployee("");
    if (!messageToEmployee || messageToEmployee.trim() === "") {
      // If messageToCustomer is empty or only contains empty spaces, do nothing
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var raw = JSON.stringify({
      message: messageToEmployee,
      employeeEmailId: emailIdOfEmployee,
    });

    // console.log(raw);

    const requestOptions = {
      headers: myHeaders,
      body: raw,
      method: "POST",
      redirect: "follow",
    };


    socket.emit("send-message", {
      receiverId: emailIdOfEmployee,
      message: messageToEmployee,
      senderId: localStorage.getItem("email"),
    });
    console.log("send-msg-reciver", emailIdOfEmployee);
    console.log("send-msg-message", messageToEmployee);
    console.log("send-msg-sender", localStorage.getItem("email"));

    fetch(`${BaseUrl}${CUSTOMER_SEND_MESSAGE_TO_EMPLOYEE}`, requestOptions)

      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");

          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })

      .then((result) => {
        if (result.statuscode === 200) {
          // window.location.reload();
          // console.log(result, "customer sent message successfully");
          setMessageToEmployee("");
          fetchMessages();
          dispatch(getAllCustomerChatListAction());

        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error status code:", error.response.status);
        } else {
          console.error("Error in sending message:", error.message);
        }
      });
  };

  //Scroll to the bottom of the div when messages change
  const scrollToBottom = () => {
    if (autoScrollRef.current && indchatReceivedMsgDivRef.current) {
      indchatReceivedMsgDivRef.current.scrollTop =
        indchatReceivedMsgDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [indAllMessagesList]);

  // Function to handle sending a message when the enter key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the newline character from being inserted
      sendMessageToEmployee(); // Call the function to send the message
    }
  };
  return (
    <>
      {indAllMessagesList.length === 0 ? (
        <div className="ecc-no-chat-selected">
          <img
            src={NoChatFoundImage}
            alt="NoChatFoundImage"
            className="ecc-no-chat-selected-img"
          />
          <p className="ecc-no-chat-selected-text">
            Select a chat to start a conversation.
          </p>
        </div>
      ) : (
        <>
          <div className="ecc-indchat-div">
            {" "}
            <div className="ecc-indchat-div-heading-div">
              <div className="ecc-indchat-div-part">
                <div className="ecc-indchat-profile-pic-div">
                  <img
                    src={employeeProfileImage ?? DefaultProfilePic}
                    className="customer-profile-img"
                    alt="customer-profile"
                  />

                  {/*  if user active shows color green, if offline shows grey color*/}
                  <div className="ecc-chat-active-status-icon-div">
                    {onlineStatus(emailIdOfEmployee) ? (
                      <StatusIconOnline color="#22C55E" />
                    ) : (
                      <StatusIconOnline color="#D1D5DB" />
                    )}
                  </div>
                </div>
                <div>
                  <p className="ecc-emp-chat-heading">{employeeName}</p>
                </div>
              </div>

              <div className="ecc-chat-icons-div">
                <div className="ecc-chat-icons">{/* <BsTelephone /> */}</div>
                <div className="ecc-chat-icons">
                  {/* <HiOutlineVideoCamera /> */}
                </div>
                <div className="ecc-chat-icons">{/* <BsThreeDots /> */}</div>
              </div>
            </div>
            {/*     this below div for chat section of customer done to employee */}
            <hr className="ecc-hr-line ecc-hr-line-margin-class"></hr>
            <div className="margin-class-chat-div"></div>

            <div
              className="ecc-indchat-recieved-msg-div"
              ref={indchatReceivedMsgDivRef}
              onScroll={(e) => {
                const div = e.target;

                // Check if the user has scrolled to the top
                if (div.scrollTop === 0) {
                  // Disable auto-scrolling when the user scrolls up
                  autoScrollRef.current = false;
                }

                // Check if the user is near the bottom when they are not at the top
                if (
                  !autoScrollRef.current &&
                  div.scrollHeight - div.scrollTop - div.clientHeight <= 50
                ) {
                  // Enable auto-scrolling when the user is near the bottom
                  autoScrollRef.current = true;
                }
              }}
            >
              {indAllMessagesList.map((message, index) => (
                <div
                  className={`ecc-indchat-${
                    message.senderId === emailIdOfEmployee
                      ? "receiver"
                      : "sender"
                  }-msg-div-part`}
                  key={index}
                >
                  <div className="ecc-indchat-recieved-msg-div-cus-side">
                    <div>
                      <div className="ecc-Indchat-details">
                        <span className="ecc-emp-chat-customer-name">
                          {message.senderId === emailIdOfEmployee ? "" : "You"}
                        </span>
                        <span className="ecc-emp-chat-time">
                          {" "}
                          {formatMessageTime(message.createdAt)}
                        </span>
                      </div>
                      <div
                        className="ecc-indchat-msg-receive-div"
                        style={{
                          backgroundColor:
                            message.senderId === emailIdOfEmployee
                              ? "#FFFFFF"
                              : "#04AFF0",
                          color:
                            message.senderId === emailIdOfEmployee
                              ? "#403D39"
                              : "#FFF",
                          borderRadius:
                            message.senderId === emailIdOfEmployee
                              ? "0px 12px 12px 12px"
                              : "12px 0px 12px 12px",
                        }}
                      >
                        <p
                          style={{
                            backgroundColor:
                              message.senderId === emailIdOfEmployee
                                ? "#FFFFFF"
                                : "#04AFF0",
                            color:
                              message.senderId === emailIdOfEmployee
                                ? "#403D39"
                                : "#FFF",
                          }}
                          className="ecc-emp-chat-msg"
                        >
                          {message.text}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="ecc-type-msg-editor">
              <div className="ecc-type-msg-editor-text-div">
                <textarea
                  placeholder="Message"
                  className="send-msg-box-cus-to-emp"
                  maxLength={500}
                  value={messageToEmployee}
                  onChange={(e) => setMessageToEmployee(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div
                className="ecc-indChat-editor-send-icon-div"
                onClick={sendMessageToEmployee}
              >
                <img
                  src={sendMsgIcon}
                  alt=""
                  className="ecc-indChat-editor-send-icon-image"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerChatWithIndividualEmployee;
// this component is for the showing the individual full of chat of employee and customer customer is "you" done by renuka
