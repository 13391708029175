import React, { useState } from "react";

import "./PhoneNumber.css";

import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";

// import { ValueContainer } from "react-select/dist/declarations/src/components/containers";

const PhoneNumber = ({ register, getPhoneNumber, prevPhoneNumber }) => {
  const [value, setValue] = useState(prevPhoneNumber || "");

  const [validPhoneNumber, setValidPhoneNumber] = useState(
    isValidPhoneNumber(prevPhoneNumber || "")
  );

  const handleChange = (e) => {
    const phoneNumber = e ? e.toString() : "";

    setValue(phoneNumber.trim());

    const isValid = isValidPhoneNumber(phoneNumber.trim());

    setValidPhoneNumber(isValid);

    getPhoneNumber(phoneNumber.trim(), isValid);
  };

  console.log(value);

  return (
    <div>
      <label className="label-registration">
        Phone Number <span className="error-symbol">*</span>
      </label>

      <PhoneInput
        className="input-box-ph"
        placeholder="(+91) 9876543210"
        international
        value={value}
        defaultCountry="TH"
        onChange={handleChange}

        // {...register}
      />

      {value && !validPhoneNumber && (
        <span className="error-msg">Please enter the valid phone number</span>
      )}
    </div>
  );
};

export default PhoneNumber;
