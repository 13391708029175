import React from "react";
import "./DeleteModalEmployee.css";
import { Modal } from "react-bootstrap";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_ACCOUNT_DELETE } from "../../../ApiEndPoints/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DeleteModalEmployee = ({ setShowDeleteEmployee }) => {
  const navigate = useNavigate();

  const handleDeleteModal = () => {
    setShowDeleteEmployee(true);
  };
  const handleCancel = () => {
    setShowDeleteEmployee(false);
    window.scrollTo(0, 0);
  };

  const onClickDelete = () => {
    const Token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_ACCOUNT_DELETE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statuscode === 200) {
          toast.info("Your account has been deleted");
          navigate("/");
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return (
    <>
      <Modal
        show={handleDeleteModal}
        onHide={handleCancel}
        contentClassName="modal-container-dce"
      >
        <Modal.Header className="header-dce">
          <Modal.Title className="title-dce">
            Are you sure you want to delete your account?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="body-dce">
          This will permanently delete your account. You cannot undo this
          action.
        </Modal.Body>
        <Modal.Footer className="footer-dce">
          <div>
            <button className="close-dce" onClick={handleCancel}>
              Cancel
            </button>
          </div>
          <div>
            <button className="delete-dce" onClick={onClickDelete}>
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteModalEmployee;
