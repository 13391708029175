import React, { useState } from "react";
import "./MobileTopBar.css";
import logo from "../../Assets/Svg/logo.svg";
import menubutton from "../../Assets/Images/menu-button.png";
import MobileSideBarCustomer from "../MobileSideBar/MobileSideBarCustomer";
import { useLocation, useNavigate } from "react-router-dom";

const MobileTopBarCustomer = () => {
  const [showMobileSideBarCustomer, setShowMobileSideBarCustomer] = useState(false);

  const handleClicktoggle = () => {
    setShowMobileSideBarCustomer(!showMobileSideBarCustomer);
  };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  const onClickLogo = () => {
    navigate("/");
  };
  return (
    <>
      <div className="mobile-topbar-container" >
        <div>
        <img className="mtp-icon" src={logo} onClick={onClickLogo} alt="" />

                </div>
        <img
          className="mtp-icon"
          src={menubutton}
          onClick={handleClicktoggle}
          alt=""
        />
      </div>
      {showMobileSideBarCustomer && <MobileSideBarCustomer />}
    </>
  );
};
export default MobileTopBarCustomer;
