var BaseUrl = "https://apis.heartyhelper.co/";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "frontend-dev.d1nbkrest4w7b5.amplifyapp.com" ||
  window.location.hostname == "dev.heartyhelper.co"
) {
  BaseUrl = "https://apis-dev.heartyhelper.co/";
}

module.exports = {
  BaseUrl,
};
