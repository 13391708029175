import React from "react";
import "./AboutUsTeamCard.css";
import aboutcard from "../../Assets/Images/aboutcard.png";
import { BiLogoTwitter } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";
import { CiBasketball } from "react-icons/ci";

const AboutUsTeamCard = ({ image, name, position, description, twitter, linkedin, website }) => {
  return (
    <div>
      <div>
        <img src={image} className="aboutus-card-img" alt={`${name}'s profile`} />
        <div className="transper-card-about">
          <p className="card-per-name">{name}</p>
          <p className="card-per-postion">{position}</p>
          <p className="card-per-description">{description}</p>
          <div className="card-per-icon">
            <a href={twitter} target="_blank" rel="noopener noreferrer"><BiLogoTwitter /></a>
            <a href={linkedin} target="_blank" rel="noopener noreferrer"><AiFillLinkedin /></a>
            <a href={website} target="_blank" rel="noopener noreferrer"><CiBasketball /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsTeamCard;
