import React, { useState, useEffect } from "react";
import ProfileTopbarEmployee from "../../../../Components/TopBar/ProfileTopbarEmployee";
import Footer from "../../../../Components/Footer/Footer";
import MobileTopBarEmployee from "../../../../Components/MobileTopBar/MobileTopBarEmployee";
import { Grid } from "@mui/material";
import MobileNavBarEmployee from "../../../../Components/MobileNavBar/MobileNavBarEmployee";
import EmployeeChatList from "../../../../Components/Employee/EmployeeChatComponents/EmployeeChatList/EmployeeChatList";
import EmployeeChatWithIndividualCustomer from "../../../../Components/Employee/EmployeeChatComponents/EmployeeChatWithIndividualCustomer/EmployeeChatWithIndividualCustomer";
import { IoIosArrowBack } from "react-icons/io";

const EmployeeChat = () => {
  const [showChatWithIndividual, setShowChatWithIndividual] = useState(true);
  const [customerProfileImage, setCustomerProfileImage] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [emailIdOfCustomer, setEmailIdOfCustomer] = useState(null);
  const [emailIdOfEmployee, setEmailIdOfEmployee] = useState("");

  // Function to set the selectedSenderId when a chat is clicked
  const handleChatClick = (emailId, customerName, profileImage) => {
    setEmailIdOfCustomer(emailId);
    setCustomerName(customerName);
    setCustomerProfileImage(profileImage);
    setShowChatWithIndividual(true);
  };
  // in mobile view back button showing to redirect to chtalist
  const handleBackToChatList = () => {
    setShowChatWithIndividual(false);
  };
  useEffect(() => {
    if (window.innerWidth < 896) {
      setShowChatWithIndividual(false);
    }
  }, []);
  return (
    <>
      <Grid>
        <div className="d-none d-md-block">
          <ProfileTopbarEmployee />
        </div>
        <div className="d-block d-sm-none">
          <MobileTopBarEmployee />
        </div>
      </Grid>
      {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem" }}
        >
          <Grid container spacing={6} className="">
            <Grid item xs={12} md={6} lg={6}>
              <div className="d-block d-sm-none">
                {window.innerWidth < 896 ? (
                  showChatWithIndividual ? (
                    <div className="ahindfv-p7-back-button-div back-button-mob-view-chat">
                      <button
                        className="ahindfv-p7-back-button"
                        onClick={handleBackToChatList}
                      >
                        <IoIosArrowBack color={"#ffffff"} size={20} /> {"  "}
                        Back
                      </button>
                    </div>
                  ) : (
                    <EmployeeChatList onChatClick={handleChatClick}  emailIdOfCustomer={emailIdOfCustomer}
                    emailIdOfEmployee={emailIdOfEmployee}
                    setEmailIdOfEmployee={setEmailIdOfEmployee}/>
                  )
                ) : (
                  <EmployeeChatList onChatClick={handleChatClick} emailIdOfCustomer={emailIdOfCustomer}
                  emailIdOfEmployee={emailIdOfEmployee}
                  setEmailIdOfEmployee={setEmailIdOfEmployee} />
                )}
              </div>
              <div className="d-none d-lg-block">
                <EmployeeChatList
                  onChatClick={handleChatClick}
                  emailIdOfCustomer={emailIdOfCustomer}
                  emailIdOfEmployee={emailIdOfEmployee}
                  setEmailIdOfEmployee={setEmailIdOfEmployee}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className="d-block d-lg-block">
                {showChatWithIndividual && (
                  <EmployeeChatWithIndividualCustomer
                    emailIdOfCustomer={emailIdOfCustomer}
                    customerName={customerName}
                    customerProfileImage={customerProfileImage}
                    emailIdOfEmployee={emailIdOfEmployee}
                    setEmailIdOfEmployee={setEmailIdOfEmployee}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid>
        <div className="d-none d-md-block">
          <Footer />
        </div>
        <div className="d-block d-sm-none">
          <MobileNavBarEmployee />
        </div>
      </Grid>
    </>
  );
};

export default EmployeeChat;
