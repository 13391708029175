import React, { useEffect, useState } from "react";
import CustomerNotificationLayout from "../../../Layout/EmployeeNotificationLayout/CustomerNotificationLayout";
import NotificationChat from "../../../Components/EmployeeNotificationChat/NotificationChat";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_NOTIFICATION_CHAT } from "../../../ApiEndPoints/ApiEndPoints";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TbSend } from "react-icons/tb";
import { FaRegComment } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { customerDeleteAllNotificationsAction } from "../../../redux/actions/customerDeleteAllNotificationsAction";

const CustomerNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const Token = localStorage.getItem("token");
  const activeUsers = useSelector((state) => state.activeUsers);
  const dispatch = useDispatch();

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = currentDate - createdAtDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
  }

  const fetchCustomerNotifications = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // console.log("Requesting data...");

    fetch(`${BaseUrl}${CUSTOMER_NOTIFICATION_CHAT}`, requestOptions)
      // .then((response) => response.json())

      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          // console.log("API Response:", response);

          return response.json().catch((error) => {
            console.error("Error parsing JSON:", error);
          });
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })

      .then((result) => {
        if (result && result.notificationsList) {
          // console.log("Noti-result", result);
          result.notificationsList.forEach((notification) => {
            // Log the entire notification object to check its properties
            // console.log("Notification:", notification);
            // console.log("messageType", notification.messageType);
          });
          setNotifications(result.notificationsList);
        } else {
          console.error(
            "notificationsList not found in the response or response is undefined"
          );
        }
      })

      .catch((error) => console.error("error", error));
  };
  useEffect(() => {
    fetchCustomerNotifications();
  }, []);
  const handleViewClick = () => {
    navigate("/customer-chat");
  };

  const deleteAllCustomerNotificationsBtnClick = async () => {
    try {
      await dispatch(customerDeleteAllNotificationsAction());
      toast.success("Deleted all notifications successfully");

      // Fetch notifications only after the deletion is successful
      fetchCustomerNotifications();
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };
  return (
    <div className="emp-noti-desktop-main">
      <CustomerNotificationLayout>
        <div className="emp-noti-container">
          {/* Conditionally render delete icon div */}
          {notifications?.length > 0 && (
            <div className="delete-all-notifi-div">
              <div
                className="delete-all-noti-icon-div"
                onClick={deleteAllCustomerNotificationsBtnClick}
              >
                <RiDeleteBinLine color={"#6B7280"} size={20} />
              </div>

              <div className="notifi-hr-line-div">
                <hr className="notifi-hr-line"></hr>
              </div>
            </div>
          )}

          {notifications?.length === 0 ? (
            <p className="no-noti-cont">No Notifications Found</p>
          ) : (
            notifications?.map((notification) => {
              // console.log(
              //   "notification.messageType:",
              //   notification.messageType
              // );
              // console.log(
              //   "notification.senderEmailId",
              //   notification.senderEmailId
              // );

              notification.messageType = notification.messageType.trim();

              // console.log("Trimmed messageType:", notification.messageType);
              return (
                <NotificationChat
                  key={notification.notificationId}
                  senderName={notification.senderName ?? "Unknown Sender"}
                  senderImageUrl={
                    notification.senderImageUrl ?? DefaultProfilePic
                  }
                  message={notification.message ?? "No message available"}
                  // onlineStatus={onlineStatus(notification.data.senderId)}
                  email={notification.senderEmailId}
                  sentTime={timeAgo(notification.createdAt)} // Replace with the actual sent time property from your data
                  messageContent={
                    notification.data?.text ??
                    notification.message ??
                    "No text available"
                  }
                  handleViewClick={handleViewClick}
                  activeUsers={activeUsers}
                  messageTypeText={
                    notification.messageType === "message" ? (
                      <div>
                        <TbSend /> Send you a message
                      </div>
                    ) : notification.messageType === "comment" ? (
                      <div>
                        <FaRegComment /> Commented on your post
                      </div>
                    ) : (
                      <div>Other notifications</div>
                    )
                  }
                />
              );
            })
          )}
        </div>
      </CustomerNotificationLayout>
    </div>
  );
};
export default CustomerNotification;
