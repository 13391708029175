import React, { useEffect } from "react";
import "./Country.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllNewCountryList } from "../../redux/reducers/NewCountryListReducer";
import { RxTriangleDown } from "react-icons/rx";

const Country = ({ register, label, labelStyle }) => {
  const { NewCountryList } = useSelector((state) => state.countryList);
  const dispatch = useDispatch();
  console.log(label, "label");

  useEffect(() => {
    dispatch(getAllNewCountryList());
  }, []);

  // console.log("Countrylist", NewCountryList);
  return (
    <div >
      {label !== "false" && (
        <label style={labelStyle} className="cp-form-label-proedit">Country <span className="error-symbol">*</span></label>
      )}
      <select
        type="text"
        className="select-box-country"
        {...register}
        // placeholder="Select"
      >
        <option value="">Select the country </option>
        {NewCountryList &&
          NewCountryList.map((e) => {
            return <option value={e}>{e}</option>;
          })}
      </select>
      <RxTriangleDown size={60} className="cu-req-select-drop-down-down-arrow country-down-arrow" />

    </div>
  );
};
export default Country;
