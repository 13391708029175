import React , {useEffect} from "react";
import "./ProfileSideBarNotificationBox.css";
import { useDispatch, useSelector } from "react-redux";
import {getUnreadMessagesCommentsCountAction} from "../../../redux/actions/getUnreadMessagesCommentsCountAction";

const ProfileSideBarNotificationBox = () => {
  const unreadMessages = useSelector((state) => state.unreadMessages.unreadMessages);
  const unreadComments = useSelector((state) => state.unreadMessages.unreadComments);
  
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  console.log("Redux State:", state);
  
  
  useEffect(() => {
    dispatch(getUnreadMessagesCommentsCountAction());
  }, []);
  
  console.log("unreadMessages:", unreadMessages);
  console.log("unreadComments:", unreadComments);
  return (
    <div className="psb-ntfn-box">
      <div className="psb-ntfn-box-text-div">
          <p className="psb-ntfn-box-text">Unread message</p>

          <button className="psb-ntfn-box-number">{unreadMessages}</button>

      </div>
      <div className="psb-ntfn-box-text-div">
          <p className="psb-ntfn-box-text">Unseen comments</p>
          <button className="psb-ntfn-box-number">{unreadComments}</button>
      </div>
    </div>
  );
};

export default ProfileSideBarNotificationBox;
