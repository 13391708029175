import React, { useState, useEffect } from "react";
import "./StaticSideBarMobile.css";

import { useLocation, useNavigate } from "react-router-dom";

import crossicon from "../../Assets/Svg/crossicon.svg";


const StaticMobileSideBar= () => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleClicktoggle = () => {
      setShowPopUp(!showPopUp);
    };

    const handleJobClick = () => {
      const latestJobsSection = document.getElementById("latest-jobs");
  
      if (latestJobsSection) {
        latestJobsSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    const handleServicesClick = () => {
      const servicesSection = document.getElementById("services");
  
      if (servicesSection) {
        servicesSection.scrollIntoView({ behavior: "smooth" });
      }
    };

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };



  return (
    <>
      {!showPopUp && (
        <div className="mobile-Sidebar-popup">
          <div className="Static-Employee-Mobile-card">
            <div className="cross-icon-div">
              <img
                src={crossicon}
                alt=""
                className="cross-icon-img"
                onClick={handleClicktoggle}
              />
            </div>
            <div></div>
            
            <div
              className="static-mob-button pt-4"
              style={{ padding: "18px" }}
            >
              <div
                onClick={() => handleClickNavigate("/home")}
                className={`${
                  pathname === "/home"
                    ? " mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
                
                <p className="px-2-static">Home</p>
              </div>
              <div
                onClick={() => handleClickNavigate("/about-us")}
                className={`${
                  pathname === "/about-us"
                    ? " mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
               
                <p className="px-2-static">About Us</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/contact-us")}
                className={`${
                  pathname === "/contact-us"
                    ? "mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
              
                <p className="px-2-static">Contact Us</p>
              </div>

              <div
                onClick={() => handleJobClick("/#latest-jobs")}
                className={`${
                  pathname === "/#latest-jobs"
                    ? " mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
                
                <p className="px-2-static">Job</p>
              </div>

              <div
                onClick={() => handleServicesClick("/#services")}
                className={`${
                  pathname === "/#services"
                    ? " mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
         
                <p className="px-2-static">Services</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/login")}
                className={`${
                  pathname === "/login"
                    ? " mt-3"
                    : "psb-btn-static mt-3"
                }`}
              >
               
                <p className="px-2-static">Login</p>
              </div>
             
              <p onClick={() => handleClickNavigate("/termsofservice")} className="psb-terms-static ">Terms & Privacy</p>
              <p className="psb-terms-static mt-3">Help Center</p>
            </div>
          </div>
        </div>
      )}
     
    </>
  );
};
export default StaticMobileSideBar;
