import axios from 'axios';
import {BaseUrl} from  '../ApiBaseUrl/Api';
import {EMPLOYEE_GET_ALL_CHATS} from '../ApiEndPoints/ApiEndPoints';
import axiosInstance from "./axiosInstance";

export const getAllEmployeeChatList = async()=>{
    try{
        const response = await axiosInstance.get(`${BaseUrl}${EMPLOYEE_GET_ALL_CHATS}`);
        console.log("response emp service api", response);
        return response;
    }
    catch(error){
        throw error.response.error
    }
};

export default getAllEmployeeChatList;