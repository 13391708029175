import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EmployeeExperienceEdit.css";
import { useForm, Controller } from "react-hook-form";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import { toast } from "react-toastify";
import Country from "../../../Components/CountryInput/Country";
import MultiInputValues from "../../../Components/MultiSelectDropdown/MultiInputValues";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import {
  EMPLOYEE_EXPERIENCE_ADD,
  EMPLOYEE_EXPERIENCE_UPDATE,
} from "../../../ApiEndPoints/ApiEndPoints";
import { useNavigate } from "react-router-dom";
// import { EMPLOYEE_EXPERIENCE_ADD, EMPLOYEE_EXPERIENCE_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";

const EmployeeExperienceEdit = ({ setShowEmpExperience, prevdata, experienceId }) => {
const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const Token = localStorage.getItem("token");
  const duties = [
    { value: "Nanny", label: "Nanny" },
    { value: "Carer", label: "Carer" },
    { value: "Maid", label: "Maid" },
  ];

  const onClickCancel = () => {
    setShowEmpExperience(false);
    window.scrollTo(0, 0);
  };

  const onSubmit = (data) => {
    console.log(data);
    const startDate = new Date(data.Start_Date);
    const endDate = new Date(data.End_Date);
    const today = new Date();

    if (startDate > today || endDate > today) {
      toast.error("Start Date and End Date must be in the past.");
      return;
    }

    if (startDate >= endDate) {
      toast.error("Start Date must be before End Date.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      position: data.Position,
      startDate: data.Start_Date,
      endDate: data.End_Date,
      employedCountry: data.country,
      location: data.location,
      duties: data.Duties,
      expId: experienceId,

    });
    console.log(raw);
    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    
    var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

    fetch(`${BaseUrl}${EMPLOYEE_EXPERIENCE_UPDATE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "experience profile data");
        if (result.statuscode === 200) {
          toast.info("Experience is successfully updated");

          window.location.reload();
          
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };
 
  useEffect(() => {
    if (prevdata && prevdata.startDate && prevdata.endDate) {
      // Convert date strings to Date objects
      // const startDateObj = new Date(prevdata.startDate);
      // const endDateObj = new Date(prevdata.endDate);
      const startDateObj = new Date(prevdata.startDate);
      const endDateObj = new Date(prevdata.endDate);
      const startDateFormatted = startDateObj.toISOString().split("T")[0];
      const endDateFormatted = endDateObj.toISOString().split("T")[0];
      setValue("Position", prevdata.position);
     
      setValue("Start_Date", startDateFormatted);
      setValue("End_Date", endDateFormatted);
      setValue("country", prevdata.employedCountry);
      setValue("location", prevdata.location);
      setValue("duties", prevdata.duties);
      
    }
  }, [prevdata, setValue]);
  return (
    <div className="container emp-profile-main">
      <div className="emp-profile-card4">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="emp-title">Edit Your work experience below.</h4>
          </div>
        </div>
        <hr className="emp-hr-line"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="emp-form-label-input">
              <label className="emp-form-label"> Position</label>
              <div className="profileeditinput">
                <select
                  className="emp-form-input"
                  {...register("Position", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  <option value="Nanny">Nanny</option>
                  <option value="Elder Care">Elder Care</option>
                  <option value="Premium Nanny">Premium Nanny</option>
                  <option value="Cook">Cook</option>
                  <option value="Pet Care">Pet Care</option>
                  <option value="Housekeeping">Housekeeping</option>
                  <option value="Babysitter">Babysitter</option>
                  <option value="Gardener">Gardener</option>
                  <option value="Driver">Driver</option>
                  <option value="Tutor">Tutor</option>
                  <option value="Personal Assistant">Personal Assistant</option>
                  <option value="Cleaning Services">Cleaning Services</option>
                  <option value="Companion">Companion</option>
                  <option value="Laundry Services">Laundry Services</option>
                  <option value="Home Organizer">Home Organizer</option>
                  <option value="Home Health Aide">Home Health Aide</option>
                  <option value="Childcare Provider">Childcare Provider</option>
                  <option value="Home Chef">Home Chef</option>
                </select>
                {errors.Position && (
                  <p className="error-msg">Please select the Position</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Start Date</label>
              <div className="profileeditinput">
              <input
                  className="emp-form-input"
                  type="Date"
                  {...register("Start_Date", {
                    required: true,
                   
                  })}
                />
                {errors.Start_Date && (
                  <p className="error-msg">Please Enter The month</p>
                )}
               </div>
              
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">End Date</label>
              <div className="profileeditinput">
              <input
                  className="emp-form-input"
                  type="Date"
                  {...register("End_Date", {
                    required: true,
                   
                  })}
                />
                {errors.End_Date && (
                  <p className="error-msg">Please Enter The month</p>
                )}
               
              </div>

              
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
             
                <label className="emp-form-label">Country</label>
             
              <div className="profileeditinput">
                <Country
                  label="false"
                  className="cp-form-input"
                  register={register("country", {
                    required: true,
                  })}
                />

                {errors.country && (
                  <p className="error-msg">Please select the country</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Duties</label>
              <div className="profileeditinput div">
                {/* <MultiSelectDropdown
                  options={[
                    { value: "label1", label: "Label 1" },
                    { value: "label2", label: "Label 2" },
                    { value: "label3", label: "Label 3" },
                  ]}
                  onChange={(selectedOptions) => {
                    // Handle the selected options
                  }}
                /> */}
                <MultiSelectDropdown
                  control={control}
                  name="duties"
                  options={duties}
                  rules={{ required: "Please select at least one option" }}
                />
              </div>
            </div>

            <div className="emp-cancel-btns-div pt-3">
              <button
                className="emp-edit-btn-cp-cancel-btn"
                type="button"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button className="emp-edit-btn cp-save-btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeExperienceEdit;
