import React from 'react';
import "./CustomerRequests.css";
import CustomerCreatePostProfileLayout from '../../../Layout/CustomerCreatePostProfileLayout/CustomerCreatePostProfileLayout';
import CustomerRequestsForm from '../../../Components/CustomerRequestHelper/CustomerRequestsForm/CustomerRequestsForm';



const CustomerRequests = () => {
  return (
        
<CustomerCreatePostProfileLayout>
  <CustomerRequestsForm/> 
  </CustomerCreatePostProfileLayout>    
  )
}

export default CustomerRequests;