import React, { useEffect, useState } from "react";
import "./OtpVerification.css";
import { OtpVerificationPage } from "../../Content/OtpVerificationPage.content";
import BackButton from "../BackButton/BackButton";
import OtpVerificationInputFields from "../OtpVerificationInputFields/OtpVerificationInputFields";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Resetpassword from "../Resetpassword/Resetpassword";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  CUSTOMER_OTP_VERIFY,
  CUSTOMER_REGISTER,
  EMPLOYEE_OTP_VERIFY,
  EMPLOYEE_REGISTER,
  SEND_OTP_CUSTOMER,
  SEND_OTP_EMPLOYEE,
  RESET_PASSWORD_OTP_CUSTOMER,
  RESET_PASSWORD_OTP_EMPLOYEE,
} from "../../ApiEndPoints/ApiEndPoints";

const OtpVerification = ({
  formSubmitted,
  setFormSubmitted,
  setShowOtp,
  setShowOtpVerification,
  otpVerification,
  showCustomer,
  showEmployee,
  data,
  forgetPasswordData,
}) => {
  const navigate = useNavigate();
  const [otpInput, setOtpInput] = useState("");
  const [delay, setDelay] = useState(180);
  const [showResend, setShowResend] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [registerApi, callRegisterApi] = useState(false);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("otpVerification", otpVerification);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  // console.log("data in otp component", data, forgetPasswordData);
  const onClickBack = () => {
    if (otpVerification) {
      setShowOtpVerification(false);
    } else {
      setFormSubmitted(false);
      setShowOtp(false);
    }
  };

  const onClickVerify = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}${
        showCustomer === "customer"
        ? CUSTOMER_OTP_VERIFY : EMPLOYEE_OTP_VERIFY
      }${
        !otpVerification === true ? data.data.email : forgetPasswordData.email
      }/${otpInput}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.statuscode === 200) {
          toast.success("Otp Verified Successfully");
          if (!otpVerification) {
            callRegisterApi(true);
          } else {
            setShowResetPassword(true);
          }
        } else if (result.statuscode == 401) {
          toast.error("Invalid Otp Please Enter Correct Otp");
          setShowResend(true);
          setDelay(180);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const onClickResend = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}${
        showCustomer === "customer"

          ? otpVerification === true
            ? RESET_PASSWORD_OTP_CUSTOMER
            : SEND_OTP_CUSTOMER
          : otpVerification === true
          ? RESET_PASSWORD_OTP_EMPLOYEE
          : SEND_OTP_EMPLOYEE
      }${
        !otpVerification === true ? data.data.email : forgetPasswordData.email
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.statuscode === 200) {
          toast.info(
            "An OTP has been sent again to the registered email. Please check it."
          );
          setDelay(180);
        } else if (result.statuscode == 400) {
          toast.error(
            "This email is already registered please try with another mail"
          );
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    // console.log("customer verify", showCustomer);

    if (registerApi) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: data.data.first_name + "," + data.data.last_name,
        emailId: data.data.email,
        phoneNumber: data.phoneNumber,
        password: data.data.password,
        country: data.data.country,
        state: data.data.state,
        city: data.data.city,
        zip: data.data.zip_code,
        confirmpassword: data.data.confirm_password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}${showCustomer === true ? CUSTOMER_REGISTER : EMPLOYEE_REGISTER}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
           navigate("/login");
          // window.location.reload();
          // toast.success("Thank you for registering with us. Your information has been successfully received. Our representative will get in touch with you shortly to provide further assistance.");
        })
        .catch((error) => console.log("error", error));
    }
  }, [registerApi]);
  // console.log("showCustomer",showCustomer);

  return (
    <>
      {showResetPassword ? (
        <Resetpassword
          setShowResetPassword={setShowResetPassword}
          showCustomer={showCustomer}
          forgetPasswordData={forgetPasswordData}
        />
      ) : (
          <div className="otp-verification-container">
            <div className="otp-verification-field-component">
              <div className="backbutton-otp-component">
                <BackButton onClick={onClickBack} />
              </div>
              <div className="field-content-component">
                <div className="field-heading">
                  {OtpVerificationPage.OTPVERIFICATIONHEADER}
                </div>
                <div className="field-description">
                  <p>
                    We've sent a 6-digit confirmation code to{" "}
                    <span
                      className="email-otp"
                      style={{ color: "var(--color-brand-color)" }}
                    >
                      {data ? data.data.email : forgetPasswordData.email}
                    </span>{" "}
                    . Make sure you enter correct code.
                  </p>
                </div>
                <div className="otp-time">
                  <span>
                    {minutes}:{seconds}
                  </span>
                </div>
                <OtpVerificationInputFields setOtpInput={setOtpInput} />
                <div className="button-otpv">
                  <Button
                    buttonName={"Verify"}
                    disabled={delay === 0 || otpInput.length < 6}
                    onClick={onClickVerify}
                  />
                </div>
                {(showResend || delay === 0) && (
                  <div className="otp-footer">
                    <p className="o-footer-content">
                      {OtpVerificationPage.OTPVERIFICATIONFOOTER}{" "}
                      <span>
                        <button
                          className="resend-button"
                          onClick={onClickResend}
                        >
                          Resend Code
                        </button>
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
      )}
    </>
  );
};
export default OtpVerification;
