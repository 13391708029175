import React, { useEffect, useState } from "react";
import "./State.css";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { STATE_LIST } from "../../ApiEndPoints/ApiEndPoints";

const StateFilter = ({
  selectedCountry,
  showLabel = true,
  customWidth,
  selectedState,
  onChange,
}) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      // If no selected country, clearing the state data
      setStates([]);
    }
  }, [selectedCountry]);

  const fetchStates = (selectedCountry) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}${STATE_LIST}${selectedCountry}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setStates(result.states);
      })
      .catch((error) => console.log("error", error));
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    onChange(selectedState);
  };
  return (
    <>
      {showLabel && (
        <label className="label-registration">
          State <span className="error-symbol">*</span>
        </label>
      )}
      <div>
        <select
          type="text"
          className="select-box-state"
          style={{ width: customWidth }}
          value={selectedState}
          onChange={handleStateChange}
          placeholder="Select"  
        >
          <option value="">Select the State</option>
          {states.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default StateFilter;
