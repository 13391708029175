import React, { useEffect, useState } from "react";
import "./City.css";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { CITY_LIST } from "../../ApiEndPoints/ApiEndPoints";

const City = ({
  selectedCountry,
  selectedState,
  showLabel = true,
  customWidth,
  onChange,
  selectedCity,
}) => {
  const [data, setData] = useState([]);


  useEffect(() => {
    // Check if both selectedCountry and selectedState have values
    if (selectedCountry && selectedState) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}${CITY_LIST}${selectedCountry}/${selectedState}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          setData(result.cities);
        })
        .catch((error) => console.log("error", error));
    } else {
      // Clear the data if either selectedCountry or selectedState is empty
      setData([]);
    }
  }, [selectedState, selectedCountry]);
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    onChange(selectedCity);
  };
  return (
    <>
      {showLabel && (
        <label className="label-registration">
          City <span className="error-symbol">*</span>
        </label>
      )}
      <div>
        <select
          type="text"
          className="select-box-city"
          style={{ width: customWidth }}
          value={selectedCity}
          onChange={handleCityChange}
          placeholder="Select"
        >
          <option value="">Select the city</option>
          {data &&
            data.map((e) => {
              return <option value={e}>{e}</option>;
            })}
        </select>
      </div>
    </>
  );
};
export default City;
