import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneNumber from "../../PhoneNumber/PhoneNumber";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_MESSANGER_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmployeemessangerEdit = ({ setShowEmployeeMessangerEdit, prevdata }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, showPhoneNumberError] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm();

  const Token = localStorage.getItem("token");
  const getPhoneNumber = (value, isValid) => {
    setPhoneNumber(value);
    setValidPhoneNumber(isValid);
  };

  const onClickCancel = () => {
    setShowEmployeeMessangerEdit(false);
    window.scrollTo(0, 0);
  };

  const onSubmit = (data) => {
    console.log(data, "this is data");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      whatsappAccount: data.Whatsapp,
      wechatAccount: data.wechat,
      facebookAccount: data.fbaccount,
      lineAccount: data.line,
      telegramAccount: data.telegram,
      phoneNumber: phoneNumber,
    });
    console.log(raw);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_MESSANGER_UPDATE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "employee account data");
        if (result.statuscode === 200) {
          toast.info("data is updated successfully");
          window.location.reload();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };
  console.log("prevdata", prevdata);
  useEffect(() => {
    if (prevdata) {
      Object.entries(prevdata).forEach(([name, value]) => {
        setValue(name, value ); 
        setValue("Whatsapp", prevdata.whatsappAccount || "");
        setValue("wechat", prevdata.wechatAccount || "");
        setValue("fbaccount", prevdata.facebookAccount || "");
        setValue("line", prevdata.lineAccount || "");
        setValue("telegram", prevdata.telegramAccount || "");
        setValue("phoneNumber", prevdata.phoneNumber || "");
      });
    }
  }, [prevdata,setValue]);

  return (
    <div className="container emp-profile-main">
      <div className="emp-messanger-card1">
        <h4 className="mesan-top"></h4>
        <hr className="emp-hr-line"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="emp-form-label-input">
              <label className="emp-form-label">WhatsApp account</label>
              <div className="profileeditinput">
                <input
                  className="emp-form-input "
                  type="text"
                  {...register("Whatsapp", {
                    required: true,
                  

                    // pattern: /^(https:\/\/)?wa\.me\/\+?[0-9]+$/,
                  })}
                  
                />
                {errors.Whatsapp && (
                  <p className="error-msg">Please Enter The Value</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">WeChat account</label>
              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="text"
                  {...register("wechat", {
                    required: true,
                   
                    pattern: /^[a-zA-Z0-9_]{6,20}$/,
                  })}
                  // defaultValue="my_wechat_account123"
                />

                {errors.wechat && (
                  <p className="error-msg">Please Enter the value</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Facebook account</label>
              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="text"
                  {...register("fbaccount", {
                    required: true,
                   
                    pattern: /^[a-zA-Z0-9_]{6,20}$/,
                  })}
                  
                />

                {errors.fbaccount && (
                  <p className="error-msg">Please Enter the value</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Line account</label>
              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="text"
                  {...register("line", {
                    required: true,
                    pattern: /^[a-zA-Z0-9_-]+$/,
                  })}
                  // placeholder="Enter your Line account"
                  // defaultValue="https://line.me/ti/p/~your_line_account"
                />

                {errors.line && (
                  <p className="error-msg">Please Enter the value</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Telegram account</label>
              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="text"
                  {...register("telegram", {
                    required: true,
                
                    pattern: /[a-zA-Z0-9_]+$/,
                  })}
                  // defaultValue="https://t.me/your_telegram_account"
                />

                {errors.telegram && (
                  <p className="error-msg">Please Enter the value</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>
            <div className="emp-form-label-input">
              <label className="emp-form-label">Contact number</label>
              <div className="profileeditinput-proedit">
              
                <PhoneNumber  getPhoneNumber={getPhoneNumber} prevPhoneNumber={prevdata.phoneNumber}  />
                {phoneNumberError && !validPhoneNumber && !phoneNumber && (
                  <span className="error-msg">
                    Please enter your phone number
                  </span>
                )}
                {errors.phoneNumber && (
                  <p className="error-msg">{errors.phoneNumber.message}</p>
                )}
              </div>
            </div>
            <hr className="emp-hr-line"></hr>

            <div className="emp-cancel-btns-div pt-3">
              <button
                className="emp-edit-btn-cp-cancel-btn"
                type="button"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button className="emp-edit-btn cp-save-btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeemessangerEdit;