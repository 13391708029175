const LoginContent = {
    LOGINHEADER:"Login to your account",
    LOGINDESCRIPTION:"Find the perfect helper at HeartyHelper.",
    LOGINIMAGEHEADER:"NEED HELP AROUND THE HOUSE? HEARTYHELPER HAS YOU COVERED.",
    LOGINIMAGEDESCRIPTION:"Login now!",
    LOGINFOOTER:"Don’t have an account?",
    PASSWORDCONTENTRIGHT:"Forgot Password?",
    PASSWORDCONTENTLEFT:"Remember Me",
    EMPLOYEECONTENT:"READY TO GET BACK TO WORK OR FIND YOUR NEXT JOB?",
    LOGINIMAGEHEADERMOBILE:"DISCOVER YOUR IDEAL WORKSPACE AND FIND THE PERFECT ASSISTANT TO MEET YOUR NEEDS!",
    FORGOTPASSWORDHEADER:"FIND THE PERFECT HELPER FOR YOUR  NEEDS!"
   
   
};
module.exports = {
    LoginContent,
  };