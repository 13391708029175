import React from "react";
import "./Button.css";

const ButtonRegistration =({buttonName,disabled,onClick}) => {
    return (
        <>
        <div className="button-registration">
            <button className="button-r" disabled={disabled} onClick ={onClick}>{buttonName}</button>
        </div>
        </>
    )
}
export default ButtonRegistration;