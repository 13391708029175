import React from "react";
import "./NotificationChat.css";
import { TbSend } from "react-icons/tb";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import StatusIconOnline from "../../Components/Employee/EmployeeChatComponents/StatusIcons/StatusIconOnline/StatusIconOnline";
import DefaultProfilePic from "../../Assets/Images/Default_pfp.svg.png";
import { useDispatch, useSelector } from "react-redux";

const NotificationChat = ({
  senderName,
  senderImageUrl,
  sentTime,
  email,
  activeUsers,
  messageContent,
  handleViewClick,
  messageTypeText,
}) => {
  // const activeUsers = useSelector((state) => state.activeUsers);
  // const onlineStatus = (email) =>
  //   activeUsers.some((user) => user.userId === email);
  const isOnline = activeUsers?.some((user) => user.userId === email);

  return (
    <>
      <div className="main-notific-chat-container">
        <div className="noti-profile-pic-div">
          <img
            src={senderImageUrl ?? DefaultProfilePic}
            className="noti-customer-profile-img"
            alt="customer-profile-img"
          />
          <div className="noti-active-status-icon-div">
            {isOnline ? (
              <StatusIconOnline color="#22C55E" />
            ) : (
              <StatusIconOnline color="#D1D5DB" />
            )}
          </div>
        </div>
        <div className="info-noti-cont">
          <div className="main-info-header-container">
            <div className="left-info">
              <p className="emp-noti-name">{senderName}</p>
              <p className="emp-noti-time">{sentTime}</p>
            </div>
            <div className="right-info">
              <div className="emp-noti-reply-container">
                <p className="emp-noti-reply" onClick={handleViewClick}>
                  view <MdOutlineKeyboardArrowRight />
                </p>
              </div>
            </div>
          </div>
          <div className="info-middle-container">
            <p className="emp-noti-sent-msg">{messageTypeText}</p>
          </div>
          <div className="info-last-container">
            <p className="emp-noti-para">{messageContent}</p>
          </div>
          <div className="emp-noti-reply-container-mobile">
            <p className="emp-noti-reply-mobile">
              view <MdOutlineKeyboardArrowRight />
            </p>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default NotificationChat;
