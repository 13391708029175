import React, { useState, useEffect } from "react";
import "./CustomerSignOut.css";
import { Modal } from "react-bootstrap";
import ThankYouModal from "../../ThankyouModal/ThankyouModal";
import socket from "../../../sockets/socket";
import { io } from "socket.io-client";

// const socket = io("https://apis-dev.heartyhelper.co/chat");

const CustomerSignOut = ({ setShowCustomerSignout }) => {
  const [showThankYou, setShowThankYou] = useState(false);

  // Disconnect the socket when the component unmounts
  useEffect(() => {
    return () => {
      socket.disconnect(() => {
        console.log("Socket disconnected");
      });
    };
  }, []);

  const handleCustomerSignout = () => {
    setShowCustomerSignout(true);
  };
  const onClickCancel = () => {
    setShowCustomerSignout(false);
    window.scrollTo(0, 0);
  };

  const onClickSignoutCustomer = () => {
    setShowThankYou(true);
  };

  return (
    <>
      <Modal
        show={handleCustomerSignout}
        onHide={onClickCancel}
        contentClassName="modal-container-cso"
      >
        <Modal.Header className="header-cso">
          <Modal.Title className="title-cso">
            Are you sure you want to log out?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="body-cso">
          You can check out any time you like but you can never leave.
        </Modal.Body>
        <Modal.Footer className="footer-cso">
          <div className="close-cso-div">
            <button className="close-cso" onClick={onClickCancel}>
              Cancel
            </button>
          </div>
          <div className="signout-cso-div">
            <button className="signout-cso" onClick={onClickSignoutCustomer}>
              Sign out
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {showThankYou && (
        <ThankYouModal
          setShowThankYou={setShowThankYou}
          setShowCustomerSignout={setShowCustomerSignout}
        />
      )}
    </>
  );
};
export default CustomerSignOut;
