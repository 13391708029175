import React from "react";
import "./ProfileSideBarSpecialRequestBox.css";
import { useNavigate } from "react-router-dom";

const ProfileSideBarSpecialRequestBox = () => {
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  return (
    <div className="psb-request-box">
      <p className="psb-request-box-text">Special Request</p>
      <button
        className="psb-request-box-button"
        onClick={() => handleClickNavigate("/customer-requests")}
      >
        Request Helper
      </button>
    </div>
  );
};

export default ProfileSideBarSpecialRequestBox;
