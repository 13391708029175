import React, { useState, useEffect } from "react";
import ProfileTopbarCustomer from "../../../Components/TopBar/ProfileTopbarCustomer";
import Footer from "../../../Components/Footer/Footer";
import MobileTopBarCustomer from "../../../Components/MobileTopBar/MobileTopBarCustomer";
import { Grid } from "@mui/material";
import CustomerChatList from "../../../Components/Customer/CustomerChatComponents/CustomerChatList/CustomerChatList";
import CustomerChatWithIndividualEmployee from "../../../Components/Customer/CustomerChatComponents/CustomerChatWithIndividualEmployee/CustomerChatWithIndividualEmployee";
import MobileNavBarCustomer from "../../../Components/MobileNavBar/MobileNavBarCustomer";
import { IoIosArrowBack } from "react-icons/io";
import "./CustomerChat.css";

const CustomerChat = () => {
  const [emailIdOfEmployee, setEmailIdOfEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeProfileImage, setEmployeeProfileImage] = useState("");
  const [showChatWithIndividual, setShowChatWithIndividual] = useState(true);
  const [emailIdOfCustomer, setEmailIdOfCustomer] = useState("");
console.log("emailIdOfCustomer", emailIdOfCustomer);
  // Function to set the selectedSenderId when a chat is clicked
  const handleChatClick = (emailId, employeeName, profileImage) => {
    setEmailIdOfEmployee(emailId);
    setEmployeeName(employeeName);
    setEmployeeProfileImage(profileImage);
    setShowChatWithIndividual(true);
  };
  // in mobile view back button showing to redirect to chtalist
  const handleBackToChatList = () => {
    setShowChatWithIndividual(false);
  };
  useEffect(() => {
    if (window.innerWidth < 896) {
      setShowChatWithIndividual(false);
    }
  }, []);
  return (
    <>
      <Grid>
        <div className="d-none d-md-block">
          <ProfileTopbarCustomer />
        </div>
        <div className="d-block d-sm-none">
          <MobileTopBarCustomer />
        </div>
      </Grid>
      {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem" }}
        >
          <Grid container spacing={6} className="">
            <Grid item xs={12} md={6} lg={6}>
              <div className="d-block d-sm-none">
                {window.innerWidth < 896 ? (
                  showChatWithIndividual ? (
                    <div className="ahindfv-p7-back-button-div back-button-mob-view-chat">
                      <button
                        className="ahindfv-p7-back-button"
                        onClick={handleBackToChatList}
                      >
                        <IoIosArrowBack color={"#ffffff"} size={20} /> {"  "}
                        Back
                      </button>
                    </div>
                  ) : (
                    <CustomerChatList
                      onChatClick={handleChatClick}
                      emailIdOfEmployee={emailIdOfEmployee}
                      emailIdOfCustomer={emailIdOfCustomer}
                      setEmailIdOfCustomer={setEmailIdOfCustomer}
                    />
                  )
                ) : (
                  <CustomerChatList
                    onChatClick={handleChatClick}
                    emailIdOfEmployee={emailIdOfEmployee}
                    emailIdOfCustomer={emailIdOfCustomer}
                    setEmailIdOfCustomer={setEmailIdOfCustomer}
                  />
                )}
              </div>
              <div className="d-none d-md-block">
                <CustomerChatList
                  onChatClick={handleChatClick}
                  emailIdOfEmployee={emailIdOfEmployee}
                  emailIdOfCustomer={emailIdOfCustomer}
                  setEmailIdOfCustomer={setEmailIdOfCustomer}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className="d-block d-lg-block">
                {showChatWithIndividual && (
                  <CustomerChatWithIndividualEmployee
                    emailIdOfEmployee={emailIdOfEmployee}
                    employeeName={employeeName}
                    employeeProfileImage={employeeProfileImage}
                    emailIdOfCustomer={emailIdOfCustomer}
                    setEmailIdOfCustomer={setEmailIdOfCustomer}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid>
        <div className="d-none d-md-block">
          <Footer />
        </div>
        <div className="d-block d-sm-none">
          <MobileNavBarCustomer />
        </div>
      </Grid>
    </>
  );
};

export default CustomerChat;
