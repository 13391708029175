import React, { useState, useEffect } from "react";

import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import { useTranslation } from "react-i18next";

const HelperAgrement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation(); //for languge conversion

  useEffect(() => {
    window.scrollTo(0, 0);
     }, []);

  // Simulate a loading delay with setTimeout
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <StaticHomePageLayout>
            <header>
              <div className="terms-container">
                <div className="terms-head-main">
                  <h1 className="m-heading">{t("translation.HelperAgreement")}</h1>
                  <p className="m-date">{t("translation.Effectivedate")} : April 3, 2023</p>
                </div>
              </div>
            </header>

            <section className="terms-m-content">
              <div className="headings-m">
                <div className="rich-text-m">
                <div className="h2-m">
                    <h2 className="heading-2-m">Helper Agreement </h2>
                    <p className="h2-light-m">
                    This agreement would detail terms for helpers (service providers) registered on Hearty Helper's platform.    
                    </p>
                  </div>
                  <div className="p-h2-m">
                    <p className="h2-bold-m">
                    Services Provided:   
                    </p>
                  </div>
                  <div className="p-light-m">
                    <p className="h2-light-m">
                    Description of services helpers are authorized to provide.   
                    </p>
                    <p className="h2-light-m">
                    Scope of work and client interaction guidelines.  
                    </p>
                  </div>
                  <div className="p-h2-m">
                    <p className="h2-bold-m">
                    Compensation and Payments:  
                    </p>
                  </div>
                  <div className="p-light-m">
                    <p className="h2-light-m">
                    Payment terms, rates, and invoicing procedures.   
                    </p>
                    <p className="h2-light-m">
                    Deductions or fees applicable to helpers.  
                    </p>
                  </div>
                  <div className="p-h2-m">
                    <p className="h2-bold-m">
                    Performance Standards:   
                    </p>
                  </div>
                  <div className="p-light-m">
                    <p className="h2-light-m">
                    Expectations for service quality and professionalism.   
                    </p>
                    <p className="h2-light-m">
                    Compliance with Hearty Helper's policies and guidelines.   
                    </p>
                  </div>
                  <div className="p-h2-m">
                    <p className="h2-bold-m">
                    Termination and Consequences:      
                    </p>
                  </div>
                  <div className="p-light-m">
                    <p className="h2-light-m">
                    Grounds for termination of the agreement.  
                    </p>
                    <p className="h2-light-m">
                    Impact of termination on access to the platform. 
                    </p>
                  </div>
                  <div className="p-h2-m">
                    <p className="h2-bold-m">
                    Intellectual Property Rights:    
                    </p>
                  </div>
                  <div className="p-light-m">
                    <p className="h2-light-m">
                    Ownership of content created by helpers on the platform. 
                    </p>
                    <p className="h2-light-m">
                    Permissions and restrictions on content usage.  
                    </p>
                  </div>
                  

                  {/* <div className="h3-m">
                    <h3 className="heading-3-m">Heading 3</h3>
                  </div>
                  <div className="p-h3-m">
                    <p className="h3-bold">
                      Eget quis mi enim, leo lacinia pharetra, semper. Eget in
                      volutpat mollis at volutpat lectus velit, sed auctor.
                      Porttitor fames arcu quis fusce augue enim. Quis at
                      habitant diam at. Suscipit tristique risus, at donec. In
                      turpis vel et quam imperdiet. Ipsum molestie aliquet
                      sodales id est ac volutpat.
                    </p>
                  </div>
                  <div className="p-h3-light-m">
                    <p className="h3-light-m">
                      Tristique odio senectus nam posuere ornare leo metus,
                      ultricies. Blandit duis ultricies vulputate morbi feugiat
                      cras placerat elit. Aliquam tellus lorem sed ac. Montes,
                      sed mattis pellentesque suscipit accumsan. Cursus viverra
                      aenean magna risus elementum faucibus molestie
                      pellentesque. Arcu ultricies sed mauris vestibulum.
                    </p>
                  </div>

                  <div className="h4-m">
                    <h4 className="heading-4-m">Heading 4</h4>
                  </div>
                  <div className="p-h4-m">
                    <p className="h4-bold-m">
                      Morbi sed imperdiet in ipsum, adipiscing elit dui lectus.
                      Tellus id scelerisque est ultricies ultricies. Duis est
                      sit sed leo nisl, blandit elit sagittis. Quisque tristique
                      consequat quam sed. Nisl at scelerisque amet nulla purus
                      habitasse.
                    </p>
                  </div>

                  <div className="h5-m">
                    <h5 className="heading-5-m">Heading 5</h5>
                  </div>
                  <div className="p-h5-m">
                    <p className="h5-bold-m">
                      Morbi sed imperdiet in ipsum, adipiscing elit dui lectus.
                      Tellus id scelerisque est ultricies ultricies. Duis est
                      sit sed leo nisl, blandit elit sagittis. Quisque tristique
                      consequat quam sed. Nisl at scelerisque amet nulla purus
                      habitasse.
                    </p>
                  </div>
                  <div className="p-h5-light-m">
                    <div className="divider-m">
                      <p className="divide-m">|</p>
                      <p className="h5-light-m">
                        "Ipsum sit mattis nulla quam nulla. Gravida id gravida
                        ac enim mauris id. Non pellentesque congue eget
                        consectetur turpis. Sapien, dictum molestie sem tempor.
                        Diam elit, orci, tincidunt aenean tempus."
                      </p>
                    </div>
                  </div>

                  <div className="h6-m">
                    <h6 className="heading-6-m">Heading 6</h6>
                  </div>
                  <div className="p-h6-m">
                    <p className="h6-bold-m">
                      Nunc sed faucibus bibendum feugiat sed interdum. Ipsum
                      egestas condimentum mi massa. In tincidunt pharetra
                      consectetur sed duis facilisis metus. Etiam egestas in nec
                      sed et. Quis lobortis at sit dictum eget nibh tortor
                      commodo cursus.
                    </p>
                  </div> */}
                </div>
              </div>
            </section>
          </StaticHomePageLayout>
        </>
      )}
    </>
  );
};

export default HelperAgrement;
