import getAllEmployeeChatList from "../../servicesApi/employeeChatListApi";
import {
  GET_ALL_EMPLOYEE_CHATS_REQUEST,
  GET_ALL_EMPLOYEE_CHATS_SUCCESS,
  GET_ALL_EMPLOYEE_CHATS_FAILURE
} from "../constants/employeeChatListConstantsTypes";

export const getAllEmployeeChatListAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_EMPLOYEE_CHATS_REQUEST });

      const response = await getAllEmployeeChatList();
      console.log("API Response Data api action emp:", response);

      if (response && response.status === 200) {
        dispatch({
          type: GET_ALL_EMPLOYEE_CHATS_SUCCESS,
          payload: response.data.conversations,
        });
        
      } else {
        dispatch({
          type: GET_ALL_EMPLOYEE_CHATS_FAILURE,
          payload: false,
        });
        
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_EMPLOYEE_CHATS_FAILURE,
        payload: false,
      });
      
    }
  };
};
