import React, { useState } from "react";
import "./Resetpassword.css";
import Password from "../Password/Password";
import ConfirmPassword from "../ConfirmPassword/ConfirmPassword";
import BackButton from "../BackButton/BackButton";
import LoginImage from "../../Assets/Images/LoginImage.png";
import { LoginContent } from "../../Content/LoginPage.content";
import { RegistrationContent } from "../../Content/RegistrationPage.content";
import { useForm } from "react-hook-form";
import OtpVerification from "../OtpVerification/OtpVerification";
import {useNavigate } from "react-router-dom";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  RESET_PASSW0RD_CUSTOMER,
  RESET_PASSW0RD_EMPLOYEE,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";

const Resetpassword = ({
  showCustomer,
  setShowResetPassword,
  forgetPasswordData,
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch("password");
  // console.log("showCustomer", showCustomer);
  const onSubmit = (data) => {
    // console.log(data, forgetPasswordData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      emailId: forgetPasswordData.email,
      password: data.password,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${BaseUrl}${
        showCustomer === "customer" ? RESET_PASSW0RD_CUSTOMER : RESET_PASSW0RD_EMPLOYEE
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.statuscode === 200) {
          toast.success("Password has been Updated ");
          navigate("/login");
        } else if (result.statuscode === 401) {
          toast.error(
            "You are not associated with any account. Please register"
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const onClickBackButton = () => {
    setShowResetPassword(false);
  };

  return (
    <>
      <div className="ResetPassword-container">
        <form className="form-resetpassword" onSubmit={handleSubmit(onSubmit)}>
          <div className="backbutton-reset">
            <BackButton onClick={onClickBackButton} />
          </div>
          <h4 className="heading-resetpassword">Create New Password</h4>
          <p className="paragraph-resetpassword">
            Enter the new password for your account.
          </p>
          <div className="newpassword">
            <Password
              label={"New Password"}
              register={register("password", {
                required: true,
                minLength: 6,
                maxLength:10,
                pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,10}$/,
              })}
            />

            {errors.password && errors.password.type === "required" && (
              <span className="error-msg">Please enter a password</span>
            )}
            {errors.password && errors.password.type === "minLength" && (
              <span className="error-msg">
                Password must be at least 8 characters long
              </span>
            )}
            {errors.password && errors.password.type === "maxLength" && (
              <span className="error-msg">
                Password should be 10 characters only
              </span>
            )}
            {errors.password && errors.password.type === "pattern" && (
              <span className="error-msg">
                Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and
                1 special character
              </span>
            )}
          </div>
          <div className="confirmpassword">
            <ConfirmPassword
              register={register("confirm_password", {
                required: true,
                validate: (value) => value === password,
              })}
            />
            {errors.confirm_password &&
              errors.confirm_password.type === "required" && (
                <span className="error-msg">
                  Please enter the password again.
                </span>
              )}
            {errors.confirm_password &&
              errors.confirm_password.type === "validate" && (
                <span className="error-msg">Passwords do not match.</span>
              )}
          </div>

          <div className="button-ResetPassword">
            <button className="button-r">Reset Password</button>
          </div>
        </form>
      </div>
    </>
  );
};
export default Resetpassword;
