import React from "react";
import "./Email.css";

const Email =({register}) => {
    return (
        <div>
          <label className="label-registration">Email <span className="error-symbol">*</span></label>
          <input className="input-box-email" type="email" placeholder="example@gmail.com" {...register}/>
        </div>
      );
    }
export default Email;