import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";

const PrivacyPolicy = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
     }, []);

  // Simulate a loading delay with setTimeout
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <StaticHomePageLayout>
            <header className="header-privacy-p">
              <div className="content-privacy-p">
                <div className="heading-privacy-p">Privacy Policy</div>
                <div className="date-privacy-p">Effective date: April 3, 2023</div>
              </div>
            </header>

            <section className="container-privacy-p">
              <div className="contain-privacy-p">
                <div className="left-privacy-p">
                  <div className="table-privacy-p">Table of contents</div>
                  <div className="table-heading-privacy-p">
                    <p className="table-h2-privacy-p">1. Information We Collect</p>
                    <p className="table-h2-privacy-p">2. Use of Information </p>
                    <p className="table-h2-privacy-p">3. Sharing of Information </p>
                    <p className="table-h2-privacy-p">4. Data Security </p>
                    <p className="table-h2-privacy-p">5. Your Rights </p>
                    <p className="table-h2-privacy-p">6. Children's Privacy </p>
                    <p className="table-h2-privacy-p">7. Changes to This Policy </p>
                    <p className="table-h2-privacy-p">8. Contact Us </p>
                  </div>
                </div>

                <div className="right-privacy-p">
                  <div className="rich-text-privacy-p">
                    <div className="h2-privacy-p">
                      <h2 className="heading-2-privacy-p">1. Information We Collect</h2>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      1.1 Personal Information 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      When you register on our platform, we may collect personal information such as your name, email address, phone number, and address. 
                      </p>
                      <p className="h2-light-privacy-p">
                      Information provided voluntarily by you when using our services, such as job postings, service requests, and communication with helpers or clients.
                      </p>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      1.2 Usage Data 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      We may collect information automatically when you visit our website or use our services, including your IP address, browser type, pages viewed, and duration of visit.
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      1.3 Cookies 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      We use cookies and similar technologies to enhance user experience, track usage patterns, and collect information about how you interact with our platform. You can manage cookie preferences through your browser settings.
                      </p>
                      
                    </div>

                    <div className="h3-privacy-p">
                      <h3 className="heading-3-privacy-p">2. Use of Information </h3>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.1 Service Delivery 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      To provide and maintain our services, including matching clients with helpers, processing payments, and facilitating communication.
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.2 Communication
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      To communicate with you about your account, service updates, promotions, and other relevant information.
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.3 Improvements 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      To analyze usage trends, conduct research, and improve our services, website functionality, and user experience.
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      2.4 Compliance 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      To comply with legal obligations and enforce our policies. 
                      </p>
                      
                    </div>
                    

                    <div className="h4-privacy-p">
                      <h4 className="heading-4-privacy-p">3. Sharing of Information </h4>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      3.1 Service Providers
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      We may share your information with third-party service providers who assist us in operating our platform and delivering services to you.
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      3.2 Legal Compliance 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      We may disclose your information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. 
                      </p>
                      
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                      3.3 Business Transfers 
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                      In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.
                      </p>
                      
                    </div>

                    <div className="h5-privacy-p">
                      <h5 className="heading-5-privacy-p">4. Data Security </h5>
                    </div>
                    <div className="p-h5-privacy-p">
                      <p className="h5-bold-privacy-p">
                      We implement appropriate technical and organizational measures to protect your information from unauthorized access, disclosure, alteration, or destruction. 
                      </p>
                    </div>
                    

                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">5. Your Rights </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      You have the right to access, correct, or delete your personal information. You may also request restrictions on processing or object to processing under certain circumstances. 
                      </p>
                    </div>
                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">6. Children's Privacy </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18 without parental consent. 
                      </p>
                    </div>
                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">7. Changes to This Policy </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website. 
                      </p>
                    </div>
                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">8. Contact Us </h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                      If you have any questions about this Privacy Policy or our privacy practices, please contact us at contact@heartyhelper.co.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </StaticHomePageLayout>
        </>
      )}
    </>
  );
};

export default PrivacyPolicy;
