import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { COUNTRY_LIST } from "../../ApiEndPoints/ApiEndPoints";

export const getAllNewCountryList = createAsyncThunk("NewCountryList", async() => {
  try {
    var requestOptions = {
        method: "GET",
        redirect: "follow",
    };
    const response = await fetch(`${BaseUrl}${COUNTRY_LIST}`, requestOptions);
    const result = await response.json();
    // console.log("result", result);
    return result.countries;
} catch (error) {
    console.log("error", error);
    throw error;
}
});

export const myReducer = createSlice({
  name: "NewCountryList",
  initialState: {
    loading: false,
    NewCountryList: [],
  },
  reducers: {
    updateSelectNewCountryList: (state, action) => {
      state.selectedCountryId = action.payload;
    },
  },
  extraReducers: {
    [getAllNewCountryList.pending]: (state) => {
      state.loading = true;
    },
    [getAllNewCountryList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.NewCountryList = payload;
      // console.log(state.NewCountryList, "payload");
    },
  },
});

export const { updateSelectNewCountryList } = myReducer.actions;

export default myReducer.reducer;
