import React from "react";
import { AiFillStar } from "react-icons/ai";

const TestimonalCard = ({ image, review, name, position, company }) => {
  return (
    <div className="box">
      <div className="star">
        <AiFillStar className="testimo-star" />
        <AiFillStar className="testimo-star" />
        <AiFillStar className="testimo-star" />
        <AiFillStar className="testimo-star" />
        <AiFillStar className="testimo-star" />
      </div>
      <div className="box-content">
        <p className="hh-home-cu-testi-review">{review}</p>
        <div className="box-c-1">
          <img src={image} alt="person" className="hh-home-cu-testi-img" />
          <div className="box-c-2">
            <h6 className="hh-home-cu-testi-name">{name}</h6>
            <p className="hh-home-cu-testi-details">{position}, {company}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonalCard;