import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import ProfileTopbarEmployee from "../../Components/TopBar/ProfileTopbarEmployee";
import EmployeeProfileSideBar from "../../Components/EmployeeProfileSideBar/EmployeeProfileSideBar";
import MobileTopBarEmployee from "../../Components/MobileTopBar/MobileTopBarEmployee";
import MobileNavBarEmployee from "../../Components/MobileNavBar/MobileNavBarEmployee";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import TopBarStaticPage from "../../Components/TopBarStaticPage/TopBarStaticPage";
import StaticMobileTopBar from "../../Components/MobileTopBar/StaticMobileTopBar";
import StaticPageFooter from "../../Components/StaticPageFooter/StaticPageFooter";
import i18n from "../../i18n";
import "./Visiblemaker.css"

const StaticHomePageLayout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };
  //for loading effect used this useEffect
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  //this loader props for the loader styles

  return (
    <>
      <>
        <Grid>
          <div className="d-none d-md-block ">
            <TopBarStaticPage
              selectedLanguage={selectedLanguage}
              changeLanguage={changeLanguage}
            />
          </div>
          <div className="d-block d-sm-none visiblemaker">
            <StaticMobileTopBar />
          </div>
        </Grid>

        <Grid className="">
          {isLoading ? (
            <div className="hhloader-div-home-page">
              <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
              <Typewriter
                options={{
                  strings: ["Loading..."],
                  autoStart: true,
                  loop: true,
                  delay: 30,
                }}
                className="loading-text-in-loader"
              />
            </div>
          ) : (
            <>{children}</>
          )}
        </Grid>

        <Grid>
          <div className="d-none d-md-block">
            <StaticPageFooter
              selectedLanguage={selectedLanguage}
              changeLanguage={changeLanguage}
            />
          </div>
          <div className="d-block d-sm-none footervis">
            <StaticPageFooter
              selectedLanguage={selectedLanguage}
              changeLanguage={changeLanguage}
            />
          </div>
        </Grid>
      </>
    </>
  );
};

export default StaticHomePageLayout;
