import React, { useState, useEffect } from "react";
import "./EmployeeEducation.css";
import EmployeeProfileLayout from "../../../Layout/EmployeeProfileLayout/EmployeeProfileLayout";
import EmployeeEducationEdit from "../../../Components/Employee/EmployeeEducationEdit/EmployeeEducationEdit";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_EDUCATION } from "../../../ApiEndPoints/ApiEndPoints";
// import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmpEducation = () => {
  const [showEmpEducationEdit, setShowEmpEducationEdit] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const Token = localStorage.getItem("token");

  const handleChangeEdit = () => {
    setShowEmpEducationEdit(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_EDUCATION}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result.educationData, "result");
        setData(result.educationData);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <EmployeeProfileLayout showCameraIcon={false}>
        {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        ) : (
          <div className="emp-educ-container">
            {
              <div className="container-emp-messanger-main">
                <div className="emp-title-div pb-4">
                  <h3 className="emp-title">Education</h3>
                  {!showEmpEducationEdit && (
                    <button className="emp-edit-btn" onClick={handleChangeEdit}>
                      Edit
                    </button>
                  )}
                </div>

                {!showEmpEducationEdit && (
                    <div className="emp-messanger-card1">
                      <h4>Your highest level of education</h4>
                      <hr className="emp-hr-line"></hr>
                      <div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">School </p>
                          <p className="emp-text">{data.school ?? "N/A"}</p>
                        </div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Education Level </p>
                          <p className="emp-text">{data.educationalLevel ?? "N/A"}</p>
                        </div>
                        <div className="emp-label-div-desktop">
                          <p className="emp-label">Calss of </p>
                          <p className="emp-text">{data.classOf ?? "N/A" }</p>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            }
            {showEmpEducationEdit && (
              <EmployeeEducationEdit
                prevdata={data}
                setShowEmpEducationEdit={setShowEmpEducationEdit}
              />
            )}
          </div>
        )}
      </EmployeeProfileLayout>
    </>
  );
};

export default EmpEducation;
