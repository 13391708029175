import React, { useState, useEffect, useRef } from "react";
import "./AvailableHelperIndividualFullView.css";
import AvailableHelperIndividualFullViewLayout from "../../../../Layout/AvailableHelpersLayouts/AvailableHelperIndividualFullViewLayout/AvailableHelperIndividualFullViewLayout";
import DefaultProfilePic from "../../../../Assets/Images/avialble-helper-dp.png";
import whatsapp from "../../../../Assets/Images/whatsapp.png";
import Lineapp from "../../../../Assets/Images/line-social-app.png";
import telegram from "../../../../Assets/Images/logos_telegram.png";
import telegramCall from "../../../../Assets/Images/logos_telegram1.png";
import wechatapp from "../../../../Assets/Images/wechat.png";
import facebook from "../../../../Assets/Images/logos_facebook.png";
import linkedin from "../../../../Assets/Images/logos_linkedin-icon.png";
import { useNavigate } from "react-router-dom";
import {
  AVAILABLE_HELPER_EMPLOYEE_FULL_PROFILE,
  CUSTOMER_START_CONVERSATION_WITH_EMPLOYEE,
} from "../../../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../../../ApiBaseUrl/Api";
import HHLogo from "../../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { IoIosArrowBack } from "react-icons/io";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";


const AvailableHelperIndividualFullView = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState([]);
  const [emailIdOfEmployee, setEmailIdOfEmployee] = useState("");
  // const { empId } = useParams();
  const Token = localStorage.getItem("token");

  let params = useParams();
  const componentRef = useRef();

  const goBackToAvailableHelpersList = () => {
    navigate("/available-helpers");
  };
//this functionis to get data of employee full view 
  const fetchHelperDataFullView = async () => {
    try {
      const Token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${Token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      // Construct the API request URL using the correct employee id  number
      const apiUrlInd = `${BaseUrl}${AVAILABLE_HELPER_EMPLOYEE_FULL_PROFILE}${params.empId}`;

      const response = await fetch(apiUrlInd, requestOptions);
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        return;
      } else if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        console.log("employeeData", data.employeeData);
        setEmployeeData(data.employeeData || []);
        setEmailIdOfEmployee(data.employeeData?.emailId || "");
        setIsLoading(false);
        window.scrollTo(0, 0);
        console.log("EmployeeData", employeeData);
        console.log("emailofEmployeeData", emailIdOfEmployee);
        console.log("empId", params.empId);
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
      } else if (response.status === 422) {
        // Handle validation issues
        console.error("Validation error: Data validation failed");
      } else {
        console.error("Unknown error: Status code", response.status);
      }
    } catch (error) {
      console.error("Error fetching data from API", Error);
    }
  };
  useEffect(() => {
    fetchHelperDataFullView(params.empId);
  }, []);
  // setEmailIdOfEmployee(employeeData.emailId);
  console.log("emailofEmployeeData", emailIdOfEmployee);

  const salaryString = employeeData.salary;
  const [minSalary, maxSalary] = salaryString
    ? salaryString.split("-").map((salary) => salary.trim().replace("$", ""))
    : [null, null];
   
  // this startConversationWithEmployee function for the customer will start message with employee for the biodata page of employee
  const startConversationWithEmployee = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var raw = JSON.stringify({
      message: "Started Conversation",
    });
    console.log(raw);
    const requestOptions = {
      headers: myHeaders,
      body: raw,
      method: "POST",
      redirect: "follow",
    };
    fetch(
      `${BaseUrl}${CUSTOMER_START_CONVERSATION_WITH_EMPLOYEE}${emailIdOfEmployee}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");

          return;
        } else if (response.status === 200) {
          return response.json();
        }else if (response.status === 201) {
          console.log("Resource created successfully");
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })
      .then((result) => {
        console.log("result",result);
        if (result && (result.statuscode === 200 || result.statuscode === 201)) {
          navigate("/customer-chat");
          console.log(result, "customer started the cnversation with employee");
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error status code:", error.response.status);
        } else {
          console.error("Error in start conversation with employee:", error.message);
        }
      });
  };
  return (
    <>
      <AvailableHelperIndividualFullViewLayout>
        {isLoading ? (
          <>
            <div className="hhloader-div">
              <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
              <Typewriter
                options={{
                  strings: ["Loading..."],
                  autoStart: true,
                  loop: true,
                  delay: 30,
                }}
                className="loading-text-in-loader"
              />
            </div>
          </>
        ) : (
          <>
            <div className="ahindfv-p7-back-button-div-top">
              <div className="ahindfv-p7-back-button-div">
                <button
                  className="ahindfv-p7-back-button"
                  onClick={goBackToAvailableHelpersList}
                >
                  <IoIosArrowBack color={"#ffffff"} size={20} /> {"  "}
                  Back
                </button>
              </div>
            </div>
            <div className="ahindfv-div" ref={componentRef}>
              <div className="ahindfv-side-bar-div">
                <div className="ahjplid-part-a ahjplid-part-a-sidebar">
                  {/* ahjplid   available helpers job post list individuak div part a iamge div */}
                  <div className="ajplid-pa-img-ind-div">
                    <img
                      alt=""
                      className=" ajplid-pa-img-ind"
                      src={employeeData.profileImageUrl ?? DefaultProfilePic}
                    />
                  </div>
                  <div className="ahjplid-pa-name-div">
                    {/* ahid  unique id give to avaialble helper */}
                    <h5 className="ahjplid-pa-name-div-ahid">
                      {" "}
                      H#{params.empId}
                    </h5>
                    <p className="ahjplid-pa-name-div-location">
                      {employeeData.city ?? "city not found"},{" "}
                      {employeeData.state ?? "state not found"},{" "}
                      {employeeData.country ?? "country not found"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="ahindfv-main-div">
                {/* avaialble helper details full view divs */}
                <div className="ahindfv-part1-div">
                  <div>
                    <h5 className="ahindfv-p1-name">
                      {employeeData.name ?? "N/A"}
                    </h5>
                  </div>
                  <div className="ahindfv-p1-details-div">
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Age</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {employeeData.age ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Position</p>
                      </div>

                      <div className="ahjplid-pb-s1-position-values-div">
                        {Array.isArray(employeeData.role) ? (
                          employeeData.role.map((role) => (
                            <div className="ahjplid-pb-s1-position-value-div">
                              <p className="ahjplid-pb-s1-position-value">
                                {role}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="ahjplid-pb-s1-position-value-div">
                            <p className="ahjplid-pb-s1-position-value">
                              No roles found
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Work Experience
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {" "}
                          {employeeData.workExperience ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Salary</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {minSalary && maxSalary
                            ? `$ ${parseInt(
                                minSalary
                              ).toLocaleString()} - $ ${parseInt(
                                maxSalary
                              ).toLocaleString()}`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ahindfv-part2-div">
                  <div className="ahindfv-p2-details">
                    <div className="ahindfv-p1-detail-label">
                      <p className="ahindfv-p2-detail-label-text">
                        Work schedule
                      </p>
                    </div>

                    <div className="ahjplid-pb-s1-position-values-div">
                      {Array.isArray(employeeData.workSchedule) ? (
                        employeeData.workSchedule.map((schedule, index) => (
                          <div
                            key={index}
                            className="ahjplid-pb-s1-position-value-div"
                          >
                            <p className="ahjplid-pb-s1-position-value">
                              {schedule}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div className="ahjplid-pb-s1-position-value-div">
                          <p className="ahjplid-pb-s1-position-value">
                            No schedules found
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="ahindfv-part3-div">
                  <div className="ahindfv-p3-details">
                    <div className="ahindfv-p1-detail-label">
                      <p className="ahindfv-p2-detail-label-text">Province</p>
                    </div>

                    <div className="ahjplid-pb-s1-position-values-div">
                      {Array.isArray(employeeData.province) ? (
                        employeeData.province.map((province, index) => (
                          <div
                            key={index}
                            className="ahjplid-pb-s1-position-value-div"
                          >
                            <p className="ahjplid-pb-s1-position-value">
                              {province}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div className="ahjplid-pb-s1-position-value-div">
                          <p className="ahjplid-pb-s1-position-value">
                            No provinces found
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="ahindfv-part4-div">
                  <div className="ahindfv-p2-details">
                    <div className="ahindfv-p1-detail-label">
                      <p className="ahindfv-p2-detail-label-text">Bio</p>
                    </div>
                    <div className="ahindfv-p4-detail-value-div">
                      <p className="ahindfv-p4-detail-value">
                        {employeeData.bio !== null &&
                        employeeData.bio !== undefined ? parse(employeeData.bio)
                          : "Not available"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ahindfv-part5-div">
                  <div>
                    <h5 className="ahindfv-p5-label">Other Details</h5>
                  </div>
                  <div className="ahindfv-p5-details-div">
                    {/* <div className="ahindfv-p5-details">
                      <div className="ahindfv-p5-detail-label">
                        <p className="ahindfv-p5-detail-label-text">
                          Appearance:
                        </p>
                      </div>
                      <div className="ahindfv-p5-detail-value-div">
                        <p className="ahindfv-p5-detail-value">
                          188 cm, 91 kg, athletic build, gradient hair and brown
                          eyes
                        </p>
                      </div>
                    </div> */}
                    <div className="ahindfv-p5-details">
                      <div className="ahindfv-p5-detail-label">
                        <p className="ahindfv-p5-detail-label-text">
                          Martial status:
                        </p>
                      </div>
                      <div className="ahindfv-p5-detail-value-div">
                        <p className="ahindfv-p5-detail-value">
                          {employeeData.maritalStatus ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p5-details">
                      <div className="ahindfv-p5-detail-label">
                        <p className="ahindfv-p5-detail-label-text">
                          Religion:
                        </p>
                      </div>
                      <div className="ahindfv-p5-detail-value-div">
                        <p className="ahindfv-p5-detail-value">
                          {employeeData.religion ?? "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="ahindfv-p5-details">
                      <div className="ahindfv-p5-detail-label">
                        <p className="ahindfv-p5-detail-label-text">
                          Knowledge of foreign languages:
                        </p>
                      </div>
                      <div className="ahindfv-p5-detail-value-div">
                        <p className="ahindfv-p5-detail-value">
                          {employeeData.language
                            ? employeeData.language.join(", ")
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ahindfv-part6-div">
                  <div>
                    <h5 className="ahindfv-p5-label">Contact</h5>
                  </div>
                  <div className="ahindfv-p6-values-div">
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.facebookAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={facebook}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.linkedinAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={linkedin}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.whatsappAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={whatsapp}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.wechatAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={wechatapp}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.lineAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={Lineapp}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={employeeData.telegramAccount}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={telegram}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                    <div className="ahindfv-p6-value-div">
                      <a
                        href={`tel:${employeeData.telegramAccount}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={telegramCall}
                          alt="social-icon"
                          className="ahindfv-p6-value-img"
                        />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ahindfv-part7-div">
              <div></div>
              <div className="ahindfv-p7-print-msg-buttons-div">
                {/* <button className="ahindfv-p7-print-button">Print</button> */}
                <ReactToPrint
                  trigger={() => (
                    <div className="ahindfv-p7-print-button-div">
                      <button className="ahindfv-p7-print-button">Print</button>
                    </div>
                  )}
                  content={() => componentRef.current}
                />

                <div className="ahindfv-p7-msg-button-div">
                  <button
                    className="ahindfv-p7-msg-button"
                    onClick={startConversationWithEmployee}
                  >
                    Message
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </AvailableHelperIndividualFullViewLayout>{" "}
    </>
  );
};

export default AvailableHelperIndividualFullView;
