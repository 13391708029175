import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import NewCountryListReducer from "./reducers/NewCountryListReducer";
import ActiveUsersReducer from "./reducers/activeUsersSlice";
import customerChatListReducer from "./reducers/CustomerChatListReducer";
import employeeChatListReducer from "./reducers/EmployeeChatListReducer";
import getUnreadMessagesCommentsCountReducer from "./reducers/getUnreadMessagesCommentsCountReducer";

const reducers = combineReducers({
  countryList:NewCountryListReducer,
  activeUsers: ActiveUsersReducer,
  customerChatListData: customerChatListReducer,
  employeeChatListData: employeeChatListReducer,
  unreadMessages: getUnreadMessagesCommentsCountReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});
