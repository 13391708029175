import React, { useEffect, useState } from "react";
import "./EmployeeNotification.css";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";
import ProfileTopbarEmployee from "../../../Components/TopBar/ProfileTopbarEmployee";
import EmployeeNotificationLayout from "../../../Layout/EmployeeNotificationLayout/EmployeeNotificationLayout";
import NotificationChat from "../../../Components/EmployeeNotificationChat/NotificationChat";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_NOTIFICATION_CHAT } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TbSend } from "react-icons/tb";
import { FaRegComment } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { employeeDeleteAllNotificationsAction } from "../../../redux/actions/employeeDeleteAllNotificationsAction";

const EmployeeNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const Token = localStorage.getItem("token");
  const activeUsers = useSelector((state) => state.activeUsers);
  const dispatch = useDispatch();

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = currentDate - createdAtDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
  }

  const fetchEmployeeNotifications = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_NOTIFICATION_CHAT}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })
      .then((result) => {
        console.log("Noti-emp-result", result);
        setNotifications(result.notificationsList);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    fetchEmployeeNotifications();
  }, []);
  const handleViewClick = () => {
    navigate("/employee-chat");
  };
  const deleteAllEmployeeNotificationsBtnClick = async () => {
    try {
      await dispatch(employeeDeleteAllNotificationsAction());
      toast.success("Deleted all notifications successfully");

      // Fetch notifications only after the deletion is successful
      fetchEmployeeNotifications();
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  return (
    <div className="emp-noti-desktop-main">
      <EmployeeNotificationLayout>
        <div className="emp-noti-container">
          {/* Conditionally render delete icon div */}
          {notifications?.length > 0 && (
            <div className="delete-all-notifi-div">
              <div
                className="delete-all-noti-icon-div"
                onClick={deleteAllEmployeeNotificationsBtnClick}
              >
                <RiDeleteBinLine color={"#6B7280"} size={20} />
              </div>

              <div className="notifi-hr-line-div">
                <hr className="notifi-hr-line"></hr>
              </div>
            </div>
          )}

          {notifications?.length === 0 ? (
            <p className="no-noti-cont">No Notifications Found</p>
          ) : (
            notifications?.map((notification) => (
              <NotificationChat
                key={notification.notificationId}
                senderName={notification.senderName ?? "Unknown Sender"}
                senderImageUrl={
                  notification.senderImageUrl ?? DefaultProfilePic
                }
                message={notification.message ?? "No message available"}
                // onlineStatus={onlineStatus(notification.data.senderId)}
                email={notification.senderEmailId}
                sentTime={timeAgo(notification.createdAt)} // Replace with the actual sent time property from your data
                messageContent={notification.data?.text ?? "No text available"}
                handleViewClick={handleViewClick}
                activeUsers={activeUsers}
                messageTypeText={
                  notification.messageType === "message" ? (
                    <div>
                      <TbSend /> Send you a message
                    </div>
                  ) : notification.messageType === "comment" ? (
                    <div>
                      <FaRegComment /> Commented on your post
                    </div>
                  ) : null
                }
              />
            ))
          )}
        </div>
      </EmployeeNotificationLayout>
    </div>
  );
};
export default EmployeeNotification;
