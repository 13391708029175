import React, { useState, useEffect } from "react";
import "./AddMoreExperience.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import Country from "../../../Components/CountryInput/Country";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_EXPERIENCE_ADD } from "../../../ApiEndPoints/ApiEndPoints";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";

const AddMoreExperienceEmp = ({ setShowAddMoreExep, prevdata }) => {
  const {
    register,

    handleSubmit,

    formState: { errors },

    control,

    setValue,
  } = useForm();

  const Token = localStorage.getItem("token");
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const onClickCancel = () => {
    setShowAddMoreExep(false);

    window.scrollTo(0, 0);
  };
  const duties = [
    { value: "Nanny", label: "Nanny" },
    { value: "Carer", label: "Carer" },
    { value: "Maid", label: "Maid" },
  ];
  const onSubmit = (data) => {
    console.log(data);
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (startDate < endDate && startDate <= today && endDate <= today) {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${Token}`);

      let duties = [];
      duties.push(data.duties);
      data.duties = duties;
      let experienceInfo = [];
      experienceInfo.push(data);
      var raw = JSON.stringify({
        experienceInfo,
      });

      console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}${EMPLOYEE_EXPERIENCE_ADD}`, requestOptions)
        .then((response) => response.json())

        .then((result) => {
          console.log(result, "experience profile data");

          if (result.statuscode === 201) {
            toast.info("Experience is successfully Added");

            window.location.reload();
            
          } else {
            toast.error("Something went wrong");
          }
        })

        .catch((error) => console.log("error", error));
    } else {
      toast.error("Please enter valid dates.");
    }
  };

  console.log(prevdata, "prev data");

  //   console.log("data changed from store");

  // }, [prevdata]);
  useEffect(() => {
    if (prevdata && prevdata.startDate && prevdata.endDate) {
      const startDateObj = new Date(prevdata.startDate);
      const endDateObj = new Date(prevdata.endDate);
      const startDateFormatted = startDateObj.toISOString().split("T")[0];
      const endDateFormatted = endDateObj.toISOString().split("T")[0];
      setValue("Position", prevdata.position);

      setValue("Start_Date", startDateFormatted);
      setValue("End_Date", endDateFormatted);
      setValue("employedCountry", prevdata.employedCountry);
      setValue("location", prevdata.location);
      setValue("duties", prevdata.duties);
    }
  }, [prevdata, setValue]);
  return (
    <div className="container emp-profile-main">
      <div className="emp-profile-card4">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="emp-experience-title">
              Add Your work experience below.
            </h4>
          </div>
        </div>

        <hr className="emp-hr-line"></hr>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="emp-form-div">
            <div className="emp-form-label-input">
              <label className="emp-form-label"> Position</label>

              <div className="profileeditinput">
                <select
                  className="emp-form-input"
                  {...register("position", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>

                  <option value="Nanny">Nanny</option>

                  <option value="Elder Care">Elder Care</option>

                  <option value="Premium Nanny">Premium Nanny</option>

                  <option value="Cook">Cook</option>

                  <option value="Pet Care">Pet Care</option>

                  <option value="Housekeeping">Housekeeping</option>

                  <option value="Babysitter">Babysitter</option>

                  <option value="Gardener">Gardener</option>

                  <option value="Driver">Driver</option>

                  <option value="Tutor">Tutor</option>

                  <option value="Personal Assistant">Personal Assistant</option>

                  <option value="Cleaning Services">Cleaning Services</option>

                  <option value="Companion">Companion</option>

                  <option value="Laundry Services">Laundry Services</option>

                  <option value="Home Organizer">Home Organizer</option>

                  <option value="Home Health Aide">Home Health Aide</option>

                  <option value="Childcare Provider">Childcare Provider</option>

                  <option value="Home Chef">Home Chef</option>
                </select>

                {errors.position && (
                  <p className="error-msg">Please select the Position</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Start Date</label>

              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="Date"
                  {...register("startDate", {
                    required: true,
                  })}
                  max={today.toISOString().split("T")[0]}
                />

                {errors.startDate && (
                  <p className="error-msg">Please enter a valid start date.</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">End Date</label>

              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="Date"
                  {...register("endDate", {
                    required: true,
                  })}
                  max={today.toISOString().split("T")[0]}
                />

                {errors.endDate && (
                  <p className="error-msg">Please Enter The date</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Country</label>

              <div className="profileeditinput">
                <Country
                  label="false"
                  className="cp-form-input"
                  register={register("employedCountry", {
                    required: true,
                  })}
                />

                {errors.employedCountry && (
                  <p className="error-msg">Please select the country</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Location</label>

              <div className="profileeditinput">
                <input
                  className="emp-form-input"
                  type="text"
                  {...register("location", {
                    required: true,
                  })}
                />

                {errors.location && (
                  <p className="error-msg">Please Enter The Location</p>
                )}
              </div>
            </div>

            <hr className="emp-hr-line"></hr>

            <div className="emp-form-label-input">
              <label className="emp-form-label">Duties</label>

              <div className="profileeditinput div">
                <MultiSelectDropdown
                  control={control}
                  name="duties"
                  options={duties}
                  rules={{ required: "Please select at least one option" }}
                />
              </div>
            </div>

            <div className="emp-cancel-btns-div pt-3">
              <button
                className="emp-edit-btn-cp-cancel-btn"
                type="button"
                onClick={onClickCancel}
              >
                Cancel
              </button>

              <button className="emp-edit-btn cp-save-btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMoreExperienceEmp;
