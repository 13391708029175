import axios from 'axios';
import {BaseUrl} from  '../ApiBaseUrl/Api';
import {CUSTOMER_GET_ALL_CHATS} from '../ApiEndPoints/ApiEndPoints';
import axiosInstance from "./axiosInstance";

export const getAllCustomerChatList = async()=>{
    try{
        const response = await axiosInstance.get(`${BaseUrl}${CUSTOMER_GET_ALL_CHATS}`);
        return response;
    }
    catch(error){
        throw error.response.error
    }
};

export default getAllCustomerChatList;