import React, { useState } from "react";
import Select, { components } from "react-select";
import "./DropdownWithCheckBox.css";
import MaidIcon from "../../../Assets/Images/maid.png";
import NannyIcon from "../../../Assets/Images/Nanny.png";
import CarerIcon from "../../../Assets/Images/carer.png";

const Option = (props) => {
  const { icon: Icon, label } = props.data;

  return (
    <components.Option {...props}>
      <span className="ch-cp-dropdown-option">
        <Icon className="ch-cp-community-icon" />

        <div className="ch-cp-dropdown-text-div">
          <label className="ch-cp-dropdown-community-text">{label}</label>
          <p className="ch-cp-dropdown-community-tag">
            Your post will only appear on the {label}’s portal.
          </p>
        </div>

        <div>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="ch-cp-checkbox"
          />
        </div>
      </span>
    </components.Option>
  );
};

const CustomMaidIcon = () => (
  <img src={MaidIcon} alt="Maid Icon" className="custom-icon" />
);

const CustomNannyIcon = () => (
  <img src={NannyIcon} alt="Nanny Icon" className="custom-icon" />
);

const CustomCarerIcon = () => (
  <img src={CarerIcon} alt="Carer Icon" className="custom-icon" />
);

const comOptions = [
  { value: "Maid", label: "Maid", icon: CustomMaidIcon },
  { value: "Nanny", label: "Nanny", icon: CustomNannyIcon },
  { value: "Carer", label: "Carer", icon: CustomCarerIcon },
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    color: state.isFocused ? "white" : "black",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#0d6efd80" : "white",
    color: state.isSelected ? "white" : "black",
  }),
  menu: (base) => ({
    ...base,
    maxHeight: '200px', // Adjust the height as per your requirement
  }),
};

const DropdownWithCheckBox = ({ optionSelected, setOptionSelected }) => {
  const customPlaceholder = "Choose a community";

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  return (
    <div>
      <Select
        className="ch-cp-dropdown-box"
        options={comOptions}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={{ Option }}
        onChange={handleChange}
        value={optionSelected}
        placeholder={customPlaceholder}
        styles={customStyles}
      />
    </div>
  );
};

export default DropdownWithCheckBox;
