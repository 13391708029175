import axios from "axios";
import { BaseUrl } from "../ApiBaseUrl/Api";
import { useSelector } from "react-redux";
import Store from "../redux/Store";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  const state = Store.getState();
  const authToken = localStorage.getItem("token");

  if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});

export default axiosInstance;
