import React, { useState, useEffect, useRef, useCallback } from "react";
import "./CustomerPostDeletePostComponent.css";
import { BsThreeDots } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_POST_DELETE } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import CreatePostModalPopup from "../CustomerCreatePostButton/CustomerCreatePostButton";
import { Modal, Button } from "react-bootstrap";
import DefaultProfilePic from "../../../Assets/Images/Default_pfp.svg.png";
import DropdownWithCheckBox from "../DropdownWithCheckBox/DropdownWithCheckBox";
import CreatePostEmojiTextArea from "../CreatePostEmojiTextArea/CreatePostEmojiTextArea";
import Dropzone from "react-dropzone";
import Webcam from "react-webcam";
import imageUpload from "../../../Assets/Svg/camera-vector.svg";
import camera from "../../../Assets/Svg/cam-vector.png";
import Resizer from "react-image-file-resizer";
import captureIcon from "../../../Assets/Images/capture-cam-icon.png";

const CustomerPostDeletePostComponent = ({
  postId,
  profileImageUrl,
  handleDrop,
}) => {
  const [cpOpenMenuOnThreeDots, setCpOpenMenuOnThreeDots] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false); // Flag to track delete operation by this multiple clicks on dlete button removed
  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal

  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false); // Track the image loading status
  const [data, setData] = useState({});
  const Token = localStorage.getItem("token");
  const [imgSrc, setImgSrc] = useState(false);
  const textAreaRef = useRef(null);

  const [showImageIconDropZoneArea, setShowImageIconDropZoneArea] =
    useState(false);
  const [showCameraIconDropZoneArea, setShowCameraIconDropZoneArea] =
    useState(false);
  const [showCapturedImage, setShowCapturedImage] = useState(false);
  const [cameraDropZoneBox, setCameraDropZoneBox] = useState(false);
  const webcamRef = useRef(null);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [text, setText] = useState("");
  const [selectedFileFromDropZone, setSelectedFileFromDropZone] =
    useState(null);
  const [capturedImgSrc, setCapturedImgSrc] = useState(null);
  const [resizedPreviews, setResizedPreviews] = useState([]);
  const [previewDropedFile, setPreviewDropedFile] = useState(true);
  const [previewImageUploadByFile, setPreviewImageUploadByFile] =
    useState(null);
  const [showEmojiTextArea, setShowEmojiTextArea] = useState(true);
  console.log(profileImageUrl, "customer pprofileImageUrl");
  const openMenuForCustomerPost = () => {
    setCpOpenMenuOnThreeDots(!cpOpenMenuOnThreeDots);
  };

  // Function to handle the delete customer post
  const handleDeletePost = () => {
    if (isDeleteInProgress) {
      return;
    }
    // Construct the URL with the postId
    const apiUrl = `${BaseUrl}${CUSTOMER_POST_DELETE}${postId}`;
    const Token = localStorage.getItem("token");
    setIsDeleteInProgress(true);

    // Make the DELETE request
    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setCpOpenMenuOnThreeDots(false);
        setTimeout(() => {
          toast.info("Post deleted successfully");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error("There was a problem deleting the post:", error);
        setIsDeleteInProgress(false);
      })
      .finally(() => {
        // Reset the flag after the delete operation is complete
        setIsDeleteInProgress(false);
      });
  };

  // Function to handle the update customer post
  const handleEditPost = () => {
    setIsEditModalOpen(true);
    // Check if textAreaRef.current is not null before accessing its value
    if (textAreaRef.current) {
      setText(textAreaRef.current.value); // Set the initial text for editing
    }
  };

  const showDropZoneImage = () => {
    setShowImageIconDropZoneArea(true);
    setShowEmojiTextArea(true);
    setShowCameraIconDropZoneArea(false);
    setCameraDropZoneBox(false);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
  };
  const closeModalForCreatePost = () => {
    setCreatePostPopupOpen(false);
  };
  const showPreviewDropedFile = () => {
    setPreviewDropedFile(true);
  };
  const capturePicture = useCallback(() => {
    if (webcamRef.current) {
      const capturedImgSrc = webcamRef.current.getScreenshot();
      setImgSrc(capturedImgSrc);
      setCapturedImgSrc(capturedImgSrc);
      setShowCameraIconDropZoneArea(false);
      setShowCapturedImage(true);
    }
  }, [webcamRef, setImgSrc, setCapturedImgSrc, setShowCapturedImage]);

  const retake = () => {
    setImgSrc(null);
    setCapturedImgSrc(null);
    setShowCapturedImage(false);
    setCameraDropZoneBox(true);
    setShowCameraIconDropZoneArea(true);
  };
  const closeCamToTakePicture = () => {
    setShowCapturedImage(!!imgSrc);
    setImgSrc(!!capturedImgSrc ? capturedImgSrc : imgSrc);
    setCameraDropZoneBox(false);
    setShowCameraIconDropZoneArea(false);
  };
  const openCamToTakePicture = () => {
    setTimeout(() => {
      setShowCameraIconDropZoneArea(false);
      setShowEmojiTextArea(true);
      setShowImageIconDropZoneArea(false);
      setCameraDropZoneBox(true);
    }, 1000);
    // setIsLoading(false);
  };
  const onClickCameraDropZoneOpenCamera = () => {
    setShowCameraIconDropZoneArea(true);
    // setIsLoading(false);
  };
  const handleUpdatePost = () => {
    console.log("Updated text:", text);
    closeModalForEditPost();
    toast.info("Post deleted successfully");
  };
  return (
    <div className="cp-drop-down-menu-div">
      <BsThreeDots
        size={24}
        color={"#7b7b7b"}
        className="cu-post-three-dots-menu-icon"
        onClick={openMenuForCustomerPost}
      />
      {cpOpenMenuOnThreeDots && (
        <div className="cp-dropdown-threedots-menu">
          {/* <div className="cp-tdots-menu-button-div1">
            <div className="cp-tdots-edit-post-icon-div">
              <BiEditAlt
                size={20}
                onClick={handleEditPost}
                className="cp-tdots-menu-list-icon"
              />
            </div>
            <div className="cp-tdots-edit-post-button-div">
              <button
                onClick={handleEditPost}
                className="cp-tdots-menu-button-edit"
              >
                Edit
              </button>
            </div>
          </div> */}
          <div className="cp-tdots-menu-button-div2" onClick={handleDeletePost}>
            <div>
              <AiOutlineDelete size={20} onClick={handleDeletePost} />
            </div>
            <div>
              <button
                onClick={handleDeletePost}
                className="cp-tdots-menu-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Render the edit post modal when isEditModalOpen is true */}
      {/* {isEditModalOpen && (
        <CreatePostModalPopup
          createPostPopupOpen={isEditModalOpen} 
          setCreatePostPopupOpen={setIsEditModalOpen}
          customerProfileImage={profileImageUrl}
        />
      )} */}
      {isEditModalOpen && (
        <Modal
          show={isEditModalOpen}
          onHide={() => setIsEditModalOpen(false)}
          className="custom-create-post-modal centered"
        >
          <Modal.Header closeButton>
            <Modal.Title className="custom-modal-title">
              Edit your post
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modal-body">
            <div className="ch-cp-popup-drodown-div">
              <img
                src={{ profileImageUrl } ?? DefaultProfilePic}
                alt=""
                className="ch-cp-profile-img"
              />
              <DropdownWithCheckBox
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
              />
              {console.log("optionSelected", optionSelected)}
            </div>
            {showEmojiTextArea && (
              <div>
                <p className="ch-cp-modal-popup-text">
                  Description(Please be as detailed as possible)
                </p>
                {showDescriptionError && (
                  <p className="ch-cp-description-error">
                    *Description cannot be empty.
                  </p>
                )}
                <CreatePostEmojiTextArea text={text} setText={setText} />

                {console.log("text", text)}
              </div>
            )}
            {showImageIconDropZoneArea && (
              <div className="ch-cp-camera-image-upload-dropzone">
                <Dropzone onDrop={handleDrop} accept="image/jpeg, image/png">
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="ch-cp-image-dropzone">
                        {previewImageUploadByFile ? (
                          <div className="d-flex flex-column">
                            {/* <img src={removeUploadedFileIcon} alt="removeuploadedfile" className="ch-cp-remove-uploaded-file-icon"/> */}
                            <img
                              src={previewImageUploadByFile}
                              alt="Preview"
                              className="ch-cp-preview-image-upload-by-file ch-cp-image-dropzone-text"
                            />
                          </div>
                        ) : (
                          <>
                            <p className="ch-cp-image-dropzone-text">
                              Drag and drop an image or
                            </p>
                            <button
                              className="ch-cp-drop-zone-button"
                              onClick={showPreviewDropedFile}
                            >
                              {" "}
                              Upload{" "}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
                {previewDropedFile && (
                  <div className="dropzone-preview">
                    {resizedPreviews.map((preview, index) => (
                      <img
                        key={index}
                        src={preview.preview}
                        alt="Preview"
                        className="preview-image"
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {cameraDropZoneBox && !imgSrc && (
              <div className="ch-cp-camera-image-upload-dropzone">
                <p
                  className="ch-cp-image-dropzone-text"
                  onClick={onClickCameraDropZoneOpenCamera}
                >
                  Click to start camera
                </p>
              </div>
            )}
            {showCameraIconDropZoneArea && (
              <div>
                {isLoading ? (
                  <div className="hhloader-div">
                    {/* <div className="hhloader-div">
                      <img
                        src={HHLogo}
                        alt="hhlogo"
                        className="logo-in-loader"
                      />
                      <Typewriter
                        options={{
                          strings: ["Loading..."],
                          autoStart: true,
                          loop: true,
                          delay: 30,
                        }}
                        className="loading-text-in-loader"
                      />
                    </div> */}
                    <p>Loading....</p>
                  </div>
                ) : (
                  <Modal
                    show={openCamToTakePicture}
                    onHide={closeCamToTakePicture}
                    className="custom-camera-modal centered"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="custom-modal-title">
                        Camera
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-camera-modal-body">
                      <div className="ch-cp-camera-div">
                        {imgSrc ? (
                          <img src={imgSrc} alt="webcam" />
                        ) : (
                          <Webcam
                            height={341}
                            width={700}
                            ref={webcamRef}
                            style={{ width: "100%", height: "auto" }}
                          />
                        )}
                        <div className="btn-container"></div>
                      </div>{" "}
                    </Modal.Body>
                    <Modal.Footer className="custom-modal-footer">
                      <div className="">
                        <div>
                          {imgSrc ? (
                            <div>
                              <button
                                onClick={retake}
                                className="ch-cp-drop-zone-button"
                              >
                                Retake
                              </button>
                              <button
                                onClick={capturePicture}
                                className="ch-cp-drop-zone-button"
                              >
                                Upload
                              </button>
                            </div>
                          ) : (
                            <button onClick={capturePicture} className="">
                              <img
                                src={captureIcon}
                                alt=""
                                className="ch-cp-popup-vector"
                              />
                            </button>
                          )}
                        </div>
                        {/* <Button variant="primary">Post</Button> */}
                      </div>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
            )}
            {showCapturedImage && (
              <div className="ch-cp-captured-image-container">
                <img
                  src={imgSrc}
                  alt="Captured"
                  className="ch-cp-captured-image"
                />
                <div className="ch-cp-captured-image-buttons">
                  <button
                    onClick={retake}
                    className="ch-cp-drop-zone-button my-3 mx-auto"
                  >
                    Retake
                  </button>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="custom-modal-footer">
            <div className="ch-cp-popup-footer">
              <div>
                <button onClick={showDropZoneImage}>
                  <img
                    src={imageUpload}
                    alt=""
                    className="ch-cp-popup-vector"
                  />
                </button>
                <button onClick={openCamToTakePicture}>
                  <img src={camera} alt="" className="ch-cp-popup-vector" />
                </button>
              </div>
              <Button
                className="post-btn-create-post-popup"
                onClick={handleUpdatePost}
              >
                Update
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default CustomerPostDeletePostComponent;
// this component is for delete the customer post which was created by customer done by renuka
