import axios from 'axios';
import {BaseUrl} from  '../ApiBaseUrl/Api';
import axiosInstance from "./axiosInstance";
import { SHOW_UNREAD_MESSAGES_AND_COMMENTS_COUNT_GET_API } from '../ApiEndPoints/ApiEndPoints';

export const getUnreadMessagesCommentsCountApi = async()=>{
    try{
        const response = await axiosInstance.get(`${BaseUrl}${SHOW_UNREAD_MESSAGES_AND_COMMENTS_COUNT_GET_API}`);
        return response;
    }
    catch(error){
        throw error.response.data;
    }
};

export default getUnreadMessagesCommentsCountApi;