import React, { useState } from "react";
import "./CreatePostEmojiTextArea.css";
import EmojiPicker from "emoji-picker-react";
import emojiImg from "../../../Assets/Svg/emoji-vector.svg";

const CreatePostEmojiTextArea = ({ text, setText }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to toggle emoji picker visibility
  const handleEmojiSelect = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setText(text + emoji);
  };
  const handleChange = (event) => {
    setText(event.target.value);
  };
  const isRedColor = text.length >= 271 ? { color: "red" } : {};


  return (
    <div>
      <textarea
        value={text}
        onChange={handleChange}
        placeholder="Type here..."
        className="ch-cp-job-desc-textarea"
        maxLength={275}
        required
      />
      <p className="ch-cp-text-area-tagline " style={isRedColor}>
        {275 - text.length} characters left
      </p>

      <button onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
        <img src={emojiImg} alt="" className="ch-cp-popup-vector mb-3" />
      </button>

      {showEmojiPicker && (
        <EmojiPicker
          onEmojiClick={handleEmojiSelect}
          disableAutoFocus
          pickerStyle={{ width: "250px" }}
        />
      )}
    </div>
  );
};

export default CreatePostEmojiTextArea;
