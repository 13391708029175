import React, { useState } from "react";
import OtpInput from "react-otp-input";

export default function App({setOtpInput}) {
  const [otp, setOtp] = useState("");

  const handleChangeOtp =(e) =>{
    //  console.log("otp",e);
     setOtpInput(e); 
     setOtp(e);
  }


  return (
    <div
      className=".otp-input-container"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "25px",
        marginBottom: "40px",
      }}
    >
      <OtpInput
        className="otp-fields"
        value={otp}
        onChange={(e)=>handleChangeOtp(e)}
        numInputs={6}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "47px",
              height: "65px",
              border: "1px solid #cdced1",
              borderRadius: "6px",
              fontSize: "30px",
              textAlign: "center",
              marginLeft:"12px",
              background: "#f5f6f7"
            }}
          />
        )}
      />
    </div>
  );
}
