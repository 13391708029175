import React, { useState } from "react";
import CountryFilter from "../../../CountryInput/CountryFilter";
import StateFilter from "../../../StateInput/StateFilter";
import CityFilter from "../../../City/CityFilter";
const AvailableJobsSideBar = ({
  handleApplyFilters,
  filters: initialFilters,
  onClickCancel,
  handleSortChange,
  sortBy,
  setSortBy,
  totalRequests,
  dataLength,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const numberArray = Array.from({ length: 83 }, (_, index) => index + 18);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const applyFiltersAndSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters(e, filters);
    console.log("Selected Position:", filters.position);
  };
  const handleClearAll = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      position: "",
      minAge: "",
      maxAge: "",
      minSalary: "",
      maxSalary: "",
      workSchedule: [],
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
    }));
    handleCountryChange("");
    handleStateChange("");
    handleCityChange("");
    onClickCancel();
  };
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setFilters((prevFilters) => ({
      ...prevFilters,
      country: country,
    }));
  };
  const handleStateChange = (state) => {
    setSelectedState(state);
    setFilters((prevFilters) => ({
      ...prevFilters,
      state: state,
    }));
    console.log("selectedState", selectedState);
  };
  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: city,
    }));
    console.log("selectedState", selectedState);
  };
  return (
    <form onSubmit={applyFiltersAndSubmit}>
      <div className="ahsb-filters-div d-flex">
        <div className="ahsb-sort-by-div">
          <select
            value={sortBy}
            onChange={handleSortChange}
            aria-label="Sort By"
            className="ahsb-sb-select-btn-sortby ahlh-sort-large-screen-view"
          >
            <option value="" className="ahsb-sb-option ahsb-sb-option-sort-by">
              Sort By
            </option>
            <option
              value="lesstomoreexp"
              className="ahsb-sb-option ahsb-sb-option-sort-by"
            >
              Less to more experience
            </option>
            <option
              value="moretolessexp"
              className="ahsb-sb-option ahsb-sb-option-sort-by"
            >
              More to less experience{" "}
            </option>{" "}
            <option
              value="lesstomoresal"
              className="ahsb-sb-option ahsb-sb-option-sort-by"
            >
              Less to more salary
            </option>
            <option
              value="moretolesssal"
              className="ahsb-sb-option ahsb-sb-option-sort-by"
            >
              More to less salary
            </option>
          </select>
        </div>
        <div className="ahsb-div">
          <div className="d-flex flex-column">
            <h5 className="ahsb-text-heading">Personalize your search</h5>
            <button className="ahsb-clear-all-button" onClick={handleClearAll}>
              Clear all
            </button>
          </div>
          <div className="ahsb-position-filter-div">
            <div className="ahsb-position-filter-label-div">
              <label
                htmlFor="position"
                className="ahsb-position-filter-label-text"
              >
                Position
              </label>
            </div>
            <div className="ahsb-position-filter-select-div ahsb-sort-by-div">
              <select
                value={filters?.position || ""}
                id="position"
                onChange={(e) => {
                  const newPosition = e.target.value;
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    position: newPosition,
                  }));
                }}
                aria-label="Select"
                className="ahsb-position-filter-select ahsb-sb-select-btn"
              >
                <option value="" className="ahsb-sb-option">
                  Select
                </option>
                <option value="Maid" className="ahsb-sb-option">
                  Maid
                </option>
                <option value="Nanny" className="ahsb-sb-option">
                  Nanny
                </option>
                <option value="Carer" className="ahsb-sb-option">
                  Carer
                </option>
              </select>
            </div>
          </div>
          <div className="ahsb-work-schedule-filter-div">
            <div className="ahsb-work-schedule-filter-label-div">
              <p className="ahsb-work-schedule-filter-label-text">
                Work schedule
              </p>
            </div>
            <div className="ahsb-work-schedule-filter-buttons-div">
            <div className={`ahsb-work-schedule-filter-button-div ${filters.workSchedule.includes("Flexible") ? 'workschedule-selected' : ''}`}>
                <label className="ahsb-work-schedule-radio">
                  <input
                    type="checkbox"
                    checked={filters.workSchedule.includes("Flexible")}
                    onChange={(e) => {
                      const newWorkSchedule = [...filters.workSchedule];
                      if (e.target.checked) {
                        newWorkSchedule.push("Flexible");
                      } else {
                        const index = newWorkSchedule.indexOf("Flexible");
                        if (index !== -1) {
                          newWorkSchedule.splice(index, 1);
                        }
                      }
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        workSchedule: newWorkSchedule,
                      }));
                    }}
                  />
                  <span className="checkmark-work-schedule"></span>
                  <span className="ahsb-work-schedule-radio-label-text">
                    Flexible
                  </span>
                </label>
              </div>
              <div className={`ahsb-work-schedule-filter-button-div ${filters.workSchedule.includes("Remote") ? 'workschedule-selected' : ''}`}>
                <label className="ahsb-work-schedule-radio">
                  <input
                    type="checkbox"
                    checked={filters.workSchedule.includes("Remote")}
                    onChange={(e) => {
                      const newWorkSchedule = [...filters.workSchedule];
                      if (e.target.checked) {
                        newWorkSchedule.push("Remote");
                      } else {
                        const index = newWorkSchedule.indexOf("Remote");
                        if (index !== -1) {
                          newWorkSchedule.splice(index, 1);
                        }
                      }
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        workSchedule: newWorkSchedule,
                      }));
                    }}
                  />
                  <span className="checkmark-work-schedule"></span>
                  <span className="ahsb-work-schedule-radio-label-text">
                    Remote
                  </span>
                </label>
              </div>
              <div className={`ahsb-work-schedule-filter-button-div ${filters.workSchedule.includes("Full Time") ? 'workschedule-selected' : ''}`}>
                <label className="ahsb-work-schedule-radio">
                  <input
                    type="checkbox"
                    checked={filters.workSchedule.includes("Full Time")}
                    onChange={(e) => {
                      const newWorkSchedule = [...filters.workSchedule];
                      if (e.target.checked) {
                        newWorkSchedule.push("Full Time");
                      } else {
                        const index = newWorkSchedule.indexOf("Full Time");
                        if (index !== -1) {
                          newWorkSchedule.splice(index, 1);
                        }
                      }
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        workSchedule: newWorkSchedule,
                      }));
                    }}
                  />
                  <span className="checkmark-work-schedule"></span>
                  <span className="ahsb-work-schedule-radio-label-text">
                    Full Time
                  </span>
                </label>
              </div>
              <div className={`ahsb-work-schedule-filter-button-div ${filters.workSchedule.includes("Part Time") ? 'workschedule-selected' : ''}`}>
                <label className="ahsb-work-schedule-radio">
                  <input
                    type="checkbox"
                    checked={filters.workSchedule.includes("Part Time")}
                    onChange={(e) => {
                      const newWorkSchedule = [...filters.workSchedule];
                      if (e.target.checked) {
                        newWorkSchedule.push("Part Time");
                      } else {
                        const index = newWorkSchedule.indexOf("Part Time");
                        if (index !== -1) {
                          newWorkSchedule.splice(index, 1);
                        }
                      }
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        workSchedule: newWorkSchedule,
                      }));
                    }}
                  />
                  <span className="checkmark-work-schedule"></span>
                  <span className="ahsb-work-schedule-radio-label-text">
                    Part Time
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="ahsb-coutry-filter-div">
            <div className="ahsb-country-filter-label-div">
              <p className="ahsb-country-filter-label-text">Country</p>
            </div>
            <div>
              <CountryFilter
                label="false"
                labelStyle={{}}
                onChange={handleCountryChange}
                selectedCountry={selectedCountry}
              />
            </div>
          </div>
          <div className="ahsb-state-filter-div">
            <div className="ahsb-state-filter-label-div">
              <p className="ahsb-state-filter-label-text">State</p>
            </div>
            <div>
              <StateFilter
                showLabel={false}
                labelStyle={{}}
                onChange={handleStateChange}
                selectedState={selectedState}
                selectedCountry={selectedCountry}
              />
            </div>
          </div>
          <div className="ahsb-city-filter-div">
            <div className="ahsb-city-filter-label-div">
              <p className="ahsb-city-filter-label-text">City</p>
            </div>
            <div>
              <CityFilter
                selectedState={selectedState}
                selectedCountry={selectedCountry}
                // customWidth= {"237px"}
                showLabel={false}
                onChange={handleCityChange}
                selectedCity={selectedCity}
              />
            </div>
          </div>
          <p className="ahsb-showing-noof-posts-text">
            {" "}
            Showing {dataLength} of {totalRequests}
          </p>
        </div>
        <div className="ahsb-div">
          <div className="ahsb-salary-filter-div">
            <div className="ahsb-salary-filter-label-div">
              <p className="ahsb-salary-filter-label-text">Salary</p>
            </div>
            <div className="ahsb-salary-range-select-div">
              <div className="ahsb-salary-range-select-min-div">
                <select
                  className="emp-form-input"
                  value={filters?.minSalary || ""}
                  onChange={(e) => {
                    const newMinSalary = e.target.value;
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      minSalary: newMinSalary,
                    }));
                  }}
                >
                  {[...Array(20)].map((_, index) => {
                    const value = (index + 1) * 100;
                    return (
                      <option key={value} value={`$ ${value}`}>
                        $ {value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="ahsb-salary-range-select-min-div">
                {" "}
                <select
                  className="emp-form-input"
                  value={filters?.maxSalary || ""}
                  onChange={(e) => {
                    const newMaxSalary = e.target.value;
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      maxSalary: newMaxSalary,
                    }));
                  }}
                >
                  {[...Array(21)].map((_, index) => {
                    const value = (index + 20) * 100;
                    return (
                      <option key={value} value={`$ ${value}`}>
                        $ {value}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="ahsb-div">
          <div className="ahsb-age-filter-div">
            <div className="ahsb-age-filter-label-div">
              <p className="ahsb-age-filter-label-text">Age</p>
            </div>
            <div className="ahsb-age-range-select-div">
              <div className="ahsb-age-range-select-min-div">
                <select
                  className="emp-form-input"
                  value={filters?.minAge || ""}
                  onChange={(e) => {
                    const newMinAge = e.target.value;
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      minAge: newMinAge,
                    }));
                  }}
                >
                  <option value=""> min</option>
                  {numberArray.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ahsb-age-range-select-min-div">
                <select
                  className="emp-form-input"
                  value={filters?.maxAge || ""}
                  onChange={(e) => {
                    const newMaxAge = parseInt(e.target.value);
                    if (newMaxAge >= filters?.minAge) {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        maxAge: newMaxAge,
                      }));
                    }
                  }}
                >
                  <option value=""> max</option>
                  {numberArray.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column ahsb-apply-filter-button-div">
          <button className="ahsb-apply-filter-button" type="submit">
            Apply Filters
          </button>
        </div>
      </div>
    </form>
  );
};

export default AvailableJobsSideBar;
