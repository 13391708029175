import React from "react";
import DefaultProfilePic from "../../../../Assets/Images/Default_pfp.svg.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./AvailableJobsList.css";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const AvailableJobsList = ({
  data,
  currentPage,
  totalRequests,
  itemsPerPage,
  handlePageChange,
}) => {
  const navigate = useNavigate();

  console.log("data", data);
  console.log("currentPage", currentPage);
  console.log("totalRequests", totalRequests);
  console.log("itemsPerPage", itemsPerPage);
  //  function to show the full view navigate to full page
  const availableJobPostIndividualFullView = (custReqId) => {
    navigate(`/available-job-fullview/${custReqId}`);
  };
  const noPostsFound = data && data.length === 0;

  return (
    <div className="ahjpl-div">
      {/* Display message when no posts are found */}
      {noPostsFound && (
        <div className="no-posts-message-div">
          <p className="no-posts-message text-center">
            *** No posts found according to the applied filters. ***
          </p>
        </div>
      )}
      {data.map((post, index) => {
        console.log("post expectations:", post.expectations);
        const salaryString = post.salary;
        const [minSalary, maxSalary] = salaryString
          ? salaryString
              .split("-")
              .map((salary) => salary.trim().replace("$", ""))
          : [null, null];
        // console.log("post.custReqId", post.custReqId);
        return (
          <div className="ajpl-ind-div" key={index}>
            <div className="ajplist-part1">
              <div className="ajplist-pa-customer-details">
                <div className="ajplid-pa-img-div">
                  <img
                    alt=""
                    className="ajplid-pa-img"
                    src={post.profileImageUrl ?? DefaultProfilePic}
                  />
                </div>
                <div className="ajplid-pa-name-div">
                  <h5 className="ajplid-pa-name-div-ahid">
                    {" "}
                    {post?.customerName?.replace(/,/g, " ")
                      ? post?.customerName?.replace(/,/g, " ")
                      : post?.customerName?.replace(/,/g, " ")}
                  </h5>
                </div>
                <div className="ajplid-pa-location-div">
                  <p className="ajplid-pa-location">
                    {post.city ?? "city not found"},{" "}
                    {post.state ?? "state not found"},{" "}
                    {post.country ?? "country not found"}{" "}
                  </p>
                </div>
              </div>
              <div>
                <div className="ajplid-pb-s1-button-div  ">
                  <button
                    className="ahjplid-pb-s1-button"
                    onClick={() =>
                      availableJobPostIndividualFullView(post.custReqId)
                    }
                  >
                    Apply Now <IoIosArrowForward />
                  </button>
                </div>
              </div>
            </div>

            <div className="ajplist-part2">
              <div className="ajplist-part21">
                <div className="ahjplid-pb-s2">
                  <div className="ahjplid-pb-s2-position-label-div">
                    <p className="ahjplid-pb-s1-position-label">Expectations</p>
                  </div>
                  {post.expectations !== null &&
                  post.expectations !== undefined ? (
                    <p className="ahjplid-pb-s2-text">
                      {post.expectations.length <= 97
                        ? parse(post.expectations)
                        : parse(post.expectations.substring(0, 97) + "...")}
                    </p>
                  ) : (
                    "Not available"
                  )}
                </div>
                <div className="ahjplid-pb-s1-position-div">
                  <div className="ahjplid-pb-s1-position-label-div">
                    <p className="ahjplid-pb-s1-position-label">Position</p>
                  </div>
                  <div className="ahjplid-pb-s1-position-values-div">
                    {/* {Array.isArray(post.position) ? (
                    post.position.map((position) => (
                      <div className="ahjplid-pb-s1-position-value-div">
                        <p className="ahjplid-pb-s1-position-value">
                          {position}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="ahjplid-pb-s1-position-value-div">
                      <p className="ahjplid-pb-s1-position-value">
                        No roles found
                      </p>
                    </div>
                  )} */}{" "}
                    <div className="ahjplid-pb-s1-position-value-div">
                      <p className="ahjplid-pb-s1-position-value">
                        {post.position ?? "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ajplist-part22">
                <div className="ajplid-pb-row">
                  <div className="ajplid-pb-cell">
                    <p className="ahjplid-pb-s3-r-label">Experience</p>
                    <p className="ahjplid-pb-s3-r-value">
                      {post.workExperience ?? "N/A"}
                    </p>
                  </div>
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Salary</p>
                    <p className="ahjplid-pb-s3-r-value-salary">
                      {minSalary && maxSalary
                        ? `$ ${parseInt(
                            minSalary
                          ).toLocaleString()} - $ ${parseInt(
                            maxSalary
                          ).toLocaleString()}`
                        : "N/A"}{" "}
                    </p>
                  </div>
                </div>
                <div className="ajplid-pb-row">
                  <div className="ajplid-pb-cell">
                    <p className="ahjplid-pb-s3-r-label">Work Schedule</p>
                    <p className="ahjplid-pb-s3-r-value">
                      {Array.isArray(post.workSchedule) ? (
                        <div className="ahjplid-pb-s1-position-values-div">
                          {post.workSchedule.join(" | ")}
                        </div>
                      ) : (
                        <div className="ahjplid-pb-s3-r-cell">
                          <p className="ahjplid-pb-s3-r-value">N/A</p>
                        </div>
                      )}{" "}
                    </p>
                  </div>
                  <div className="ahjplid-pb-s3-r-cell">
                    <p className="ahjplid-pb-s3-r-label">Age</p>
                    <p className="ahjplid-pb-s3-r-value">{post.age ?? "N/A"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="ahjpl-pagination-div">
        <>
          {" "}
          {/* Pagination controls */}
          {/* Previous button */}
          <div className="ahpl-pagination-prev-button-div">
            <button
              onClick={() => handlePageChange(currentPage - 0)}
              disabled={currentPage === 0}
              className="ahpl-pagination-prev-button"
            >
              <IoIosArrowBack color={"#04AFF0"} size={20} /> Prev
            </button>
            {console.log("handlePageChange", currentPage - 0)}
          </div>
          {/* Page buttons */}
          <div className="ahpl-pagination-page-buttons-div">
            {Array.from(
              { length: Math.ceil(totalRequests / itemsPerPage) },
              (_, index) => (
                <div className="ahpl-pagination-page-button-div" key={index}>
                  {" "}
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`ahpl-page-button ${
                      currentPage === index ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                </div>
              )
            )}
          </div>
          {/* Next button */}
          <div className="ahpl-pagination-prev-button-div">
            <button
              onClick={() => handlePageChange(currentPage + 2)}
              disabled={
                currentPage === Math.ceil(totalRequests / itemsPerPage) - 1
              }
              className="ahpl-pagination-prev-button"
            >
              {" "}
              Next
              <IoIosArrowForward color={"#04AFF0"} size={20} />
              {console.log("handlePageChange", currentPage + 2)}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default AvailableJobsList;
