import { createSlice } from '@reduxjs/toolkit';

const activeUsersSlice = createSlice({
  name: 'activeUsers',
  initialState: [],
  reducers: {
    updateActiveUsers: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateActiveUsers } = activeUsersSlice.actions;
export default activeUsersSlice.reducer;