import React, { useEffect } from "react";
import "./Country.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllNewCountryList } from "../../redux/reducers/NewCountryListReducer";
import { RxTriangleDown } from "react-icons/rx";

const CountryFilter = ({ label, labelStyle, onChange , selectedCountry}) => {
  const { NewCountryList } = useSelector((state) => state.countryList);
  const dispatch = useDispatch();
  console.log(label, "label");

  useEffect(() => {
    dispatch(getAllNewCountryList());
  }, []);
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    onChange(selectedCountry);
  };

  // console.log("Countrylist", NewCountryList);
  return (
    <div>
      {label !== "false" && (
        <label style={labelStyle} className="cp-form-label-proedit">
          Country <span className="error-symbol">*</span>
        </label>
      )}
      <select
        type="text"
        className="select-box-country"
        onChange={handleCountryChange}
        placeholder="Select"  value={selectedCountry}
      >
        <option value="">Select the country</option>
        {NewCountryList &&
          NewCountryList.map((e) => {
            return <option value={e}>{e}</option>;
          })}
      </select>
      <RxTriangleDown className="cu-req-select-drop-down-down-arrow country-down-arrow" />

    </div>
  );
};
export default CountryFilter;
