import { DELETE_ALL_EMPLOYEE_NOTIFICATIONS_FAILURE, DELETE_ALL_EMPLOYEE_NOTIFICATIONS_REQUEST, DELETE_ALL_EMPLOYEE_NOTIFICATIONS_SUCCESS } from "../constants/deleteNotificationsConstatnts";
import employeeDeleteAllNotificationsApi from '../../servicesApi/employeeDeleteAllNotificationsApi';

export const employeeDeleteAllNotificationsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ALL_EMPLOYEE_NOTIFICATIONS_REQUEST });

      const response = await employeeDeleteAllNotificationsApi();
      console.log("API Response Data delete all emp noti api action:", response);

      if (response && response.status === 200) {
        dispatch({
          type: DELETE_ALL_EMPLOYEE_NOTIFICATIONS_SUCCESS,
          payload: response?.status
        });
        
      } else {
        dispatch({
          type: DELETE_ALL_EMPLOYEE_NOTIFICATIONS_FAILURE,
          payload: {
            error: "Failed to delete notifications",
            status: response?.status,
          },        });
        
      }
    } catch (error) {
        console.error("Error in delete all employee notifications API action:", error);
      dispatch({
        type: DELETE_ALL_EMPLOYEE_NOTIFICATIONS_FAILURE,
        payload: {error: "An error occured in delete all employee notifications"},
      });
      
    }
  };
};
