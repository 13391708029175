import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
// import Topbar from "../../Components/TopBar/ProfileTopbarEmployee";
import ProfileTopbarCustomer from "../../Components/TopBar/ProfileTopbarCustomer";
import ProfileSideBar from "../../Components/ProfileSideBar/ProfileSideBar";
import MobileTopBarCustomer from "../../Components/MobileTopBar/MobileTopBarCustomer";
import MobileNavBarCustomer from "../../Components/MobileNavBar/MobileNavBarCustomer";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
// import camera from "../../Assets/Svg/camera.svg";
import CustomerProfilePictureModal from "../../Modal/ProfilePicture/Customer/CustomerProfilePictureModal";
const ProfileLayoutCustomer = ({ showCameraIcon, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState(false); //for profile picture change popup

  //for loading effect used this useEffect
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  //this loader props for the loader styles

  // const onClickCamera = () => {
  //   setProfilePicture(true);
  // };

  return (
      <div className="" > 
        <Grid>
          <div className="d-none d-md-block" >
            <ProfileTopbarCustomer />
          </div>
          <div className="d-block d-sm-none">
            <MobileTopBarCustomer />
          </div>
        </Grid>
              {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem" }}
        >
          <Grid container spacing={6} className="">
            <Grid item xs={12} md={3} lg={3}>
              <div className="d-none d-lg-block">
                <ProfileSideBar showCameraIcon={showCameraIcon} />
              </div>
            </Grid>

            <Grid item xs={12} md={9} lg={9}>
              {isLoading ? (
                  <div className="hhloader-div">
                    <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
                  </div>
              ) : (
                <div>{children}</div>
              )}
            </Grid>
          </Grid>
        </div>
        </div>
        <Grid>
          <div className="d-none d-md-block">
            <Footer />
          </div>
          <div className="d-block d-sm-none">
            <MobileNavBarCustomer />
          </div>{" "}
        </Grid>
        {profilePicture && (
          <CustomerProfilePictureModal setProfilePicture={setProfilePicture} />
        )}
      </div>
  );
};

export default ProfileLayoutCustomer;
