import getAllCustomerChatList from "../../servicesApi/customerChatListApi";
import {
  GET_ALL_CUSTOMER_CHATS_REQUEST,
  GET_ALL_CUSTOMER_CHATS_SUCCESS,
  GET_ALL_CUSTOMER_CHATS_FAILURE
} from "../constants/customerChatListConstantsTypes";

export const getAllCustomerChatListAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_CUSTOMER_CHATS_REQUEST });

      const response = await getAllCustomerChatList();
      console.log("API Response Data api action cust:", response);

      if (response && response.status === 200) {
        dispatch({
          type: GET_ALL_CUSTOMER_CHATS_SUCCESS,
          payload: response.data.conversations,
        });
        
      } else {
        dispatch({
          type: GET_ALL_CUSTOMER_CHATS_FAILURE,
          payload: false,
        });
        
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_CUSTOMER_CHATS_FAILURE,
        payload: false,
      });
      
    }
  };
};
