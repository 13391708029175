import React, { useEffect, useState } from "react";
import ProfileLayoutCustomer from "../../../Layout/ProfileLayout/ProfileLayoutCustomer";
import "./CustomerSubscription.css";
import CustomerSubscriptionEdit from "../../../Components/Customer/CustomerSubscriptionEdit/CustomerSubscriptionEditPutData";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_SUBSCRIPTION_DETAILS_GETAPI } from "../../../ApiEndPoints/ApiEndPoints";
import CustomerSubscriptionEditPutData from "../../../Components/Customer/CustomerSubscriptionEdit/CustomerSubscriptionEditPutData";
import CustomerSubscriptionAddPostData from "../../../Components/Customer/CustomerSubscriptionEdit/CustomerSubscriptionAddPostData";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CustomerSubscription = () => {
  const [showEditPage, setShowEditPage] = useState(false);
  const [showAddSubscriptionPage, setShowAddSubscriptionPage] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const Token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleChangeEdit = () => {
    setShowEditPage(true);
    setShowAddSubscriptionPage(false);
  };

  const handleChangeAdd = () => {
    setShowEditPage(false);
    setShowAddSubscriptionPage(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_SUBSCRIPTION_DETAILS_GETAPI}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");
          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
          
        } else {
         
          console.error("Unknown error: Status code", response.status);
          
        }
      })
      .then((result) => {
        setData(result.billingInfo || {});
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <ProfileLayoutCustomer showCameraIcon={false}>
        {isLoading ? (
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        ) : (
          <div>
            <div className="container cs-profile-main">
              <div className="cp-title-div pb-4">
                <h3 className="cp-title">Subscription</h3>
                {!showAddSubscriptionPage &&
                  Object.keys(data || {}).length === 0 && (
                    <button className="cp-edit-btn" onClick={handleChangeAdd}>
                      Add
                    </button>
                  )}
                {!showEditPage && Object.keys(data || {}).length > 0 && (
                  <button className="cp-edit-btn" onClick={handleChangeEdit}>
                    Edit
                  </button>
                )}
              </div>
              {!showEditPage && !showAddSubscriptionPage && (
                <div className="cp-profile-card">
                  <h4>Billing information</h4>
                  {data ? (
                    <div>
                      <div className="cp-label-div">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Name</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.name}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Address</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.address}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Country</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.country}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Stated/Province/Region</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.state}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">City</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.city}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Zip/Postal code</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.zip}</p>
                        </div>
                      </div>
                      <div className="cp-label-div pt-2">
                        <div className="cp-label-div-part1">
                          <p className="cp-label">Email Id</p>
                        </div>
                        <div className="cp-text-div">
                          <p className="cp-text">{data.emailId}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>No billing information found.</p>
                      {/* {!showAddSubscriptionPage && (
                        <button
                          className="cp-edit-btn"
                          onClick={handleChangeAdd}
                        >
                          Add
                        </button>
                      )} */}
                    </div>
                  )}
                </div>
              )}
            </div>

            {showEditPage && (
              <CustomerSubscriptionEditPutData
                setShowEditPage={setShowEditPage}
                prevdata={data}
              />
            )}

            {showAddSubscriptionPage && (
              <CustomerSubscriptionAddPostData
                setShowAddSubscriptionPage={setShowAddSubscriptionPage}
                setShowEditPage={setShowEditPage}
                prevdata={data}
              />
            )}
          </div>
        )}
      </ProfileLayoutCustomer>
    </>
  );
};

export default CustomerSubscription;
