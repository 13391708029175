import { GET_ALL_CUSTOMER_CHATS_FAILURE, GET_ALL_CUSTOMER_CHATS_REQUEST, GET_ALL_CUSTOMER_CHATS_SUCCESS } from '../constants/customerChatListConstantsTypes';

const initialState = {
  chats: [],      
  loading: false, 
  error: null,    
};

export const customerChatListReducer = (state = initialState, action) => {
  switch(action.type){
    case GET_ALL_CUSTOMER_CHATS_REQUEST:
      return{
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_CUSTOMER_CHATS_SUCCESS:
      return{
        ...state,
        chats: action.payload,
        loading: false,
      };
    case GET_ALL_CUSTOMER_CHATS_FAILURE:
      return{
        ...state,
        loading: false,
        error: "An error occur while fetching customer chat list data",
      };
      default:
        return state;
  }
  

};


export default customerChatListReducer;
