import React, { useState, useEffect, useRef } from "react";
import "./AvailableJobIndividualFullView.css";
import AvailableJobIndividualFullViewLayout from "../../../../../Layout/AvailableJobsLayout/AvailableJobsIndividualFullViewLayout/AvailableJobsIndividualFullViewLayout";
import DefaultProfilePic from "../../../../../Assets/Images/avialble-helper-dp.png";
import whatsapp from "../../../../../Assets/Images/whatsapp.png";
import Lineapp from "../../../../../Assets/Images/line-social-app.png";
import telegram from "../../../../../Assets/Images/logos_telegram.png";
import telegramCall from "../../../../../Assets/Images/logos_telegram1.png";
import wechatapp from "../../../../../Assets/Images/wechat.png";
import facebook from "../../../../../Assets/Images/logos_facebook.png";
import linkedin from "../../../../../Assets/Images/logos_linkedin-icon.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
} from "react-share";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import {
  CUSTOMER_REQUEST_FULLVIEW_FOR_EMPLOYEE,
  EMPLOYEE_START_CONVERSATION_WITH_CUSTOMER,
} from "../../../../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../../../../ApiBaseUrl/Api";
import HHLogo from "../../../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { IoIosArrowBack } from "react-icons/io";
import ReactToPrint from "react-to-print";
import DotIcon from "../../../../../Assets/Svg/Ellipse.svg";
import CategoryIcon from "../../../../../Assets/Svg/category-Icon.svg";
import LocationIcon from "../../../../../Assets/Svg/location-Icon.png";
import "./AvailableJobIndividualFullView.css";
import { toast } from "react-toastify";
import { GoShareAndroid } from "react-icons/go";
import { Modal, Button } from "react-bootstrap";

const AvailableJobIndividualFullView = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [customerRequestData, setCustomerRequestData] = useState([]);
  const [emailIdOfCustomer, setEmailIdOfCustomer] = useState("");
  const Token = localStorage.getItem("token");

  let params = useParams();
  const componentRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const websiteUrl = `${BaseUrl}${CUSTOMER_REQUEST_FULLVIEW_FOR_EMPLOYEE}${params.custReqId}`;
  const title = "Welcome to the Hearty Helper! Check out job requirements";
  const titleTag = "Welcome to the Hearty Helper! Check out job requirements";
  const emailBody = `Welcome to the Hearty Helper! Check out job requirements Check out Hearty Helper: ${websiteUrl}`;
  const twitterHashtags = ["heartyhelper", "Heary Helper"];
  const twitterrelated = ["heartyhelper", "Heary Helper"];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCopy = () => {
    toast.success("Link copied to clipboard!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const goBackToAvailableHelpersList = () => {
    navigate("/available-jobs");
  };

  //this function is to get the data of customer request full view
  const fetchHelperDataFullView = async () => {
    try {
      const Token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${Token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      // Construct the API request URL using the correct employee id  number
      const apiUrlInd = `${BaseUrl}${CUSTOMER_REQUEST_FULLVIEW_FOR_EMPLOYEE}${params.custReqId}`;

      const response = await fetch(apiUrlInd, requestOptions);
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        return;
      } else if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        console.log("customerRequestData", data.customerRequestDetailInfo);
        setCustomerRequestData(data.customerRequestDetailInfo || []);
        // setTotalRequests(data.customerRequestsData.length);
        setEmailIdOfCustomer(data.customerRequestDetailInfo?.emailId || "");
        console.log(emailIdOfCustomer, emailIdOfCustomer);
        setIsLoading(false);
        window.scrollTo(0, 0);
        console.log("customerRequestData", customerRequestData);
        console.log("custReqId", params.custReqId);
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
      } else if (response.status === 422) {
        // Handle validation issues
        console.error("Validation error: Data validation failed");
      } else {
        console.error("Unknown error: Status code", response.status);
      }
    } catch (error) {
      console.error("Error fetching data from API", error);
    }
  };
  useEffect(() => {
    fetchHelperDataFullView(params.custReqId);
  }, []);

  const salaryString = customerRequestData.salary;
  const [minSalary, maxSalary] = salaryString
    ? salaryString.split("-").map((salary) => salary.trim().replace("$", ""))
    : [null, null];

  const timeFormatChange = (timeString) => {
    const currentTime = new Date();
    const apiTime = new Date(timeString);
    const timeDifference = currentTime - apiTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 7) {
      // If more than 7 days, show the date in YYYY-MM-DD format
      const year = apiTime.getFullYear();
      const month = String(apiTime.getMonth() + 1).padStart(2, "0");
      const day = String(apiTime.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };
  const apiTimeString = customerRequestData.createdAt;
  const formattedTime = timeFormatChange(apiTimeString);
  console.log(formattedTime, "formattedTime");

  // this startConversationWithCustomer function for the Employee will start message with customer for the request helper data in available job page
  const startConversationWithCustomer = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var raw = JSON.stringify({
      message: "Started Conversation",
    });
    console.log(raw);
    const requestOptions = {
      headers: myHeaders,
      body: raw,
      method: "POST",
      redirect: "follow",
    };
    fetch(
      `${BaseUrl}${EMPLOYEE_START_CONVERSATION_WITH_CUSTOMER}${emailIdOfCustomer}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");

          return;
        } else if (response.status === 200) {
          return response.json();
        } else if (response.status === 201) {
          console.log("Resource created successfully");
          return response.json();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        } else {
          console.error("Unknown error: Status code", response.status);
        }
      })
      .then((result) => {
        console.log("result", result);
        if (
          result &&
          (result.statuscode === 200 || result.statuscode === 201)
        ) {
          navigate("/employee-chat");
          console.log(result, "customer started the cnversation with employee");
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error status code:", error.response.status);
        } else {
          console.error(
            "Error in start conversation with customer:",
            error.message
          );
        }
      });
  };

  const handleShareButtonClick = () => {
    const apiUrlInd = `${BaseUrl}${CUSTOMER_REQUEST_FULLVIEW_FOR_EMPLOYEE}${params.custReqId}`;

    const profileUrl = apiUrlInd;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        // Show a toast notification indicating successful copy
        toast.success("Copied the bio URL successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard: ", error);
        // You can show an error toast here if needed
      });
  };

  return (
    <>
      <AvailableJobIndividualFullViewLayout>
        {isLoading ? (
          <>
            <div className="hhloader-div">
              <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
              <Typewriter
                options={{
                  strings: ["Loading..."],
                  autoStart: true,
                  loop: true,
                  delay: 30,
                }}
                className="loading-text-in-loader"
              />
            </div>
          </>
        ) : (
          <>
            <div className="ahindfv-p7-back-button-div-top">
              <div className="ahindfv-p7-back-button-div">
                <button
                  className="ahindfv-p7-back-button"
                  onClick={goBackToAvailableHelpersList}
                >
                  <IoIosArrowBack color={"#ffffff"} size={20} /> {"  "}
                  Back
                </button>
              </div>
            </div>
            <div className="ajindfv-div" ref={componentRef}>
              <div className="ahjplid-pa-img-div  ajplid-pa-img-ind-div">
                <img
                  alt=""
                  className="ahjplid-pa-img ajplid-pa-img-ind"
                  src={customerRequestData.profileImageUrl ?? DefaultProfilePic}
                />
              </div>

              <div className="ahindfv-main-div">
                {/* avaialble helper details full view divs */}
                <div className="ahindfv-part1-div">
                  <div className="ahindfv-p1-name-div">
                    <div>
                      <h5 className="ahindfv-p1-name">
                        {customerRequestData?.name?.replace(/,/g, " ")
                          ? customerRequestData?.name?.replace(/,/g, " ")
                          : customerRequestData?.name?.replace(/,/g, " ")}
                      </h5>
                    </div>
                    <div className="ch-cjp-details-div">
                      <img src={DotIcon} className="ch-cjp-dot-icon" alt="" />

                      <span className="ch-cjp-details">
                        {/* {fromEmployee == true ? empSimplifiedHour : simplifiedHour} */}
                        {formattedTime || "N/A"}{" "}
                      </span>
                      <img
                        src={CategoryIcon}
                        className="ch-cjp-category-icon"
                        alt=""
                      />
                      <span className="ch-cjp-details">
                        {customerRequestData?.position || "N/A"}{" "}
                      </span>
                      <img
                        src={LocationIcon}
                        className="ch-cjp-location-icon"
                        alt=""
                      />
                      <span className="ch-cjp-details">
                        {customerRequestData.city ?? "city not found"},{" "}
                        {customerRequestData.state ?? "state not found"},{" "}
                        {customerRequestData.country ?? "country not found"}
                      </span>
                    </div>
                  </div>
                  <div className="ahindfv-p1-details-div">
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Position</p>
                      </div>

                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {" "}
                          {customerRequestData.position ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Experience
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {" "}
                          {customerRequestData.workExperience ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Schedule</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {Array.isArray(customerRequestData.workSchedule) ? (
                            <div className="ahjplid-pb-s1-position-values-div">
                              {customerRequestData.workSchedule.join(" | ")}
                            </div>
                          ) : (
                            <div className="ahjplid-pb-s3-r-cell">
                              <p className="ahjplid-pb-s3-r-value">N/A</p>
                            </div>
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details ">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Location</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.city ?? "city not found"},{" "}
                          {customerRequestData.state ?? "state not found"},{" "}
                          {customerRequestData.country ?? "country not found"}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details  ">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Salary</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {minSalary && maxSalary
                            ? `$ ${parseInt(
                                minSalary
                              ).toLocaleString()} - $ ${parseInt(
                                maxSalary
                              ).toLocaleString()}`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Nationality
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.nationality ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Marital status
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.maritalStatus ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Religion</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.religion ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Age</p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.age ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Education level
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {customerRequestData.educationalLevel ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">
                          Cooking ability
                        </p>
                      </div>
                      <div className="ahindfv-p1-detail-value-div">
                        <p className="ahindfv-p1-detail-value">
                          {Array.isArray(customerRequestData.cookingAbility) ? (
                            <div className="ahjplid-pb-s1-position-values-div">
                              {customerRequestData.cookingAbility.join(" , ")}
                            </div>
                          ) : (
                            <div className="ahjplid-pb-s3-r-cell">
                              <p className="ahindfv-p1-detail-value">N/A</p>
                            </div>
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="ahindfv-p1-details">
                      <div className="ahindfv-p1-detail-label">
                        <p className="ahindfv-p1-detail-label-text">Language</p>
                      </div>
                      <div className="ahjplid-pb-s1-position-values-div">
                        {Array.isArray(customerRequestData.language) ? (
                          customerRequestData.language.map(
                            (language, index) => (
                              <div
                                key={index}
                                className="ahjplid-pb-s1-position-value-div"
                              >
                                <p className="ahjplid-pb-s1-position-value">
                                  {language}
                                </p>
                              </div>
                            )
                          )
                        ) : (
                          <div className="ahjplid-pb-s1-position-value-div">
                            <p className="ahjplid-pb-s1-position-value">
                              No languages found
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ahindfv-part3-div">
                  <div className="ahindfv-p3-details">
                    <div className="ahindfv-p1-detail-label">
                      <p className="ahindfv-p2-detail-label-text">Peronality</p>
                    </div>

                    <div className="ahjplid-pb-s1-position-values-div">
                      {Array.isArray(customerRequestData.personality) ? (
                        customerRequestData.personality.map(
                          (personality, index) => (
                            <div
                              key={index}
                              className="ahjplid-pb-s1-position-value-div"
                            >
                              <p className="ahjplid-pb-s1-position-value">
                                {personality}
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <div className="ahjplid-pb-s1-position-value-div">
                          <p className="ahjplid-pb-s1-position-value">
                            No personality details found
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="ahindfv-part4-div">
                  <div className="ahindfv-p2-details">
                    <div className="ahindfv-p1-detail-label">
                      <p className="ahindfv-p2-detail-label-text">
                        Expectation
                      </p>
                    </div>
                    <div className="ahindfv-p4-detail-value-div">
                      <p className="ahindfv-p4-detail-value">
                        {customerRequestData.expectations !== null &&
                        customerRequestData.expectations !== undefined
                          ? parse(customerRequestData.expectations)
                          : "Not available"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ahindfv-part7-div">
              <div></div>
              <div className="ahindfv-p7-print-msg-buttons-div">
                {/* <button className="ahindfv-p7-print-button">Print</button> */}
                <div
                  className="ahindfv-p7-print-button-div"
                  onClick={openModal}
                >
                  <button
                    className="ahindfv-p7-print-button"
                    onClick={openModal}
                  >
                    Share{" "}
                    <GoShareAndroid
                      color={"#04aff0"}
                      size={25}
                      className="share-icon-cus-req-fulview"
                    />
                  </button>
                </div>
                {isModalOpen && (
                  <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Share this url</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="share-invite-friends-icons-div">
                        <FacebookShareButton
                          url={websiteUrl}
                          title={title}
                          quote="link"
                          hashtag="#heartyhelper"
                        >
                          <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>{" "}
                        <LinkedinShareButton
                          url={websiteUrl}
                          title={titleTag}
                          summary={title}
                          source={websiteUrl}
                        >
                          <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>
                        <WhatsappShareButton
                          url={websiteUrl}
                          title={title}
                          separator=" "
                        >
                          <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>{" "}
                        <EmailShareButton
                          url={websiteUrl}
                          subject="Welcome to the Hearty Helper!"
                          body={emailBody}
                        >
                          <EmailIcon size={40} round={true} />
                        </EmailShareButton>
                        <MdOutlineContentCopy
                          size={30}
                          color="#212529"
                          className="copy-clipboard-invite-friends"
                          onClick={handleShareButtonClick}
                        />
                      </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button variant="secondary" onClick={closeModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={closeModal}>
                        Save Changes
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
                )}
                <div className="ahindfv-p7-msg-button-div">
                  <button
                    className="ahindfv-p7-msg-button"
                    onClick={startConversationWithCustomer}
                  >
                    Message
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </AvailableJobIndividualFullViewLayout>{" "}
    </>
  );
};

export default AvailableJobIndividualFullView;
