export const COUNTRY_LIST = "getAllCountries";
export const STATE_LIST = "getAllStates/";
export const CITY_LIST = "getAllCities/";
export const SEND_OTP_CUSTOMER = "v1/customer/sendOTP/";
export const CUSTOMER_OTP_VERIFY = "v1/customer/verifyOTP/";
export const CUSTOMER_REGISTER = "v1/customer/register";
export const SEND_OTP_EMPLOYEE = "v1/employee/sendOTP/";
export const EMPLOYEE_OTP_VERIFY = "v1/employee/verifyOTP/";
export const EMPLOYEE_REGISTER = "v1/employee/register";
export const CUSTOMER_LOGIN = "v1/customer/login";
export const EMPLOYEE_LOGIN = "v1/employee/login";
export const RESET_PASSW0RD_CUSTOMER = "v1/customer/ResetPassword";
export const RESET_PASSW0RD_EMPLOYEE = "v1/employee/ResetPassword";
export const RESET_PASSWORD_OTP_CUSTOMER = "v1/customer/resetPasswordOTP/";
export const RESET_PASSWORD_OTP_EMPLOYEE = "v1/employee/resetPasswordOTP/";
export const CUSTOMER_PROFILE = "v1/customer/getProfile/";
export const GET_CUSTOMER_DETAILS = "v1/customer/getAccountDetails/";
export const POST_CUSTOMER_REQUEST = "v1/customer/saveCustomerRequests";
export const CUSTOMER_POST_DELETE = "v1/customer/deleteCustomerPost/";
// export const GET_ACCOUNT_EMPLOYEE_DETAILS ="v1/employee/getAccountDetails/";
export const CUSTOMER_NOTIFICATION = "v1/customer/getNotificationStatus";
export const EMPLOYEE_PROFILE = "v1/employee/getProfile";
export const EMPLOYEE_QUALIFICATION = "v1/employee/getQualificationsInfo";
export const EMPLOYEE_QUALIFICATION_UPDATE = "v1/employee/updateQualification";
export const EMPLOYEE_ACCOUNT = "v1/employee/getAccountDetails";
export const EMPLOYEE_ACCOUNT_UPDATE = "v1/employee/updateAccount";
export const EMPLOYEE_ACCOUNT_DELETE = "v1/employee/deleteAccount";

export const EMPLOYEE_EDUCATION = "v1/employee/getEducationalInfo";
export const EMPLOYEE_EDUCATION_UPDATE = "v1/employee/updateEducation";

export const EMPLOYEE_DOCUMENTS = "v1/employee/getDocs";
export const EMPLOYEE_DOCUMENTS_UPDATE = "v1/employee/updateDocs";

export const EMPLOYEE_EXPERIENCE = "v1/employee/getExperience";
export const EMPLOYEE_EXPERIENCE_UPDATE = "v1/employee/updateExperience";
export const EMPLOYEE_EXPERIENCE_ADD = "v1/employee/insertExperience";

export const EMPLOYEE_MESSANGER = "v1/employee/getMessangerInfo";
export const EMPLOYEE_MESSANGER_UPDATE = "v1/employee/updateMessangerInfo";
export const EMPLOYEE_MESSANGER_ADD = "v1/employee/insertExperience";

export const CUSTOMER_PROFILE_UPDATE = "v1/customer/updateProfile";
export const EMPLOEE_PROFILE_UPDATE = "v1/employee/updateProfile";

export const CUSTOMER_ACCOUNT_UPDATE = "v1/customer/updateAccount";
export const CUSTOMER_ACCOUNT_DELETE = "v1/customer/deleteAccount";
export const CUSTOMER_NOTIFICATION_UPDATE =
  "v1/customer/updateNotificationStatus";
export const CUSTOMER_PROFILE_PICTURE_UPDATE = "v1/customer/updateProfileImage";
export const EMPLOYEE_PROFILE_PICTURE_UPDATE = "v1/employee/updateProfileImage";

export const CUSTOMER_CREATE_POST = "v1/customer/createPost";
export const CUSTOMER_GET_ALL_POSTS = "v1/customer/getCustomerPosts";
export const ADD_COMMENTS = "v1/employee/addComment";
// export const CUSTOMER_ADD_COMMENTS = "v1/customer/addComment";
export const EMPLOYEE_GET_COMMENT_IN_POSTS_LISTS =
  "v1/customer/getAllComments/{custPostId}";
export const CUSTOMER_REPLY_TO_EMPLOYEE_COMMENT_POSTAPI = "v1/customer/replay/";
export const EMPLOYEE_REPLY_TO_CUSTOMER_POST = "v1/employee/replay/";
export const CUSTOMER_SUBSCRIPTION_BILLING_POSTAPI =
  "v1/customer/saveBillingInfo";
export const CUSTOMER_SUBSCRIPTION_DETAILS_GETAPI =
  "v1/customer/getBillingInfo";
export const CUSTOMER_SUBSCRIPTION_DETAILS_PUTAPI =
  "v1/customer/updateBillingInfo";
export const AVAILABLE_HELPERS_POSTS_LISTS = "v1/customer/getAvailableHelpers/";
export const AVAILABLE_JOBS_POSTS_LISTS = "v1/employee/getAvailableJobs/";
export const AVAILABLE_HELPER_EMPLOYEE_FULL_PROFILE =   "v1/customer/getCustomerAvailableHelperProfile/";
export const CUSTOMER_REQUEST_FULLVIEW_FOR_EMPLOYEE =   "v1/employee/getDetailJobInfo/";
export const EMPLOYEE_GET_ALL_POSTS = "v1/employee/getAllPosts";

export const EMPLOYEE_GET_PROFILE_COMMENT_REPLY = "v1/employee/getProfileImage";
export const CUSTOMER_GET_PROFILE_COMMENT_REPLY = "v1/customer/getProfileImage";
export const EMPLOYEE_GET_ALL_COMMENTS = "v1/employee/getAllComments";
export const CUSTOMER_GET_ALL_COMMENTS = "v1/customer/getAllComments";
export const EMPLOYEE_ADD_COMMENTS = "v1/employee/addComment";
export const CUSTOMER_ADD_COMMENTS = "v1/customer/addComment";
export const EMPLOYEE_REPLY = "v1/employee/replay";
export const CUSTOMER_REPLY = "v1/customer/replay";
export const EMPLOYEE_NOTIFICATION_CHAT = "v1/employee/getNotifications";
export const CUSTOMER_NOTIFICATION_CHAT = "v1/customer/getNotifications";

export const POST_CONTACT_US = "ContactUs/SaveContact";

export const GET_LATEST_JOINING = "homepage/getLatestJoinee";
export const GET_LATEST_JOB = "homepage/latestjob";


// customer chat section api url below added by renuka
export const CUSTOMER_GET_ALL_CHATS = "v1/chat/customer/getAllChats";
export const CUSTOMER_GET_SINGLE_FULL_VIEW_CHAT_WITH_EMPLOYEE = "v1/customer/getMessages";
export const CUSTOMER_SEND_MESSAGE_TO_EMPLOYEE = "v1/customer/sendMessage/";
export const CUSTOMER_START_CONVERSATION_WITH_EMPLOYEE = "v1/customer/chat/startConversion/";

// Employee chat section api url below added by renuka
export const EMPLOYEE_GET_ALL_CHATS = "v1/chat/employee/getAllChats";
export const EMPLOYEE_GET_SINGLE_FULL_VIEW_CHAT_WITH_CUSTOMER = "v1/employee/getMessages/";
export const EMPLOYEE_SEND_MESSAGE_TO_CUSTOMER = "v1/employee/sendMessage/";
export const EMPLOYEE_START_CONVERSATION_WITH_CUSTOMER = "v1/employee/chat/startConversion/";

// Notification section delete api url below added by renuka
export const CUSTOMER_DELETE_ALL_NOTIFICATIONS = "v1/customer/deleteNotifications";
export const CUSTOMER_DELETE_NOTIFICATION_BY_ID = "v1/customer/delete-notification-byid/";
export const EMPLOYEE_DELETE_ALL_NOTIFICATIONS = "v1/employee/deleteNotifications";
export const EMPLOYEE_DELETE_NOTIFICATION_BY_ID = "v1/employee/delete-notification-byid/";
export const SHOW_UNREAD_MESSAGES_AND_COMMENTS_COUNT_GET_API = "v1/customer/getUread-comments-message-count";

