import React from "react";
import "./Banner.css";

const Banner = ({ image, headerContent, descriptionContent }) => {
  return (
    <>
      <div className="banner-container">
        <div className="white-line-1"></div>
        <div className="white-line-2"></div>
        <div className="outer-circle-1">
          <div className="inner-circle">
            <h1 className="r-header">{headerContent}</h1>
            <h5 className="r-description">{descriptionContent}</h5>
          </div>
        </div>
        <div className="outer-circle-2">
          <div className="image-r-container">
            <img className="image-r" src={image} />   
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
