import React, { useState } from "react";
import "./EmployeeProfilePictureModal.css";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import camFeatureIcon from "../../../Assets/Images/profile-pic-upload.png";
import closeButton from "../../../Assets/Images/close-button.png";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_PROFILE_PICTURE_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";

const EmployeeProfilePictureModal = ({ setProfilePictureEmployee }) => {
  const [filePreviews, setFilePreviews] = useState([]);
  const [showDropzone, setShowDropzone] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const changeProfilePicture = () => {
    setProfilePictureEmployee(true);
  };

  //FUNCTIONALITY FOR BOTH CLOSE ICON & CANCEL BUTTON
  const handleClose = () => {
    setProfilePictureEmployee(false);
    setShowDropzone(true);
  };

  const onClickCloseImage = () => {
    setShowDropzone(true);
  };


  const [resizedPreviews, setResizedPreviews] = useState([]);

  const handleDrop = (acceptedFiles) => {
    // console.log("Accepted files:", acceptedFiles);

    const updatedPreviews = [];

    acceptedFiles.forEach((file) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (resizedFile) => {
          // console.log("Resized file:", resizedFile);
          const preview = URL.createObjectURL(resizedFile);
          updatedPreviews.push({ file: resizedFile, preview });

          if (updatedPreviews.length === acceptedFiles.length) {
            setResizedPreviews(updatedPreviews);
            setFilePreviews(updatedPreviews);
            setShowDropzone(false);
          }
        },
        "blob",
        200,
        100
      );
    });
  };

  const onClickHandleUpload = () => {
    const Token = localStorage.getItem("token");

    resizedPreviews.forEach((item) => {
      const resizedFile = item.file;
      const formData = new FormData();
      const blob = new Blob([resizedFile], { type: "image/jpeg" });
      formData.append("profileImage", blob, "filename.jpg");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Token}`);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
        body: formData,
      };
      // console.log("body", formData);

      fetch(`${BaseUrl}${EMPLOYEE_PROFILE_PICTURE_UPDATE}`, requestOptions)
        .then((result) => {
          if (result.status === 200) {
            // console.log("result", result);
            toast.info("Your profile picture will be updated");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 5000);
            setProfilePictureEmployee(false);
            setIsLoading(false);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    });
  };

  // Call onClickHandleUpload when the upload button is clicked
  const onClickUploadButton = () => {
    onClickHandleUpload();
  };

  return (
    <>
   <>
          <>
            <Modal
              show={changeProfilePicture}
              onHide={handleClose}
              className="profile-pic-change-modal custom-create-post-modal centered"
              contentClassName="modal-content-cp"
            >
              <Modal.Header className="header-cp">
                <Modal.Title className="custom-modal-title">
                  <div className="d-flex header-container">
                    <img
                      src={camFeatureIcon}
                      alt="camFeatureIcon"
                      className="ch-changepro-pic"
                    />
                    <div className="ch-changepro-pic-text">
                      <h3 className="ch-changepro-pic-text1">
                        Change your profile image
                      </h3>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-b-container">
                {showDropzone && (
                  <div className="modal-body-cp">
                    <Dropzone onDrop={handleDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="ch-profilechange-image-dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="text-dropzone-0">
                              <a href="#" className="text-dropzone-1">
                                Click to upload
                              </a>{" "}
                              or drag and drop
                            </p>
                            <p className="text-dropzone-2">
                              SVG, PNG, JPG (max. 300x300px)
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
                {!showDropzone && (
                  <div className="image-preview-container-main">
                    <img
                      src={closeButton}
                      className="close-b-ip"
                      onClick={onClickCloseImage}
                    />
                    {filePreviews.length > 0 && (
                      <div className="image-previews">
                        {filePreviews.map((preview, index) => (
                          <div key={index} className="image-preview-container">
                            <img
                              src={preview.preview}
                              alt={`Preview ${index + 1}`}
                              className="image-preview"
                            />
                            <span className="image-name">
                              {preview.file.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="modal-footer-container">
                <div className="footer-modal-cp">
                  <button className="modal-button-1" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
                <div className="footer-modal-cp">
                  <button
                    className="modal-button-2"
                    onClick={onClickUploadButton}
                  >
                    Upload
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        </>
    </>
  );
};
export default EmployeeProfilePictureModal;