import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneNumber from "../../PhoneNumber/PhoneNumber";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { EMPLOYEE_ACCOUNT_UPDATE } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { margin } from "@mui/system";
import { right } from "@popperjs/core";
import { useNavigate } from "react-router-dom";
import { show } from "dom7";

const EmployeeAccountEdit = ({ setShowEmployeeAccountEdit, prevData }) => {
  const [showPasswordEmployee, setShowPasswordEmployee] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, showPhoneNumberError] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const toggleShowPasswordE = () => {
    setShowPasswordEmployee(!showPasswordEmployee);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    setValue,
  } = useForm();

  const getPhoneNumber = (value, isValid) => {
    setPhoneNumber(value);
    setValidPhoneNumber(isValid);
  };

  const Token = localStorage.getItem("token");

  const onSubmit = (data) => {
    // console.log(data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      phoneNumber: phoneNumber,
      facebookAccount: data.facebookAccount,
      linkedinAccount: data.linkedinAccount,
      password: data.password,
    });
    // console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_ACCOUNT_UPDATE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        // console.log(result, "employee account data");
        if (result.statuscode === 200) {
          toast.info("Account is updated successfully");
          window.location.reload();
        window.scrollTo(0, 0);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {
  //   Object.entries(prevData).forEach(([name, value]) => {
  //     setValue(name, value || "");
  //   });
  // }, [prevData]);
  useEffect(() => {
    setValue("phoneNumber", prevData.phoneNumber);
    setValue("facebookAccount", prevData.facebookAccount || "");
    setValue("linkedinAccount", prevData.linkedinAccount || "");
    setValue("password", prevData.password);
    console.log("prevData", prevData);
    console.log("phoneNumber", prevData.phoneNumber);
    console.log("facebookAccount", prevData.facebookAccount);
    console.log("linkedinAccount", prevData.linkedinAccount);
    console.log("password", prevData.password);
  }, [prevData, setValue]);

  const onClickCancel = () => {
    setShowEmployeeAccountEdit(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container cs-profile-main">
      <div className="cp-profile-card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="cp-form-div">
            <h4 className="cp-title">Update Account</h4>
            <hr className="cp-hr-line mt-5"></hr>

            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Phone number</label>
              </div>{" "}
              <div className="profileeditinput-proedit">
               
                <PhoneNumber getPhoneNumber={getPhoneNumber} prevPhoneNumber={prevData.phoneNumber} />
                {phoneNumberError && !validPhoneNumber && !phoneNumber && (
                  <span className="error-msg">
                    Please enter your phone number
                  </span>
                )}
              </div>
            </div>

            <hr className="cp-hr-line"></hr>
            <h4 className="cp-title">Linked Accounts</h4>
            <div className="cp-form-label-input-proedit">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">
                  Facebook account
                </label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <input
                  className="cp-form-input"
                  type="text"
                  {...register("facebookAccount", {
                    required: true,
                    pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                  })}
                />
                {errors.facebookAccount &&
                  errors.facebookAccount.type === "required" && (
                    <p className="error-msg">Please enter your Facebook URL</p>
                  )}
                {errors.facebookAccount &&
                  errors.facebookAccount.type === "pattern" && (
                    <p className="error-msg">Please enter a valid URL</p>
                  )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input-proedit mt-3">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">
                  LinkedIn account
                </label>
              </div>{" "}
              <div className="profileeditinput-proedit">
                <input
                  className="cp-form-input"
                  type="text"
                  {...register("linkedinAccount", {
                    required: true,
                    pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                  })}
                />
                {errors.linkedinAccount &&
                  errors.linkedinAccount.type === "required" && (
                    <p className="error-msg">Please enter your LinkedIn URL</p>
                  )}
                {errors.linkedinAccount &&
                  errors.linkedinAccount.type === "pattern" && (
                    <p className="error-msg">Please enter a valid URL</p>
                  )}
              </div>
            </div>
            <hr className="cp-hr-line"></hr>
            <h4 className="cp-title">Security</h4>
            <div className="cp-form-label-input-proedit mt-3">
              <div className="cp-form-label-div-proedit">
                <label className="cp-form-label-proedit">Password</label>
              </div>{" "}
              <div
                className="profileeditinput-proedit"
                style={{ position: "relative" }}
              >
                <input
                  className="cp-form-input"
                  type={showPasswordEmployee ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    minLength: 8,
                    maxLength: 10,
                    pattern:
                      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).*$/i,
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/,
                  })}
                />
                {errors.password && errors.password.type === "required" && (
                  <span className="error-msg">Please enter a password</span>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <span className="error-msg">
                    Password must be at least 8 characters long
                  </span>
                )}
                {errors.password && errors.password.type === "maxLength" && (
                  <span className="error-msg">
                    Password should be 10 characters only
                  </span>
                )}
                {errors.password && errors.password.type === "pattern" && (
                  <span className="error-msg">
                    Password must contain at least one uppercase letter, one lowercase letter,  one
                    number and one special character
                  </span>
                )}
                {showPasswordEmployee ? (
                  <FaEye
                    onClick={toggleShowPasswordE}
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "17px",
                      color: "#667085",
                    }}
                  />
                ) : (
                  <FaEyeSlash
                    onClick={toggleShowPasswordE}
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "17px",
                      color: "#667085",
                    }}
                  />
                )}
              </div>
            </div>
            {/* <hr className="cp-hr-line"></hr>
            <div className="cp-form-label-input mt-3">
              <label className="cp-form-label">Two step authentication </label>
              <div className="profileeditinput">
                <button type="button" className="cp-edit-btn cp-save-btn">
                  Update
                </button>
              </div>
            </div> */}
            <hr className="cp-hr-line"></hr>
          </div>

          <div className="cp-cancel-btns-div pt-3">
            <button
              className="cp-edit-btn cp-cancel-btn"
              type="button"
              onClick={onClickCancel}
            >
              Cancel
            </button>
            <button className="cp-edit-btn cp-save-btn" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeAccountEdit;
