import React from "react";
import "./CustomerRequestsForm.css";
import { useForm, Controller } from "react-hook-form";
import TextEditor from "../../TextEditor/TextEditor";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { POST_CUSTOMER_REQUEST } from "../../../ApiEndPoints/ApiEndPoints";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import Country from "../../../Components/CountryInput/Country";
import State from "../../../Components/StateInput/State";
import City from "../../../Components/City/City";
import { IoIosArrowDown } from "react-icons/io";

const CustomerRequestsForm = () => {
  const numberArray = Array.from({ length: 83 }, (_, index) => index + 18);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm();

  const Token = localStorage.getItem("token");
  const selectedCountry = watch("country") ?? "N/A";
  const selectedState = watch("state") ?? "N/A";

  console.log("Selected Country:", selectedCountry);
  console.log("Selected State:", selectedState);
  const multiSelectCooking = [
    { value: "AB", label: "Alb" },
    { value: "Bri", label: "Br" },
    { value: "Man", label: "Ma" },
    { value: "New", label: "Ne" },
  ];
  const multiSelectLanguage = [
    { value: "AB", label: "Alb" },
    { value: "Bri", label: "Br" },
    { value: "Man", label: "Ma" },
    { value: "New", label: "Ne" },
  ];
  const multiSelectSkills = [
    { value: "AB", label: "Alb" },
    { value: "Bri", label: "Br" },
    { value: "Man", label: "Ma" },
    { value: "New", label: "Ne" },
  ];
  const multiSelectPersonality = [
    { value: "AB", label: "Alb" },
    { value: "Bri", label: "Br" },
    { value: "Man", label: "Ma" },
    { value: "New", label: "Ne" },
  ];

  const multiSelectWorkSchedule = [
    { value: "Flexible", label: "Flexible" },
    { value: "Remote", label: "Remote" },
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
  ];
  const onSubmit = (data) => {
    console.log("Data received:", data);
    if (Object.keys(errors).length > 0) {
      toast.error("All required fields should be entered.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);
    const salary = `${data.sal}-${data.Last_sal}`;

    var raw = JSON.stringify({
      customerReq: {
        position: data.position,
        workExperience: data.workExperience,
        workSchedule: data.workSchedule,
        salary: salary,
        nationality: data.country,
        country: data.country,
        state: data.state,
        city: data.city,
        maritalStatus: data.maritalStatus,
        religion: data.religion,
        age: data.age,
        educationalLevel: data.educationalLevel,
        cookingAbility: data.cookingAbility,
        language: data.language,
        skills: data.skills,
        personality: data.personality,
        expectations: data.expectations,
      },
    });

    console.log(raw, "raw");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${POST_CUSTOMER_REQUEST}`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        console.log("Response received:", response);
        console.log(response.status, "response.status");

        if (response.status === 401) {
          // Handle unauthorized access by redirecting to the login page
          navigate("/login");
          toast.error("Connection timed out, Please log in to access");

          return;
        } else if (response.status === 200 || response.status === 201) {
          toast.info("Requirements posted successfully");
          window.scrollTo(0, 0);
          window.location.reload();
          navigate("/customer-requests");

          // reset();
        } else if (response.status === 500) {
          // Handle server error (something went wrong)
          console.error("Server error: Something went wrong");
        }
      })

      .catch((error) => console.log("error", error));
  };

  const onClickCancel = () => {
    window.scrollTo(0, 0);
    reset();
  };

  return (
    <div className="cr-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="cr-form-heading">Customer Requests</p>
        <label className="cr-form-heading-tagline">Employee Requirements</label>
        <hr className="cr-form-hr-line" />

        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Position</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="cp-form-input"
              {...register("position", {
                required: true,
              })}
            >
              <option value=""> select</option>
              <option value="Maid"> Maid</option>
              <option value="Carer"> Carer</option>
              <option value="Nanny"> Nanny</option>
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />
            {errors.position && (
              <p className="error-msg">Please select the Position</p>
            )}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Work Experience</label>
          </div>
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="emp-form-input"
              {...register("workExperience", {
                required: true,
              })}
            >
              <option value=""> select </option>
              <option value="0-1 years"> 0-1 years</option>
              <option value="1-2 years"> 1-2 years</option>
              <option value="2-3 years"> 2-3 years</option>
              <option value="2-3 years"> 3-4 years</option>
              <option value="2-3 years"> 4-5 years</option>
              <option value="2-3 years"> 5-6 years</option>
              <option value="2-3 years"> 6-7 years</option>
              <option value="2-3 years"> 7-8 years</option>
              <option value="2-3 years"> 8-9 years</option>
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />

            {errors.workExperience && (
              <p className="error-msg">Please select the years</p>
            )}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Work Schedule</label>
          </div>{" "}
          <div className="profileeditinput div">
            <MultiSelectDropdown
              control={control}
              name="workSchedule"
              options={multiSelectWorkSchedule}
              rules={{ required: "Please select at least one option" }}
              className="cp-form-creq-multi-select-dropdown"
            />
            {/* {errors.workSchedule && (
              <p className="error-msg">Please select at least one option</p>
            )} */}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Salary</label>
          </div>
          <div className="profileeditinput-cust-req-sal">
            <div className="namefields">
              <select
                className="emp-form-input"
                {...register("sal", {
                  required: true,
                })}
              >
                {[...Array(20)].map((_, index) => {
                  const value = (index + 1) * 100;
                  return (
                    <option key={value} value={`$ ${value}`}>
                      $ {value}
                    </option>
                  );
                })}
              </select>

              <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />

              {errors.sal && (
                <p className="error-msg">Please Select the salary</p>
              )}
            </div>
            <span className="cust-req-form-sal-hiphen">-</span>
            <div className="namefields">
              <select
                className="emp-form-input"
                {...register("Last_sal", {
                  required: true,
                })}
              >
                {[...Array(21)].map((_, index) => {
                  const value = (index + 20) * 100;
                  return (
                    <option key={value} value={`$ ${value}`}>
                      $ {value}
                    </option>
                  );
                })}
              </select>
              <IoIosArrowDown
                size={60}
                className="cu-req-select-drop-down-down-arrow"
              />

              {errors.Last_sal && (
                <p className="error-msg">Please Select the salary</p>
              )}
            </div>
          </div>
        </div>

        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Country</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <Country
              label="false"
              className="cp-form-input"
              register={register("country", {
                required: true,
              })}
            />

            {errors.country && (
              <p className="error-msg">Please select the country</p>
            )}
          </div>
        </div>

        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">State</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <State
              label="false"
              showLabel={false}
              register={register("state", {
                required: true,
              })}
              selectedCountry={selectedCountry}
            />
            {errors.state && (
              <span className="error-msg">Please select your State</span>
            )}
          </div>
        </div>
        <hr className="cp-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">City</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <City
              showLabel={false}
              register={register("city", {
                required: true,
              })}
              selectedCountry={selectedCountry}
              selectedState={selectedState}
            />
            {errors.city && (
              <span className="error-msg">Please select your City</span>
            )}
          </div>
        </div>
        <hr className="cp-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Marital status</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="emp-form-input"
              {...register("maritalStatus", {
                required: true,
              })}
            >
              <option value=""> select</option>
              <option value="Single"> Single</option>
              <option value="Married"> Married</option>
              <option value="Widowed "> Widowed </option>
              <option value="Separated"> Separated</option>
              <option value="Divorced "> Divorced</option>
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />

            {errors.maritalStatus && (
              <p className="error-msg">Please select the martial status</p>
            )}
          </div>
        </div>
        <hr className="cp-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Religion</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="emp-form-input"
              {...register("religion", {
                required: true,
              })}
            >
              <option value=""> select</option>
              <option value="Buddhism"> Buddhism</option>
              <option value="Islam"> Islam</option>
              <option value="Christianity"> Christianity</option>
              <option value="Hinduism"> Hinduism</option>
              <option value="Sikhism"> Sikhism</option>
              <option value="Confucianism"> Confucianism</option>
              <option value="Other religions"> Other religions</option>
              <option value="Not religious"> Not religious</option>
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />

            {errors.religion && (
              <p className="error-msg">Please select the religion</p>
            )}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Age</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="emp-form-input"
              {...register("age", {
                required: true,
              })}
            >
              <option value=""> select</option>
              {numberArray.map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />

            {errors.age && <p className="error-msg">Please select the age</p>}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Education level</label>
          </div>{" "}
          <div className="profileeditinput-proedit-cu-req">
            <select
              className="emp-form-input"
              {...register("educationalLevel", {
                required: true,
              })}
            >
              <option value=""> select</option>
              <option value="10"> 10+</option>
              <option value="12"> 12+</option>
              <option value="ug"> Under Graduate</option>
              <option value="pg"> Post Graduate</option>
            </select>
            <IoIosArrowDown className="cu-req-select-drop-down-down-arrow" />
            {errors.educationalLevel && (
              <p className="error-msg">Please select the Education level</p>
            )}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Cooking ability</label>
          </div>{" "}
          <div className="profileeditinput div">
            <MultiSelectDropdown
              control={control}
              name="cookingAbility"
              options={multiSelectCooking}
              rules={{ required: "Please select at least one option" }}
            />
            {/* {errors.cookingAbility && (
              <p className="error-msg">Please select at least one option</p>
            )} */}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Language</label>
          </div>{" "}
          <div className="profileeditinput div">
            <MultiSelectDropdown
              control={control}
              name="language"
              options={multiSelectLanguage}
              rules={{ required: "Please select at least one option" }}
            />
            {/* {errors.language && (
              <p className="error-msg">Please select at least one option</p>
            )} */}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Skills</label>
          </div>{" "}
          <div className="profileeditinput div">
            <MultiSelectDropdown
              control={control}
              name="skills"
              options={multiSelectSkills}
              rules={{ required: "Please select at least one option" }}
            />
            {/* {errors.skills && (
              <p className="error-msg">Please select at least one option</p>
            )} */}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>
        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Personality</label>
          </div>{" "}
          <div className="profileeditinput div">
            <MultiSelectDropdown
              control={control}
              name="personality"
              options={multiSelectPersonality}
              rules={{ required: "Please select at least one option" }}
            />
            {/* {errors.personality && (
              <p className="error-msg">Please select at least one option</p>
            )} */}
          </div>
        </div>
        <hr className="cr-form-hr-line"></hr>

        <div className="cp-form-label-input-proedit">
          <div className="cp-form-label-div-proedit-cu-req">
            <label className="cp-form-label-proedit">Expectation</label>{" "}
            <p>Write a short Description.</p>
          </div>
          <div className="profileeditinput-cu-req-text-editor">
            <Controller
              name="expectations"
              control={control}
              rules={{ required: true, maxLength: 250 }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <TextEditor
                    data={value}
                    onBlur={onBlur}
                    onChange={(event, editor) => onChange(editor.getData())}
                    errorLabel={false}
                  />
                  {errors.expectations && (
                    <span className="error-msg">
                      Please enter the Expectation
                    </span>
                  )}
                  {errors.expectations &&
                    errors.expectations.type === "maxLength" && (
                      <span className="error-msg">
                        Character limit exceeded! Maximum length is 250
                        characters.
                      </span>
                    )}
                </>
              )}
            />
          </div>
        </div>
        <div className="emp-cancel-btns-div pt-3">
          <button
            className="emp-edit-btn cp-cancel-btn cu-req-cancel-btn"
            type="button"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button className="emp-edit-btn cp-save-btn" type="submit">
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomerRequestsForm;
