import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ProfileTopbarEmployee from "../../../Components/TopBar/ProfileTopbarEmployee";
import MobileTopBarEmployee from "../../../Components/MobileTopBar/MobileTopBarEmployee";
import HHLogo from "../../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import MobileNavBarEmployee from "../../../Components/MobileNavBar/MobileNavBarEmployee";
import Footer from "../../../Components/Footer/Footer";
import AvailableJobsSideBar from "../../../Components/Employee/AvailableJobsComponents/AvailableJobsSideBar/AvailableJobsSideBar";
import { BsFilter } from "react-icons/bs";
import { RiArrowDropDownFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

const AvailableJobsLayout = ({
  children,
  handleApplyFilters,
  filters,
  setFilters,
  handleSortChange,
  sortBy,
  setSortBy,
  totalRequests,
  dataLength,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showFiltersMobview, setShowFiltersMobview] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const initialFilters = {
    position: "",
    workSchedule: [], // Initialize workSchedule as an empty array
    minAge: "",
    maxAge: "",
    minSalary: "",
    setSortBy: "",
    maxSalary: "",
    country: "",
    state: "",
    city: "",
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const onClickCancel = () => {
    setFilters((prevFilters) => ({ ...prevFilters, position: "" }));
    window.scrollTo(0, 0);
  };
  const handleToggleFiltersMobView = () => {
    setShowFiltersMobview(!showFiltersMobview); // Toggle the state when "Filters" is clicked
  };
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <Grid>
        <div className="d-none d-md-block">
          <ProfileTopbarEmployee />
        </div>
        <div className="d-block d-sm-none">
          <MobileTopBarEmployee />
        </div>
      </Grid>
      {/* Background color wrapper */}
      <div style={{ backgroundColor: "#F7FAFB" }}>
        <div
          className="container profile-layout-padding-class"
          style={{ paddingTop: "5.5rem" }}
        >
          {/*below div is for jobpostlist posted by customer employee side */}
          <div className="ahlh-div">
            <p className="ahlh-text">Tagline</p>
            <h2 className="ahlh-heading">Find your perfect job </h2>
            <p className="ahlh-sub-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.{" "}
            </p>
            <div className="ahlh-filter-mobile-view">
              <div className="ahlh-filter-sort-buttons-div">
                <div className="ahlh-filter-button-div">
                  {" "}
                  <button onClick={handleToggleFiltersMobView}>
                    {" "}
                    <BsFilter color={"#04AFF0"} size={24} /> Filters{" "}
                  </button>
                </div>
                <div className="ahlh-sort-button-div">
                  <div className="ahsb-sort-by-div ">
                    <select
                      value={sortBy}
                      onChange={handleSortChange}
                      aria-label="Sort By"
                      className="ahsb-sb-select-btn-sortby"
                    >
                      <option
                        value=""
                        className="ahsb-sb-option ahsb-sb-option-sort-by"
                      >
                        Sort By
                      </option>
                      <option
                        value="lesstomoreexp"
                        className="ahsb-sb-option ahsb-sb-option-sort-by"
                      >
                        Less to more experience
                      </option>
                      <option
                        value="moretolessexp"
                        className="ahsb-sb-option ahsb-sb-option-sort-by"
                      >
                        More to less experience{" "}
                      </option>{" "}
                      <option
                        value="lesstomoresal"
                        className="ahsb-sb-option ahsb-sb-option-sort-by"
                      >
                        Less to more salary
                      </option>
                      <option
                        value="moretolesssal"
                        className="ahsb-sb-option ahsb-sb-option-sort-by"
                      >
                        More to less salary
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {showFiltersMobview && (
                <div className="mt-3">
                  {" "}
                  <AvailableJobsSideBar
                    handleApplyFilters={handleApplyFilters}
                    filters={initialFilters}
                    onClickCancel={onClickCancel}
                    setFilters={setFilters}
                    handleSortChange={handleSortChange}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    totalRequests={totalRequests}
                    dataLength={dataLength}
                  />{" "}
                </div>
              )}
            </div>
          </div>
          <Grid container spacing={6} className="">
            <Grid item xs={12} md={3} lg={3}>
              <div className="d-none d-lg-block">
                <AvailableJobsSideBar
                  handleApplyFilters={handleApplyFilters}
                  filters={initialFilters}
                  onClickCancel={onClickCancel}
                  setFilters={setFilters}
                  handleSortChange={handleSortChange}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  totalRequests={totalRequests}
                  dataLength={dataLength}
                />{" "}
                <div className="ahsb-terms-cond-div">
                  <div className="psb-about-div psb-about-div-ahsb pt-4">
                    {" "}
                    <p onClick={() => handleClickNavigate("/about-us")}>
                      About
                    </p>
                    <p>Pricing</p>
                    <p onClick={() => handleClickNavigate("/contact-us")}>
                      Help Center
                    </p>
                  </div>
                  <p
                    className="psb-terms pt-4 pb-4"
                    onClick={() => handleClickNavigate("/termsofservice")}
                  >
                    Terms & Privacy{" "}
                    <RiArrowDropDownFill
                      color={"#7B7B7B"}
                      size={35}
                      style={{ marginTop: "-3px" }}
                    />
                  </p>
                </div>
              </div>
            </Grid>
            {isLoading ? (
              <div className="hhloader-div">
                <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
                <Typewriter
                  options={{
                    strings: ["Loading..."],
                    autoStart: true,
                    loop: true,
                    delay: 30,
                  }}
                  className="loading-text-in-loader"
                />
              </div>
            ) : (
              <Grid item xs={12} md={9} lg={9}>
                {children}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <Grid>
        <div className="d-none d-md-block">
          <Footer />
        </div>
        <div className="d-block d-sm-none">
          <MobileNavBarEmployee />
        </div>{" "}
      </Grid>
    </>
  );
};

export default AvailableJobsLayout;
