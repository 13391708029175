import React, { useState, useEffect } from "react";
import "./MobileSideBar.css";
import { useLocation, useNavigate } from "react-router-dom";
import camera from "../../Assets/Svg/camera.svg";
// import empfake from "../../Assets/Svg/empfake.svg";
import crossicon from "../../Assets/Svg/crossicon.svg";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillFileTextFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { AiFillGift } from "react-icons/ai";
import { GoSignOut } from "react-icons/go";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { EMPLOYEE_PROFILE } from "../../ApiEndPoints/ApiEndPoints";
import EmployeeProfilePictureModal from "../../Modal/ProfilePicture/Employee/EmployeeProfilePictureModal";
import EmployeeSignOut from "../../Modal/SignOut/Employee/EmployeeSignOut";
import { RiArrowDropDownFill } from "react-icons/ri";
import { toast } from "react-toastify";

const MobileSideBarEmployee = () => {
  const [data, setData] = useState({});
  const [profilePictureEmployee, setProfilePictureEmployee] = useState(false); //for profile picture change popup
  const [showEmployeeSignout, setShowEmployeeSignout] = useState(false); //for signout popup

  const [showPopUp, setShowPopUp] = useState(false);

  const handleClicktoggle = () => {
    setShowPopUp(!showPopUp);
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };

  const Token = localStorage.getItem("token");

  //get api for employee profile
  useEffect(() => {
    window.scrollTo(0, 0);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${EMPLOYEE_PROFILE}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // Handle unauthorized access by redirecting to the login page
        navigate("/login");
        toast.error("Connection timed out, Please log in to access");
        return;
      } else if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        // Handle server error (something went wrong)
        console.error("Server error: Something went wrong");
        
      } else {
       
        console.error("Unknown error: Status code", response.status);
        
      }
    })
      .then((result) => {
        console.log(result, "result");
        setData(result.employeeData);
      })
      .catch((error) => console.log("error", error));
  }, []);

  //profile picture popup
  const onClickCamera = () => {
    setProfilePictureEmployee(true);
  };
  const onClickSignOutEmployee = () => {
    setShowEmployeeSignout(true);
  };

  return (
    <>
      {!showPopUp && (
        <div className="mobile-Sidebar-popup">
          <div className="Employee-Mobile-card">
            <div className="cross-icon-div">
              <img
                src={crossicon}
                alt=""
                className="cross-icon-img"
                onClick={handleClicktoggle}
              />
            </div>
            <div></div>
            <div className="Employee-text-div">
              <p className="Employee-text">Employee</p>
            </div>
            <div className="Employee-img-div">
              <img
                src={data.profileImageUrl}
                alt=""
                className="Employee-profile-img"
              />
              <div className="name-area">
                <p className="Employee-name">
                  {data.firstName} {data.lastName}
                </p>

                <div className="image-area">
                  <img
                    src={camera}
                    alt=""
                    className="Employee-camera-img"
                    onClick={onClickCamera}
                  />
                  <p className="img-text">Change Image</p>
                </div>
              </div>
            </div>
            <div
              className="Employee-mob-button-div pt-4"
              style={{ padding: "18px" }}
            >
              <div
                onClick={() => handleClickNavigate("/employee-profile")}
                className={`${
                  pathname === "/employee-profile"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <BsFillPersonFill />
                <p className="px-2">Profile</p>
              </div>
              <div
                onClick={() => handleClickNavigate("/employee-account")}
                className={`${
                  pathname === "/employee-account"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <IoMdSettings />
                <p className="px-2">Account</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/employee-messanger")}
                className={`${
                  pathname === "/employee-messanger"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <IoIosChatbubbles />
                <p className="px-2">Messenger</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/employee-documents")}
                className={`${
                  pathname === "/employee-documents"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <BsFillFileTextFill />
                <p className="px-2">Documents</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/employee-qualification")}
                className={`${
                  pathname === "/employee-qualification"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <FaStar />
                <p className="px-2">Qualifications</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/employee-experience")}
                className={`${
                  pathname === "/employee-experience"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <FaBriefcase />
                <p className="px-2">Experience</p>
              </div>

              <div
                onClick={() => handleClickNavigate("/employee-education")}
                className={`${
                  pathname === "/employee-education"
                    ? "psb-btn-active mt-3"
                    : "psb-btn mt-3"
                }`}
              >
                <FaGraduationCap />
                <p className="px-2">Education</p>
              </div>

              <div className="Employee-psb-btn mt-3">
                <AiFillGift />
                <p className="px-2">Invite friends</p>
              </div>

              <div className="Employee-psb-btn mt-3">
                <GoSignOut />
                <p className="px-2" onClick={onClickSignOutEmployee}>
                  Sign out{" "}
                </p>
              </div>
              <div className="Employee-psb-about-div mt-3">
                <p>About</p>
                <p>Pricing</p>
                <p>Help Center</p>
              </div>
              <p className="Employee-psb-terms pt-4 pb-4">Terms & Privacy <RiArrowDropDownFill color={"#7B7B7B"} size={35} style={{ marginTop: "-3px" }} /></p>
            </div>
          </div>
        </div>
      )}
      {profilePictureEmployee && (
        <EmployeeProfilePictureModal
          setProfilePictureEmployee={setProfilePictureEmployee}
        />
      )}
      {showEmployeeSignout && (
        <EmployeeSignOut setShowEmployeeSignout={setShowEmployeeSignout} />
      )}
    </>
  );
};
export default MobileSideBarEmployee;
