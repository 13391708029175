import React, { useEffect, useState } from "react";
import "./State.css";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { STATE_LIST } from "../../ApiEndPoints/ApiEndPoints";

const State = ({ register, selectedCountry, showLabel = true , customWidth}) => {
  const [data, setData] = useState([]);
  // console.log(selectedCountry , "selectedCountry")

  useEffect(() => {
    if (selectedCountry) { // Check if selectedCountry is not null or undefined
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${BaseUrl}${STATE_LIST}${selectedCountry}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setData(result.states);
          // console.log(result);
        })
        .catch((error) => console.log("error", error));
    }
  }, [selectedCountry]);
  return (
    <>
      {showLabel && (
        <label className="label-registration">
          State <span className="error-symbol">*</span>
        </label>
      )}
      <div>
        <select type="text" className="select-box-state" style={{width: customWidth}}  {...register}>
          <option value="">Select the State</option>
          {data &&
            data.map((e) => {
              return <option value={e}>{e}</option>;
            })}
        </select>
      </div>
    </>
  );
};
export default State;
